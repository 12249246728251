import queryString from 'query-string';

import { AssetCategoryCode } from 'pages/account/trading/layouts/trading.hook';

import { userAuthenticatedApi } from '../fetcher';

export const fetchTradeRequests = async (data: {
  pairId: string;
  accountAddress: string;
  searchByOrderId?: string;
  status?: string;
  assetCategory?: AssetCategoryCode;
}) => {
  const qs = queryString.stringify(data, { arrayFormat: 'bracket', skipEmptyString: true, skipNull: true });
  const endpoint = `/oms/trade/trade-requests?${qs}`;
  const userService = await userAuthenticatedApi();

  const res = userService.url(endpoint).get();
  return res.json();
};
