import { userAuthenticatedApi } from 'data-provider/fetcher';

import { TokenPriceResponse } from './fetchTokenPrice';

type EntityTokenPricesResponse = TokenPriceResponse[];

export const fetchEntityTokenPrices = async (type: 'BUY' | 'SELL') => {
  const endpoint = '/oms/trade/entity-token-prices';
  const userService = await userAuthenticatedApi();
  const res = userService.url(endpoint).query({ type }).get();
  return res.json<EntityTokenPricesResponse>();
};
