import { MarketFlags } from '@aircarbon/utils-common/src/dto';

import { userAuthenticatedApi } from '../fetcher';

export const fetchMarketSettings = async (includeMarketStatus: boolean) => {
  const endpoint = '/oms/order/market-settings';
  const userService = await userAuthenticatedApi();
  const res = userService
    .url(endpoint)
    .query({ includeMarketStatus: includeMarketStatus ? 'yes' : 'no' })
    .get();
  return res.json<MarketFlags>();
};
