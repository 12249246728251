export const enum ProjectStatus {
  Draft,
  Rejected,
  Submitted,
  Active,
}

export enum ApxProjectStatusCode {
  Draft = 'draft',
  Submitted = 'submitted',
  Rejected = 'rejected',
  Active = 'active',
}
