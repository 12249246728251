import { FilterSelections, Filters as SelectionFilters } from 'refreshed-component/molecules/Filter';

import { Filters } from './Filters';

type ToFiltersProps = {
  filters: Filters;
  selections?: FilterSelections<SelectionFilters>;
  shouldMerge?: boolean;
};

export const toFilters = (props: ToFiltersProps): Filters => {
  let newFilters: Filters = {};

  if (props.shouldMerge) {
    newFilters = {
      ...props.filters,
    };
    if (props.selections?.projectType === undefined) {
      delete newFilters.projectTypes;
    }

    if (props.selections?.ccbStandard === undefined) {
      delete newFilters.ccbStandards;
    }

    if (props.selections?.sectoralScope === undefined) {
      delete newFilters.sectoralScopes;
    }

    if (
      (
        props.selections?.vintageYear?.selection as {
          from?: number;
          to?: number;
        }
      )?.from === undefined &&
      newFilters?.vintageYear?.from !== undefined
    ) {
      delete newFilters?.vintageYear?.from;
    }

    if (
      (
        props.selections?.vintageYear?.selection as {
          from?: number;
          to?: number;
        }
      )?.to === undefined &&
      newFilters?.vintageYear?.to !== undefined
    ) {
      delete newFilters?.vintageYear?.to;
    }
  }

  if ((props.selections?.projectType?.selection as unknown as Array<number>)?.length) {
    newFilters = {
      ...newFilters,
      projectTypes: props.selections?.projectType?.selection as unknown as Array<number>,
    };
  }

  if ((props.selections?.ccbStandard?.selection as unknown as Array<number>)?.length) {
    newFilters = {
      ...newFilters,
      ccbStandards: props.selections?.ccbStandard?.selection as unknown as Array<number>,
    };
  }

  if ((props.selections?.sectoralScope?.selection as unknown as Array<number>)?.length) {
    newFilters = {
      ...newFilters,
      sectoralScopes: props.selections?.sectoralScope?.selection as unknown as Array<number>,
    };
  }

  if (
    (
      props.selections?.vintageYear?.selection as {
        from?: number;
        to?: number;
      }
    )?.from !== undefined
  ) {
    newFilters = {
      ...newFilters,
      vintageYear: {
        ...newFilters.vintageYear,
        from: (
          props.selections?.vintageYear?.selection as {
            from?: number;
            to?: number;
          }
        )?.from,
      },
    };
  }

  if (
    (
      props.selections?.vintageYear?.selection as {
        from?: number;
        to?: number;
      }
    )?.to !== undefined
  ) {
    newFilters = {
      ...newFilters,
      vintageYear: {
        ...newFilters.vintageYear,
        to: (
          props.selections?.vintageYear?.selection as {
            from?: number;
            to?: number;
          }
        )?.to,
      },
    };
  }

  return newFilters;
};
