import { MouseEventHandler, ReactNode } from 'react';
import { Colors, FontSize, FontWeight, Radius, Spacing } from 'refreshed-component/design-system';
import styled from 'styled-components';

type BaseProps = {
  onClick?: MouseEventHandler<HTMLDivElement>;
  color?: Colors;
  size?: FontSize | null;
  weight?: FontWeight | null;
  lineHeight?: number;
  radius?: Radius | 'no-radius';
  prefix?: {
    view: ReactNode;
  };
  postfix?: {
    view: ReactNode;
  };
  className?: string;
  align?: 'left' | 'center' | 'right';
  background?: Colors;
  spacing?: Spacing;
  spacingLR?: Spacing;
  spacingTB?: Spacing;
  display?: 'flex' | 'block';
};

export type TextProps = { children?: ReactNode } & BaseProps;

const TextRoot = styled.div<{
  color: Colors;
  fontSize?: FontSize;
  fontWeight?: FontWeight;
  background?: Colors;
  spacingLR?: Spacing;
  spacingTB?: Spacing;
  radius?: Radius | 'no-radius';
  align?: 'left' | 'center' | 'right';
  display?: 'flex' | 'block';
  lineHeight?: number;
}>`
  color: ${({ color }) => `var(${color})`};
  ${({ fontWeight }) => (!fontWeight ? `` : ` font-weight: var(${fontWeight});`)}
  ${({ fontSize }) => (!fontSize ? `` : `  font-size: var(${fontSize});`)}
  ${({ background }) => (!background ? `` : `  background: var(${background});`)}
  ${({ spacingLR }) => (!spacingLR ? `` : `padding-left: var(${spacingLR});padding-right: var(${spacingLR});`)}
  ${({ spacingTB }) => (!spacingTB ? `` : `padding-top: var(${spacingTB});padding-bottom: var(${spacingTB});`)}
  ${({ lineHeight }) => (!lineHeight ? `line-height: inherit;` : `line-height: ${lineHeight}px;`)}
  

  ${({ display, align }) =>
    display === 'flex'
      ? `display: flex;
  flex-direction: row;
  gap: var(${Spacing._2xs});
  justify-content: ${align};
  align-items: center;`
      : `display: block;`}
  text-align: ${({ align }) => align};
  ${({ radius }) => (radius !== 'no-radius' ? `border-radius: var(${radius});` : '')}
  svg {
    path[fill] {
      fill: ${({ color }) => `var(${color})`};
    }
    path[stroke] {
      stroke: ${({ color }) => `var(${color})`};
    }
  }
`;

export const Text: React.FC<TextProps> = ({
  children,
  className,
  color,
  size,
  weight = FontWeight.default,
  align = 'left',
  postfix,
  prefix,
  onClick,
  background,
  spacingLR,
  spacingTB,
  spacing,
  radius,
  display,
  lineHeight,
}) => {
  return (
    <TextRoot
      onClick={onClick}
      align={align}
      color={color || Colors.gray_900}
      fontWeight={weight === null ? undefined : weight || FontWeight.default}
      fontSize={size === null ? undefined : size || FontSize.base}
      className={className}
      background={background}
      spacingLR={spacingLR || spacing}
      spacingTB={spacingTB || spacing}
      radius={radius || Radius.medium}
      display={display || 'flex'}
      lineHeight={lineHeight}
    >
      {prefix?.view} {children} {postfix?.view}
    </TextRoot>
  );
};
