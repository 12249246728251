import { isEqual } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { toast } from 'refreshed-component/molecules/toast';

import { helpers, hooks, logger } from '@aircarbon/utils-common';

import { userAuthenticatedApi } from 'data-provider/fetcher';

interface UseProjectsProps {
  assetCategoryId: string;
}

export const useProjectsFromUrlQuery = (props: UseProjectsProps) => {
  const { assetCategoryId } = props;
  const { search: queryParams } = useLocation();

  const [projects, setProjects] = useState<
    Array<{
      id: number;
      label: string;
    }>
  >([]);
  const [isLoading, setIsLoading] = useState(false);

  const projectIds = useMemo(() => {
    const urlParams = new URLSearchParams(queryParams);

    return urlParams.get('projectIds')?.split(',');
  }, [queryParams]);

  const prevProjectIds = hooks.usePrevious(projectIds as any);

  useEffect(() => {
    const fetchProjects = async () => {
      setIsLoading(true);
      try {
        const queryParams: Record<string, string | Array<string>> = {
          assetCategoryId,
        };

        if (projectIds?.length) {
          queryParams.ids = projectIds;
        }

        const urlSearchParams = helpers.objectToQueryString(queryParams);
        const userService = await userAuthenticatedApi();
        const request = await userService.url(`/user/carbon/project?${urlSearchParams.toString()}`).get();

        const response = (await request.json()) as any;

        setProjects(
          response.items.map((item: { name: string; id: number }) => {
            return {
              label: item.name,
              id: item.id,
            };
          }),
        );
      } catch (e) {
        logger.error('Failed to fetch projects for filters!', e);
        toast.error('Something went wrong');
      } finally {
        setIsLoading(false);
      }
    };

    if (isEqual(projectIds, prevProjectIds)) {
      return;
    }

    fetchProjects();
  }, [projectIds, prevProjectIds, assetCategoryId]);

  return {
    projects,
    setProjects,
    isLoading,
  };
};
