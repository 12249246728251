import * as React from 'react';
import ContentLoader from 'react-content-loader';
import { Dropdown, DropdownItem } from 'refreshed-component/atoms/Dropdown';
import { Colors } from 'refreshed-component/design-system';
import styled from 'styled-components';

import { Account } from 'state/account';

export interface AccountDetails {
  userId: number;
  account: string;
  fullName: string;
}

interface Props {
  name: string;
  inputValue: string;
  defaultOption?: Array<DropdownItem>;
  className?: string;
  setFieldValue(field: string, value: { value: string; user_id: number; fullName: string }): void;
}

const SelectOboAccount: React.FC<Props> = ({ name, inputValue, setFieldValue, defaultOption = [], className }) => {
  const { memberOboAccounts, isLoadingLinkedUsers } = Account.useContainer();

  if (isLoadingLinkedUsers) {
    return (
      <ContentLoader
        speed={2}
        width={400}
        height={60}
        viewBox="0 0 400 60"
        backgroundColor={`var(${Colors.gray_100})`}
        foregroundColor={`var(${Colors.gray_300})`}
      >
        <rect x="51" y="0" rx="3" ry="3" width="400" height="37" />
        <circle cx="20" cy="20" r="20" />
      </ContentLoader>
    );
  }

  const options = [
    ...defaultOption,
    ...memberOboAccounts?.map(({ account, fullName, user_id, user_name, email, account_type }) => ({
      account_type,
      user_name,
      user_id,
      email,
      id: account,
      fullName,
      label: fullName,
    })),
  ];

  const onSearch = (searchValue: string) => {
    if (!searchValue) {
      return options;
    }

    return options.filter((option) => (option.label as string).toLowerCase().includes(searchValue.toLowerCase()));
  };

  const onChangeOboAccount = (selectedItem: DropdownItem) => {
    setFieldValue(name, {
      value: selectedItem.id as string,
      user_id: (selectedItem as unknown as { user_id: number }).user_id,
      fullName: (selectedItem as unknown as { fullName: string }).fullName,
    });
  };

  return (
    <StyledDropdown
      config={{
        color: 'gray',
        size: 'sm',
        shouldStayWithinMaxWidth: true,
      }}
      className={className}
      selected={[inputValue]}
      onSearch={onSearch}
      onSelectItem={onChangeOboAccount}
      list={options}
    />
  );
};

export default SelectOboAccount;

const StyledDropdown = styled(Dropdown)`
  width: 100%;
`;
