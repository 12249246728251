import { yupResolver } from '@hookform/resolvers/yup';
import * as React from 'react';
import { useForm } from 'react-hook-form';
import { Button } from 'refreshed-component/atoms/Button';
import { Dropdown } from 'refreshed-component/atoms/Dropdown';
import { Icon, IconType } from 'refreshed-component/atoms/Icon';
import { Input } from 'refreshed-component/atoms/Input';
import { Text } from 'refreshed-component/atoms/Text';
import { Tooltip } from 'refreshed-component/atoms/Tooltip';
import { Colors, FontSize, FontWeight } from 'refreshed-component/design-system/enums';
import Loading from 'refreshed-component/molecules/Loading';
import { ModalContent, ModalFooter } from 'refreshed-component/molecules/Modal';
import * as yup from 'yup';

import { formatter } from '@aircarbon/utils-common';

import useMarketSettings from 'pages/account/trading/hooks/useMarketSettings';

import FormDevTool from 'components/FormDevTool';

import { Entity } from 'state/entity';
import { User } from 'state/user';

import useAccountBalance from 'hooks/useAccountBalance';

import { convertTextNumberToValue } from 'utils/helpers';

interface Props {
  onSubmit: (
    values: Record<string, any>,
    handlers?: {
      onSuccess?: () => void;
    },
  ) => void;
  accountReference: string;
}

type FormData = {
  ccyTypeId: string;
  amount: number;
};

const schema = yup.object().shape({
  amount: yup
    .number()
    .required()
    .transform(function (value, originalValue) {
      if (this.isType(value)) return value;
      return convertTextNumberToValue(originalValue);
    })
    .min(1, `amount must be greater than 0`),
});

const FiatDepositForm: React.FC<Props> = ({ onSubmit, accountReference }: Props) => {
  const [saving, setSaving] = React.useState(false);
  const {
    entity,
    selector: { availableCurrencies, mainCcyScId, mainCcySymbol, mainCcyCode, mainCcyNumDecimals },
  } = Entity.useContainer();
  const { rootUser } = User.useContainer();

  const { marketSettings, isLoading: isLoadingMarketSettings } = useMarketSettings({});

  const { availableAmount, refetchBalance } = useAccountBalance({
    getRootBalance: 'yes',
    ccyTypeId: mainCcyScId,
    ccySymbol: mainCcySymbol,
    queryConfig: {
      refetchInterval: 3000,
    },
  });

  const {
    handleSubmit,
    reset,
    control,
    formState: { errors },
    getValues,
    setValue,
    watch,
  } = useForm<FormData>({
    resolver: yupResolver(schema) as any,
    defaultValues: {
      ccyTypeId: String(mainCcyScId),
    },
  });

  const onSubmitHandler = (data: FormData, evt: any) => {
    setSaving(true);
    const amount = Number(String(data?.amount ?? 0).replace(/,/g, ''));
    const depositData = {
      ...data,
      amount,
    };
    onSubmit(depositData, {
      onSuccess: () => {
        reset();
        setSaving(false);
        refetchBalance();
      },
    });
  };

  watch();

  const { bank: entityBankAccount } = JSON.parse(entity?.bankAccount ?? '{}');

  if (isLoadingMarketSettings) return <Loading />;

  return (
    <form onSubmit={handleSubmit(onSubmitHandler)}>
      <ModalContent>
        <FormDevTool control={control} />
        <div className="flex flex-wrap gap-large">
          <div className="flex flex-col w-full rounded-md bg-gray_100 p-large">
            <div className="flex flex-col gap-xs">
              <div className="flex gap-1 items-center text-gray_500">
                <Text size={FontSize.large} color={Colors.gray_500}>
                  Consolidated Balance ({mainCcySymbol})
                </Text>
                <Tooltip text="This balance reflects the total of all your current account balances." />
              </div>
              <Text size={FontSize._2Xl} weight={FontWeight.semibold}>
                {mainCcyCode ?? ''}
                {formatter.formatNumber(availableAmount || 0, mainCcyNumDecimals)}
              </Text>
            </div>
          </div>
          <div className="flex flex-row w-full h-auto gap-small">
            <div>
              <div className="flex flex-col gap-xs">
                <Text size={FontSize.small}>Currency</Text>
                <Dropdown
                  key={'ccyTypeId'}
                  list={Object.entries(availableCurrencies).map(([id, label]) => {
                    return {
                      id,
                      label,
                    };
                  })}
                  selected={[getValues('ccyTypeId')]}
                  onSelectItem={(item) => {
                    setValue('ccyTypeId', item.id.toString());
                  }}
                  config={{
                    color: 'gray',
                    size: 'sm',
                  }}
                  placeholder="Select Currency"
                />
              </div>
            </div>
            <div className="flex-1">
              <div className="flex flex-col gap-xs">
                <Text size={FontSize.small}>Amount *</Text>
                <Input
                  config={{
                    size: 'sm',
                    color: errors.amount ? 'error' : 'gray',
                    postfix: availableCurrencies?.[getValues('ccyTypeId')] || '',
                    validation: {
                      type: 'float',
                      numeralDecimalScale: 2,
                      numeralPositiveOnly: true,
                      numeralThousandsGroupStyle: 'thousand',
                    },
                  }}
                  onChange={(event) => {
                    let value = (event?.target as any)?.rawValue;
                    setValue('amount', value as unknown as number);
                  }}
                  value={getValues('amount')}
                />
                {errors.amount && (
                  <Text size={FontSize.small} color={Colors.danger_700}>
                    {errors.amount.message}
                  </Text>
                )}
              </div>
            </div>
          </div>
          <div className="flex flex-col w-full">
            <div className="flex flex-col w-full rounded-tl-md rounded-tr-md bg-gray_200 p-small gap-small">
              <div className="flex flex-col gap-3xs">
                <Text color={Colors.gray_500} size={FontSize.small} weight={FontWeight.semibold}>
                  Deposit/Wire Reference
                </Text>
                <Text size={FontSize.small} weight={FontWeight.semibold} className="max-w-full">
                  Reference ID: <Text>{accountReference}</Text>
                </Text>
              </div>
            </div>
            <div className="flex flex-col w-full rounded-br-md rounded-bl-md bg-gray_100 p-small gap-small">
              <div className="flex flex-row gap-small">
                <div className="flex flex-col flex-1 gap-small">
                  <div className="flex flex-col gap-3xs">
                    <Text color={Colors.gray_500} size={FontSize.small} weight={FontWeight.semibold}>
                      Beneficiary Name
                    </Text>
                    <Text size={FontSize.small} weight={FontWeight.semibold}>
                      {entityBankAccount?.beneficiaryName}
                    </Text>
                  </div>
                  <div className="flex flex-col gap-3xs">
                    <Text color={Colors.gray_500} size={FontSize.small} weight={FontWeight.semibold}>
                      Beneficiary Account Number
                    </Text>
                    <Text size={FontSize.small} weight={FontWeight.semibold}>
                      {Boolean(entityBankAccount?.beneficiaryAccountNumber)
                        ? entityBankAccount?.beneficiaryAccountNumber
                        : rootUser?.idxKesiCashAccount}
                    </Text>
                  </div>
                  <div className="flex flex-col gap-3xs">
                    <Text color={Colors.gray_500} size={FontSize.small} weight={FontWeight.semibold}>
                      Beneficiary Address
                    </Text>
                    <Text size={FontSize.small} weight={FontWeight.semibold}>
                      {entityBankAccount?.beneficiaryAddress}
                    </Text>
                  </div>
                </div>
                <div className="flex flex-col flex-1 gap-small">
                  <div className="flex flex-col gap-3xs">
                    <Text color={Colors.gray_500} size={FontSize.small} weight={FontWeight.semibold}>
                      Bank Name
                    </Text>
                    <Text size={FontSize.small} weight={FontWeight.semibold}>
                      {entityBankAccount?.bankName}
                    </Text>
                  </div>
                  <div className="flex flex-col gap-3xs">
                    <Text color={Colors.gray_500} size={FontSize.small} weight={FontWeight.semibold}>
                      SWIFT BIC Code
                    </Text>
                    <Text size={FontSize.small} weight={FontWeight.semibold}>
                      {entityBankAccount?.swiftCode}
                    </Text>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ModalContent>
      <ModalFooter>
        <Button
          isLoading={saving}
          disabled={saving || marketSettings?.fiatEntryEnabled === 0}
          config={{
            color: 'secondary',
            size: 'sm',
          }}
          type="submit"
          className="flex-1"
        >
          Confirm
          <Icon width={14} height={14} type={IconType.ArrowRight} />
        </Button>
      </ModalFooter>
    </form>
  );
};

export default FiatDepositForm;
