import { Button } from 'refreshed-component/atoms/Button';
import { Icon, IconType } from 'refreshed-component/atoms/Icon';
import { Text } from 'refreshed-component/atoms/Text';
import { Colors, FontSize, FontWeight } from 'refreshed-component/design-system';
import styled from 'styled-components';

import { toMargin, ToMarginProps } from './utils/toMargin';

export const PageHeader: React.FC<
  {
    title: string;
    subtitle?: React.ReactNode;
    onBack?(): void;
    suffix?: React.ReactNode;
  } & ToMarginProps
> = (props) => {
  const { title, subtitle, suffix, onBack } = props;

  return (
    <StyledPageHeader margin={props}>
      {!!onBack && (
        <Button
          onClick={onBack}
          config={{
            variant: 'icon',
            color: 'outlined',
            icon: {
              left: <Icon type={IconType.ChevronLeft} />,
            },
          }}
        />
      )}
      <TitleContainer>
        <Text size={FontSize.xl} weight={FontWeight.semibold}>
          {title}
        </Text>
        <Text size={FontSize.xs} color={Colors.gray_500}>
          {subtitle}
        </Text>
      </TitleContainer>
      {suffix}
    </StyledPageHeader>
  );
};

const StyledPageHeader = styled.div<{
  margin: ToMarginProps;
}>`
  ${({ margin }) => toMargin(margin)}
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;

  @media (max-width: 767px) {
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;
