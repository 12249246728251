// eslint-disable-next-line no-shadow
export enum AssetCategory {
  token = 1,
  currency = 2,
  biofuel = 3,
  fct = 4,
  rec = 5,
}

type AssetCategoryCode = keyof typeof AssetCategory;

type ExchangeAssetCategory = AssetCategory.biofuel | AssetCategory.token | AssetCategory.fct | AssetCategory.rec;

export type { ExchangeAssetCategory };

export const TokenAssetCategories = Object.values(AssetCategory).filter(
  (value) => !Number.isNaN(Number(value)) && value !== AssetCategory.currency,
) as AssetCategory[];

export const AssetCategories = Object.values(AssetCategory).filter(
  (value) => !Number.isNaN(Number(value)),
) as AssetCategory[];

export type { AssetCategoryCode };
