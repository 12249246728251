import * as React from 'react';
import { Colors } from 'refreshed-component/design-system';
import styled from 'styled-components';

export const LoadingRoot = styled.div<{ backgroundAlpha?: boolean; color: Colors }>`
  ${({ backgroundAlpha }) => (backgroundAlpha ? 'background-color: rgba(0, 0, 0, 0.25);' : '')}
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  &.size-default {
    > .loader {
      width: 50px;
      height: 50px;
      .lds-ring {
        width: 50px;
        height: 50px;
        div {
          border-width: 5px;
          border-style: solid;
          border-radius: 50%;
          animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
          border-color: var(${({ color }) => color}) transparent transparent transparent;
        }
      }
    }
  }
  &.size-medium {
    > .loader {
      width: 30px;
      height: 30px;
      .lds-ring {
        width: 30px;
        height: 30px;
        div {
          border-width: 3px;
          border-style: solid;
          border-radius: 50%;
          animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
          border-color: var(${({ color }) => color}) transparent transparent transparent;
        }
      }
    }
  }

  &.size-small {
    > .loader {
      width: 15px;
      height: 15px;
      .lds-ring {
        width: 15px;
        height: 15px;
        div {
          border-width: 2px;
          border-style: solid;
          border-radius: 50%;
          animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
          border-color: var(${({ color }) => color}) transparent transparent transparent;
        }
      }
    }
  }

  > .loader {
    width: 50px;
    height: 50px;
    .lds-ring {
      display: inline-block;
      position: relative;
      width: 50px;
      height: 50px;
      overflow: visible;
    }
    .lds-ring div {
      box-sizing: border-box;
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      margin: 0px;
      border-radius: 50%;
      animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
      border: 4px solid var(${Colors.primaryDefault});
      border-color: var(${Colors.primaryDefault}) transparent transparent transparent;
    }
    .lds-ring div:nth-child(1) {
      animation-delay: -0.45s;
    }
    .lds-ring div:nth-child(2) {
      animation-delay: -0.3s;
    }
    .lds-ring div:nth-child(3) {
      animation-delay: -0.15s;
    }
    @keyframes lds-ring {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }
`;

const Loading: React.FC<{
  backgroundAlpha?: boolean;
  style?: React.CSSProperties;
  isOverLay?: boolean;
  size?: 'default' | 'medium' | 'small';
  color?: Colors;
}> = ({ children, backgroundAlpha, style, isOverLay = true, size = 'default', color }) => {
  return (
    <LoadingRoot
      className={`${!isOverLay ? 'relative' : 'absolute w-full h-full'} size-${size}`}
      style={style}
      backgroundAlpha={backgroundAlpha}
      color={color || Colors.primaryDefault}
    >
      <div className="loader">
        <div className="lds-ring">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
        {children}
      </div>
    </LoadingRoot>
  );
};

export default Loading;
