import { Dto } from '@aircarbon/utils-common';

type ToAssetBalanceProps = {
  asset?: Dto.Asset | null;
  assetBalances?: Record<number, number>;
};

export const toAssetBalance = (props: ToAssetBalanceProps) => {
  const { asset, assetBalances } = props;
  if (!asset || !assetBalances) {
    return null;
  }

  const assetQuantity = assetBalances[asset.scId] / (asset.uom?.scRatio ?? 1000);

  if (isNaN(assetQuantity)) {
    return null;
  }

  return assetQuantity;
};
