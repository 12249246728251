const TradeType = {
  SpotExchange: 1,
  CarbonFinder: 2,
  CMB: 3,
  BlockTrade: 4,
  BrokerTrade: 5,
  Bilateral: 6,
  Auction: 7,
} as const;

export default TradeType;
