/* eslint-disable import/prefer-default-export */

export enum ProjectRatingType {
  BezeroCarbonRating = 'BEZERO_CARBON_RATING',
  SylveraRatingCategory = 'SYLVERA_RATING_CATEGORY',
}

export enum BezeroCarbonRating {
  AAA = 'AAA',
  AA = 'AA',
  A = 'A',
  BBB = 'BBB',
  BB = 'BB',
  B = 'B',
  C = 'C',
  D = 'D',
}

export enum SylveraRatingCategory {
  One = '1',
  Two = '2',
  Three = '3',
}
