import { useContext } from 'react';
import { IconType, PlainSVGIcon } from 'refreshed-component/atoms/Icon';
import { ResponsiveContainerContext, ResponsiveContainerWrapper } from 'refreshed-component/atoms/ResponsiveContainer';
import { Colors, FontSize, Radius, Spacing } from 'refreshed-component/design-system';
import styled from 'styled-components';

import { TextColor, Text, TypographyVariant, useLayerBackground } from '@aircarbon/ui';

import { Entity } from 'state/entity';
import { UI } from 'state/ui';
import { User } from 'state/user';

import FooterLinks from './FooterLinks';
import { MarketStatus } from './MarketStatus';

export type FooterProps = { notLogged?: boolean };
const FooterRoot = styled.div<{
  background: string;
}>`
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: ${({ background }) => background};
  overflow-x: auto;
  > .item {
    height: 32px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: var(${Radius.small}) var(${Radius.xl});
    box-sizing: border-box;
    gap: var(${Spacing.base});
    > .section {
      display: flex;
      flex-direction: row;
      gap: var(${Spacing.large});
      flex: 1 1 0;
      justify-content: center;
      min-width: fit-content;
      &:first-child {
        justify-content: start;
      }
      &:last-child {
        justify-content: end;
      }

      > .separator {
        width: 1px;
        align-self: stretch;
        margin-top: var(${Spacing._2xs});
        margin-bottom: var(${Spacing._2xs});
        background-color: var(${Colors.gray_200});
      }
      > .item {
        font-size: var(${FontSize.xs});
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: var(${Spacing.xs});
        svg.coverage {
          path {
            fill: var(-${Colors.success_700}) !important;
          }
        }
      }
    }
  }
`;

export const PoweredACXByContent = (
  <>
    <Text color={TextColor.secondary} variant={TypographyVariant.body2}>
      Powered by
    </Text>
    <PlainSVGIcon width={39} height={15} type={IconType.PoweredByACXLogo} />
  </>
);

export const Footer = ResponsiveContainerWrapper(({ notLogged }: FooterProps) => {
  const { size } = useContext(ResponsiveContainerContext);
  const { layerBackground } = useLayerBackground();
  const { entity } = Entity.useContainer();
  const {
    selector: { getUserId },
  } = User.useContainer();
  const { uiStatus } = UI.useContainer();
  const loggedInFooterItems = entity?.config?.footer?.loggedIn;
  const loggedOutFooterItems = entity?.config?.footer?.loggedOut;

  const isLoggedIn = !(!getUserId() && uiStatus.isReady());
  const isSmallSize = size === 'small' || size === 'xs';

  const poweredByACX = (
    <div className="section">
      <div className="item">{PoweredACXByContent}</div>
    </div>
  );

  const marketStatus = (
    <div className="item">
      <MarketStatus />
    </div>
  );

  return (
    <FooterRoot background={layerBackground('layer')}>
      <div className="item">
        {isLoggedIn && !notLogged && <div className="section">{!isSmallSize && marketStatus}</div>}
        {!isSmallSize || !isLoggedIn ? poweredByACX : <div className="section">{marketStatus}</div>}
        <div className="section">
          {!isLoggedIn || notLogged ? (
            <FooterLinks items={loggedOutFooterItems || []} />
          ) : (
            <FooterLinks items={loggedInFooterItems || []} />
          )}
        </div>
      </div>
    </FooterRoot>
  );
});
