import { UserOpenCurrencyBalance } from '@aircarbon/utils-common/src/dto';

import { userAuthenticatedApi } from '../fetcher';

export const fetchUserOpenCurrencyBalance = async ({
  assetId,
  accountAddress,
  ccyTypeId,
}: {
  assetId: number;
  accountAddress: string;
  ccyTypeId: number;
}) => {
  const endpoint = '/oms/account/open-currency-balance';
  const userService = await userAuthenticatedApi();
  const res = userService.url(endpoint).query({ assetId, accountAddress, ccyTypeId }).get();
  return res.json<UserOpenCurrencyBalance>();
};
