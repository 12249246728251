import { useQuery } from 'react-query';

import { AssetCategory, FeeType } from '@aircarbon/utils-common';

import { fetchFee } from 'data-provider/user/fetchFee';

function useFee({
  params: { feeType, assetCategoryId, tokenQty, totalAmount },
  options,
}: {
  params: {
    feeType: keyof typeof FeeType;
    assetCategoryId?: AssetCategory;
    tokenQty?: number;
    totalAmount?: number;
  };
  options?: Record<string, any>;
}) {
  const {
    data = 0,
    isLoading,
    error,
  } = useQuery(
    ['/api/user/settings/fee', feeType, tokenQty, totalAmount, assetCategoryId],
    () =>
      fetchFee({
        feeType,
        assetCategoryId,
        tokenQty,
        totalAmount,
      }),
    {
      ...options,
      enabled: typeof options?.enabled === 'boolean' ? options.enabled : true,
    },
  );

  return {
    feeAmount: data,
    isLoading,
    error,
  };
}

export default useFee;
