import { useMemo } from 'react';
import { Icon, IconType } from 'refreshed-component/atoms/Icon';
import { ContentHolder, MapItem } from 'refreshed-component/templates/ContentHolder';

import { AssetCategory } from '@aircarbon/utils-common';

import RequestForQuote from 'pages/account/RFQ';

import { UI } from 'state/ui';
import { User } from 'state/user';

import { BatchHistory, BatchHistoryType } from './BatchHistory';
import { Cash } from './Cash';
import { Dashboard } from './Dashboard';
import { NotificationsPage } from './Notifications';
import { Projects, ProjectsType } from './Projects';
import { MarketPlace as MarketPlaceAuction } from './auction/Marketplace';
import { MyBids as MyBidsAuction } from './auction/MyBids';
import { MyListings as MyListingsAuction } from './auction/MyListings';
import { CreateRequest } from './block-trade/create-request';
import { IncomingRequests } from './block-trade/incoming-requests';
import { OutgoingRequests } from './block-trade/outgoing-requests';
import { BankDetails } from './compnay-settings/BankDetails';
import { ExternalClients } from './compnay-settings/ExternalClients';
import { ManageUser } from './compnay-settings/ManageUser';
import { Exchange } from './exchange';
import { Ger } from './ger';
import { GERHistory } from './ger/components/GERHistory';
import { MarketPlace as MarketPlaceMarketBoard } from './market-board/Marketplace';
import { MyBids as MyBidsMarketBoard } from './market-board/MyBids';
import { MyListings as MyListingsMarketBoard } from './market-board/MyListings';
import { ProjectExchange } from './project-exchange';
import { Assets } from './reports/Asset';
import { CurrentBalance } from './reports/CurrentBalance';
import { SpotOrders } from './reports/SpotOrders';
import { Trades } from './reports/Trades';
import { Transfers } from './reports/Transfers';

const Icons = {
  Exchange: (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19.554 1.68153C19.5555 1.86179 19.4862 2.03545 19.361 2.16511L18.4721 3.05405L22.3125 3.05405C22.4949 3.05405 22.6697 3.12649 22.7987 3.25542C22.9276 3.38435 23 3.55922 23 3.74155C23 3.92389 22.9276 4.09876 22.7987 4.22769C22.6697 4.35662 22.4949 4.42905 22.3125 4.42905L18.4721 4.42905L19.361 5.31799C19.4267 5.38141 19.4791 5.45727 19.5151 5.54115C19.5511 5.62503 19.5701 5.71524 19.5709 5.80653C19.5717 5.89781 19.5543 5.98834 19.5197 6.07283C19.4851 6.15732 19.4341 6.23408 19.3695 6.29864C19.305 6.36319 19.2282 6.41424 19.1437 6.4488C19.0592 6.48337 18.9687 6.50077 18.8774 6.49997C18.7861 6.49918 18.6959 6.48021 18.612 6.44418C18.5282 6.40815 18.4523 6.35578 18.3889 6.29011L16.3263 4.22761C16.1974 4.09869 16.125 3.92385 16.125 3.74155C16.125 3.55925 16.1974 3.38441 16.3263 3.25549L18.3889 1.19299C18.5185 1.06776 18.6922 0.998459 18.8725 1.00003C19.0527 1.00159 19.2252 1.0739 19.3526 1.20136C19.4801 1.32883 19.5524 1.50127 19.554 1.68153Z"
        fill="#6B7280"
      />
      <path
        d="M4.4461 22.3185C4.44453 22.1382 4.51383 21.9646 4.63906 21.8349L5.52802 20.9459H1.68755C1.50521 20.9459 1.33034 20.8735 1.2014 20.7446C1.07247 20.6157 1.00004 20.4408 1.00004 20.2584C1.00004 20.0761 1.07247 19.9012 1.2014 19.7723C1.33034 19.6434 1.50521 19.5709 1.68755 19.5709H5.52802L4.63906 18.682C4.5734 18.6186 4.52102 18.5427 4.48499 18.4589C4.44896 18.375 4.42999 18.2848 4.4292 18.1935C4.42841 18.1022 4.4458 18.0117 4.48037 17.9272C4.51494 17.8427 4.56599 17.7659 4.63054 17.7014C4.69509 17.6368 4.77186 17.5858 4.85635 17.5512C4.94084 17.5166 5.03137 17.4992 5.12266 17.5C5.21395 17.5008 5.30416 17.5198 5.38804 17.5558C5.47192 17.5918 5.54778 17.6442 5.61121 17.7099L7.67374 19.7724C7.80263 19.9013 7.87504 20.0761 7.87504 20.2584C7.87504 20.4407 7.80263 20.6156 7.67374 20.7445L5.61121 22.807C5.48154 22.9322 5.30787 23.0015 5.12761 23C4.94735 22.9984 4.77491 22.9261 4.64744 22.7986C4.51997 22.6712 4.44766 22.4987 4.4461 22.3185Z"
        fill="#6B7280"
      />
      <path
        d="M10.5335 16.95C14.0773 16.95 16.9502 14.0772 16.9502 10.5334C16.9502 6.98953 14.0773 4.11669 10.5335 4.11669C6.98968 4.11669 4.11684 6.98953 4.11684 10.5334C4.11684 14.0772 6.98968 16.95 10.5335 16.95Z"
        fill="#6B7280"
      />
      <path
        d="M18.0994 10.5475C18.0994 14.7183 14.7183 18.0994 10.5475 18.0994C10.0878 18.0994 9.63777 18.0583 9.20075 17.9797C10.3422 19.5761 12.2114 20.6167 14.3236 20.6167C17.7992 20.6167 20.6168 17.7991 20.6168 14.3235C20.6168 12.2112 19.5762 10.342 17.9796 9.20056C18.0583 9.63764 18.0994 10.0878 18.0994 10.5475Z"
        fill="#6B7280"
      />
    </svg>
  ),
  Assets: (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.40004 11.9984C2.40004 9.45236 3.41146 7.01056 5.21181 5.21021C7.01216 3.40986 9.45396 2.39844 12 2.39844V11.9984H21.6C21.6 14.5445 20.5886 16.9863 18.7883 18.7867C16.9879 20.587 14.5461 21.5984 12 21.5984C9.45396 21.5984 7.01216 20.587 5.21181 18.7867C3.41146 16.9863 2.40004 14.5445 2.40004 11.9984Z"
        fill="#6B7280"
      />
      <path
        d="M14.4 2.70312C16.0604 3.13328 17.5755 3.99966 18.7883 5.21247C20.0011 6.42528 20.8675 7.94037 21.2976 9.60073H14.4V2.70312Z"
        fill="#6B7280"
      />
    </svg>
  ),
  MarketBoard: (
    <svg width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.20004 0.398438C2.56352 0.398438 1.95307 0.651294 1.50298 1.10138C1.05289 1.55147 0.800036 2.16192 0.800036 2.79844V17.1984C0.800036 17.835 1.05289 18.4454 1.50298 18.8955C1.95307 19.3456 2.56352 19.5984 3.20004 19.5984H12.8C13.4366 19.5984 14.047 19.3456 14.4971 18.8955C14.9472 18.4454 15.2 17.835 15.2 17.1984V6.89524C15.1999 6.25877 14.947 5.64842 14.4968 5.19844L10.4 1.10164C9.95005 0.651519 9.3397 0.398573 8.70324 0.398438H3.20004ZM5.60004 12.3984C5.60004 12.0802 5.47361 11.775 5.24856 11.5499C5.02352 11.3249 4.7183 11.1984 4.40004 11.1984C4.08178 11.1984 3.77655 11.3249 3.55151 11.5499C3.32646 11.775 3.20004 12.0802 3.20004 12.3984V15.9984C3.20004 16.3167 3.32646 16.6219 3.55151 16.847C3.77655 17.072 4.08178 17.1984 4.40004 17.1984C4.7183 17.1984 5.02352 17.072 5.24856 16.847C5.47361 16.6219 5.60004 16.3167 5.60004 15.9984V12.3984ZM8.00004 8.79844C8.3183 8.79844 8.62352 8.92487 8.84856 9.14991C9.07361 9.37495 9.20004 9.68018 9.20004 9.99844V15.9984C9.20004 16.3167 9.07361 16.6219 8.84856 16.847C8.62352 17.072 8.3183 17.1984 8.00004 17.1984C7.68178 17.1984 7.37655 17.072 7.15151 16.847C6.92647 16.6219 6.80004 16.3167 6.80004 15.9984V9.99844C6.80004 9.68018 6.92647 9.37495 7.15151 9.14991C7.37655 8.92487 7.68178 8.79844 8.00004 8.79844V8.79844ZM12.8 7.59844C12.8 7.28018 12.6736 6.97495 12.4486 6.74991C12.2235 6.52487 11.9183 6.39844 11.6 6.39844C11.2818 6.39844 10.9766 6.52487 10.7515 6.74991C10.5265 6.97495 10.4 7.28018 10.4 7.59844V15.9984C10.4 16.3167 10.5265 16.6219 10.7515 16.847C10.9766 17.072 11.2818 17.1984 11.6 17.1984C11.9183 17.1984 12.2235 17.072 12.4486 16.847C12.6736 16.6219 12.8 16.3167 12.8 15.9984V7.59844Z"
        fill="#6B7280"
      />
    </svg>
  ),
  Auctions: (
    <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.21961 9.01934C6.74001 8.53636 6.74001 7.7533 7.21961 7.27033C7.69921 6.78735 8.4768 6.78735 8.9564 7.27033L17.6403 16.0154C18.1199 16.4984 18.1199 17.2814 17.6403 17.7644C17.1607 18.2474 16.3831 18.2474 15.9035 17.7644L7.21961 9.01934Z"
        fill="#6B7280"
      />
      <path
        d="M1.43884 20C0.76058 20 0.210744 19.4463 0.210744 18.7633C0.210744 18.0802 0.76058 17.5265 1.43884 17.5265L8.8074 17.5265C9.48566 17.5265 10.0355 18.0802 10.0355 18.7633C10.0355 19.4463 9.48566 20 8.8074 20L1.43884 20Z"
        fill="#6B7280"
      />
      <path
        d="M0.719437 10.344C-0.239764 9.37808 -0.239764 7.81196 0.719437 6.846L6.79819 0.724464C7.7574 -0.241488 9.31257 -0.241488 10.2718 0.724464L12.0086 2.47348C12.9678 3.43943 12.9678 5.00555 12.0086 5.9715L5.9298 12.093C4.9706 13.059 3.41543 13.059 2.45622 12.093L0.719437 10.344Z"
        fill="#6B7280"
      />
    </svg>
  ),
  BlockTrades: (
    <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.00004 19C1.45004 19 0.979203 18.8042 0.587536 18.4125C0.19587 18.0208 3.62396e-05 17.55 3.62396e-05 17V6C3.62396e-05 5.45 0.19587 4.97917 0.587536 4.5875C0.979203 4.19583 1.45004 4 2.00004 4H6.00004V2C6.00004 1.45 6.19587 0.979167 6.58754 0.5875C6.9792 0.195833 7.45004 0 8.00004 0H12C12.55 0 13.0209 0.195833 13.4125 0.5875C13.8042 0.979167 14 1.45 14 2V4H18C18.55 4 19.0209 4.19583 19.4125 4.5875C19.8042 4.97917 20 5.45 20 6V17C20 17.55 19.8042 18.0208 19.4125 18.4125C19.0209 18.8042 18.55 19 18 19H2.00004ZM8.00004 4H12V2H8.00004V4Z"
        fill="#6B7280"
      />
    </svg>
  ),
  Biofuels: (
    <svg width="21" height="24" viewBox="0 0 21 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17 15.835C17 19.6815 14.0896 22.8 10.5 22.8C6.91037 22.8 4 19.6815 4 15.835C4 9.26428 10.5 1.19998 10.5 1.19998C10.5 1.19998 17 9.26428 17 15.835Z"
        fill="#1F2A37"
      />
    </svg>
  ),
  Reports: (
    <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.400036 10.2016C0.400036 9.8833 0.526464 9.57808 0.751508 9.35303C0.976552 9.12799 1.28178 9.00156 1.60004 9.00156H4.00004C4.3183 9.00156 4.62352 9.12799 4.84856 9.35303C5.07361 9.57808 5.20004 9.8833 5.20004 10.2016V16.2016C5.20004 16.5198 5.07361 16.825 4.84856 17.0501C4.62352 17.2751 4.3183 17.4016 4.00004 17.4016H1.60004C1.28178 17.4016 0.976552 17.2751 0.751508 17.0501C0.526464 16.825 0.400036 16.5198 0.400036 16.2016V10.2016ZM7.60004 5.40156C7.60004 5.0833 7.72647 4.77808 7.95151 4.55303C8.17655 4.32799 8.48178 4.20156 8.80004 4.20156H11.2C11.5183 4.20156 11.8235 4.32799 12.0486 4.55303C12.2736 4.77808 12.4 5.0833 12.4 5.40156V16.2016C12.4 16.5198 12.2736 16.825 12.0486 17.0501C11.8235 17.2751 11.5183 17.4016 11.2 17.4016H8.80004C8.48178 17.4016 8.17655 17.2751 7.95151 17.0501C7.72647 16.825 7.60004 16.5198 7.60004 16.2016V5.40156ZM14.8 1.80156C14.8 1.4833 14.9265 1.17808 15.1515 0.953034C15.3766 0.727991 15.6818 0.601563 16 0.601562H18.4C18.7183 0.601563 19.0235 0.727991 19.2486 0.953034C19.4736 1.17808 19.6 1.4833 19.6 1.80156V16.2016C19.6 16.5198 19.4736 16.825 19.2486 17.0501C19.0235 17.2751 18.7183 17.4016 18.4 17.4016H16C15.6818 17.4016 15.3766 17.2751 15.1515 17.0501C14.9265 16.825 14.8 16.5198 14.8 16.2016V1.80156Z"
        fill="#6B7280"
      />
    </svg>
  ),
  CarbonCredits: <Icon type={IconType.CarbonCredits} width={20} height={20} />,
};

export const RootHolder = () => {
  const {
    status: {
      canViewCorpHistory,
      isIndividual,
      canDeposit,
      canWithdraw,
      canRetireToken,
      canDeliverToken,
      canViewCmbAsk,
      canViewAuction,
      canAccessGER,
      canSeeExternalBrokerClientForBlockTrade,
      canSeeExternalBrokerClientForBiofuel,
      canAccessReport,
      canManageUser,
      canViewRFQ,
      canViewBiofuel,
      canViewSpot,
      canAccessAPX,
      canAccessRecs,
      canTradeAPX,
      canManageAPX,
      canViewIncomingBlockRequest,
      canViewOutgoingBlockRequest,
      canCreateBlockRequest,
    },
  } = User.useContainer();
  const { getSetting } = UI.useContainer();

  const webEnableAuction = Number(getSetting('web_settings_enable_auction') || 0);

  const isGERDisabled = !canAccessGER();
  const isCashDisabled = !(canDeposit() || canWithdraw());
  const isDeliveryDisabled = !canDeliverToken();
  const isRetirementDisabled = !canRetireToken();
  const isSpotExchangeDisabled = !canViewSpot();
  const isAPXEnabled = canAccessAPX();
  const isRecsEnabled = canAccessRecs();
  const isCmbAskDisabled = !canViewCmbAsk();
  const isAuctionsDisabled = !(webEnableAuction && canViewAuction());
  const isBlockTradesDisabled = !(
    canViewIncomingBlockRequest() ||
    canViewOutgoingBlockRequest() ||
    canCreateBlockRequest()
  );
  const isIncomingRequestsDisabled = !canViewIncomingBlockRequest();
  const isOutgoingRequestsDisabled = !canViewOutgoingBlockRequest();
  const isCreateRequestDisabled = !canCreateBlockRequest();
  const isCarbonFinderDisabled = !canViewRFQ();
  const isACXBiofuelsDisabled = !canViewBiofuel();
  const isAPXMarketplaceDisabled = !canTradeAPX();
  const isAPXMyProjectsDisabled = !canManageAPX();
  const isReportsDisabled = !canAccessReport();
  const isBankDetailsDisabled = !(canDeposit() || canWithdraw());
  const isExternalClientsDisabled = !(
    canSeeExternalBrokerClientForBlockTrade() || canSeeExternalBrokerClientForBiofuel()
  );
  const isManageUsersDisabled = !canManageUser();
  const isCompanySettingsDisabled = !(
    canSeeExternalBrokerClientForBlockTrade() ||
    canSeeExternalBrokerClientForBiofuel() ||
    canManageUser() ||
    canDeposit() ||
    canWithdraw()
  );
  const isTransfersReportDisabled = !canViewCorpHistory();
  const isAssetsReportDisabled = !(canViewCorpHistory() || isIndividual());
  const isCurrentBalanceReportDisabled = !(canViewCorpHistory() || isIndividual());

  const contentHolderConfig = useMemo<MapItem[]>(
    () => [
      {
        sideBarConfig: { icon: Icons.Assets, name: 'Assets' },
        type: 'list',
        list: [
          {
            path: '/account/balances',
            title: 'Balances',
            component: <Dashboard />,
          },
          {
            title: 'Projects',
            path: '/account/projects',
            component: <Projects type={ProjectsType.Project} />,
          },
          {
            title: 'GER',
            isDisabled: isGERDisabled,
            component: <Ger />,
            path: '/account/assets/ger/*',
            tabs: [
              {
                title: 'Dashboard',
                path: '/account/assets/ger',
              },
              {
                title: 'History',
                path: '/account/assets/ger/history',
                component: <GERHistory />,
              },
            ],
          },
          {
            title: 'Cash',
            path: '/account/cash',
            isDisabled: isCashDisabled,
            component: <Cash />,
          },
          {
            title: 'Delivery',
            path: '/account/deliver',
            isDisabled: isDeliveryDisabled,
            tabs: [
              {
                title: 'Projects',
                path: '/account/deliver',
                component: <Projects type={ProjectsType.Deliver} />,
              },
              {
                title: 'History',
                path: '/account/deliver/history',
                component: <BatchHistory type={BatchHistoryType.Deliver} />,
              },
            ],
          },
          {
            title: 'Retirement',
            path: '/account/retire',
            isDisabled: isRetirementDisabled,
            tabs: [
              {
                title: 'Projects',
                path: '/account/retire',
                component: <Projects type={ProjectsType.Retire} />,
              },
              {
                title: 'History',
                path: '/account/retire/history',
                component: <BatchHistory type={BatchHistoryType.Retire} />,
              },
            ],
          },
        ],
      },
      {
        type: 'sidebar-separator',
      },
      {
        sideBarConfig: { icon: Icons.Exchange, name: 'Spot' },
        type: 'link',
        page: {
          title: 'Exchange',
          path: `/account/trade`,
          isExpandable: false,
          isExpanded: false,
          isDisabled: isSpotExchangeDisabled,
          availableProducts: [
            AssetCategory.token,
            ...(isAPXEnabled ? [AssetCategory.fct] : []),
            ...(isRecsEnabled ? [AssetCategory.rec] : []),
          ],
          component: <Exchange />,
        },
      },
      {
        sideBarConfig: { icon: Icons.MarketBoard, name: 'Market Board' },
        type: 'link',
        page: {
          title: 'Market Board',
          path: '/account/market-board',
          isDisabled: isCmbAskDisabled,
          availableProducts: [
            AssetCategory.token,
            ...(isAPXEnabled ? [AssetCategory.fct] : []),
            ...(isRecsEnabled ? [AssetCategory.rec] : []),
          ],
          tabs: [
            {
              title: 'Marketplace',
              path: '/account/market-board/marketplace',
              component: <MarketPlaceMarketBoard />,
            },
            {
              title: 'My Bids',
              path: '/account/market-board/my-bids',
              component: <MyBidsMarketBoard />,
            },
            {
              title: 'My Listings',
              path: '/account/market-board/my-listings/*',
              component: <MyListingsMarketBoard />,
            },
          ],
        },
      },

      {
        sideBarConfig: { icon: Icons.Auctions, name: 'Auctions' },
        type: 'link',
        page: {
          title: 'Auctions',
          isDisabled: isAuctionsDisabled,
          path: '/account/auctions',
          availableProducts: [
            AssetCategory.token,
            ...(isAPXEnabled ? [AssetCategory.fct] : []),
            ...(isRecsEnabled ? [AssetCategory.rec] : []),
          ],
          tabs: [
            {
              title: 'Marketplace',
              path: '/account/auctions/marketplace',
              component: <MarketPlaceAuction />,
            },
            {
              title: 'My Bids',
              path: '/account/auctions/my-bids',
              component: <MyBidsAuction />,
            },
            {
              title: 'My Listings',
              path: '/account/auctions/my-listings/*',
              component: <MyListingsAuction />,
            },
          ],
        },
      },

      {
        sideBarConfig: { icon: Icons.BlockTrades, name: 'Block Trades' },
        type: 'link',
        page: {
          title: 'Block Trades',
          path: '/account/block-trade/*',
          isDisabled: isBlockTradesDisabled,
          availableProducts: [AssetCategory.token, ...(isRecsEnabled ? [AssetCategory.rec] : [])],
          tabs: [
            {
              title: 'Incoming Requests',
              path: '/account/block-trade/incoming-requests',
              component: <IncomingRequests />,
              isDisabled: isIncomingRequestsDisabled,
            },
            {
              title: 'Outgoing Requests',
              path: '/account/block-trade/outgoing-requests',
              component: <OutgoingRequests />,
              isDisabled: isOutgoingRequestsDisabled,
            },
            {
              title: 'Create Request',
              path: '/account/block-trade/create-request/*',
              component: <CreateRequest />,
              isDisabled: isCreateRequestDisabled,
            },
          ],
        },
      },
      {
        type: 'link',
        sideBarConfig: { icon: Icons.MarketBoard, name: 'Carbon Finder' },
        page: {
          path: '/account/carbon-finder/*',
          title: 'Carbon Finder',
          isDisabled: isCarbonFinderDisabled,
          component: <RequestForQuote />,
        },
      },
      {
        sideBarConfig: { icon: Icons.Biofuels, name: 'ACXBiofuels' },
        type: 'link',
        page: {
          title: 'ACXBiofuels',
          isExpandable: false,
          isExpanded: false,
          path: '/account/trade-biofuel',
          isDisabled: isACXBiofuelsDisabled,
          availableProducts: [AssetCategory.biofuel],
          component: <Exchange />,
        },
      },
      {
        type: 'sidebar-separator',
        isDisabled: !isAPXEnabled,
      },
      {
        sideBarConfig: { icon: Icons.CarbonCredits, name: 'Project Exchange' },
        type: 'link',
        isDisabled: !isAPXEnabled,
        page: {
          title: 'Project Exchange',
          path: '/account/apx/*',
          component: <ProjectExchange />,
          tabs: [
            {
              title: 'Marketplace',
              path: '/account/apx/marketplace/*',
              component: <ProjectExchange />,
              isDisabled: isAPXMarketplaceDisabled,
            },
            {
              title: 'My projects',
              path: '/account/apx/my-projects/*',
              component: <ProjectExchange />,
              isDisabled: isAPXMyProjectsDisabled,
            },
          ],
        },
      },
      {
        type: 'sidebar-separator',
      },
      {
        sideBarConfig: { icon: Icons.Reports, name: 'Reports' },
        type: 'list',
        isDisabled: isReportsDisabled,
        list: [
          {
            title: 'Report',
            name: 'Current Balance',
            path: '/account/reports/balance',
            isDisabled: isCurrentBalanceReportDisabled,
            component: <CurrentBalance />,
          },
          {
            title: 'Report',
            name: 'Assets',
            path: '/account/reports/assets',
            isDisabled: isAssetsReportDisabled,
            component: <Assets />,
          },
          {
            title: 'Report',
            name: 'Spot Orders',
            path: '/account/reports/spot-orders',
            component: <SpotOrders />,
          },
          {
            title: 'Report',
            name: 'Trades',
            path: '/account/reports/trades',
            component: <Trades />,
          },
          {
            title: 'Report',
            name: 'Users Transfers',
            path: '/account/reports/transfers',
            isDisabled: isTransfersReportDisabled,
            component: <Transfers />,
          },
        ],
      },
      {
        type: 'link',
        page: {
          title: 'Company Settings',
          path: '/account/company-settings',
          isDisabled: isCompanySettingsDisabled,
          tabs: [
            {
              title: 'Manage Users',
              path: '/account/company-settings/manage-user',
              component: <ManageUser />,
              isDisabled: isManageUsersDisabled,
            },
            {
              title: 'External Clients',
              path: '/account/company-settings/external-clients',
              isDisabled: isExternalClientsDisabled,
              component: <ExternalClients />,
            },
            {
              title: 'Bank Details',
              path: '/account/company-settings/bank-details',
              isDisabled: isBankDetailsDisabled,
              component: <BankDetails />,
            },
          ],
        },
      },
      {
        type: 'link',
        page: {
          title: 'Notifications',
          component: <NotificationsPage />,
          path: '/account/notifications',
        },
      },
    ],
    [
      isGERDisabled,
      isCashDisabled,
      isDeliveryDisabled,
      isRetirementDisabled,
      isSpotExchangeDisabled,
      isAPXEnabled,
      isRecsEnabled,
      isCmbAskDisabled,
      isAuctionsDisabled,
      isBlockTradesDisabled,
      isIncomingRequestsDisabled,
      isOutgoingRequestsDisabled,
      isCreateRequestDisabled,
      isCarbonFinderDisabled,
      isACXBiofuelsDisabled,
      isAPXMarketplaceDisabled,
      isAPXMyProjectsDisabled,
      isReportsDisabled,
      isBankDetailsDisabled,
      isExternalClientsDisabled,
      isManageUsersDisabled,
      isCompanySettingsDisabled,
      isTransfersReportDisabled,
      isAssetsReportDisabled,
      isCurrentBalanceReportDisabled,
    ],
  );
  return <ContentHolder config={contentHolderConfig} />;
};
