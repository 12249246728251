import { Vintage } from './Vintage';

export const toVintages = (props: { prevVintages: Array<Vintage>; startYear: string; endYear: string }) => {
  const { startYear, endYear, prevVintages } = props;
  if (!startYear || !endYear) {
    return [];
  }

  const prevVintagesMap: Record<string, Vintage> = prevVintages.reduce(
    (acc, current) => ({
      ...acc,
      [current.year]: current,
    }),
    {},
  );

  return Array.from({ length: Number(endYear) - Number(startYear) + 1 }, (_, index) => {
    const currentYear = '' + (Number(startYear) + index);

    return (
      prevVintagesMap[currentYear] || {
        year: currentYear,
        dateOfIssuance: '',
        estimatedQty: '0',
        offeredQty: '0',
      }
    );
  });
};
