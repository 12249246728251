// eslint-disable-next-line no-shadow
export enum TradeStatus {
  New = 1,
  Processed = 2,
  Reversed = 3,
  Replaced = 4,
}

export const OrderStatus: Record<string, number> = {
  New: 0,
  'Partially filled': 1,
  Filled: 2,
  'Done for day': 3,
  Canceled: 4,
  'Pending Cancel': 5,
  Stopped: 6,
  Rejected: 7,
  Suspended: 8,
  'Pending New': 9,
  Calculated: 10,
  Expired: 11,
  'Accepted for bidding': 12,
  'Pending Replace': 13,
  Submitted: 14,
  Queued: 15,
  Draft: 16,
  'Draft Cancel': 17,
  'Queued Cancel': 18,
  'Submitted Cancel': 19,
  End: 20,
  Active: 21,
  Inactive: 22,
  'User Rejected': 23,
} as const;

export enum OrderSide {
  Buy = 1,
  Sell = 2,
  Cross = 3,
  Borrow = 4,
}

// eslint-disable-next-line no-shadow
export enum BlockTradeRequestStatus {
  Cancelled = 'cancelled',
  Executed = 'executed',
  PendingApproval = 'pending approval',
}

export enum TradeRequestStatus {
  Approved = 'APPROVED',
  Cancelled = 'CANCELLED',
  Executed = 'EXECUTED',
  New = 'NEW',
  Pending = 'PENDING',
  Processing = 'PROCESSING',
  Rejected = 'REJECTED',
}

// eslint-disable-next-line no-shadow
export enum BrokerTradeRequestStatus {
  Processed = 'PROCESSED',
}

export const OrderSideSelector: Record<number, string> = {
  1: 'Buy',
  2: 'Sell',
  3: 'Cross',
  4: 'Borrow',
};

export const OrderTypeSelector: Record<number, string> = {
  1: 'Market',
  2: 'Limit',
  3: 'Stop',
};

export const OrderTimeInForceTypeSelector: Record<number, string> = {
  0: 'Day',
  1: 'Good Till Cancel',
  2: 'At the Opening',
  3: 'Immediate or Cancel',
  4: 'Fill or Kill',
  5: 'Good Till Crossing',
  6: 'Good Till Date',
  7: 'At the Close',
};

export const OrderStatusSelector: Record<number, string> = {
  0: 'New',
  1: 'Partially filled',
  2: 'Filled',
  3: 'Done for day',
  4: 'Canceled',
  5: 'Pending Cancel',
  6: 'Stopped',
  7: 'Rejected',
  8: 'Suspended',
  9: 'Pending New',
  10: 'Calculated',
  11: 'Expired',
  12: 'Accepted for bidding',
  13: 'Pending Replace',
  14: 'Submitted',
  15: 'Queued',
  16: 'Draft',
  17: 'Draft Cancel',
  18: 'Queued Cancel',
  19: 'Submitted Cancel',
  20: 'End',
  21: 'Active',
  22: 'Inactive',
  23: 'User Rejected',
};

export const OrderSourceSelector: Record<number, string> = {
  1: 'API',
  2: 'FIX',
  3: 'FAX',
  4: 'HX_IMPORT',
  5: 'WEB UI',
};

export const ShippingTypeSelector: Record<number, string> = {
  1: 'Week 0',
  2: 'Week 1',
  3: 'Week 2',
  4: 'Week 3',
  5: 'Week 4',
  6: 'Week 5',
};
