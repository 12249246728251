import queryString from 'query-string';

import { CommonInterfaces } from '@aircarbon/utils-common';

import { userAuthenticatedApi } from '../fetcher';

export const fetchAddressData = async (address: string) => {
  const qs = queryString.stringify({ address }, { arrayFormat: 'bracket' });
  const endpoint = `/account/contract/address-data?${qs}`;
  const userService = await userAuthenticatedApi();

  const res = userService.url(endpoint).get();
  return res.json<CommonInterfaces.AddressData>();
};
