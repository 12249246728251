import { useQuery } from 'react-query';

import { MarketFlags } from '@aircarbon/utils-common/src/dto';

import { fetchUserMarketSettings } from 'data-provider/trade/fetchUserMarketSettings';

function useUserMarketSettings({ accountAddress, options }: { accountAddress: string; options?: Record<string, any> }) {
  const {
    data: userMarketSettings,
    isLoading,
    error,
  } = useQuery<MarketFlags>(
    ['/api/oms/order/user-market-settings', accountAddress],
    () => fetchUserMarketSettings(accountAddress),
    {
      ...(options ?? {}),
      enabled: accountAddress,
      refetchOnWindowFocus: true,
    },
  );

  return {
    userMarketSettings,
    isLoading,
    error,
  };
}

export default useUserMarketSettings;
