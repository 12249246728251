import { useEffect, useState } from 'react';

import { ProjectFormMetadata } from '../../ProjectForm';
import { fetchFormMetadata } from '../utils/fetchFormMetadata';

export interface UseProjectFormMetadataHook {
  metadata: ProjectFormMetadata | null;
  isLoading: boolean;
}

export const useProjectFormMetadata = (projectId?: string): UseProjectFormMetadataHook => {
  const [metadata, setMetadata] = useState<UseProjectFormMetadataHook['metadata'] | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const initiateMetadata = async () => {
      setIsLoading(true);

      const metadata = await fetchFormMetadata();

      if (!metadata) {
        // store error and return with the hook
      }

      setMetadata(metadata);
      setIsLoading(false);
    };

    initiateMetadata();
  }, [projectId]);

  return {
    metadata,
    isLoading,
  };
};
