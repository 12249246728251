import * as React from 'react';
import { Button } from 'refreshed-component/atoms/Button';
import { Icon, IconType } from 'refreshed-component/atoms/Icon';

import Modal, { ModalContent, ModalFooter } from './Modal';

interface Props {
  title?: React.ReactNode;
  children: React.ReactNode;
  isOpen?: boolean;
  isLoading?: boolean;
  onClose?: () => void;
  cancel?: {
    label?: string;
    icon?: IconType | 'no-icon';
    callback?: (param: { onClose: () => void; onLoading: (loading?: boolean | undefined) => void }) => void | false;
  };
  accept?: {
    label?: string;
    callback?: (param: { onClose: () => void; onLoading: (loading?: boolean | undefined) => void }) => void | false;
    icon?: IconType | 'no-icon';
  };
  footer?: React.ReactNode;
  action?: React.ReactElement;
}

export const ConfirmModal = ({
  title,
  children,
  action,
  isOpen,
  isLoading,
  cancel,
  accept,
  footer,
  ...props
}: Props) => {
  return (
    <Modal isOpen={isOpen} isLoading={isLoading} title={title} action={action} onClose={props.onClose}>
      {({ onClose, onLoading }) => {
        return (
          <>
            <ModalContent>{children}</ModalContent>
            {(cancel || accept || footer) && (
              <ModalFooter>
                <div className="flex flex-row flex-1 gap-base">
                  {cancel && (
                    <Button
                      className="flex-1"
                      onClick={() => {
                        const value = cancel?.callback?.({
                          onClose,
                          onLoading,
                        });
                        if (value !== false) {
                          onClose();
                        }
                      }}
                      config={{
                        color: 'outlined',
                        size: 'base',
                      }}
                    >
                      {cancel.label || 'Cancel'}{' '}
                      {cancel.icon !== 'no-icon' && (
                        <Icon type={cancel.icon || IconType.XCircle} width={14} height={14} />
                      )}
                    </Button>
                  )}
                  {accept && (
                    <Button
                      className="flex-1"
                      onClick={() => {
                        const value = accept?.callback?.({
                          onClose,
                          onLoading,
                        });
                        if (value !== false) {
                          onClose();
                        }
                      }}
                      config={{
                        color: 'secondary',
                        size: 'base',
                      }}
                    >
                      {accept.label || 'Accept'}{' '}
                      {accept.icon !== 'no-icon' && (
                        <Icon type={accept.icon || IconType.CheckCircle} width={14} height={14} />
                      )}
                    </Button>
                  )}
                </div>
              </ModalFooter>
            )}
          </>
        );
      }}
    </Modal>
  );
};
