import { UserTcAccepted } from '@aircarbon/utils-common/src/dto';

import { userAuthenticatedApi } from '../fetcher';

type UserTcAcceptedResponse = {
  acceptedDoc: UserTcAccepted;
  status: boolean;
};

export const fetchAcceptDocument = async (filename: string) => {
  const endpoint = '/account/user/accepted-document';
  const userService = await userAuthenticatedApi();
  const res = userService.url(endpoint).query({ filename }).get();
  return res.json<UserTcAcceptedResponse>();
};
