import { Asset } from "./pair";

export enum ApxProjectStatusCode {
  Draft = 'draft',
  Submitted = 'submitted',
  Rejected = 'rejected',
  Active = 'active',
}

interface ApxVintage {
  id: number;
  expectedIssuanceDateUtc?: string;
  vintageYear: number;
  projectedCreditQuantity: number;
  actualCreditQuantity: number;
  offeredCreditQuantity: number;
  maxOfferedPercentage: number;
  mintedQuantity?: number;
  fctAssetId?: number;
  fctAsset?: Asset;
  mintTransactionId?: number;
  mintTransaction?: {
    id: number;
    txStatus?: string;
    txHash?: string;
    txToAccountAddress?: string;
    exchangeTxType?: string;
    txFromAccountAddress?: string;
    createdAt?: string;
    confirmedAt?: string;
  };
}

export interface ApxProject {
  id: number;
  apxId: number;
  name: string;
  apxPhaseId?: number;
  createdBy?: number;
  maxOfferPercentage?: number;
  methodologyId?: number;
  registryId?: number;
  carbonProjectTypeId?: number;
  description?: string;
  registryProjectId?: string;
  implementationDate?: string;
  country?: {
    code: string;
    name?: string;
  };
  sectoralScope?: {
    id: number;
    name: string;
  };
  ccbStandards?: {
    id: number;
    name: string;
  }[];
  sdgGoals?: {
    id: number;
    name: string;
  }[];
  status: {
    code: string;
    description: string;
  };
  apxPhase?: {
    id: number;
    name: string;
    description: string;
  };
  registry?: {
    id: number;
    name: string;
  };
  carbonProjectType?: {
    id: number;
    name: string;
  };
  methodology?: {
    id: number;
    name: string;
    methodologyId?: string;
  };
  vintages?: ApxVintage[];
  apxProponents?: {
    id?: number;
    relationType?: string;
    companyName?: string;
    address?: string;
    phone?: string;
    contactPersonName?: string;
    contactPersonRole?: string;
    contactEmails?: string;
  }[];
  apxDocuments?: {
    id?: number;
    name?: string;
    size?: number;
    documentTypeId?: number;
    url?: string;
    documentType?: {
      id?: number;
      name?: string;
    };
  }[];
  createdAt: string;
  updatedAt?: string;
}

export interface GetApxProjectsApiParams {
  filter?: {
    'project-types'?: number[];
    'sectoral-scopes'?: number[];
    'pair-ids'?: number[];
    vintage?: {
      from?: number;
      to?: number;
    };
    status?: string;
    search?: {
      fields: string[];
      value: string;
    };
    userId?: number;
  };
  page?: {
    size?: number;
    number?: number;
  };
}

export interface UpdateMyApxProjectApiParams {
  id: number;
  name?: string;
  countryCode?: string;
  carbonStandard?: string;
  methodologyId?: number;
  customMethodology?: string;
  carbonProjectTypeId?: number;
  registryProjectId?: string;
  registryId?: number;
  description?: string;
  sectoralScopeId?: number;
  sdgGoals?: number[];
  apxProject?: {
    apxPhaseId?: number;
    creditPeriodStartDate?: Date;
    creditPeriodEndDate?: Date;
    implementationDate?: Date;
  };
  apxDocuments?: {
    id?: number;
    name?: string;
    documentTypeId?: number;
    url?: string;
  }[];
  apxProponents?: {
    id?: number;
    relationType?: string;
    contactPersonRole?: string;
    companyName?: string;
    address?: string;
    phone?: string;
    contactPersonName?: string;
    contactEmails?: string;
  }[];
  vintages?: {
    id?: number;
    expectedIssuanceDate?: Date;
    vintageYear?: number;
    projectedCreditQuantity?: number;
    offeredCreditQuantity?: number;
  }[];
}

export interface AddApxProjectApiParams {
  name: string;
  countryCode: string;
  methodology: number;
  customMethodology: string;
  carbonProjectType: number;
  apxPhase: number;
  registry: number;
  description?: string;
  sectoralScopeId?: number;
  sdgGoals?: number[];
}

export interface SubmitMyApxProjectApiParams {
  projectId: number;
}

export interface GetProjectApiResponse {
  data: ApxProject;
}
