import { Tip } from 'grommet';

import { contractParser } from '@aircarbon/utils-common';

import { Contract } from 'state/contract';

import { Themes } from '../components/Themes';
import { useTradeAdjustment } from '../hooks';

const { ethExplorerUrlFromTXID } = contractParser;

type Props = {
  tradeId: number;
  replacingTradeId?: number;
};

const TradeReversalToolTip = ({ tradeId, replacingTradeId }: Props) => {
  const { contractJson } = Contract.useContainer();
  const { theme } = Themes.useContainer();
  const networkId = Number(contractJson?.networkId);

  const { tradeAdjustment, isLoading } = useTradeAdjustment(tradeId);

  if (isLoading || !tradeAdjustment) {
    return <div>Empty</div>;
  }
  const tx = tradeAdjustment?.__xTradeAdjustmentTransactions__?.[0]?.__transaction__;

  const toolTipContent = (reverseReason: string, replacingTradeId?: number) => (
    <div className="p-1 mr-1 text-xs font-bold text-red-500 bg-white">
      {replacingTradeId && <div>Replaced By Trade ID #{replacingTradeId}</div>}
      <div>Reason: {tradeAdjustment?.reverseReason}</div>
    </div>
  );

  return (
    <>
      {tradeAdjustment?.reverseReason && (
        <Tip
          content={toolTipContent(tradeAdjustment?.reverseReason, replacingTradeId)}
          dropProps={{ align: { bottom: 'top', left: 'right' } }}
          plain={false}
        >
          <div className="flex flex-row items-center mr-1">
            <img className="ml-1 text-red-200" height="15px" width="15px" src="/info-red.png" alt="info" />
          </div>
        </Tip>
      )}
      {tx?.id && tx?.txHash && (
        <a
          href={`${
            ['error', 'failed'].includes(tx?.txStatus)
              ? ethExplorerUrlFromTXID(networkId, tx?.txHash)
              : window.location.origin + '/explore/tx/' + tx?.txHash
          }`}
          className="flex flex-row justify-center items-center"
          target="_blank"
          rel="noreferrer"
        >
          <svg width="12" height="12" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M20 5L10 5C8.89543 5 8 5.89543 8 7L8 41C8 42.1046 8.89543 43 10 43L38 43C39.1046 43 40 42.1046 40 41L40 24.75"
              stroke={theme.table.text.red}
              strokeWidth="3"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M28 5H40V17"
              stroke={theme.table.text.red}
              strokeWidth="3"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M21.0002 23.9998L39.0001 6"
              stroke={theme.table.text.red}
              strokeWidth="3"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </a>
      )}
    </>
  );
};

export default TradeReversalToolTip;
