import { convertUnit, hex2int } from './formatter';

/**
 * Check the project metadata against the filters
 * @param filters
 * @param batches
 */
export function rfqMatching(
  filters: Array<{
    filterName: string;
    filterValue: string;
  }>,
  batches: Array<{
    project: Record<string, string>;
  }>,
): boolean {
  const uniqueFilters = [...new Set(filters.map((filter) => filter.filterName))];
  // check the meta base on the condition
  // AND between meta field
  // OR if that is the same filter
  return batches.some((batch) =>
    uniqueFilters.every((filter) =>
      filters
        .filter((item) => item.filterName === filter)
        .some((item) => {
          // support the multi value
          if (filter.startsWith('LIST_')) {
            const values = batch.project[filter]?.split('|') ?? [];
            return values.includes(item.filterValue);
          }

          // support CALCULATED_VINTAGE_YEAR, this is not generic one
          if (filter === 'CALCULATED_VINTAGE_YEAR') {
            const startVintageYear = batch.project.DATE_VINTAGE_START?.substr(
              batch.project?.DATE_VINTAGE_START.length - 4,
              4,
            );

            return Number(item.filterValue) === Number(startVintageYear);
          }

          return batch.project[filter] === item.filterValue;
        }),
    ),
  );
}

/**
 * validate the balance and return invalid open rfq
 * @param ccys
 * @param rfqRequests
 */
export function validateOpenRFQWithBalance({
  ccys,
  ccyTypes,
  rfqRequests,
}: {
  ccys: Array<{
    name: string;
    unit: string;
    balance: {
      _hex: string;
    };
    ccyTypeId: {
      _hex: string;
    };
  }>;
  ccyTypes: Array<{
    id: {
      _hex: string;
    };
    unit: string;
    decimals: number;
    name: string;
  }>;
  rfqRequests: Array<{
    id: number;
    status: 'OPEN';
    quantity: number;
    ccyTypeId: number;
    tokenTypeId: number;
    price: number;
    filled: number;
  }>;
}): Array<{
  id: number;
  status: 'OPEN';
  quantity: number;
  ccyTypeId: number;
  tokenTypeId: number;
  price: number;
  filled: number;
}> {
  const balances: Record<string, number> = ccys.reduce(
    (result, ccy) => ({
      ...result,
      [hex2int(ccy.ccyTypeId)]: convertUnit(
        ccy.balance,
        ccyTypes.find((type) => type.name === ccy.name)?.decimals ?? 0,
      ),
    }),
    {},
  );

  const invalidItems: {
    id: number;
    status: 'OPEN';
    quantity: number;
    ccyTypeId: number;
    tokenTypeId: number;
    price: number;
    filled: number;
  }[] = [];

  const sortedRFQByQty = rfqRequests.sort((prevRfq, nextRqf) => prevRfq.quantity - nextRqf.quantity);
  sortedRFQByQty.forEach((request) => {
    if (balances[request.ccyTypeId]) {
      balances[request.ccyTypeId] -= request.price * (request.quantity - request.filled);

      if (balances[request.ccyTypeId] < 0) {
        invalidItems.push(request);
      }
    } else {
      invalidItems.push(request);
    }
  });

  return invalidItems;
}

export default {
  rfqMatching,
  validateOpenRFQWithBalance,
};
