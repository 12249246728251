import { GetApxProjectsApiParams } from '@aircarbon/utils-common/src/dto';

import { ProjectsListFilters } from '../components/ProjectsList';
import { toApiProjectStatus } from './toApiProjectStatus';

export const toMyProjectsApiParams =
  (userId: number) =>
  (filters: ProjectsListFilters): GetApxProjectsApiParams => {
    let fetchParams: GetApxProjectsApiParams = {
      filter: {
        userId,
      },
    };

    const status = toApiProjectStatus(filters.status);
    if (status) {
      fetchParams = {
        ...fetchParams,
        filter: {
          ...fetchParams.filter,
          status,
        },
      };
    }

    if (filters.sectoralScopes?.length) {
      fetchParams = {
        ...fetchParams,
        filter: {
          ...fetchParams.filter,
          'sectoral-scopes': filters.sectoralScopes,
        },
      };
    }

    if (filters.projectTypes?.length) {
      fetchParams = {
        ...fetchParams,
        filter: {
          ...fetchParams.filter,
          'project-types': filters.projectTypes,
        },
      };
    }

    if (filters.vintageYear?.from !== undefined) {
      fetchParams = {
        ...fetchParams,
        filter: {
          ...fetchParams.filter,
          vintage: {
            ...fetchParams.filter?.vintage,
            from: filters.vintageYear?.from,
          },
        },
      };
    }

    if (filters.vintageYear?.to !== undefined) {
      fetchParams = {
        ...fetchParams,
        filter: {
          ...fetchParams.filter,
          vintage: {
            ...fetchParams.filter?.vintage,
            to: filters.vintageYear?.to,
          },
        },
      };
    }

    return fetchParams;
  };
