import { BasicDetails, BasicDetailsMetadata } from './components/BasicDetails';
import { Documentation, DocumentationFormValue } from './components/Documentation';
import { Milestones } from './components/Milestones';
import { Proponents, ProponentsMetadata, ProponentsValue } from './components/Proponents';

export interface ProjectFormValue extends ProponentsValue, DocumentationFormValue {
  id: string;
  apxId: string;
  projectName: string;
  projectDescription: string;
  projectLocation: string;
  projectPhase: string;
  standardUsed: string;
  projectId: string;
  projectType: string;
  methodology: string;
  customMethodology: string;
  implementationDate: string;
  additionalCertifications: Array<string>;
  sectoralScope: string;
  eligibilityForInternalGrades: string;
  sdgGoals: Array<string>;
  vintages: Array<{
    id?: number;
    year: string;
    dateOfIssuance: string;
    estimatedQty: string;
    offeredQty: string;
  }>;
}

export interface ProjectFormMetadata extends BasicDetailsMetadata, ProponentsMetadata {}

export interface ProjectFormProps {
  activeStepIndex: number;
  value: ProjectFormValue;
  metadata: ProjectFormMetadata;
  errors: Array<Record<string, string>>;
  onChange(props: ProjectFormValue): void;
}

export const ProjectForm: React.FC<ProjectFormProps> = (props) => {
  const { onChange, activeStepIndex, value, metadata, errors } = props;

  const onChangeFormValue = (newValue: Partial<ProjectFormValue>) => {
    onChange({
      ...value,
      ...newValue,
    });
  };

  const steps = [
    <BasicDetails error={errors[0]} value={value} metadata={metadata} onChange={onChangeFormValue} />,
    <Proponents value={value} error={errors[1]} metadata={metadata} onChange={onChangeFormValue} />,
    <Milestones value={value} error={errors[2]} onChange={onChangeFormValue} />,
    <Documentation error={errors[3]} value={value} onChange={onChangeFormValue} />,
  ];

  return steps[activeStepIndex];
};
