import { CarbonProject } from '@aircarbon/utils-common/src/dto/carbon';

import { userAuthenticatedApi } from '../fetcher';

export type CarbonProjectsResponse = {
  total: number;
  totalPage: number;
  items: CarbonProject[];
};
export const fetchCarbonProjects = async ({
  searchBy,
  filterBy,
  isFilterLike,
}: {
  searchBy?: string;
  filterBy?: Record<string, any>;
  isFilterLike?: boolean;
}) => {
  const endpoint = '/user/carbon/project';
  const userService = await userAuthenticatedApi();
  const res = userService
    .url(endpoint)
    .query({ searchBy, isFilterLike: isFilterLike ? 'yes' : 'no', ...filterBy })
    .get();
  return res.json<CarbonProjectsResponse>();
};
