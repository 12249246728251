import { useMemo } from 'react';

import { UI } from 'state/ui';

import { DisclaimerUrl } from '../ProjectRatingBadge';

function useProjectRatingDisclaimers() {
  const { getSetting } = UI.useContainer();

  const sylveraRatingDisclaimerSetting = getSetting('web_settings_bezeroRatingDisclaimers');
  const bezeroRatingDisclaimerSetting = getSetting('web_settings_sylveraRatingDisclaimers');

  const urls = useMemo(() => {
    let bezeroDisclaimerUrls: DisclaimerUrl[] = [];
    let sylveraDisclaimerUrls: DisclaimerUrl[] = [];

    try {
      bezeroDisclaimerUrls = JSON.parse(sylveraRatingDisclaimerSetting);
      sylveraDisclaimerUrls = JSON.parse(bezeroRatingDisclaimerSetting);
    } catch {}

    return { bezeroDisclaimerUrls, sylveraDisclaimerUrls };
  }, [sylveraRatingDisclaimerSetting, bezeroRatingDisclaimerSetting]);

  return urls;
}

export default useProjectRatingDisclaimers;
