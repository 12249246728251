import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import 'react-phone-number-input/style.css';
import { Button } from 'refreshed-component/atoms/Button';
import { Dropdown } from 'refreshed-component/atoms/Dropdown';
import { Icon, IconType } from 'refreshed-component/atoms/Icon';
import { Input } from 'refreshed-component/atoms/Input';
import { Text } from 'refreshed-component/atoms/Text';
import { Colors, FontSize } from 'refreshed-component/design-system';
import { ModalContent, ModalFooter } from 'refreshed-component/molecules/Modal';
import * as yup from 'yup';

import { Themes } from 'pages/account/trading/components/Themes';

import FormDevTool from 'components/FormDevTool';

import { UI } from 'state/ui';

interface Props {
  onSubmit: (
    values: Record<string, any>,
    handlers?: {
      onSuccess?: () => void;
    },
  ) => void;
  isLoading: boolean;
  remainingAllowedAccounts: {
    admins: number;
    traders: number;
    monitors: number;
    clients: number;
  };
  isMember?: number;
}

type FormData = {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  accountType: string;
};

const schema = yup.object().shape({
  firstName: yup.string().required(),
  lastName: yup.string().required(),
  email: yup.string().required().email(),
  accountType: yup.string().required(),
});

const AddUserForm = ({ onSubmit, isLoading, remainingAllowedAccounts, isMember }: Props) => {
  const { theme: themeColors } = Themes.useContainer();
  const { getSetting } = UI.useContainer();

  const enabledParticipantApprovalFlow =
    Number(getSetting('web_settings_enabled_participant_subAccount_approvalFlow') || 0) === 1;
  const traderAnnouncement = getSetting('web_settings_participant_trader_announcement') ?? '';
  const corpAdminAnnouncement = getSetting('web_settings_participant_corpAdmin_announcement') ?? '';

  const { handleSubmit, formState, control, watch, getValues, setValue } = useForm<FormData>({
    resolver: yupResolver(schema) as any,
  });

  watch();

  const { errors } = formState;

  const onSubmitHandler = (data: any, evt: any) => {
    onSubmit(data, {
      onSuccess: () => evt.target.reset(),
    });
  };

  const { isSubmitting } = formState;
  return (
    <>
      <FormDevTool control={control} />
      <form onSubmit={handleSubmit(onSubmitHandler)}>
        <ModalContent>
          <div className="flex flex-col gap-base">
            <div className={`flex flex-col sm:flex-row gap-base`}>
              <div className="flex flex-col flex-1 gap-xs">
                <Text size={FontSize.small}>First Name</Text>
                <Input
                  config={{
                    color: 'gray',
                    size: 'base',
                  }}
                  value={getValues('firstName')}
                  onChange={(event) => {
                    setValue('firstName', (event?.target.value as any) || undefined, {
                      shouldValidate: true,
                    });
                  }}
                  placeholder="First Name"
                />
                {errors.firstName?.message && (
                  <Text size={FontSize.small} color={Colors.danger_700}>
                    {errors.firstName.message}
                  </Text>
                )}
              </div>
              <div className="flex flex-col flex-1 gap-xs">
                <Text size={FontSize.small}>Last Name</Text>
                <Input
                  config={{
                    color: 'gray',
                    size: 'base',
                  }}
                  value={getValues('lastName')}
                  onChange={(event) => {
                    setValue('lastName', (event?.target.value as any) || undefined, {
                      shouldValidate: true,
                    });
                  }}
                  placeholder="Last Name"
                />
                {errors.lastName?.message && (
                  <Text size={FontSize.small} color={Colors.danger_700}>
                    {errors.lastName.message}
                  </Text>
                )}
              </div>
            </div>
            <div className={`flex flex-col sm:flex-row gap-base`}>
              <div className="flex flex-col flex-1 gap-xs">
                <Text size={FontSize.small}>Email</Text>
                <Input
                  config={{
                    color: 'gray',
                    size: 'base',
                  }}
                  value={getValues('email')}
                  onChange={(event) => {
                    setValue('email', (event?.target.value as any) || undefined, {
                      shouldValidate: true,
                    });
                  }}
                  placeholder="Enter Email"
                />
                {errors.email?.message && (
                  <Text size={FontSize.small} color={Colors.danger_700}>
                    {errors.email.message}
                  </Text>
                )}
              </div>
              <div className="flex flex-col flex-1 gap-xs">
                <Text size={FontSize.small}>Type</Text>
                <Dropdown
                  list={[
                    {
                      label: 'Trader',
                      id: 'Trader',
                    },
                    {
                      label: 'Admin',
                      id: 'Admin',
                    },
                    {
                      label: 'Monitor',
                      id: 'Monitor',
                    },
                    {
                      label: 'Client (DMA)',
                      id: 'Client_DMA',
                    },
                    {
                      label: 'Client (Read Only)',
                      id: 'Client_Read_Only',
                    },
                  ].filter((item) => {
                    if (item.id === 'Trader') {
                      return remainingAllowedAccounts.traders > 0;
                    } else if (item.id === 'Admin') {
                      return remainingAllowedAccounts.admins > 0;
                    } else if (item.id === 'Monitor') {
                      return remainingAllowedAccounts.monitors > 0;
                    } else if (['Client_DMA', 'Client_Read_Only'].includes(item.id)) {
                      return remainingAllowedAccounts.clients > 0;
                    }
                    return true;
                  })}
                  selected={[getValues('accountType')]}
                  onSelectItem={(list) => {
                    setValue('accountType', (list?.id as any) || undefined, {
                      shouldValidate: true,
                    });
                  }}
                  config={{
                    color: 'gray',
                    size: 'base',
                  }}
                  placeholder="Select type"
                />
                {errors.accountType?.message && (
                  <Text size={FontSize.small} color={Colors.danger_700}>
                    {errors.accountType?.message}
                  </Text>
                )}
              </div>
            </div>
            <div
              className="text-center"
              style={{
                maxWidth: '500px',
              }}
            >
              {isMember !== 1 && watch('accountType') === 'Trader' && enabledParticipantApprovalFlow && (
                <div
                  style={{ color: themeColors.dashboard.form.warning }}
                  className="mt-1 mb-4 text-sm font-semibold text-left"
                >
                  {traderAnnouncement}
                </div>
              )}
              {isMember !== 1 && watch('accountType') === 'Admin' && enabledParticipantApprovalFlow && (
                <div
                  style={{ color: themeColors.dashboard.form.warning }}
                  className="mt-1 mb-4 text-sm font-semibold text-left"
                >
                  {corpAdminAnnouncement}
                </div>
              )}

              {isLoading && <div className="mt-4">Registering new user. It may take a while.. </div>}
            </div>
          </div>
        </ModalContent>
        <ModalFooter>
          <Button
            className="flex-1"
            config={{
              color: 'secondary',
            }}
            isLoading={isSubmitting || isLoading}
            disabled={Object.keys(errors).length > 0}
          >
            Confirm
            <Icon width={14} height={14} type={IconType.ArrowRight} />
          </Button>
        </ModalFooter>
      </form>
    </>
  );
};

export default AddUserForm;
