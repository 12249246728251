import * as React from 'react';
import styled from 'styled-components';

const LoadingSpin = styled.div<{ size: string }>`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: ${(props) => props.size};
  height: ${(props) => props.size};

  .spinner {
    width: ${(props) => props.size};
    height: ${(props) => props.size};
    &:after {
      width: ${(props) => props.size};
      height: ${(props) => props.size};
      top: calc(50% - (${(props) => props.size} / 2));
      left: calc(50% - (${(props) => props.size} / 2));
    }
  }
`;

type Props = {
  children?: React.ReactNode;
  size?: 'small' | 'normal';
};

const Loading: React.FC<Props> = ({ size, children }) => {
  let spinSize = '2.88em';
  if (size === 'small') spinSize = '1em';
  return (
    <LoadingSpin size={spinSize}>
      <div role="alert" className="pb-2 mb-6 w-3 h-3 spinner" />
      {children}
    </LoadingSpin>
  );
};

export default Loading;
