import { memo, useState } from 'react';
import { AlertType, Alerts } from 'refreshed-component/atoms/Alerts';

import { LocalStorage, resolveWebSettingsAnnouncement } from '@aircarbon/utils-common';

import { UI } from 'state/ui';

const webSettingsAnnouncementKey = 'web-settings-announcement';

export const WebAnnouncement = memo(() => {
  const { getSetting } = UI.useContainer();
  const webAnnouncement = resolveWebSettingsAnnouncement(getSetting('web_settings_announcement'));
  const webAnnouncementJson = JSON.stringify(webAnnouncement);
  const isClosed = LocalStorage.getItem('web-settings-announcement') === webAnnouncementJson;
  const [isOpen, setOpen] = useState(!isClosed);
  return (
    <>
      {isOpen && webAnnouncement.message && (
        <Alerts
          type={webAnnouncement.type as AlertType}
          content={{
            message: webAnnouncement.message,
          }}
          closeIcon={
            webAnnouncement.cancelable
              ? {
                  visible: true,
                  onClick: () => {
                    LocalStorage.setItem(webSettingsAnnouncementKey, webAnnouncementJson);
                    setOpen(false);
                  },
                }
              : undefined
          }
        />
      )}
    </>
  );
});
