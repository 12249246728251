import queryString from 'query-string';

import { MyBidsResponse } from '@aircarbon/utils-common/src/dto/carbon';

import { userAuthenticatedApi } from 'data-provider/fetcher';

export const fetchMyAuctionBid = async ({
  page,
  limit,
  status,
  assetCategoryId,
}: {
  page: number;
  limit: number;
  status?: string;
  assetCategoryId?: string;
}) => {
  const qs = queryString.stringify(
    { page, limit, status: status ? [status] : undefined, assetCategoryId },
    { arrayFormat: 'bracket' },
  );
  const endpoint = `/user/carbon/my-auction-bid?${qs}`;
  const userService = await userAuthenticatedApi();

  const res = userService.url(endpoint).get();
  return res.json<MyBidsResponse>();
};
