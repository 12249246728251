import { UserFullProfileResponse } from '@aircarbon/utils-common/src/dto/user';

import { externalApi } from '../fetcher';

export const fetchUserFullProfile = async (sub: string, sessionAuth: string) => {
  const endpoint = `/user/user/${sub}`;
  const userService = externalApi.auth(sessionAuth);
  const res = userService.url(endpoint).get();
  return res.json<UserFullProfileResponse>();
};
