import styled from 'styled-components';

import { formatter } from '@aircarbon/utils-common';
import { Asset, Pair } from '@aircarbon/utils-common/src/dto';

import Loading from 'components/styled/Loading';

import { Entity } from 'state/entity';

import { Themes } from '../trading/components/Themes';
import { BidTable } from './Styled';

export interface TokenPriceResponse {
  pairId: number;
  marketPrice: number;
  settlementPrice: number;
}

const Wrapper = styled.div`
  border-radius: 0 0 5px 5px;
  overflow: hidden;
`;
const TableWrapper = styled.div``;
interface Props {
  entityTokenPrice?: Array<TokenPriceResponse>;
  activeTokens: Array<Asset>;
  pairsWatchList?: Partial<Pair[]>;
  isLoadingEntityTokenPrice: boolean;
}

const TokenPrices = ({ entityTokenPrice, activeTokens, pairsWatchList, isLoadingEntityTokenPrice }: Props) => {
  const { theme } = Themes.useContainer();
  const {
    selector: { mainCcyNumDecimals },
  } = Entity.useContainer();
  const pairMapper: Record<string, any> =
    pairsWatchList?.reduce((prev, curr) => {
      const stlPrice =
        entityTokenPrice?.find((tokenPrice) => tokenPrice?.pairId === curr?.pairId)?.settlementPrice ?? 0;
      return {
        ...prev,
        [curr?.baseAsset?.scId ?? 0]: {
          id: curr?.id,
          pairId: curr?.pairId,
          baseAsset: curr?.baseAsset,
          settlementPrice: formatter.formatNumber(stlPrice, mainCcyNumDecimals),
          limitUp: (100 + (curr?.settings?.limitUpPercentage || 10)) / 100,
          limitDown: (100 - (curr?.settings?.limitDownPercentage || 10)) / 100,
        },
      };
    }, {}) ?? {};

  const availableToken = activeTokens?.filter(
    (token) => pairsWatchList?.find((pair) => pair?.baseAsset?.scId === token.scId)?.marketFlags?.showInRfq === 1,
  );
  if (isLoadingEntityTokenPrice) return <Loading />;

  return (
    <Wrapper>
      {availableToken?.length === 0 ? (
        <div className="mt-base">No prices set.</div>
      ) : (
        <TableWrapper>
          <BidTable>
            <thead>
              <tr>
                <th className="assetName">Asset</th>
                <th>Min Bid</th>
                <th>Price</th>
                <th>Max Bid</th>
              </tr>
            </thead>
            {/* TODO: get range from db for min/max */}
            <tbody>
              {availableToken?.map((token) => (
                <tr key={token.id}>
                  <td>{token?.symbol}</td>
                  <td>
                    {formatter.formatNumber(
                      pairMapper?.[token?.scId]?.settlementPrice * pairMapper?.[token?.scId]?.limitDown,
                      mainCcyNumDecimals,
                    ) ?? 'N/A'}
                  </td>
                  <td>{pairMapper?.[token?.scId]?.settlementPrice ?? 'N/A'}</td>
                  <td>
                    {formatter.formatNumber(
                      pairMapper?.[token?.scId]?.settlementPrice * pairMapper?.[token?.scId]?.limitUp,
                      mainCcyNumDecimals,
                    ) ?? 'N/A'}
                  </td>
                </tr>
              ))}
            </tbody>
          </BidTable>
        </TableWrapper>
      )}
    </Wrapper>
  );
};

export default TokenPrices;
