import queryString from 'query-string';

import { MyAskResponse } from '@aircarbon/utils-common/src/dto/carbon';

import { userAuthenticatedApi } from 'data-provider/fetcher';

export const fetchMyCMBAsks = async (data: {
  page: number;
  limit: number;
  status: string | number;
  search?: string;
  isAuction: 'yes' | 'no';
  assetCategoryId?: string;
}) => {
  const qs = queryString.stringify(data, {
    skipNull: true,
    skipEmptyString: true,
  });

  const endpoint = `/user/carbon/my-cmb-ask?${qs}`;

  const userService = await userAuthenticatedApi();
  const res = userService.url(endpoint).get();
  return res.json<MyAskResponse>();
};
