import { ReactNode } from 'react';
import { Colors, Spacing } from 'refreshed-component/design-system';
import styled from 'styled-components';

import { Themes } from 'pages/account/trading/components/Themes';

import { Entity } from 'state/entity';

import { Footer } from './Footer';

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  flex: 1 1 100%;
  align-items: stretch;
  > .controls {
    width: 560px;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    form {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: stretch;
      width: 100%;
      max-width: 300px;
      gap: var(${Spacing.large});
    }

    .hidden {
      opacity: 0;
      position: absolute;
      right: 100%;
    }

    input:disabled {
      background: transparent;
      border-color: #ddd;
      padding: 0;
      border: none;
      height: auto;
      font-weight: 300;
      font-size: 1.2rem;
    }

    .forgot-password-button {
      margin-top: -0.5rem;
      display: flex;
      margin-left: auto;
      margin-bottom: 2rem;
    }
  }
  > .brand-image {
    width: auto;
    height: auto;
    flex: 1 0 0;
    background-color: var(${Colors.gray_0});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }
`;

export const AuthWrapper = ({ children, notLogged }: { children: ReactNode; notLogged?: boolean }) => {
  const { entity } = Entity.useContainer();
  const { themeMode } = Themes.useContainer();
  return (
    <Wrapper>
      <div className="controls">
        <div className="flex flex-row justify-start p-large">
          <div
            className="flex-1"
            style={{
              height: '32px',
              backgroundImage: `url(${
                themeMode === 'dark' ? entity.theme.web.logoSidebarDark : entity.theme.web.logoSidebarLight
              })`,
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'left center',
              backgroundSize: 'contain',
            }}
          />
        </div>
        <div className="flex flex-col flex-1 justify-center items-center h-auto py-large">{children}</div>
        <div className="p-small">
          <Footer notLogged={notLogged} />
        </div>
      </div>
      <div
        className="brand-image"
        style={{
          backgroundImage: `url(${
            themeMode === 'dark' ? entity.theme.web.authBrandImageDark : entity.theme.web.authBrandImageLight
          })`,
        }}
      />
    </Wrapper>
  );
};
