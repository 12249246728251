import { useState } from 'react';
import { queryCache, useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';
import { Button } from 'refreshed-component/atoms/Button';
import { Empty } from 'refreshed-component/atoms/Empty';
import { HitArea } from 'refreshed-component/atoms/HitArea';
import { Icon, IconType } from 'refreshed-component/atoms/Icon';
import { Input } from 'refreshed-component/atoms/Input';
import { Text } from 'refreshed-component/atoms/Text';
import { Colors, FontSize, FontWeight, Spacing } from 'refreshed-component/design-system';
import AddAskProjectForm from 'refreshed-component/forms/AddAskProjectForm';
import EditAskForm from 'refreshed-component/forms/EditAskForm';
import { FilterDropdown, FilterRadioBox, FilterSelections } from 'refreshed-component/molecules/Filter';
import Loading from 'refreshed-component/molecules/Loading';
import Modal from 'refreshed-component/molecules/Modal';
import { Pagination, usePagination } from 'refreshed-component/molecules/Pagination';
import { PageControls } from 'refreshed-component/organisms/PageControls';
import { PageHolder, PageSections } from 'refreshed-component/organisms/PageHolder';
import { MarketPlaceProjectCard } from 'refreshed-component/templates/market-board/MarketPlaceProjectCard';

import { AssetCategory, formatter } from '@aircarbon/utils-common';

import { MyEmbAsk } from 'pages/account/carbon/CMB/types';
import useMarketSettings from 'pages/account/trading/hooks/useMarketSettings';

import { UI } from 'state/ui';
import { User } from 'state/user';

import useCurrencies from 'hooks/useCurrencies';
import { useMarketplaceProduct } from 'hooks/useMarketplaceProduct';

import { fetchMyCMBAsks } from 'data-provider/market-board';

import { toAssetMinMaxLots } from 'utils/toAssetMinMaxLots';

export const askStatusLabel = (ask: MyEmbAsk) => {
  if (ask?.status === 'NEW' || ask.__carbonProject__?.statusCode === 'NEW') return 'PENDING APPROVAL';
  if (ask?.status === 'REJECTED' || ask.__carbonProject__?.statusCode === 'REJECTED') return 'REJECTED';
  switch (ask.status) {
    case 'LIST':
      return 'LISTED';

    case 'UNLIST':
      return 'UNLISTED';
  }
  return ask.status;
};

export const MyListingAsks = () => {
  const history = useHistory();
  const [search, setSearch] = useState('');
  const { currenciesById } = useCurrencies();
  const currenciesObjById = currenciesById();
  const { product } = useMarketplaceProduct();
  const [isListNewProjectModalVisible, setIsListNewProjectModalVisible] = useState(false);

  const {
    status: { canBidCmbAsk, canEditCmbAsk, canManageCmbBid },
  } = User.useContainer();

  const { getSetting } = UI.useContainer();

  const { askMinLots, askMaxLots, bidMinLots } = toAssetMinMaxLots({
    assetCategory: Number(product),
    getSetting,
  });
  const { marketSettings, isLoading: isLoadingMarketSettings } = useMarketSettings({});

  const pagination = usePagination();
  const tokenUnit = getSetting('web_settings_tokenUnit');
  const filters: {
    status: FilterRadioBox;
  } = {
    status: {
      type: 'radio-box',
      label: 'Status',
      list: [
        {
          id: 'listed',
          label: 'Listed Projects',
        },
        {
          id: 'requests',
          label: 'Projects With Bids',
        },
        {
          id: 'unlisted',
          label: 'Unlisted',
        },
        {
          id: 'new',
          label: 'Pending Approval',
        },
        {
          id: 'rejected',
          label: 'Rejected Projects',
        },
      ],
    },
  };
  const [filterSelections, setFilterSelections] = useState<FilterSelections<typeof filters> | undefined>({});
  const getAskQueryKey = '/api/user/carbon/my-cmb-ask';

  const { data, isLoading, refetch } = useQuery(
    [getAskQueryKey, pagination.page, pagination.pageSize, product, filterSelections?.status?.selection, search],
    async () =>
      fetchMyCMBAsks({
        page: pagination.page,
        limit: pagination.pageSize,
        assetCategoryId: product,
        status: filterSelections?.status?.selection || 'all',
        isAuction: 'no',
        search,
      }),
  );

  const asks = data?.items;

  if (isLoadingMarketSettings) return <Loading />;

  const onPressListNewProject = () => {
    setIsListNewProjectModalVisible(true);
  };

  const onCloseNewProjectModal = () => {
    setIsListNewProjectModalVisible(false);
  };

  return (
    <PageHolder>
      <PageSections>
        <PageControls
          title="My Listings"
          controls={{
            secondary: (
              <>
                {canBidCmbAsk() && (
                  <div className="flex flex-row justify-start">
                    <Button
                      disabled={marketSettings?.otcEntryEnabled === 0}
                      className="flex-1"
                      onClick={onPressListNewProject}
                      config={{
                        color: 'primary',
                        size: 'base',
                      }}
                    >
                      <Icon type={IconType.PlusCircle} width={14} height={14} /> List New Offer
                    </Button>
                    <Modal
                      title={'List A New Project Offer'}
                      isOpen={isListNewProjectModalVisible}
                      onClose={onCloseNewProjectModal}
                    >
                      {({ onClose, onLoading }) => {
                        return (
                          <AddAskProjectForm
                            minLotQty={askMinLots}
                            maxLotQty={askMaxLots}
                            bidMinLots={bidMinLots}
                            isProjectOffer={true}
                            onSuccess={onClose}
                            onLoading={(isLoading) => onLoading(isLoading)}
                            refetchAsks={refetch}
                            assetCategoryId={Number(product) as AssetCategory}
                          />
                        );
                      }}
                    </Modal>
                  </div>
                )}
              </>
            ),
          }}
        />
      </PageSections>
      <PageSections type="card" className="flex flex-col">
        <PageControls
          controls={{
            primary: (
              <>
                <Input
                  placeholder="Search"
                  config={{
                    size: 'base',
                    color: 'gray',
                    postfix: (
                      <HitArea
                        width={20}
                        height={20}
                        className="cursor-pointer"
                        onClick={() => {
                          setSearch('');
                        }}
                      >
                        <Icon type={IconType.X} width={10} height={10} />
                      </HitArea>
                    ),
                    prefix: <Icon type={IconType.Search} width={14} height={14} />,
                  }}
                  value={search}
                  onChange={(event) => {
                    setSearch(event.target.value || '');
                  }}
                />
                <FilterDropdown
                  selections={filterSelections}
                  onChange={(value) => setFilterSelections(value)}
                  list={filters}
                  appearance={{
                    size: 'base',
                  }}
                />
              </>
            ),
            secondary: <Pagination actions={pagination} total={data?.total ?? 0} />,
          }}
        />
        <FilterSelections
          selections={filterSelections}
          onChange={(value) => setFilterSelections(value)}
          list={filters}
        />
        <div className="flex flex-col w-full gap-large">
          {asks &&
            (Array.isArray(asks) ? asks : [])
              .filter((ask) => {
                return search
                  ? ask?.__carbonProject__?.name?.toLocaleLowerCase()?.startsWith?.(search.trim().toLocaleLowerCase())
                  : true;
              })
              .map((ask) => {
                const ccyAsset = currenciesObjById?.[ask?.quoteAssetId];
                const newBids = ask?.__carbonEmbBids__?.filter((bid: { status: string }) => bid.status === 'NEW');
                const confirmedBids = ask?.__carbonEmbBids__?.filter((bid: { status: string }) =>
                  ['CONFIRMED_BY_SELLER', 'DONE'].includes(bid.status),
                );
                const project = { ...ask?.__carbonProject__, vintageYear: ask?.carbonProjectVintageYear };
                const isObo = ask.userId !== ask.createdBy; // if bid was placed by member
                const tokenAsset = (ask.__tokenAsset__ as any | undefined) || {};
                return (
                  <MarketPlaceProjectCard
                    key={ask.id}
                    info={{
                      onClick: () => {
                        canManageCmbBid() && history.push(`/account/market-board/my-listings/${ask.id}`);
                      },
                      tokenAsset,
                      carbonProject: project,
                      id: `Project #${ask.id}`,
                      carbonProjectVintageYear: ask?.carbonProjectVintageYear,
                      indicators: [`New Bids: ${newBids?.length || 0}`, `Accepted Bids: ${confirmedBids?.length || 0}`],
                    }}
                    controls={
                      <>
                        <div className="flex flex-row justify-between whitespace-pre gap-base">
                          <div>
                            <Text
                              color={Colors.gray_500}
                              size={FontSize.small}
                              background={Colors.gray_200}
                              spacing={Spacing.small}
                              spacingTB={Spacing._2xs}
                            >
                              {askStatusLabel(ask)}
                            </Text>
                          </div>
                        </div>

                        <div className="flex flex-row justify-between whitespace-pre gap-base">
                          <div className="flex flex-col gap-xs">
                            <Text color={Colors.gray_500} size={FontSize.small}>
                              Price ({ccyAsset?.symbol})
                            </Text>
                            <Text color={Colors.gray_900} size={FontSize._2Xl} weight={FontWeight.semibold}>
                              {ccyAsset?.code}
                              {formatter.formatNumber(ask.price, ccyAsset?.numDecimals)}
                            </Text>
                          </div>
                          <div className="flex flex-col gap-xs">
                            <Text color={Colors.gray_500} size={FontSize.small}>
                              Listed Qty ({ask?.__tokenAsset__?.__uom__?.code || tokenUnit})
                            </Text>
                            <Text color={Colors.gray_900} size={FontSize._2Xl} weight={FontWeight.semibold}>
                              {formatter.formatNumber(ask?.quantity - ask?.openBidsTotalQty, 0)}{' '}
                            </Text>
                          </div>
                        </div>
                        <div className="flex flex-col w-full rounded-md bg-gray_100 p-small gap-3xs">
                          <Text color={Colors.gray_500} size={FontSize.small}>
                            Initial: {formatter.formatNumber(ask?.quantity, 0)}{' '}
                            {ask?.__tokenAsset__?.__uom__?.code || tokenUnit}
                          </Text>
                          <Text color={Colors.gray_500} size={FontSize.small}>
                            Open: {formatter.formatNumber(ask?.openBidsTotalQty, 0)}{' '}
                            {ask?.__tokenAsset__?.__uom__?.code || tokenUnit}
                          </Text>
                        </div>

                        <div className="flex flex-col">
                          <Text color={Colors.gray_500} size={FontSize.small}>
                            Account: {ask.__user__?.firstName} {ask.__user__?.lastName} [{ask.userId}]
                          </Text>

                          <Text color={Colors.gray_500} size={FontSize.small}>
                            Placed by: {ask.__createdByUser__?.firstName} {ask.__createdByUser__?.lastName} [
                            {ask.createdBy}]
                          </Text>
                        </div>
                        <div className="flex flex-row justify-between whitespace-pre gap-base">
                          {canEditCmbAsk() && (
                            <div className="flex flex-row flex-1 gap-base">
                              <Modal
                                title={'Edit Project Offering'}
                                action={
                                  <Button
                                    className="flex-1"
                                    disabled={marketSettings?.otcEditEnabled === 0}
                                    config={{
                                      color: 'outlined',
                                      size: 'base',
                                    }}
                                  >
                                    Edit <Icon type={IconType.Pencil} width={14} height={14} />
                                  </Button>
                                }
                              >
                                {({ onClose }) => {
                                  return (
                                    <EditAskForm
                                      lotToTons={ask?.__tokenAsset__?.lotQtySize || 1}
                                      minLotQty={askMinLots}
                                      maxLotQty={askMaxLots}
                                      bidMinLots={bidMinLots}
                                      ask={ask}
                                      onSubmit={() => {
                                        onClose();
                                        queryCache.invalidateQueries(getAskQueryKey);
                                      }}
                                    />
                                  );
                                }}
                              </Modal>
                            </div>
                          )}
                          {canManageCmbBid() && (
                            <div className="flex flex-row flex-1 gap-base">
                              <Button
                                className="flex-1"
                                config={{
                                  color: 'secondary',
                                  size: 'base',
                                }}
                                onClick={() => {
                                  history.push(`/account/market-board/my-listings/${ask.id}`);
                                }}
                              >
                                Bids <Icon type={IconType.Bids} width={14} height={14} />
                              </Button>
                            </div>
                          )}
                        </div>
                      </>
                    }
                  />
                );
              })}
          {(isLoading || isLoadingMarketSettings) && (
            <div className="p-large">
              <Loading isOverLay={false} />
            </div>
          )}
          {!isLoading && (Array.isArray(asks) ? asks : []).length === 0 && (
            <Empty
              title="No Listings"
              description="You have no listings yet. Your market board listings will show up here."
            />
          )}
          {/* TODO: better control this scenario. Perhaps telling the Pagination component if location is top or bottom. */}
          {/* If total items outside of view show bottom pagination */}
          {(data?.total ?? 0) > 4 && (
            <PageControls
              controls={{
                secondary: <Pagination actions={pagination} total={data?.total ?? 0} />,
              }}
            />
          )}
        </div>
      </PageSections>
    </PageHolder>
  );
};
