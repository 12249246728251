import { ProjectsListFilters } from '../components/MyProjects/components/ProjectsList';
import { QueryParamKey } from '../components/MyProjects/utils/QueryParams';
import { toApiProjectStatus } from '../components/MyProjects/utils/toApiProjectStatus';

export const toQueryParamsFromFilters = (filters: ProjectsListFilters) => {
  const queryParams: Partial<Record<QueryParamKey, string>> = {};

  const statusQuery = toApiProjectStatus(filters.status);

  if (statusQuery !== null) {
    queryParams[QueryParamKey.Status] = statusQuery;
  }

  if (filters.ccbStandards?.length) {
    queryParams[QueryParamKey.CcbStandards] = filters.ccbStandards.join(',');
  }

  if (filters.projectTypes?.length) {
    queryParams[QueryParamKey.ProjectTypes] = filters.projectTypes.join(',');
  }

  if (filters.sectoralScopes?.length) {
    queryParams[QueryParamKey.SectoralScopes] = filters.sectoralScopes.join(',');
  }

  if (filters.vintageYear?.from !== undefined) {
    queryParams[QueryParamKey.VintageYearFrom] = String(filters.vintageYear?.from);
  }

  if (filters.vintageYear?.to !== undefined) {
    queryParams[QueryParamKey.VintageYearTo] = String(filters.vintageYear?.to);
  }

  return queryParams;
};
