import { UserOpenTokenBalance } from '@aircarbon/utils-common/src/dto/user';

import { userAuthenticatedApi } from '../fetcher';

export const fetchUserOpenTokenBalance = async (accountAddress: string) => {
  const endpoint = '/oms/account/open-token-balance';
  const userService = await userAuthenticatedApi();
  const res = userService.url(endpoint).query({ accountAddress }).get();
  return res.json<UserOpenTokenBalance>();
};
