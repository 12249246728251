import { MyEmbAsk } from '@aircarbon/utils-common/src/dto/carbon';

import { userAuthenticatedApi } from 'data-provider/fetcher';

export const fetchMyCMBAsk = async (askId: string) => {
  const endpoint = `/user/carbon/my-cmb-ask/${askId}`;

  const userService = await userAuthenticatedApi();
  const res = userService.url(endpoint).get();
  return res.json<MyEmbAsk>();
};
