import { Auth } from 'aws-amplify';
import wretch, { FetchLike, WretchOptions } from 'wretch';

import { logger } from '@aircarbon/utils-common';

export type ApiResponse<T> = { status: 'success' | 'fail' | 'error'; code?: number; data?: T };

export type ApiMutationError = {
  message: string;
  status: number;
  text: string;
  url: string;
  json: {
    code: number;
    message: string;
    name: string;
    data?: any;
    type?: string;
  };
};
/**
 * Logger middleware.
 * Prints the request method and url.
 */
function loggerMiddleware() {
  return (next: FetchLike) => (url: string, opts: WretchOptions) => {
    logger.info(`MUTATOR: ${opts.method}@${url}`);
    return next(url, opts);
  };
}

async function getAuthToken() {
  const user = await Auth.currentSession();
  return `Bearer ${user.getAccessToken().getJwtToken()}`;
}

export const externalApi = wretch('/api', { mode: 'cors' }).middlewares([loggerMiddleware()]).errorType('json');

export const userAuthenticatedApi = async () => {
  const authToken = await getAuthToken();
  return externalApi.auth(authToken);
};
