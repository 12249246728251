import { Colors, Radius } from 'refreshed-component/design-system';
import styled from 'styled-components';

import { ThemeColors } from 'pages/account/trading/components/Themes';

import { ScreenSizes } from 'state/ui';

export const Wrapper = styled.div`
  .pagination-wrapper {
    margin-left: auto;

    & > div {
      display: flex;
      flex-direction: row;
      gap: 1rem;
      align-items: center;
    }
  }
`;

export const BidTableWrapper = styled.div<{ screenSizes?: ScreenSizes; themeColor: ThemeColors }>`
  & > table {
    table-layout: fixed;

    th,
    td {
      width: ${(props) => (props.screenSizes === 'small' || props.screenSizes === 'medium' ? 'auto' : `120px`)};
      overflow: hidden;
    }

    thead th {
      position: sticky;
      top: 0;
      z-index: 9;
    }

    td {
      border-top: 2px solid #000;
    }

    td.criteria {
      position: relative;
      min-width: 120px;
    }

    .criteria {
      width: 100%;
      min-width: 120px;
    }
  }
`;

export const BidExpandWrapper = styled.div`
  background: var(${Colors.gray_0});
  border: 1px solid var(${Colors.gray_900});
  padding: 1.5rem;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 90;
  width: 100%;
  border-radius: var(${Radius.medium});
`;
export const BidExpandClose = styled.div`
  position: absolute;
  right: 1rem;
  top: 1rem;
  cursor: pointer;
  z-index: 1;
`;
export const BidsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
  padding: 10px 1rem;
`;
export const CriteriaWrapper = styled.div<{ screenSize?: ScreenSizes }>`
  display: flex;
  flex-wrap: nowrap;
  gap: 10px;
  width: 100%;
  align-items: center;

  ${(props) => {
    if (props.screenSize === 'small' || props.screenSize === 'medium') {
      return ``;
    } else {
      return `
        position: relative;
        width: auto;
        padding-left: 1em; 
      `;
    }
  }}
`;
