import helpers from './helpers';
import { groupSectors } from './metadata';

export const countryNames: Record<string, string> = {
  AF: 'Afghanistan',
  AX: 'Aland Islands',
  AL: 'Albania',
  DZ: 'Algeria',
  AS: 'American Samoa',
  AD: 'Andorra',
  AO: 'Angola',
  AI: 'Anguilla',
  AQ: 'Antarctica',
  AG: 'Antigua And Barbuda',
  AR: 'Argentina',
  AM: 'Armenia',
  AW: 'Aruba',
  AU: 'Australia',
  AT: 'Austria',
  AZ: 'Azerbaijan',
  BS: 'Bahamas',
  BH: 'Bahrain',
  BD: 'Bangladesh',
  BB: 'Barbados',
  BY: 'Belarus',
  BE: 'Belgium',
  BZ: 'Belize',
  BJ: 'Benin',
  BM: 'Bermuda',
  BT: 'Bhutan',
  BO: 'Bolivia',
  BA: 'Bosnia And Herzegovina',
  BW: 'Botswana',
  BV: 'Bouvet Island',
  BR: 'Brazil',
  IO: 'British Indian Ocean Territory',
  BN: 'Brunei Darussalam',
  BG: 'Bulgaria',
  BF: 'Burkina Faso',
  BI: 'Burundi',
  KH: 'Cambodia',
  CM: 'Cameroon',
  CA: 'Canada',
  CV: 'Cape Verde',
  KY: 'Cayman Islands',
  CF: 'Central African Republic',
  TD: 'Chad',
  CL: 'Chile',
  CN: 'China',
  CX: 'Christmas Island',
  CC: 'Cocos (Keeling) Islands',
  CO: 'Colombia',
  KM: 'Comoros',
  CG: 'Congo',
  CD: 'Congo, Democratic Republic',
  CK: 'Cook Islands',
  CR: 'Costa Rica',
  CI: "Cote D'Ivoire",
  HR: 'Croatia',
  CU: 'Cuba',
  CY: 'Cyprus',
  CZ: 'Czech Republic',
  DK: 'Denmark',
  DJ: 'Djibouti',
  DM: 'Dominica',
  DO: 'Dominican Republic',
  EC: 'Ecuador',
  EG: 'Egypt',
  SV: 'El Salvador',
  GQ: 'Equatorial Guinea',
  ER: 'Eritrea',
  EE: 'Estonia',
  ET: 'Ethiopia',
  FK: 'Falkland Islands (Malvinas)',
  FO: 'Faroe Islands',
  FJ: 'Fiji',
  FI: 'Finland',
  FR: 'France',
  GF: 'French Guiana',
  PF: 'French Polynesia',
  TF: 'French Southern Territories',
  GA: 'Gabon',
  GM: 'Gambia',
  GE: 'Georgia',
  DE: 'Germany',
  GH: 'Ghana',
  GI: 'Gibraltar',
  GR: 'Greece',
  GL: 'Greenland',
  GD: 'Grenada',
  GP: 'Guadeloupe',
  GU: 'Guam',
  GT: 'Guatemala',
  GG: 'Guernsey',
  GN: 'Guinea',
  GW: 'Guinea-Bissau',
  GY: 'Guyana',
  HT: 'Haiti',
  HM: 'Heard Island & Mcdonald Islands',
  VA: 'Holy See (Vatican City State)',
  HN: 'Honduras',
  HK: 'Hong Kong',
  HU: 'Hungary',
  IS: 'Iceland',
  IN: 'India',
  ID: 'Indonesia',
  IR: 'Iran, Islamic Republic Of',
  IQ: 'Iraq',
  IE: 'Ireland',
  IM: 'Isle Of Man',
  IL: 'Israel',
  IT: 'Italy',
  JM: 'Jamaica',
  JP: 'Japan',
  JE: 'Jersey',
  JO: 'Jordan',
  KZ: 'Kazakhstan',
  KE: 'Kenya',
  KI: 'Kiribati',
  KR: 'Korea',
  KW: 'Kuwait',
  KG: 'Kyrgyzstan',
  LA: "Lao People's Democratic Republic",
  LV: 'Latvia',
  LB: 'Lebanon',
  LS: 'Lesotho',
  LR: 'Liberia',
  LY: 'Libyan Arab Jamahiriya',
  LI: 'Liechtenstein',
  LT: 'Lithuania',
  LU: 'Luxembourg',
  MO: 'Macao',
  MK: 'Macedonia',
  MG: 'Madagascar',
  MW: 'Malawi',
  MY: 'Malaysia',
  MV: 'Maldives',
  ML: 'Mali',
  MT: 'Malta',
  MH: 'Marshall Islands',
  MQ: 'Martinique',
  MR: 'Mauritania',
  MU: 'Mauritius',
  YT: 'Mayotte',
  MX: 'Mexico',
  FM: 'Micronesia, Federated States Of',
  MD: 'Moldova',
  MC: 'Monaco',
  MN: 'Mongolia',
  ME: 'Montenegro',
  MS: 'Montserrat',
  MA: 'Morocco',
  MZ: 'Mozambique',
  MM: 'Myanmar',
  NA: 'Namibia',
  NR: 'Nauru',
  NP: 'Nepal',
  NL: 'Netherlands',
  AN: 'Netherlands Antilles',
  NC: 'New Caledonia',
  NZ: 'New Zealand',
  NI: 'Nicaragua',
  NE: 'Niger',
  NG: 'Nigeria',
  NU: 'Niue',
  NF: 'Norfolk Island',
  MP: 'Northern Mariana Islands',
  NO: 'Norway',
  OM: 'Oman',
  PK: 'Pakistan',
  PW: 'Palau',
  PS: 'Palestinian Territory, Occupied',
  PA: 'Panama',
  PG: 'Papua New Guinea',
  PY: 'Paraguay',
  PE: 'Peru',
  PH: 'Philippines',
  PN: 'Pitcairn',
  PL: 'Poland',
  PT: 'Portugal',
  PR: 'Puerto Rico',
  QA: 'Qatar',
  RE: 'Reunion',
  RO: 'Romania',
  RU: 'Russian Federation',
  RW: 'Rwanda',
  BL: 'Saint Barthelemy',
  SH: 'Saint Helena',
  KN: 'Saint Kitts And Nevis',
  LC: 'Saint Lucia',
  MF: 'Saint Martin',
  PM: 'Saint Pierre And Miquelon',
  VC: 'Saint Vincent And Grenadines',
  WS: 'Samoa',
  SM: 'San Marino',
  ST: 'Sao Tome And Principe',
  SA: 'Saudi Arabia',
  SN: 'Senegal',
  RS: 'Serbia',
  SC: 'Seychelles',
  SL: 'Sierra Leone',
  SG: 'Singapore',
  SK: 'Slovakia',
  SI: 'Slovenia',
  SB: 'Solomon Islands',
  SO: 'Somalia',
  ZA: 'South Africa',
  GS: 'South Georgia And Sandwich Isl.',
  ES: 'Spain',
  LK: 'Sri Lanka',
  SD: 'Sudan',
  SR: 'Suriname',
  SJ: 'Svalbard And Jan Mayen',
  SZ: 'Swaziland',
  SE: 'Sweden',
  CH: 'Switzerland',
  SY: 'Syrian Arab Republic',
  TW: 'Taiwan',
  TJ: 'Tajikistan',
  TZ: 'Tanzania',
  TH: 'Thailand',
  TL: 'Timor-Leste',
  TG: 'Togo',
  TK: 'Tokelau',
  TO: 'Tonga',
  TT: 'Trinidad And Tobago',
  TN: 'Tunisia',
  TR: 'Turkey',
  TM: 'Turkmenistan',
  TC: 'Turks And Caicos Islands',
  TV: 'Tuvalu',
  UG: 'Uganda',
  UA: 'Ukraine',
  AE: 'United Arab Emirates',
  GB: 'United Kingdom',
  US: 'United States',
  UM: 'United States Outlying Islands',
  UY: 'Uruguay',
  UZ: 'Uzbekistan',
  VU: 'Vanuatu',
  VE: 'Venezuela',
  VN: 'Viet Nam',
  VG: 'Virgin Islands, British',
  VI: 'Virgin Islands, U.S.',
  WF: 'Wallis And Futuna',
  EH: 'Western Sahara',
  YE: 'Yemen',
  ZM: 'Zambia',
  ZW: 'Zimbabwe',
};

// NOTE: List of provinces is left for backward compatibility. Now a province of a project is stored as a free text in DB.
export const provinces: any[] = [
  { id: 1, name: 'Aceh', countryCode: 'ID' },
  { id: 2, name: 'North Sumatra', countryCode: 'ID' },
  { id: 3, name: 'West Sumatra', countryCode: 'ID' },
  { id: 4, name: 'Riau', countryCode: 'ID' },
  { id: 5, name: 'Riau Islands', countryCode: 'ID' },
  { id: 6, name: 'Jambi', countryCode: 'ID' },
  { id: 7, name: 'Bengkulu', countryCode: 'ID' },
  { id: 8, name: 'South Sumatra', countryCode: 'ID' },
  { id: 9, name: 'Bangka Belitung', countryCode: 'ID' },
  { id: 10, name: 'Lampung', countryCode: 'ID' },
  { id: 11, name: 'Banten', countryCode: 'ID' },
  { id: 12, name: 'Jakarta SCR', countryCode: 'ID' },
  { id: 13, name: 'West Java', countryCode: 'ID' },
  { id: 14, name: 'Central Java', countryCode: 'ID' },
  { id: 15, name: 'Yogyakarta SR', countryCode: 'ID' },
  { id: 16, name: 'East Java', countryCode: 'ID' },
  { id: 17, name: 'Bali', countryCode: 'ID' },
  { id: 18, name: 'West Nusa Tenggara', countryCode: 'ID' },
  { id: 19, name: 'East Nusa Tenggara', countryCode: 'ID' },
  { id: 20, name: 'West Borneo', countryCode: 'ID' },
  { id: 21, name: 'Central Borneo', countryCode: 'ID' },
  { id: 22, name: 'South Borneo', countryCode: 'ID' },
  { id: 23, name: 'East Borneo', countryCode: 'ID' },
  { id: 24, name: 'North Borneo', countryCode: 'ID' },
  { id: 25, name: 'North Sulawesi', countryCode: 'ID' },
  { id: 26, name: 'Gorontalo', countryCode: 'ID' },
  { id: 27, name: 'Central Sulawesi', countryCode: 'ID' },
  { id: 28, name: 'West Sulawesi', countryCode: 'ID' },
  { id: 29, name: 'South Sulawesi', countryCode: 'ID' },
  { id: 30, name: 'Southeast Sulawesi', countryCode: 'ID' },
  { id: 31, name: 'North Maluku', countryCode: 'ID' },
  { id: 32, name: 'Maluku', countryCode: 'ID' },
  { id: 33, name: 'West Papua', countryCode: 'ID' },
  { id: 34, name: 'Papua', countryCode: 'ID' },
];

export const projectLabel: Record<string, any> = {
  TXT_REGISTRY: 'Registry',
  TXT_VERIFIED_SERIAL_BLOCK: 'Verified Serial Block Range',
  TXT_MINTED_SERIAL_BLOCK: 'Minted Serial Block Range',
  TXT_PROJECT_ID: 'Project ID',
  TXT_PROJECT_NAME: 'Project Name',
  TXT_PROJECT_DESCRIPTION: 'Project Description',
  TXT_PROJECT_DEVELOPER: 'Project Developer',
  URL_PROJECT_REGISTRY: 'Project Registry URL',
  URL_PROJECT_IMG: 'Project Image URL',
  LIST_COUNTRY: 'Country',
  TXT_VERIFIER_REFERENCE_NUMBER: 'Verifier Reference Number',
  TXT_CARBON_MITIGATION_TYPE: 'Carbon Mitigation Type',
  TXT_REGISTRY_METHODOLOGY: 'Methodology',
  TXT_UN_SECTORAL_SCOPE: 'UN Sectoral Scope',
  TXT_SDG_DECLARATION_LEVEL: 'SDG Declaration Level',
  LIST_SDG_CONTRIBUTIONS: 'SDG Contributions',
  TXT_CCB_STANDARD: 'CCB Standard',
  LIST_CCB_STANDARDS: 'CCB Standards',
  LIST_OTHER_CERTIFICATIONS: 'Other Certifications',
  TXT_PROJECT_LOCATION: 'Location',
  TXT_PROJECT_CREDITING_PERIOD: 'Crediting Period',
  TXT_PROJECT_AMOUNT_OF_REDUCTIONS: 'Amount of Reductions',
  TXT_MINTED_SERIAL_START: 'Minted Serial Number Start',
  TXT_MINTED_SERIAL_END: 'Minted Serial Number End',
  DATE_VINTAGE_START: 'Vintage Start',
  DATE_VINTAGE_END: 'Vintage End',
  IPFS_PROJECT_FILE: 'Project File',
  URL_ISSUANCE: 'Registry Issuance URL',
  IPFS_PROJECT_DOCUMENT: 'Project Document',
  IPFS_ISSUANCE_DOCUMENT: 'Issuance Document',
  IPFS_PROJECT_DOCUMENT_SALES: 'Sales Document',
  IPFS_PROJECT_DOCUMENT_LEGAL: 'Legal Document',
  IPFS_PROJECT_DOCUMENT_PROSPECTUS: 'Prospectus Document',
  IPFS_PROJECT_PROOF_OF_ITMO: 'Proof of International Transfer of Mitigation Outcome',
  URL_PROJECT: 'Project URL',
  TXT_VINTAGE_YEAR: 'Vintage Year',
  CALCULATED_VINTAGE_YEAR: 'Serial Block Vintage Year',
  // EU-ETS - Union Registry fields
  TXT_UNIT_TYPE: 'Unit Type',
  TXT_ETS_PHASE: 'EU Emissions Trading System Phase',
  TXT_STATE_OR_PROVINCE: 'State Or Province',
  TXT_INITIAL_COMPLIANCE_YEAR: 'Initial Year Of Compliance',
  TXT_CARBON_UNIT_TYPE: 'Type Of Carbon Unit',
  TXT_SECTOR: 'Sector',
  TXT_SUB_SECTOR: 'Sub Sector',
  TXT_CARBON_STANDARD: 'Carbon Standard',
  TXT_MATURITY_ALLOWANCE_DATE: 'Maturity Date Of Allowance',
};

// TODO UN_SectoralScopes
export const projectSectoralScopes: Array<string> = [
  '1:Energy industries (renewable - / non-renewable sources)',
  '2:Energy distribution',
  '3:Energy demand',
  '4:Manufacturing industries',
  '5:Chemical industry',
  '6:Construction',
  '7:Transport',
  '8:Mining/Mineral production',
  '9:Metal production',
  '10:Fugitive emissions from fuels (solid, oil and gas)',
  '11:Fugitive emissions from production and consumption of halocarbons and sulphur hexafluoride',
  '12:Solvents use',
  '13:Waste handling and disposal',
  '14:Afforestation and reforestation',
  '15:Agriculture',
  '16:Other',
];

export const projectSDGGoals: Array<string> = [
  '1:No Poverty',
  '2:Zero Hunger',
  '3:Good Health and Well-being',
  '4:Quality Education',
  '5:Gender Equality',
  '6:Clean Water and Sanitation',
  '7:Affordable and Clean Energy',
  '8:Decent Work and Economic Growth',
  '9:Industry, Innovation and Infrastructure',
  '10:Reduced Inequality',
  '11:Sustainable Cities and Communities',
  '12:Responsible Consumption and Production',
  '13:Climate Action',
  '14:Life Below Water',
  '15:Life on Land',
  '16:Peace and Justice Strong Institutions',
  '17:Partnerships to achieve the Goal',
];

// TODO CCBCertifications
export const projectCCBStandards: Array<string> = [
  'Climate Gold',
  'Community Gold',
  'Biodiversity Gold',
  'Gold',
  'No distinctions',
];

export const projectClassification: Array<string> = ['Emission Reduction', 'Carbon Removal'];
export const SDGVerificationLevel: Array<string> = ['Certified SDG Impacts', 'Reported SDG Contributions', 'None'];
export const projectOtherCertifications: Array<string> = [
  'CORSIA Labelling',
  // TODO: this will be deprecated. Changing to Third Edition. Update all batches.
  'Climate, Community & Biodiversity (CCB) standards',
  'Climate, Community & Biodiversity (CCB) standards - Third Edition',
  'Climate, Community & Biodiversity (CCB) standards - Second Edition',
  'SD VISta by VERRA',
  'Social Carbon',
  'W+ Standard',
  'SDG Impact Quantification by Gold Standard',
];

export const registries: Array<string> = [
  'Gold Standard (GS) | Impact Registry',
  'Verified Carbon Standard (VCS) | VERRA Registry',
  // 'Climate Action Reserve (CAR)',
  'EU-ETS | Union Registry',
];

export const registryRetirementReasons = [
  {
    name: 'Gold Standard (GS) | Impact Registry',
    retirementReasons: [],
  },
  {
    name: 'Verified Carbon Standard (VCS) | VERRA Registry',
    retirementReasons: [
      'Environmental Benefit',
      'Retirement for Person or Organization',
      'Retail Program Requirements',
      'Compliance Requirements',
      'Green-e Climate Certification',
      'NCOS Programme',
      'Section 13 of the South African Carbon Tax Act',
      'Other',
    ],
  },
  {
    name: 'EU-ETS | Union Registry',
    retirementReasons: [],
  },
];

export const euETSPhases: Array<string> = ['Phase 3', 'Phase 4'];

export const methodology: Array<string> = [
  'ACM0002:Grid-connected electricity generation from renewable sources',
  'VM0015:Methodology for Avoided Unplanned Deforestation',
  'VM0015-REDD Methodology',
  'AM0023 ver. 4:Leak detection and repair in gas production, processing, transmission, storage and distribution systems and in refinery facilities',
  'AMS-I.D.:Grid connected renewable electricity generation',
  'AMS-I.E.:Switch from Non-Renewable Biomass for Thermal Applications by the User',
  'Methodology for Emission Reductions from Safe Drinking Water Supply (Version 1.0)',
];

export const bezeroRatings: Record<string, string> = {
  '0': 'AAA',
  '1': 'AA',
  '2': 'A',
  '3': 'BBB',
  '4': 'BB',
  '5': 'B',
  '6': 'C',
  '7': 'D',
};
export const bezeroRatingStatus: Record<string, string> = {
  '0': 'provisional',
  '1': 'final',
  '2': 'void',
};

export const typeOfAircarbonUnit: string[] = ['PTBAE-PU', 'SPE-GRK'];
export const carbonStandard: Record<string, string> = {
  GoldStandard: 'Gold Standard (GS)',
  VerifiedCarbonStandard: 'Verified Carbon Standard (VCS)',
  IndonesianStandard: 'Indonesian Standard',
  Other: 'Other',
};

export const getLastMetaKeyVersion = (metaKey: string, projectMetaKeys: Array<string>) => {
  const keys = projectMetaKeys
    .filter((key) => key === metaKey || key.includes(`${metaKey}:`))
    .sort((key1: string, key2: string) => {
      const [, version1] = key1.split(':v');
      const [, version2] = key2.split(':v');
      return Number(version1) - Number(version2);
    });
  return keys[keys.length - 1];
};

export const getNextMetakeyVersion = (metaKey: string, projectMetaKeys: Array<string>) => {
  const lastExistingMetaKey = getLastMetaKeyVersion(metaKey, projectMetaKeys);
  // NOTE: version 1 has no versioning (i.e. ':v2'), just plain key name i.e. 'TXT_BEZERO_RATING'
  // if no lastExistingMetakey found, then is version 1. If found, get current version.
  const currentVersion = lastExistingMetaKey ? lastExistingMetaKey?.split(':')?.[1]?.replace('v', '') ?? 1 : 0;

  return Number(currentVersion) > 0 ? `${metaKey}:v${Number(currentVersion) + 1}` : metaKey;
};

export const carbonProjectMetadataSchema = [
  {
    key: 'TXT_REGISTRY',
    label: projectLabel.TXT_REGISTRY,
    options: registries.map((value) => ({ value, label: value })),
    type: 'select',
    required: true,
    appliesToRegistry: [
      'Gold Standard (GS) | Impact Registry',
      'Verified Carbon Standard (VCS) | VERRA Registry',
      'EU-ETS | Union Registry',
    ],
    encrypted: false,
    publicView: true,
    userView: true,
    rfqForm: 2,
  },
  {
    key: 'TXT_UNIT_TYPE',
    label: projectLabel.TXT_UNIT_TYPE,
    options: ['EU-ETS EUA'].map((value) => ({ value, label: value })),
    type: 'select',
    required: true,
    appliesToRegistry: ['EU-ETS | Union Registry'],
    editable: false,
    publicView: false,
  },
  {
    key: 'TXT_ETS_PHASE',
    label: projectLabel.TXT_ETS_PHASE,
    options: euETSPhases.map((value) => ({ value, label: value })),
    type: 'select',
    appliesToRegistry: ['EU-ETS | Union Registry'],
    required: true,
    editable: true,
    encrypted: false,
  },
  {
    key: 'TXT_PROJECT_ID',
    label: projectLabel.TXT_PROJECT_ID,
    type: 'text',
    required: true,
    appliesToRegistry: ['Gold Standard (GS) | Impact Registry', 'Verified Carbon Standard (VCS) | VERRA Registry'],
    encrypted: false,
    publicView: true,
    userView: true,
  },
  {
    key: 'TXT_VERIFIER_REFERENCE_NUMBER',
    label: projectLabel.TXT_VERIFIER_REFERENCE_NUMBER,
    type: 'text',
    required: true,
    appliesToRegistry: ['Gold Standard (GS) | Impact Registry', 'Verified Carbon Standard (VCS) | VERRA Registry'],
    encrypted: false,
    publicView: false,
    userView: true,
    formValidationRegex: /[A-Z]_\d/g,
    formValidationErrorMessage:
      'Format must be verifier name in capital letters + underscore (_) + number. i.e. BSI_#######',
  },
  {
    key: 'TXT_PROJECT_NAME',
    label: projectLabel.TXT_PROJECT_NAME,
    type: 'text',
    appliesToRegistry: ['Gold Standard (GS) | Impact Registry', 'Verified Carbon Standard (VCS) | VERRA Registry'],
    required: true,
    encrypted: false,
    publicView: true,
    userView: true,
  },
  {
    key: 'TXT_PROJECT_DESCRIPTION',
    label: projectLabel.TXT_PROJECT_DESCRIPTION,
    type: 'text',
    required: true,
    appliesToRegistry: ['Gold Standard (GS) | Impact Registry', 'Verified Carbon Standard (VCS) | VERRA Registry'],
    encrypted: false,
    publicView: true,
    userView: true,
  },
  {
    key: 'TXT_PROJECT_DEVELOPER',
    label: projectLabel.TXT_PROJECT_DEVELOPER,
    type: 'text',
    required: true,
    appliesToRegistry: ['Gold Standard (GS) | Impact Registry', 'Verified Carbon Standard (VCS) | VERRA Registry'],
    encrypted: false,
    publicView: true,
    userView: true,
  },
  {
    key: 'URL_PROJECT_REGISTRY',
    label: projectLabel.URL_PROJECT_REGISTRY,
    type: 'url',
    required: true,
    appliesToRegistry: ['Gold Standard (GS) | Impact Registry', 'Verified Carbon Standard (VCS) | VERRA Registry'],
    encrypted: false,
    publicView: true,
    userView: true,
  },
  {
    key: 'URL_PROJECT_IMG',
    label: projectLabel.URL_PROJECT_IMG,
    type: 'text',
    required: false,
    appliesToRegistry: ['Gold Standard (GS) | Impact Registry', 'Verified Carbon Standard (VCS) | VERRA Registry'],
    encrypted: false,
    publicView: true,
    userView: true,
  },
  {
    key: 'LIST_COUNTRY',
    label: projectLabel.LIST_COUNTRY,
    type: 'multi-select',
    required: true,
    appliesToRegistry: ['Gold Standard (GS) | Impact Registry', 'Verified Carbon Standard (VCS) | VERRA Registry'],
    encrypted: false,
    options: Object.keys(countryNames).map((value) => ({ value, label: countryNames[value] })),
    publicView: true,
    userView: true,
    rfqForm: 3,
  },
  {
    key: 'TXT_CARBON_MITIGATION_TYPE',
    label: projectLabel.TXT_CARBON_MITIGATION_TYPE,
    type: 'select',
    options: projectClassification.map((value) => ({ value, label: value })),
    required: true,
    appliesToRegistry: ['Gold Standard (GS) | Impact Registry', 'Verified Carbon Standard (VCS) | VERRA Registry'],
    encrypted: false,
    publicView: false,
    userView: true,
    rfqForm: 7,
  },
  {
    key: 'TXT_REGISTRY_METHODOLOGY',
    label: projectLabel.TXT_REGISTRY_METHODOLOGY,
    type: 'select',
    options: methodology.map((value) => ({ value, label: value })),
    required: false,
    appliesToRegistry: ['Gold Standard (GS) | Impact Registry', 'Verified Carbon Standard (VCS) | VERRA Registry'],
    encrypted: false,
    publicView: true,
    userView: true,
    rfqForm: 8,
  },
  {
    key: 'TXT_UN_SECTORAL_SCOPE',
    label: projectLabel.TXT_UN_SECTORAL_SCOPE,
    type: 'select',
    options: projectSectoralScopes.map((value) => ({ value, label: value })),
    required: true,
    appliesToRegistry: ['Gold Standard (GS) | Impact Registry', 'Verified Carbon Standard (VCS) | VERRA Registry'],
    encrypted: false,
    publicView: false,
    userView: true,
    rfqForm: 4,
  },
  {
    key: 'TXT_SDG_DECLARATION_LEVEL',
    label: projectLabel.TXT_SDG_DECLARATION_LEVEL,
    type: 'select',
    options: SDGVerificationLevel.map((value) => ({ value, label: value })),
    required: false,
    appliesToRegistry: ['Gold Standard (GS) | Impact Registry', 'Verified Carbon Standard (VCS) | VERRA Registry'],
    encrypted: false,
    publicView: false,
    userView: true,
    rfqForm: 5,
  },
  {
    key: 'LIST_SDG_CONTRIBUTIONS',
    label: projectLabel.LIST_SDG_CONTRIBUTIONS,
    type: 'multi-select',
    options: projectSDGGoals.map((value) => ({ value, label: value })),
    required: false,
    appliesToRegistry: ['Gold Standard (GS) | Impact Registry', 'Verified Carbon Standard (VCS) | VERRA Registry'],
    encrypted: false,
    publicView: true,
    userView: true,
    rfqForm: 6,
  },
  {
    key: 'LIST_CCB_STANDARDS',
    label: projectLabel.LIST_CCB_STANDARDS,
    type: 'multi-select',
    options: projectCCBStandards.map((value) => ({ value, label: value })),
    required: false,
    appliesToRegistry: ['Gold Standard (GS) | Impact Registry', 'Verified Carbon Standard (VCS) | VERRA Registry'],
    encrypted: false,
    publicView: false,
    userView: true,
  },
  {
    key: 'TXT_CCB_STANDARD',
    label: projectLabel.TXT_CCB_STANDARD,
    type: 'multi-select',
    options: projectCCBStandards.map((value) => ({ value, label: value })),
    required: false,
    appliesToRegistry: ['Gold Standard (GS) | Impact Registry', 'Verified Carbon Standard (VCS) | VERRA Registry'],
    encrypted: false,
    publicView: false,
    userView: true,
  },
  {
    key: 'LIST_OTHER_CERTIFICATIONS',
    label: projectLabel.LIST_OTHER_CERTIFICATIONS,
    type: 'multi-select',
    options: projectOtherCertifications.map((value) => ({ value, label: value })),
    required: false,
    appliesToRegistry: ['Gold Standard (GS) | Impact Registry', 'Verified Carbon Standard (VCS) | VERRA Registry'],
    encrypted: false,
    publicView: true,
    userView: true,
  },
  {
    key: 'TXT_VERIFIED_SERIAL_BLOCK',
    label: projectLabel.TXT_VERIFIED_SERIAL_BLOCK,
    type: 'text',
    required: true,
    appliesToRegistry: ['Gold Standard (GS) | Impact Registry', 'Verified Carbon Standard (VCS) | VERRA Registry'],
    encrypted: false,
    publicView: false,
    userView: true,
    perBatch: true,
  },
  {
    key: 'TXT_MINTED_SERIAL_BLOCK',
    label: projectLabel.TXT_MINTED_SERIAL_BLOCK,
    type: 'text',
    required: true,
    appliesToRegistry: ['Gold Standard (GS) | Impact Registry', 'Verified Carbon Standard (VCS) | VERRA Registry'],
    encrypted: false,
    publicView: false,
    userView: true,
    perBatch: true,
  },
  {
    key: 'TXT_MINTED_SERIAL_START',
    label: projectLabel.TXT_MINTED_SERIAL_START,
    type: 'text',
    required: true,
    appliesToRegistry: ['Gold Standard (GS) | Impact Registry', 'Verified Carbon Standard (VCS) | VERRA Registry'],
    isCalculated: true,
    encrypted: false,
    publicView: false,
    userView: true,
    perBatch: true,
  },
  {
    key: 'TXT_MINTED_SERIAL_END',
    label: projectLabel.TXT_MINTED_SERIAL_END,
    type: 'text',
    required: true,
    appliesToRegistry: ['Gold Standard (GS) | Impact Registry', 'Verified Carbon Standard (VCS) | VERRA Registry'],
    isCalculated: true,
    encrypted: false,
    publicView: false,
    userView: true,
    perBatch: true,
  },
  {
    key: 'TXT_VINTAGE_YEAR',
    label: projectLabel.TXT_VINTAGE_YEAR,
    type: 'text',
    options: [],
    appliesToRegistry: [
      'Gold Standard (GS) | Impact Registry',
      'Verified Carbon Standard (VCS) | VERRA Registry',
      'SRN',
      'APPle Gatrik',
    ],
    required: false,
    encrypted: false,
    publicView: false,
    userView: true,
    perBatch: true,
  },
  {
    key: 'DATE_VINTAGE_START',
    label: projectLabel.DATE_VINTAGE_START,
    type: 'date',
    required: true,
    appliesToRegistry: ['Gold Standard (GS) | Impact Registry', 'Verified Carbon Standard (VCS) | VERRA Registry'],
    isCalculated: true,
    encrypted: false,
    publicView: true,
    userView: true,
    perBatch: true,
  },
  {
    key: 'DATE_VINTAGE_END',
    label: projectLabel.DATE_VINTAGE_END,
    type: 'date',
    required: true,
    appliesToRegistry: ['Gold Standard (GS) | Impact Registry', 'Verified Carbon Standard (VCS) | VERRA Registry'],
    isCalculated: true,
    encrypted: false,
    publicView: true,
    userView: true,
    perBatch: true,
  },
  {
    key: 'URL_ISSUANCE',
    label: projectLabel.URL_ISSUANCE,
    type: 'url',
    required: false,
    appliesToRegistry: ['Gold Standard (GS) | Impact Registry', 'Verified Carbon Standard (VCS) | VERRA Registry'],
    encrypted: false,
    publicView: false,
    userView: true,
    perBatch: true,
  },
  {
    key: 'IPFS_PROJECT_DOCUMENT',
    label: projectLabel.IPFS_PROJECT_DOCUMENT,
    type: 'IPFS',
    required: true,
    appliesToRegistry: ['Gold Standard (GS) | Impact Registry', 'Verified Carbon Standard (VCS) | VERRA Registry'],
    encrypted: false,
    publicView: false,
    userView: true,
    perBatch: true,
  },
  {
    key: 'TXT_INITIAL_COMPLIANCE_YEAR',
    label: projectLabel.TXT_INITIAL_COMPLIANCE_YEAR,
    type: 'text',
    appliesToRegistry: ['APPle Gatrik'],
    required: false,
    encrypted: false,
    publicView: false,
    userView: true,
    perBatch: true,
  },
  {
    key: 'TXT_STATE_OR_PROVINCE',
    label: projectLabel.TXT_STATE_OR_PROVINCE,
    type: 'text',
    appliesToRegistry: [
      'APPle Gatrik',
      'SRN',
      'Gold Standard (GS) | Impact Registry',
      'Verified Carbon Standard (VCS) | VERRA Registry',
    ],
    required: false,
    encrypted: false,
    publicView: false,
    userView: true,
    perBatch: true,
  },
  {
    key: 'TXT_CARBON_UNIT_TYPE',
    label: projectLabel.TXT_CARBON_UNIT_TYPE,
    type: 'select',
    options: typeOfAircarbonUnit.map((value) => ({ value, label: value })),
    appliesToRegistry: ['SRN'],
    required: false,
    encrypted: false,
    publicView: false,
    userView: true,
    perBatch: true,
  },
  {
    key: 'TXT_SECTOR',
    label: projectLabel.TXT_SECTOR,
    type: 'select',
    options: [],
    appliesToRegistry: ['SRN', 'APPle Gatrik'],
    required: false,
    encrypted: false,
    publicView: false,
    userView: true,
    perBatch: true,
  },
  {
    key: 'TXT_SUB_SECTOR',
    label: projectLabel.TXT_SUB_SECTOR,
    type: 'select',
    options: [],
    appliesToRegistry: ['SRN', 'APPle Gatrik'],
    required: false,
    encrypted: false,
    publicView: false,
    userView: true,
    perBatch: true,
  },
  {
    key: 'TXT_CARBON_STANDARD',
    label: projectLabel.TXT_CARBON_STANDARD,
    type: 'select',
    options: Object.keys(carbonStandard).map((key) => ({ value: carbonStandard[key], label: carbonStandard[key] })),
    appliesToRegistry: ['SRN', 'APPle Gatrik'],
    required: false,
    encrypted: false,
    publicView: false,
    userView: true,
    perBatch: true,
  },
  {
    key: 'TXT_MATURITY_ALLOWANCE_DATE',
    label: projectLabel.TXT_MATURITY_ALLOWANCE_DATE,
    type: 'date',
    appliesToRegistry: ['APPle Gatrik'],
    required: false,
    encrypted: false,
    publicView: false,
    userView: true,
    perBatch: true,
  },
];

export const carbonProjectMetadataFilters = () =>
  carbonProjectMetadataSchema.map((field) => ({
    label: field.label,
    options: field.options,
  }));

export const getProjectMetadataSchema = () => carbonProjectMetadataSchema;

export const getProjectMetadataSchemaBasedOnBatch = (
  batch: { metaKeys: string[]; metaValues: string[] },
  sectors: any[],
) => {
  if (!batch?.metaKeys || !batch?.metaValues) {
    return carbonProjectMetadataSchema;
  }

  const metadataSchema = structuredClone(carbonProjectMetadataSchema);
  const sectorSchema: Record<string, any> = metadataSchema.find((f: any) => f.key === 'TXT_SECTOR') || {};
  const groupedSectors: Record<string, any> = groupSectors(sectors) || {};
  sectorSchema.options = Object.keys(groupedSectors).map((k: any) => ({ value: k, label: k }));

  const subSectorSchema: Record<string, any> = metadataSchema.find((f: any) => f.key === 'TXT_SUB_SECTOR') || {};
  const latestSectorKeyVersion = getLastMetaKeyVersion('TXT_SECTOR', batch?.metaKeys);
  const latestSectorEncoded = batch.metaValues[batch.metaKeys.indexOf(latestSectorKeyVersion)];
  const latestSector = helpers.revealMessage(latestSectorEncoded);
  subSectorSchema.options = groupedSectors[latestSector]?.map((s: any) => ({
    value: s.subSector,
    label: s.subSector,
  }));

  return metadataSchema;
};

/**
 *
 * @param metaKey key identifying the project metadata
 * @param value value of project metadata
 * @returns the label of the metadata value if any
 */
export const getProjectMetaValueLabel = (metaKey: string, value: string) => {
  const projectMetaSchema = getProjectMetadataSchema();
  const options = projectMetaSchema.find((schema) => schema.key === metaKey)?.options;
  return options?.find((option) => option.value === value)?.label ?? value;
};

// NOTE: these are fake projects, only for auto funding on DEV, UAT and DEMO environment
export const projects: Record<string, Array<Record<string, string>>> = {
  nature: [
    {
      TXT_VERIFIER_REFERENCE_NUMBER: 'BSI_3346566',
      TXT_REGISTRY: 'Verified Carbon Standard (VCS) | VERRA Registry',
      TXT_PROJECT_ID: 'C111',
      TXT_PROJECT_NAME: 'Mississippi Alluvial Valley Bio-Diversity & Reforestation Project',
      TXT_PROJECT_DESCRIPTION: 'Reforestation in the Mississippi area',
      TXT_PROJECT_DEVELOPER: 'Mississippi Green Project',
      LIST_COUNTRY: 'US',
      TXT_UN_SECTORAL_SCOPE: '14:Afforestation and reforestation',
      TXT_SDG_DECLARATION_LEVEL: 'Reported SDG Contributions',
      TXT_CARBON_MITIGATION_TYPE: 'Carbon Removal',
      LIST_SDG_CONTRIBUTIONS:
        '3:Good Health and Well-being|6:Clean Water and Sanitation|8:Decent Work and Economic Growth',
      URL_PROJECT_REGISTRY: 'https://demo.aircarbon.co/project',
      IPFS_PROJECT_DOCUMENT: 'QmRfxRKQ6tKzKEvsLPeC1daYapcFcAeBbLBsqgRRwTvK8p',
      TXT_VERIFIED_SERIAL_BLOCK: 'C111-8073803-8085003-VCS-VCU-261-VER-US-14-C111-01012017-31122015-1',
      TXT_MINTED_SERIAL_BLOCK: 'C111-8073803-8085003-VCS-VCU-261-VER-US-14-C111-01012017-31122015-1',
      URL_PROJECT_IMG:
        'https://demo.aircarbon.co/projects/Mississippi-Alluvial-Valley-Bio-Diversity-Reforestation-Project.png',
      DATE_VINTAGE_START: '01012017',
      DATE_VINTAGE_END: '31122017',
    },
    {
      TXT_VERIFIER_REFERENCE_NUMBER: 'BSI_3347766',
      TXT_REGISTRY: 'Verified Carbon Standard (VCS) | VERRA Registry',
      TXT_PROJECT_ID: 'C3A4',
      TXT_PROJECT_NAME: 'Rural Forest Carbon Project - Snoqualmie',
      TXT_PROJECT_DESCRIPTION: 'Rural Forest Carbon Project',
      TXT_PROJECT_DEVELOPER: 'Snoqualmie',
      LIST_COUNTRY: 'US',
      TXT_UN_SECTORAL_SCOPE: '14:Afforestation and reforestation',
      TXT_SDG_DECLARATION_LEVEL: 'Reported SDG Contributions',
      LIST_SDG_CONTRIBUTIONS: '4:Quality Education|6:Clean Water and Sanitation|8:Decent Work and Economic Growth',
      TXT_CARBON_MITIGATION_TYPE: 'Emission Reduction',
      URL_PROJECT_REGISTRY: 'https://demo.aircarbon.co/project',
      IPFS_PROJECT_DOCUMENT: 'QmRfxRKQ6tKzKEvsLPeC1daYapcFcAeBbLBsqgRRwTvK8p',
      TXT_MINTED_SERIAL_BLOCK: 'C3A4-8073803-8085003-VCS-VCU-261-VER-US-14-C3A4-01012015-31122015-1',
      TXT_VERIFIED_SERIAL_BLOCK: 'C3A4-8073803-8085003-VCS-VCU-261-VER-US-14-C3A4-01012015-31122015-1',
      URL_PROJECT_IMG: 'https://demo.aircarbon.co/projects/Rural-Forest-Carbon-Project-Snoqualmie.png',
      DATE_VINTAGE_START: '01012015',
      DATE_VINTAGE_END: '31122015',
    },
  ],
  corsia: [
    {
      TXT_REGISTRY: 'Verified Carbon Standard (VCS) | VERRA Registry',
      TXT_VERIFIER_REFERENCE_NUMBER: 'BSI_3341876',
      TXT_PROJECT_ID: '1572',
      TXT_PROJECT_NAME: 'Southeast Asia Bio-CoGen Rice Husk Power',
      TXT_PROJECT_DESCRIPTION: 'Southeast Asia Bio-CoGen Rice Husk Power',
      TXT_PROJECT_DEVELOPER: 'Southeast Asia Bio-CoGen',
      LIST_COUNTRY: 'KH',
      TXT_UN_SECTORAL_SCOPE: '1:Energy industries (renewable - / non-renewable sources)',
      TXT_SDG_DECLARATION_LEVEL: 'Reported SDG Contributions',
      LIST_SDG_CONTRIBUTIONS: '4:Quality Education|6:Clean Water and Sanitation|8:Decent Work and Economic Growth',
      TXT_REGISTRY_METHODOLOGY: 'ACM0002:Grid-connected electricity generation from renewable sources',
      TXT_CARBON_MITIGATION_TYPE: 'Emission Reduction',
      URL_PROJECT_REGISTRY: 'https://demo.aircarbon.co/project',
      TXT_VERIFIED_SERIAL_BLOCK: '1572-8073803-8085003-VCS-VCU-261-VER-KH-14-1572-01012016-31122016-1',
      TXT_MINTED_SERIAL_BLOCK: '1572-8073803-8085003-VCS-VCU-261-VER-KH-14-1572-01012016-31122016-1',
      URL_PROJECT_IMG: 'https://demo.aircarbon.co/projects/Southeast-Asia-Bio-CoGen-Rice-Husk-Power.png',
      DATE_VINTAGE_START: '01012016',
      DATE_VINTAGE_END: '31122016',
    },
    {
      TXT_VERIFIER_REFERENCE_NUMBER: 'BSI_3347766',
      TXT_REGISTRY: 'Gold Standard (GS) | Impact Registry',
      TXT_PROJECT_ID: 'GS854',
      TXT_PROJECT_NAME: 'Balikesir Susurluk 45 MW Wind Farm Project (GS854)',
      TXT_PROJECT_DESCRIPTION: 'Wind farms in the Balikesir area',
      TXT_PROJECT_DEVELOPER: 'Wind Sustainable',
      LIST_COUNTRY: 'TR',
      TXT_UN_SECTORAL_SCOPE: '1:Energy industries (renewable - / non-renewable sources)',
      TXT_SDG_DECLARATION_LEVEL: 'Reported SDG Contributions',
      LIST_SDG_CONTRIBUTIONS: '3:Good Health and Well-being|4:Quality Education|6:Clean Water and Sanitation',
      TXT_CARBON_MITIGATION_TYPE: 'Carbon Removal',
      TXT_CCB_STANDARD: 'Climate Gold',
      URL_PROJECT_REGISTRY: 'https://registry.goldstandard.org/projects/details/1225',
      TXT_VERIFIED_SERIAL_BLOCK: 'GS1-1-TR-GS854-12-2018-19798-5161-5326',
      TXT_MINTED_SERIAL_BLOCK: 'GS1-1-TR-GS854-12-2018-19798-5161-5326',
      URL_PROJECT_IMG: 'https://via.placeholder.com/500',
      DATE_VINTAGE_START: '01012018',
      DATE_VINTAGE_END: '31122018',
    },
  ],
};

// eslint-disable-next-line no-shadow
export enum RegistryType {
  International = 'INTERNATIONAL',
  IndonesiaSRN = 'INDONESIA_SRN',
  IndonesiaAppleGatrik = 'INDONESIA_APPLE_GATRIK',
}

export enum RecTechnology {
  PvGrounded = 'PV Grounded',
  PvFloating = 'PV Floating',
  WindOnshore = 'Wind Onshore',
  WindOffshore = 'Wind Offshore',
  HydroRunOfRiver = 'Hydro Run-of-river',
  HydroReservoir = 'Hydro Reservoir',
  Biomass = 'Biomass',
  Geothermal = 'Geothermal',
}

export enum RecPrimaryFuel {
  Coal = 'Coal',
  Gas = 'Gas',
  Hydro = 'Hydro',
  Wind = 'Wind',
  Solar = 'Solar',
  Biomass = 'Biomass',
  Geothermal = 'Geothermal',
  Waste = 'Waste',
  Nuclear = 'Nuclear',
  Other = 'Other',
}

export const euProjectMetadataSchema = [];
