import { TooltipPlacement } from 'antd/lib/tooltip';
import {
  ButtonHTMLAttributes,
  ChangeEventHandler,
  DetailedHTMLProps,
  ReactNode,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import React from 'react';
import { Colors, FontSize, Radius, Spacing } from 'refreshed-component/design-system';
import { LoadingRoot } from 'refreshed-component/molecules/Loading';
import styled from 'styled-components';

import { hooks } from '@aircarbon/utils-common';

import { Button, ButtonProps } from './Button';
import { Icon, IconType } from './Icon';
import { Input } from './Input';
import Popover from './Popover';
import { Text } from './Text';

export type DropdownItem = { id: number | string; label: ReactNode };
const { useOnClickOutside } = hooks;

const DownSvg = (
  <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.5 1.5L5 5L1.5 1.5"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

const DropdownList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: var(${Radius.medium});
  border: 1px solid var(${Colors.gray_300});
  background: var(${Colors.gray_0});
  box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.05), 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
  padding: var(${Spacing.small});
  gap: var(${Spacing.small});

  &:focus {
    border: 1px solid var(${Colors.gray_300}, #d1d5db);
  }
  > .before {
    display: flex;
    flex-direction: row;
    width: 100%;
    flex: 1 1 0;
    &:empty {
      display: none;
    }
  }
  > .body {
    width: 100%;
    max-height: 300px;
    overflow-y: auto;
    overflow-x: hidden;
    > div {
      color: var(${Colors.gray_900});
      width: 100%;
      min-height: 32px;
      left: 0;
      right: 0;
      padding: var(${Spacing.xs}) var(${Spacing.small});
      border-radius: var(${Radius.small});
      display: flex;
      flex-direction: column;
      justify-content: center;
      font-size: var(${FontSize.small});
      align-items: start;
      cursor: pointer;
      &:hover,
      &.focused {
        background-color: var(${Colors.gray_200});
      }
      &.selected {
        background-color: var(${Colors.gray_100});
      }
    }
  }
  > .after {
    display: flex;
    flex-direction: row;
    width: 100%;
    flex: 1 1 0;
    &:empty {
      display: none;
    }
  }
`;

const StyledDropdownButton = styled(Button)<{
  hasError?: boolean;
}>`
  ${({ hasError }) =>
    hasError &&
    `
    &.color-gray {
      color: var(${Colors.gray_900});
      background: var(${Colors.gray_50});
      > .children > ${LoadingRoot} > .loader .lds-ring div {
        border-color: var(${Colors.gray_900}) transparent transparent transparent !important;
      }
      &.border-true {
        border: 1px solid var(${Colors.danger_400});
      }

      &:hover {
        background: var(${Colors.danger_100});
        &.border-true {
          border: 1px solid var(${Colors.danger_700});
        }
      }

      &:focus,
      &.is-focused-true {
        background: var(${Colors.danger_100});
        outline: var(${Colors.danger_100}) solid 3px !important;
        &.border-true {
          border: 1px solid var(${Colors.danger_700});
        }
      }
    }
  `}
`;

type DropdownProps = DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> & {
  config?: ButtonProps & { border?: never; shouldStayWithinMaxWidth?: boolean };
  list?: DropdownItem[];
  content?: {
    placement?: TooltipPlacement;
    style?: React.CSSProperties | undefined;
    before?: ReactNode | ((onClose: () => void) => ReactNode);
    after?: ReactNode | ((onClose: () => void) => ReactNode);
    contentHook?: {
      close?: () => void;
    };
  };
  alwaysVisible?: boolean;
  onShowChange?: (showMenu: boolean) => void;
  error?: string;
  selected?: (number | string | undefined)[];
  placeholder?: string;
  /**
   * If set to true, will render a search bar
   */
  isSearchable?: boolean;

  /**
   * If set to true, user is allowed to select the value from the search
   */
  isCustomValueAllowed?: boolean;

  onSelectItem?: (selectedItems: DropdownItem, index: number) => void;
  children?: ReactNode | ((selectedItem?: DropdownItem[]) => ReactNode);

  /**
   * Custom search hook to return list of searched values
   */
  onSearch?(searchValue: string): DropdownItem[];
  renderItem?(props: {
    selectedItem?: DropdownItem[];
    item: DropdownItem;
    index: number;
    isFocused: boolean;
    onPressItem: (
      event: React.MouseEvent,
      props: {
        item: DropdownItem;
        index: number;
      },
    ) => void;
  }): ReactNode;
} & {
  ref?: React.RefObject<HTMLButtonElement> | React.ForwardedRef<HTMLButtonElement>;
};

export const Dropdown: React.FC<DropdownProps> = React.forwardRef(
  (
    {
      config,
      list = [],
      selected,
      onSelectItem,
      placeholder,
      content,
      isSearchable,
      isCustomValueAllowed,
      onShowChange,
      onSearch,
      renderItem,
      error,
      alwaysVisible,
      ...props
    },
    forwardedRef,
  ) => {
    const [searchValue, setSearchValue] = useState('');
    const [showMenu, setShowMenu] = useState(false);
    const [focused, setFocused] = useState(-1);
    const searchInputRef = useRef<HTMLInputElement>(null);

    const searchItems = (searchValue: string) => {
      if (!searchValue) {
        return list;
      }

      return list.filter((item) => {
        return String(item.label).toLowerCase().includes(searchValue.toLowerCase());
      });
    };

    const filteredList = useMemo(() => {
      if (!searchValue) {
        return list;
      }

      const searchResult = !!onSearch ? onSearch(searchValue) : searchItems(searchValue);

      return searchResult;
    }, [searchValue, list]);

    if (content?.contentHook)
      content.contentHook.close = () => {
        setShowMenu(false);
      };

    const buttonRef = useRef<HTMLButtonElement>(null) || forwardedRef;

    useEffect(() => {
      if (!showMenu) {
        setFocused(-1);
      } else if (searchInputRef.current) {
        setTimeout(() => {
          searchInputRef.current?.focus();
        });
      }
      onShowChange?.(showMenu);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showMenu]);

    const clearSearch = () => {
      setSearchValue('');
    };

    const dropDownRef = useRef<HTMLDivElement>(null);
    useOnClickOutside(dropDownRef as any, () => {
      if (!showMenu) {
        return;
      }
      setShowMenu(false);
      clearSearch();
    });

    const selectedItem = list.filter((listItem) => selected?.find((selectedItem) => listItem.id === selectedItem));

    let children;
    if (typeof props.children === 'function') {
      children = props.children(selectedItem.length ? selectedItem : undefined);
    } else if (props.children) {
      children = props.children;
    } else if (selectedItem.length) {
      children = selectedItem
        .map((item) => {
          return item.label;
        })
        .join(',');
    } else if (isCustomValueAllowed && selected?.length) {
      children = selected.join(',');
    } else if (placeholder) {
      children = <Text color={Colors.gray_400}>{placeholder}</Text>;
    }

    const onPressClearSearch = () => {
      clearSearch();
    };

    const onChangeSearchValue: ChangeEventHandler<HTMLInputElement> = (e) => {
      setSearchValue(e.target.value);
    };

    const onPressItem = (event: React.MouseEvent, props: { item: DropdownItem; index: number }) => {
      event.stopPropagation();
      setShowMenu(false);
      buttonRef.current?.focus();
      onSelectItem?.(props.item, props.index);
      clearSearch();
    };

    return (
      <Popover
        view="transparent"
        placement={content?.placement || 'bottomLeft'}
        visible={alwaysVisible ? true : showMenu}
        content={() => (
          <DropdownList
            style={{
              minWidth: `${buttonRef.current?.getBoundingClientRect().width}px`,
              maxWidth: config?.shouldStayWithinMaxWidth
                ? `${buttonRef.current?.getBoundingClientRect().width}px`
                : undefined,
              ...content?.style,
            }}
            ref={dropDownRef}
          >
            {content?.before && (
              <div className="before">
                {typeof content?.before === 'function'
                  ? content?.before?.(() => {
                      setShowMenu(false);
                    })
                  : content?.before}
              </div>
            )}
            {(!!onSearch || isSearchable) && (
              <StyledSearchInput
                placeholder="Search"
                config={{
                  color: 'gray',
                  postfix: !!searchValue && (
                    <ClearSearch onClick={onPressClearSearch}>
                      <Icon type={IconType.XCircle} width={14} height={14} />
                    </ClearSearch>
                  ),
                  prefix: <Icon type={IconType.Search} width={14} height={14} />,
                }}
                value={searchValue}
                onChange={onChangeSearchValue}
                ref={searchInputRef}
              />
            )}
            {!!filteredList.length && (
              <div className="body">
                {filteredList.map((item, index) => {
                  if (renderItem) {
                    return renderItem({
                      item,
                      index,
                      selectedItem,
                      onPressItem,
                      isFocused: focused === index,
                    });
                  }

                  const isItemSelected = !!selectedItem?.find((i) => i.id === item.id);

                  return (
                    <div
                      key={`${item.id?.toString()}-${index}`}
                      className={`${focused === index ? 'focused' : ''} ${isItemSelected ? 'selected' : ''}`}
                      onClick={(event) => onPressItem(event, { item, index })}
                    >
                      {typeof item === 'string' ? item : item.label}
                    </div>
                  );
                })}
              </div>
            )}
            {!filteredList.length && !!searchValue && isCustomValueAllowed && (
              <div className="body">
                <div
                  onClick={(event) =>
                    onPressItem(event, {
                      item: {
                        id: '',
                        label: searchValue,
                      },
                      index: 0,
                    })
                  }
                >
                  {searchValue}
                </div>
              </div>
            )}
            {content?.after && (
              <div className="after">
                {typeof content?.after === 'function'
                  ? content?.after?.(() => {
                      setShowMenu(false);
                    })
                  : content?.after}
              </div>
            )}
          </DropdownList>
        )}
      >
        <>
          <StyledDropdownButton
            hasError={!!error}
            {...props}
            config={{
              isFocused: showMenu,
              ...config,
              border: true,
              icon: {
                right: DownSvg,
                ...config?.icon,
              },
            }}
            ref={buttonRef}
            onMouseDown={(event) => {
              const isOpen = !showMenu;
              const mouseup = () => {
                setShowMenu(isOpen);
              };
              const clear = () => {
                event.target.removeEventListener('mouseup', mouseup);
                window.removeEventListener('mouseup', clear);
              };
              event.target.addEventListener('mouseup', mouseup);
              window.addEventListener('mouseup', clear);
            }}
            type="button"
            onKeyDown={(event) => {
              if (event.key === 'ArrowDown') {
                const _focused = focused + 1;
                setFocused(_focused > filteredList.length - 1 ? 0 : _focused);
              } else if (event.key === 'ArrowUp') {
                const _focused = focused - 1;
                setFocused(_focused < 0 ? filteredList.length - 1 : _focused);
              } else if (event.key === 'Enter') {
                setShowMenu(!showMenu);
                if (focused !== -1) onSelectItem?.(filteredList[focused], focused);
              } else if (event.key === 'Tab') {
                setShowMenu(false);
                return;
              }
              if (showMenu) event.preventDefault();
            }}
          >
            <div className="overflow-hidden w-full text-left whitespace-pre overflow-ellipsis">{children}</div>
          </StyledDropdownButton>
          {!!error && (
            <Text color={Colors.danger_700} size={FontSize.small}>
              {error}
            </Text>
          )}
        </>
      </Popover>
    );
  },
);

const ClearSearch = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  margin-inline-end: -6px;
  color: var(${Colors.gray_400});
  cursor: pointer;
`;

const StyledSearchInput = styled(Input)`
  width: 100%;
`;
