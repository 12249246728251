import { Badge, BadgeSize, BadgeVariant } from 'refreshed-component/atoms/Badge';
import { Icon, IconType } from 'refreshed-component/atoms/Icon';
import { Text } from 'refreshed-component/atoms/Text';
import { Colors, FontSize, FontWeight, Spacing } from 'refreshed-component/design-system';
import styled from 'styled-components';

export const enum StepStatus {
  Current,
  Completed,
  Next,
  Active,
}

export const Step: React.FC<{
  status: StepStatus;
  onPress?(): void;
}> = (props) => {
  const { status = StepStatus.Current, onPress, children } = props;
  return (
    <StyledStepsStatus isPressable={!!onPress} status={status} onClick={onPress}>
      <BadgeForStepStatus status={status} variant={BadgeVariant.Circle} size={BadgeSize.Small}>
        {status === StepStatus.Completed && <Icon type={IconType.Check} width={14} height={14} />}
        {status === StepStatus.Active && <ActiveCircle />}
      </BadgeForStepStatus>
      <Text
        size={FontSize.base}
        weight={FontWeight.medium}
        color={status === StepStatus.Next ? Colors.gray_500 : Colors.secondaryDefault}
      >
        {children}
      </Text>
    </StyledStepsStatus>
  );
};

const StyledStepsStatus = styled.div<{
  status: StepStatus;
  isPressable: boolean;
}>`
  display: flex;
  cursor: ${({ isPressable }) => (isPressable ? 'pointer' : 'default')};
  gap: var(${Spacing._1_5});
  color: ${({ status }) => (status === StepStatus.Next ? `var(${Colors.gray_500})` : `var(${Colors.gray_900})`)};
`;

const BadgeForStepStatus = styled(Badge)<{
  status: StepStatus;
}>`
  color: var(${Colors.gray_0});
  ${({ status }) => {
    switch (status) {
      case StepStatus.Completed:
        return `background-color: var(${Colors.secondaryDefault});`;
      case StepStatus.Current || StepStatus.Active:
        return `
          background-color: var(${Colors.gray_0});
          border-color: var(${Colors.secondaryDefault});
        `;
      default:
        return `
            background-color: var(${Colors.gray_0});
            border-color: var(${Colors.gray_500});
          `;
    }
  }}

  background-color: ${(props) =>
    props.status === StepStatus.Completed ? `var(${Colors.secondaryDefault})` : `var(${Colors.gray_0})`};
`;

const ActiveCircle = styled.div`
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: var(${Colors.secondaryDefault});
`;
