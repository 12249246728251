import React from 'react';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { ButtonStyle } from 'components/styled/Styled';

import { ScreenSizes } from 'state/ui';

import { ThemeColors, Themes } from '../trading/components/Themes';

export const ScrollableWrapper = styled.div`
  height: 100%;
  overflow: scroll;
`;

export const Toolbar = styled.div<{ screenSize?: ScreenSizes; themeColors?: ThemeColors }>`
  display: flex;
  background: ${({ themeColors }) => themeColors?.dashboard.background || '#f9f9f9'};
  height: 70px;
  padding: 0 1.5rem;
  flex: 1;

  ${(props) => {
    if (props.screenSize === 'small') {
      return `flex-direction: column; height: auto; padding: 16px; margin-bottom: 16px;`;
    } else {
      return `align-items: center; height: 70px; margin-bottom: 1rem;`;
    }
  }}

  & > * {
    margin-bottom: 0 !important;
  }

  .toolbarButton {
    white-space: nowrap;
    height: 30px;
  }
`;
export const ToolbarActionWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  border-right: 1px solid #c5c5c5;
  padding-right: 1rem;
  margin-right: 1rem;

  & > * {
    flex: 1;
    height: 100%;
  }
`;

export const Breadcrumbs = styled.div<{ screenSize: ScreenSizes }>`
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 13px;
  position: relative;
  top: -5px;
  font-weight: 400;
  white-space: pre;
  ${(props) => {
    if (props.screenSize === 'small' || props.screenSize === 'medium') {
      return ` max-width: 100%; flex-wrap: wrap;`;
    }
  }}
  a {
    color: ${(props) => props.theme.brandColor};
    font-weight: 500;
  }
  svg {
    fill: #a2a2a2;
  }
`;

export const BalanceItemCss = css`
  font-size: 1em;
  font-weight: 400;
  line-height: 1.4;
  text-align: right;
  padding: 2px 7px;
  min-width: 150px;
  max-width: 150px;
  position: relative;

  span {
    font-size: 0.85em;
    font-weight: 500;
    display: block;
  }

  &.headerItem {
    // background: ${(props) => `linear-gradient(-45deg, #fafafa, #fafafa, rgba(${props.theme.brandRbgColor}, 0.7))`};

    &.basket {
      &::before {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        top: 4px;
        left: 2px;
        border-right: 1px solid rgba(200, 200, 200, 0.7);
        border-bottom: 1px solid rgba(200, 200, 200, 0.7);
        z-index: 8;
      }
    }
  }

  .tokenName {
    position: absolute;
    top: 0;
    opacity: 0;
    transition: top 0.2s, opacity 0.2s;
    white-space: nowrap;
    left: 50%;
    transform: translateX(-50%);
    background: #424242;
    border-radius: 10px;
    color: #ededed;
    padding: 5px 1.5em;
    font-size: 0.75em;
    font-weight: bold;
    line-height: 1;
  }

  &.showTokenName {
    &:hover {
      .tokenName {
        top: -25px;
        opacity: 1;
      }
    }
  }
`;

export const TipInner = styled.div`
  border-radius: 10px;
  background: white;
  padding: 12px;
  font-size: 13px;
`;

export const LoadingBalance = styled.div`
  width: 100%;
  text-align: center;

  .spinner {
    width: 100%;
    height: 14px;
  }

  .spinner::after {
    width: 12px;
    height: 12px;
    top: calc(50% - (14px / 2));
    left: 90%;
    border: 1px solid #aaa;
    border-right-color: transparent;
    border-top-color: transparent;
  }
`;
export const BalanceItem = styled.div<{ screenSize?: ScreenSizes }>`
  ${BalanceItemCss}
  ${(props) => {
    if (props.screenSize === 'small') {
      return `
         min-width: auto;
         max-width: auto;
      `;
    }
  }}
`;

export const BalanceItemButtonWrapper = styled(({ themeColors, ...rest }) => <Link {...rest} />)<{
  themeColors?: ThemeColors;
}>`
  display: flex;
  align-items: center;
  background: ${({ themeColors }) => themeColors?.dashboard.block.background || '#fff'};
  transition: background box-shadow 0.2s;
  border: 1px solid ${({ themeColors }) => themeColors?.dashboard.block.border || '#ccc'};
  min-width: 150px;
  max-width: 150px;
  border-radius: 4px;

  &:hover,
  &.active {
    background: rgb(187 187 187 / 25%);
    box-shadow: none !important;
    background: ${(props) =>
      `linear-gradient(-45deg, rgba(${props.theme.brandRbgColor}, 0.7), ${
        props.themeColors?.dashboard.block.background || '#fff'
      }, ${props.themeColors?.dashboard.block.background || '#fff'})`};
  }

  &.active {
    background: rgb(187 187 187 / 40%);

    & > div {
      font-weight: 600;
    }
  }
`;

export const Announcement = styled.div<{ themeColors: ThemeColors }>`
  padding-left: 15px;
  padding-right: 15px;
  height: 50px;
  width: 100%;
  font-size: 14px;
  border-radius: 3px;
  font-weight: 600;
  background: rgba(${(props) => props.theme.brandRbgColor}, 0.2);
  line-height: 1.4;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid ${(props) => props.themeColors?.dashboard.deepBackground || '#ededed'};
  color: ${(props) => props.themeColors?.dashboard.color || 'inherit'};
  &.warning {
    background: rgba(255, 0, 0, 0.4);
  }
  &.support-link {
    a {
      color: ${(props) => props.themeColors?.dashboard.color || props.theme.brandRbgColor};
    }
  }
`;

export const PageTitle = styled.div`
  font-size: 2em;
  font-weight: 600;
  margin-bottom: 2rem;
  line-height: 3rem;
`;
export const PageSubtitle = styled.div`
  font-size: 1.5em;
  font-weight: 300;
  margin-top: -1rem;
  margin-bottom: 2rem;
`;
export const SectionTitle = styled.div`
  font-size: 1.8em;
  font-weight: 400;
  margin-bottom: 1.5rem;
  line-height: 1.8em;
`;

export const DashboardNav = styled.div`
  display: flex;
  background: #f9f9f9;
  align-items: center;
  gap: 2rem;
  &.dark {
    background: #1a1a1a;
    button {
      color: #828282;
    }
  }
  &.transparent {
    background: transparent;
  }
  button {
    padding: 1.5rem;
    border-bottom: 4px solid transparent;
    font-size: 1.1rem;
    font-weight: 500;
    letter-spacing: 1px;
    color: #424242;
  }
  button {
    &.selected {
      color: ${(props) => `rgba(${props.theme.brandRbgColor},1)`};
      border-color: ${(props) => `rgba(${props.theme.brandRbgColor},0.8)`};
      font-weight: 700;
      div {
        color: ${(props) => `rgba(${props.theme.brandRbgColor},1)`};
      }
    }
    &:hover {
      color: ${(props) => `rgba(${props.theme.brandRbgColor},1)`};
    }
    & > div {
      margin-bottom: 0;
      color: #828181;
      font-weight: 400;
    }
  }
  &.small {
    button {
      font-size: 0.85rem;
      border-bottom-width: 2px;
      padding: 1rem 1.5rem;
    }
  }
`;

const DashboardActionStyle = css`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  font-weight: 600;
  font-size: 0.8rem;
  opacity: 0.9;
  transition: opacity 0.4s;
  heigth: 30px;

  &:hover {
    opacity: 1;
  }
`;

const DashboardButtonStyle = css<{ themeColors?: ThemeColors }>`
  ${ButtonStyle}
  display: inline-flex;
`;

export const DashboardButton = styled.button<{ themeColors?: ThemeColors }>`
  ${DashboardButtonStyle}
`;

export const DashboardButtonLink = styled(Link)<{ themeColors?: ThemeColors }>`
  ${DashboardButtonStyle}
`;

export const DashboardLink = styled(Link)`
  ${DashboardActionStyle}
  color: ${(props) => `rgba(${props.theme.brandRbgColor},1)`};
`;

export const DashboardMenu = styled.div<{ themeColors: ThemeColors }>`
  display: flex;
  flex-direction: column;
  background: ${({ themeColors }) => themeColors.dashboard.background};
  height: 100%;
  box-shadow: 2px 0 4px rgb(0 0 0 / 10%);
  overflow-y: auto;

  a {
    color: ${({ themeColors }) => themeColors.dashboard.sideMenu.color};
    border-bottom: 1px solid ${({ themeColors }) => themeColors.dashboard.sideMenu.border};
    text-align: left;
    display: flex;
    align-items: center;
    transition: all 0.2s;
    font-size: 14px;
    line-height: 1;

    &.selected,
    &.active {
      border-left: 5px solid ${(props) => `rgba(${props.theme.brandRbgColor},0.8)`};
      background: #ededed;
      color: ${(props) => `rgba(${props.theme.brandRbgColor},1)`};
      font-weight: 700;
    }

    &:hover {
      color: ${(props) => `rgba(${props.theme.brandRbgColor},1)`};
    }

    & > div {
      margin-bottom: 0;
      color: #828181;
      font-weight: 400;
    }

    svg {
      margin-left: auto;
    }
  }

  & > a {
    padding: 1.2rem;
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 1.2px;
    font-weight: 500;
  }

  .subMenuWrapper {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid ${({ themeColors }) => themeColors.dashboard.sideMenu.border};

    & > a {
      border-bottom: 0;
    }

    .subMenuLabel {
      color: ${({ themeColors }) => themeColors.dashboard.color};
      padding: 1.2rem;
      letter-spacing: 0.5px;
      display: flex;
      align-items: center;
      border-bottom: 0;
      font-size: 12px;
      text-transform: uppercase;
      letter-spacing: 1.2px;
      font-weight: 500;

      svg {
        margin-left: auto;
      }
    }

    .submenu {
      display: none;
    }

    &.expanded {
      .subMenuLabel {
        border-bottom: 0;
      }

      .submenu {
        display: flex;
        flex-direction: column;
        gap: 1.2rem;
        padding: 1.2rem;
        padding-top: 0;
      }
    }

    &.selected {
      border-bottom: 1px solid ${({ themeColors }) => themeColors.dashboard.sideMenu.border};
      border-left: 3px solid ${(props) => `rgba(${props.theme.brandRbgColor},0.8)`};

      a {
        border-left: 0;
      }
    }
  }

  .subOption {
    margin-left: 1.2rem;
    border-bottom: 0;

    &.active,
    &:hover {
      border-left: 0;
      background: transparent;
      color: ${(props) => `rgba(${props.theme.brandRbgColor},1)`};
      font-weight: 700;
    }
  }
`;

export const TableWithThemeColors = React.forwardRef((props: any, ref: any) => {
  const { theme: themeColors } = Themes.useContainer();
  return <Table {...props} ref={ref} themeColors={themeColors} />;
}) as unknown as typeof Table;

export const Table = styled.table<{ themeColors?: ThemeColors }>`
  width: 100%;

  tr {
    &:hover {
      cursor: pointer;

      td {
        background: ${({ themeColors }) => themeColors?.dashboard.table.hover.background || '#ededed'};
      }
    }
  }

  th,
  td {
    padding: 1rem;
    border: none;
    text-align: left;
    background: ${({ themeColors }) => themeColors?.dashboard.table.background || '#f5f5f5'};
    border-bottom: 1px solid ${({ themeColors }) => themeColors?.dashboard.table.border || '#eaeaea'};
    font-size: 14px;
    transition: all 0.4s;
    white-space: pre;
  }
`;
