export const enum QueryParamKey {
  PageNumber = 'page[number]',
  PageSize = 'page[size]',
  Search = 'search',
  Status = 'status',
  CcbStandards = 'ccb-standards',
  ProjectTypes = 'project-types',
  SectoralScopes = 'sectoral-scopes',
  VintageYearFrom = 'vintage-year[from]',
  VintageYearTo = 'vintage-year[to]',
}

export interface GetMyApxProjectsApiParams {
  filter?: {
    'project-types'?: number[];
    'sectoral-scopes'?: number[];
    vintage?: {
      from?: number;
      to?: number;
    };
    status?: string;
    search?: {
      fields: string[];
      value: string;
    };
  };
  page?: {
    size?: number;
    number?: number;
  };
}

export interface UpdateMyApxProjectApiParams {
  id: number;
  name?: string;
  countryCode?: string;
  carbonStandard?: string;
  methodologyId?: number;
  carbonProjectTypeId?: number;
  registryProjectId?: string;
  registryId?: number;
  description?: string;
  sectoralScopeId?: number;
  sdgGoals?: number[];
  apxProject?: {
    apxPhaseId?: number;
    creditPeriodStartDate?: Date;
    creditPeriodEndDate?: Date;
    implementationDate?: Date;
  };
  apxDocuments?: {
    id?: number;
    name?: string;
    documentTypeId?: number;
    url?: string;
  }[];
  apxProponents?: {
    id?: number;
    relationType?: string;
    companyName?: string;
    address?: string;
    phone?: string;
    contactPersonName?: string;
    contactEmails?: string;
  }[];
  vintages?: {
    id?: number;
    expectedIssuanceDate?: Date;
    vintageYear?: number;
    projectedCreditQuantity?: number;
    offeredCreditQuantity?: number;
  }[];
}

export interface AddApxProjectApiParams {
  name: string;
  countryCode: string;
  methodology: number;
  carbonProjectType: number;
  apxPhase: number;
  registry: number;
  description?: string;
}

export interface SubmitMyApxProjectApiParams {
  projectId: number;
}
