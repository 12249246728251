/* eslint-disable prefer-destructuring */
import { Contract } from 'ethers';

import { allTransferTypes } from './const';
import { hex2int, formatNumber, formatCurrency, convertUnit, truncateMiddle, hex2eth } from './formatter';
import { timestampToUtc, revealMessage, decodeWeb3Object } from './helpers';
import { LedgerEntry, ScLedgerEntry, CcyType } from './sc-types';

// import * as projects from './projects';

interface EventProps {
  [k: string]: {
    label: string;
    type?: string;
    fn?: typeof truncateMiddle | typeof hex2int | typeof formatNumber | typeof formatCurrency | typeof convertUnit;
    copy?: string;
  };
}
/**
 * This is used by UI to know how to display SC values
 */
export const eventProps: EventProps = {
  from: {
    label: 'From',
    fn: truncateMiddle,
    copy: 'value',
    type: 'address',
  },
  to: {
    label: 'To',
    fn: truncateMiddle,
    copy: 'value',
    type: 'address',
  },
  batchOwner: {
    label: 'Batch Owner',
    fn: truncateMiddle,
    copy: 'value',
    type: 'address',
  },
  ledgerOwner: {
    label: 'Ledger Owner',
    fn: truncateMiddle,
    copy: 'value',
    type: 'address',
  },
  ccyTypeId: {
    label: 'Currency',
    fn: hex2int,
    copy: 'value',
    type: 'currencyType',
  },
  ccyAmount: {
    label: 'Ccy Amount',
    fn: convertUnit,
    copy: 'function',
    type: 'currency',
  },
  tokTypeId: {
    label: 'Asset Type',
    fn: hex2int,
    copy: 'value',
    type: 'tokenType',
  },
  tokenTypeId: {
    label: 'Asset Type',
    fn: hex2int,
    copy: 'value',
    type: 'tokenType',
  },
  amount: {
    label: 'Amount',
    fn: convertUnit,
    copy: 'function',
    type: 'currency',
  },
  ccyQty: {
    label: 'Amount',
    fn: convertUnit,
    copy: 'function',
    type: 'currency',
  },
  tokQty: {
    label: 'Asset Qty',
    fn: convertUnit,
    copy: 'function',
    type: 'number',
  },
  mintQty: {
    label: 'Quantity',
    fn: convertUnit,
    copy: 'function',
    type: 'number',
  },
  mintedQty: {
    label: 'Quantity',
    fn: convertUnit,
    copy: 'function',
    type: 'number',
  },
  qty: {
    label: 'Quantity',
    fn: convertUnit,
    copy: 'function',
    type: 'number',
  },
  burnedQty: {
    label: 'Quantity',
    fn: convertUnit,
    copy: 'function',
    type: 'number',
  },
  transferType: {
    label: 'Transfer Type',
    fn: (type: number): string =>
      allTransferTypes.find((transferType) => transferType.SCCode === type)?.label ?? type.toString(),
    copy: 'value',
  },
  stId: {
    label: 'ST ID',
    fn: hex2int,
    copy: 'function',
    type: 'number',
  },
  mergedToSecTokenId: {
    label: 'Merged to ST ID',
    fn: hex2int,
    copy: 'function',
    type: 'number',
  },
  splitFromSecTokenId: {
    label: 'Split from ST ID',
    fn: hex2int,
    copy: 'function',
    type: 'number',
  },
  newSecTokenId: {
    label: 'New ST ID',
    fn: hex2int,
    copy: 'function',
    type: 'number',
  },
  mintSecTokenCount: {
    label: 'ST Count',
    fn: hex2int,
    copy: 'function',
    type: 'number',
  },
  name: {
    label: 'Name',
  },
  fee_tokenQty_Fixed: {
    label: 'Fixed Qty',
    fn: convertUnit,
    copy: 'function',
    type: 'number',
  },
  fee_ccy_perMillion: {
    label: 'Ccy Fee per Million',
    fn: convertUnit,
    copy: 'function',
    type: 'currency',
  },
  fee_ccy_Min: {
    label: 'Minimum Ccy Fee',
    fn: convertUnit,
    copy: 'function',
    type: 'currency',
  },
  fee_ccy_Fixed: {
    label: 'Ccy Fee Fixed',
    fn: convertUnit,
    copy: 'function',
    type: 'currency',
  },
  unit: {
    label: 'Unit',
  },
};

interface EventPropsTypes {
  [k: string]: {
    type: string;
  };
}

/**
 * Mapping the field name from SC to the real value
 * Web3 return value in big number (hex object)
 */
export const eventPropsTypes: EventPropsTypes = {
  batchId: {
    type: 'number',
  },
  ccyTypeId: {
    type: 'number',
  },
  ccyAmount: {
    type: 'currency',
  },
  tokTypeId: {
    type: 'number',
  },
  tokenTypeId: {
    type: 'number',
  },
  amount: {
    type: 'currency',
  },
  ccyQty: {
    type: 'currency',
  },
  tokQty: {
    type: 'number',
  },
  mintQty: {
    type: 'number',
  },
  mintedQty: {
    type: 'number',
  },
  qty: {
    type: 'number',
  },
  burnedQty: {
    type: 'number',
  },
  mintSecTokenCount: {
    type: 'number',
  },
  // TODO: Make sure not converting this value works on AC side.
  // splitFromSecTokenId: {
  //   type: 'number',
  // },
  stId: {
    type: 'number',
  },
  fee_tokenQty_Fixed: {
    type: 'number',
  },
  fee_ccy_perMillion: {
    type: 'currency',
  },
  fee_ccy_Min: {
    type: 'currency',
  },
  fee_ccy_Fixed: {
    type: 'currency',
  },
  ccyFeeFrom: {
    type: 'currency',
  },
  ccyFeeTo: {
    type: 'currency',
  },
  weiSent: {
    type: 'eth',
  },
  weiChange: {
    type: 'eth',
  },
  tokensSubscribed: {
    type: 'number',
  },
};

/**
 * Convert to human readable value from event
 * @param values
 * @param ccys
 */
export const parseEventValues = (
  values: any,
  ccys: Record<
    number,
    {
      decimals: number;
    }
  >,
) => {
  const result = { ...values };

  Object.keys(values).forEach((key) => {
    if (eventPropsTypes[key]) {
      const prop = eventPropsTypes[key];

      if (prop.type === 'currency') {
        const decimals = ccys[values.ccyTypeId]?.decimals ?? 0;
        result[key] = convertUnit(values[key], decimals);
      } else if (prop.type === 'eth') {
        result[key] = hex2eth(values[key]);
      } else {
        result[key] = hex2int(values[key]);
      }
    }
  });

  return result;
};

export function parseTokenBatch(batch: Record<string, any>) {
  const metaData: Record<string, any> = {};
  Object.values(batch.metaKeys).forEach((metaKey: any, index: number) => {
    // NOTE: there could be multiple versions of a metadata.
    // rootKey is the metadata key string without versioning (i.e. ':v2'),
    // and resulting assigned value is always the value of last version.
    const rootKey = metaKey.split(':')[0];
    metaData[rootKey] = batch.metaValues[index];
  });
  return {
    batchId: hex2int(batch.id),
    tokTypeId: hex2int(batch.tokTypeId),
    originator: batch.originator,
    mintedQty: hex2int(batch.mintedQty),
    burnedQty: hex2int(batch.burnedQty),
    metaData,
    rawMetadata: {
      metaKeys: batch.metaKeys,
      metaValues: batch.metaValues,
    },
    origTokFee: {
      fee_fixed: hex2int(batch.origTokFee?.fee_fixed),
      fee_percBips: hex2int(batch.origTokFee?.fee_percBips),
      fee_min: hex2int(batch.origTokFee?.fee_min),
      fee_max: hex2int(batch.origTokFee?.fee_max),
      ccy_perMillion: hex2int(batch.origTokFee?.ccy_perMillion),
      ccy_mirrorFee: batch.origTokFee?.ccy_mirrorFee,
    },
    origCcyFee_percBips_ExFee: batch.origCcyFee_percBips_ExFee,
    mintedTimestamp: timestampToUtc(hex2int(batch.mintedTimestamp)),
  };
}

export function parseAccountLedger(accountLedgerRaw: LedgerEntry, ccys: Record<string, any>[]): ScLedgerEntry {
  const parsedCcys: CcyType[] = decodeWeb3Object(ccys).ccyTypes.map((ccy: any) => ({
    ...ccy,
    id: hex2int(ccy.id),
    decimals: hex2int(ccy.decimals),
  }));

  return {
    ...accountLedgerRaw,
    entry: {
      ...accountLedgerRaw?.entry,
      spotSumQty: hex2int(accountLedgerRaw?.entry?.spot_sumQty),
      spotSumQtyMinted: hex2int(accountLedgerRaw?.entry?.spot_sumQtyMinted),
      spotSumQtyBurned: hex2int(accountLedgerRaw?.entry?.spot_sumQtyBurned),
      tokens:
        accountLedgerRaw?.entry?.tokens?.map((token) => ({
          ...token,
          tokTypeId: hex2int(token.tokTypeId),
          batchId: hex2int(token.batchId),
          stId: hex2int(token.stId),
          currentQty: hex2int(token.currentQty),
          mintedQty: hex2int(token.mintedQty),
        })) ?? [],
      ccys:
        accountLedgerRaw?.entry?.ccys?.map((ccy) => {
          const ccyDecimals = parsedCcys.find((c) => c.id === hex2int(ccy.ccyTypeId))?.decimals ?? 0;
          return {
            ...ccy,
            ccyTypeId: hex2int(ccy.ccyTypeId),
            balance: convertUnit(ccy.balance, ccyDecimals),
            reserved: hex2int(ccy.reserved),
            unit: ccy.unit,
          };
        }) ?? [],
    },
  };
}

export function groupEventsBy(events: Array<any>, key: string): Record<string, any> {
  const transactions = events
    .sort(({ blockNumber }: Record<string, any>, { blockNumber: nextBlockNumber }: Record<string, any>) => {
      if (blockNumber > nextBlockNumber) return -1;
      if (blockNumber < nextBlockNumber) return +1;
      return 0;
    })
    .reduce((result, event) => {
      const res = { ...result };
      const recordKey = event[key];
      res[recordKey] = res[recordKey] || [];
      res[recordKey].push(event);

      return res;
    }, {});

  return transactions;
}

export const eventTypes: Record<string, Record<string, any>> = {
  AddedCcyType: {},
  AddedSecTokenType: {},
  AddedBatchMetadata: { id: ['batchId'] },
  Approval: { address: ['owner', 'spender'] },
  Burned: { address: ['from'] },
  BurnedFullSecToken: { address: ['from'], id: ['stId'] },
  BurnedPartialSecToken: { address: ['from'], id: ['stId'] },
  CcyFundedLedger: { address: ['to'] },
  CcyWithdrewLedger: { address: ['from'] },
  Minted: { address: ['to'], id: ['batchId'] },
  MintedSecToken: { address: ['to'], id: ['stId', 'batchId'] },
  SetBatchOriginatorFee_Token: { id: ['batchId'] },
  SetBatchOriginatorFee_Currency: { id: ['batchId'] },
  SetFeeCcyPerMillion: { address: ['ledgerOwner'], value: 'fee_ccy_perMillion' },
  SetFeeTokFix: { address: ['ledgerOwner'], value: 'fee_tokenQty_Fixed' },
  SetFeeCcyFix: { address: ['ledgerOwner'], value: 'fee_ccy_Fixed' },
  SetFeeTokBps: { address: ['ledgerOwner'], value: 'fee_token_PercBips' },
  SetFeeCcyBps: { address: ['ledgerOwner'], value: 'fee_ccy_PercBips' },
  SetFeeTokMin: { address: ['ledgerOwner'], value: 'fee_token_Min' },
  SetFeeCcyMin: { address: ['ledgerOwner'], value: 'fee_ccy_Min' },
  SetFeeTokMax: { address: ['ledgerOwner'], value: 'fee_token_Max' },
  SetFeeCcyMax: { address: ['ledgerOwner'], value: 'fee_ccy_Max' },
  TradedCcyTok: { address: ['from', 'to'], id: ['ccyTypeId', 'tokTypeId'] },
  TransferedLedgerCcy: { address: ['from', 'to'] },
  TransferedFullSecToken: { address: ['from', 'to'], id: ['stId'] },
  TransferedPartialSecToken: { address: ['from', 'to'], id: ['splitFromSecTokenId'] },
  Transfer: { address: ['from', 'to'] },
  IssuanceSubscribed: { address: ['subscriber', 'issuer'] },
  IssuerPaymentProcessed: { id: ['paymentId'], address: ['issuer'], weiSent: 'totalAmount' },
  IssuerPaymentBatchProcessed: { id: ['paymentId', 'paymentBatchId'], address: ['issuer'], weiSent: 'weiSent' },
  SubscriberPaid: { id: ['paymentId, paymentBatchId'], address: ['issuer', 'subscriber'], weiSent: 'shareWei' },
  bilateralTradeRequested: { address: ['ledger_A', 'ledger_B'], id: ['ccyTypeId', 'tokenTypeId'] },
  bilateralTradeReqbilateralTradeConfirmeduested: {
    address: ['ledger_A', 'ledger_B'],
    id: ['ccyTypeId', 'tokenTypeId'],
  },
  bilateralTradeCancelled: { address: ['ledger_A', 'ledger_B'], id: ['ccyTypeId', 'tokenTypeId'] },
};

export const rootEvents: Record<string, any> = {
  mint: {
    key: 'mint',
    mainEventName: 'Minted',
    label: 'Asset Minted',
    events: ['Minted', 'MintedSecToken'],
    affectsBalance: true,
    to: {
      key: 'mintQty',
      type: 'token',
    },
  },
  fund: {
    key: 'fund',
    mainEventName: 'CcyFundedLedger',
    label: 'Funds Added',
    events: ['CcyFundedLedger'],
    affectsBalance: true,
    to: {
      key: 'amount',
      type: 'currency',
    },
  },
  withdraw: {
    key: 'withdraw',
    mainEventName: 'CcyWithdrewLedger',
    label: 'Funds Withdrawn',
    events: ['CcyWithdrewLedger'],
    affectsBalance: true,
    from: {
      key: 'amount',
      type: 'currency',
    },
  },
  trade: {
    key: 'trade',
    mainEventName: 'TradedCcyTok',
    label: 'Asset Traded',
    events: ['TradedCcyTok', 'TransferedPartialSecToken', 'TransferedFullSecToken', 'TransferedLedgerCcy'],
    affectsBalance: true,
    from: {
      key: 'tokQty',
      type: 'token',
    },
    to: {
      key: 'ccyAmount',
      type: 'currency',
    },
  },
  bilateralTradeRequested: {
    key: 'bilateralTradeRequested',
    mainEventName: 'bilateralTradeRequested',
    label: 'Bilateral Trade Requested',
    events: ['bilateralTradeRequested'],
    affectsBalance: false,
    from: {
      key: 'tokenQty',
      type: 'token',
    },
    to: {
      key: 'ccyQty',
      type: 'currency',
    },
  },
  bilateralTradeConfirmed: {
    key: 'bilateralTradeConfirmed',
    mainEventName: 'bilateralTradeConfirmed',
    label: 'Bilateral Trade Confirmed',
    events: ['bilateralTradeConfirmed'],
    affectsBalance: false,
    from: {
      key: 'tokenQty',
      type: 'token',
    },
    to: {
      key: 'ccyQty',
      type: 'currency',
    },
  },
  bilateralTradeCancelled: {
    key: 'bilateralTradeCancelled',
    mainEventName: 'bilateralTradeCancelled',
    label: 'Bilateral Trade Cancelled',
    events: ['bilateralTradeCancelled'],
    affectsBalance: false,
    from: {
      key: 'tokenQty',
      type: 'token',
    },
    to: {
      key: 'ccyQty',
      type: 'currency',
    },
  },
  burn: {
    key: 'burn',
    mainEventName: 'Burned',
    label: 'Asset Burned',
    events: ['Burned', 'BurnedPartialSecToken', 'BurnedFullSecToken'],
    affectsBalance: true,
    from: {
      key: 'burnedQty',
      type: 'token',
    },
  },
  tokenTransfer: {
    key: 'tokenTransfer',
    label: 'Asset Transferred',
    events: ['TransferedPartialSecToken', 'TransferedFullSecToken'],
    compareOperator: 'OR',
    affectsBalance: true,
    from: {
      key: 'qty',
      type: 'token',
    },
  },
  currencyTransfer: {
    key: 'currencyTransfer',
    label: 'Currency Transferred',
    events: ['TransferedLedgerCcy'],
    affectsBalance: true,
    from: {
      key: 'amount',
      type: 'currency',
    },
  },
  erc20Transfer: {
    key: 'erc20Transfer',
    label: 'ERC20 Transferred',
    mainEventName: 'Transfer',
    events: ['Transfer'],
    affectsBalance: true,
    from: {
      key: 'value',
    },
  },
  issuanceSubscribed: {
    key: 'issuanceSubscribed',
    label: 'Issuance Subscribed',
    mainEventName: 'IssuanceSubscribed',
    events: ['IssuanceSubscribed'],
    affectsBalance: true,
    contractType: 'CASHFLOW',
    from: {
      address: 'subscriber',
      key: 'weiSent',
      type: 'eth',
    },
    to: {
      address: 'issuer',
      key: 'tokensSubscribed',
      type: 'value',
    },
  },
  subscriberPaid: {
    key: 'subscriberPaid',
    label: 'subscriberPaid',
    mainEventName: 'SubscriberPaid',
    events: ['SubscriberPaid', 'IssuerPaymentBatchProcessed', 'IssuerPaymentProcessed'],
    affectsBalance: true,
    contractType: 'CASHFLOW',
    from: {
      address: 'issuer',
      key: 'amount',
      type: 'eth',
    },
    to: {
      address: 'subscriber',
      key: '',
      type: 'value',
    },
  },
  feeCcyPerMillion: {
    key: 'feeCcyPerMillion',
    label: 'Currency Per Million Fee Set',
    mainEventName: 'SetFeeCcyPerMillion',
    events: ['SetFeeCcyPerMillion'],
    to: {
      key: 'fee_ccy_perMillion',
      type: 'currency',
    },
  },
  feeCcyBps: {
    key: 'feeCcyBps',
    label: 'Set Fee Per Basis Points',
    mainEventName: 'SetFeeCcyBps',
    events: ['SetFeeCcyBps'],
    to: {
      key: 'fee_ccy_PercBips',
      type: 'value',
    },
  },
  feeCcyFix: {
    key: 'feeCcyFix',
    label: 'Currency Fixed Fee Set',
    mainEventName: 'SetFeeCcyFix',
    events: ['SetFeeCcyFix'],
    to: {
      key: 'fee_ccy_Fixed',
      type: 'currency',
    },
  },
  feeCcyMin: {
    key: 'feeCcyMin',
    label: 'Currency Minimum Fee Set',
    mainEventName: 'SetFeeCcyMin',
    events: ['SetFeeCcyMin'],
    to: {
      key: 'fee_ccy_Min',
      type: 'currency',
    },
  },
  addCcyType: {
    key: 'addCcyType',
    label: 'Currency Type Added',
    mainEventName: 'AddedCcyType',
    events: ['AddedCcyType'],
    to: {
      key: 'name',
      type: 'addedType',
    },
  },
  addSecTokenType: {
    key: 'addSecTokenType',
    label: 'Asset Type Added',
    mainEventName: 'AddedSecTokenType',
    events: ['AddedSecTokenType'],
    to: {
      key: 'name',
      type: 'addedType',
    },
  },
  // TODO: check proper handling of these two events.
  // addBatchMetadata: {
  //   key: 'addBatchMetadata',
  //   label: 'Metadata added to batch',
  //   mainEventname: 'AddedBatchMetadata',
  //   events: ['AddedBatchMetadata'],
  // },
  // setBatchOriginatorFeeCurrency: {
  //   key: 'setBatchOriginatorFeeCurrency',
  //   label: 'Batch Originator Currency Fee Set',
  //   mainEventName: 'SetBatchOriginatorFee_Currency'
  // }
};

const contractType = process.env.CONTRACT_TYPE ?? 'COMMODITY';

export const txTypes = Object.keys(rootEvents).map((key) => ({
  key: rootEvents[key].key,
  label: rootEvents[key].label,
}));

export const txTypesAffectingBalance = Object.keys(rootEvents)
  .filter(
    (key) =>
      rootEvents[key].affectsBalance &&
      (!rootEvents[key]?.contractType || rootEvents[key]?.contractType === contractType),
  )
  .map((key) => ({
    key: rootEvents[key].key,
    label: rootEvents[key].label,
  }));

export const getRootEvent = (transaction: Array<any>): Record<string, any> => {
  const transactionEvents = transaction.map((event: Record<string, any>) => event.event);
  const rootEventKey = Object.keys(rootEvents).find((key: string) => {
    const { events, mainEventName, compareOperator } = rootEvents[key];
    if (mainEventName) return transactionEvents.includes(mainEventName);

    if (compareOperator === 'OR') return transactionEvents.every((eventName: string) => events.includes(eventName));

    return events.every((event: string) => transactionEvents.includes(event));
  });
  return rootEventKey ? rootEvents[rootEventKey] : {};
};

export const tokenAcronym: Record<string, string> = {
  'AirCarbon CORSIA Token': 'ACCT',
  'AirCarbon Nature Token': 'ACNT',
  'AirCarbon Premium Token': 'ACPT',
  'American Nature Token': 'ANT',
  'CORSIA Eligible Token': 'CET',
  CFT_Base1: 'CFT_Base1',
  CFT_Base2: 'CFT_Base2',
};

export const tokenFullname: Record<string, string> = {
  CFT_Base1: 'CFT_Base1',
  CFT_Base2: 'CFT_Base2',
  CET: 'CORSIA Eligible Token',
  ANT: 'American Nature Token',
  RET: 'Renewable Energy Token',
  GPT: 'Global Premium Token',
  GNT: 'Global Nature Token',
};

export const rootEventsNames = Object.keys(rootEvents).map((key) => rootEvents[key].mainEventName);

// TODO: need to refactor to UOM on db
const tokenQtyMultiplier = () => 1000;

// TODO: Vince will refactor this part on indexer
export async function extractTransactionData({
  contract,
  ccys,
  tokenTypes,
  events,
}: {
  contract: Contract;
  ccys: Record<string, { decimals: number; name: string }>;
  tokenTypes: Record<string, any>;
  events: Array<Record<string, any>>;
}): Promise<Record<string, any>> {
  let mainEvent: Record<string, any> = {};
  let feeEvents: Array<Record<string, any>> = [];
  let nonFeeEvents: Array<Record<string, any>> = [];
  let rootEvent: Record<string, any> = {};

  // NOTE: filter out fee events to keep main transaction events pure.
  const feeTransferTypes = [2, 3, 12, 7, 8, 9, 10, 11];
  feeEvents = events.filter((event) => feeTransferTypes.includes(event.returnValues.transferType));
  nonFeeEvents = events.filter((event) => !feeTransferTypes.includes(event.returnValues.transferType));

  if (nonFeeEvents.length > 0) {
    rootEvent = getRootEvent(nonFeeEvents);

    if (rootEvent.mainEventName)
      mainEvent = nonFeeEvents.filter((event) => rootEvent.mainEventName === event.event)?.[0];
    else if (rootEvent.key === 'tokenTransfer') {
      mainEvent = { ...nonFeeEvents[0] };
      // NOTE: spread was still modifying original events array. Needed to spread internal object as well.
      const cloneReturnValues = { ...mainEvent.returnValues };

      // NOTE: in case there's muliple transferToken events, sum all quantities.
      cloneReturnValues.qty = nonFeeEvents.reduce(
        (sum: number, event: Record<string, any>) => sum + event.returnValues.qty,
        0,
      );

      mainEvent.returnValues = cloneReturnValues;
    }
    // eslint-disable-next-line prefer-destructuring
    else mainEvent = nonFeeEvents[0];
  } else {
    // NOTE: if there's no non-fee events, then it's a fee event.
    rootEvent = getRootEvent(feeEvents);
    mainEvent = feeEvents[0];
  }

  const values = mainEvent?.returnValues;

  const data: Record<string, any> = {
    txType: rootEvent?.key ?? 'unknown',
    txHash: mainEvent?.transactionHash ?? '',
    txLabel: rootEvent?.label ?? 'unknown',
    blockNumber: mainEvent?.blockNumber ?? '',
    from: {},
    to: {},
    mainEvent: {
      event: mainEvent.event,
      returnValues: mainEvent.returnValues,
    },
    affectsBalance: rootEvent?.affectsBalance ? 1 : 0,
  };

  if (data.txType === 'currencyTransfer' || data.txType === 'tokenTransfer') {
    const transferType = allTransferTypes.find((type: { SCCode: number }) => type.SCCode === values.transferType);
    data.transferType = transferType?.key;
    data.transferTypeCode = values.transferType;
    data.txLabel = transferType?.label ?? '';

    if (data.txType === 'tokenTransfer') {
      const stId = values.splitFromSecTokenId ?? values.stId;
      const secToken = await contract.getSecToken(stId);
      if (secToken) values.tokTypeId = hex2int(secToken.tokTypeId);
    }
  }

  const fromTo = ['from', 'to'];
  fromTo.forEach((column: string) => {
    let valueType = '';
    let columnType = '';
    let value = 0;
    let fee = 0;
    let formattedValue = '';
    let symbol;
    const account: string = values?.[column] ?? values?.ledgerOwner ?? values?.batchOwner ?? '';

    if (rootEvent?.[column] && values) {
      const { key, type } = rootEvent[column];
      columnType = type;
      fee = column === 'from' ? values.ccyFeeFrom : values.ccyFeeTo;
      value = values[key] ?? 0;

      if (type === 'currency') {
        const ccy = ccys[values.ccyTypeId];
        valueType = ccy?.name?.trim() ?? '';
        symbol = valueType; // same as type for currency
        formattedValue = formatNumber(value, ccy.decimals);
      } else if (type === 'addedType') {
        valueType = values[key]?.trim() ?? '';
        symbol = tokenAcronym[valueType] ?? valueType;
      } else if (type === 'eth') {
        valueType = 'ETH';
      } else {
        if (values.tokTypeId) {
          const tokenType = tokenTypes[values.tokTypeId];
          valueType = tokenType?.name?.trim() ?? '';
          symbol = tokenAcronym[valueType] ?? valueType;
        }

        value /= tokenQtyMultiplier();
        formattedValue = formatNumber(value, 0);
      }
    }

    data[column] = {
      account,
      columnType,
      valueType,
      value,
      fee: fee ? Number(fee) : 0,
      formattedValue,
      symbol,
    };
  });

  if (feeEvents.length > 0 && nonFeeEvents.length > 0) {
    const accountsFee = feeEvents.reduce((acc: Record<string, number>, event: Record<string, any>) => {
      const cloneAcc = { ...acc };
      const account = event.returnValues.from;
      const amount = Number(event.returnValues.amount ?? 0);

      if (cloneAcc[account]) cloneAcc[account] += amount;
      else cloneAcc[account] = amount;

      return cloneAcc;
    }, {});

    data.from.fee = accountsFee[data.from.account] ?? 0;
  }

  // Get originator fee if any
  const originatorFees = feeEvents
    ?.filter((event) => event.event === 'TransferedLedgerCcy' && Number(event.returnValues.transferType) === 3)
    ?.reduce((sum: number, event) => {
      const totalFees = sum + Number(event.returnValues.amount);
      return totalFees;
    }, 0);

  data.totalOriginatorFees = originatorFees;

  if (['trade', 'tokenTransfer'].includes(data.txType)) {
    data.tradePrice = data.to.value / data.from.value;

    const tokensEvents = ['TransferedPartialSecToken', 'TransferedFullSecToken'];
    const fetchSecTokens = nonFeeEvents
      .filter((event: Record<string, any>) => tokensEvents.includes(event.event))
      .map((event: Record<string, any>) => {
        const secTokIdRaw = event?.returnValues?.splitFromSecTokenId || event?.returnValues?.stId;
        const secTokId = hex2int(secTokIdRaw ?? 0);
        return contract.getSecToken(secTokId);
      });

    const secTokens: Array<any> = await Promise.all(fetchSecTokens);
    const batchIds = secTokens.map((secToken: { batchId: any }) => hex2int(secToken.batchId ?? 0));

    data.batchIds = batchIds;
  }

  if (data.txType === 'mint') {
    const tokensEvents = ['Minted'];
    const getSecTokEvent = nonFeeEvents.filter((event: Record<string, any>) => tokensEvents.includes(event.event))[0];
    if (getSecTokEvent?.returnValues?.batchId) {
      const batchId = hex2int(getSecTokEvent?.returnValues?.batchId);
      data.batchId = batchId;
    }
  }

  if (data.txType === 'burn') {
    const tokensEvents = ['BurnedFullSecToken', 'BurnedPartialSecToken'];
    const fetchSecTokens = events
      .filter((event: Record<string, any>) => tokensEvents.includes(event.event))
      .map((event: Record<string, any>) => {
        const secTokId = hex2int(event?.returnValues?.stId ?? 0);
        return contract.getSecToken(secTokId);
      });

    const secTokens: Array<any> = await Promise.all(fetchSecTokens);
    const batchIds = secTokens.map((secToken: { batchId: any }) => hex2int(secToken.batchId ?? 0));

    data.batchIds = batchIds;
  }

  if (data.txType === 'issuanceSubscribed') {
    data.weiChange = values.weiChange;
  }

  if (data.txType === 'issuerPaymentBatchProcessed') {
    data.weiChange = values.weiChange;
    // for loop for all sub-events
    data.shareWei = values.shareWei;
  }

  return data;
}

// TODO: support private chain for IDX
export const ethExplorerUrl = (networkId: number) => {
  switch (networkId) {
    case 3:
      return 'https://ropsten.etherscan.io';
    case 4:
      return 'https://rinkeby.etherscan.io';
    case 56:
      return 'https://www.bscscan.com';
    case 97:
      return 'https://testnet.bscscan.com';
    case 42101:
      return 'https://ac-dev1.net:39136';
    case 52101:
      return 'https://ac-prod0.aircarbon.co:39136';
    case 80001:
      return 'https://mumbai.polygonscan.com';
    case 137:
      return 'https://polygonscan.com';
    default:
      return 'https://etherscan.io';
  }
};

export const ethExplorerUrlFromTXID = (networkId: number, txId: string) => `${ethExplorerUrl(networkId)}/tx/${txId}`;

export const availableCurrencies: Record<string, any> = {
  '1': 'USD',
};

export const parseBatchMetadata = async (batch: Record<string, any>) => {
  let batchMetadata = Object.keys(batch.metaData).reduce((result: Record<string, any>, key: string) => {
    const cloneResult = { ...result };
    const value = batch.metaData[key];

    if (value.startsWith('U2Fsd')) {
      cloneResult[key] = revealMessage(value);
    } else cloneResult[key] = value;

    return cloneResult;
  }, {});

  // NOTE: disabling this for now until business confirms if this is the official approach.
  // NOTE: if vintage start month is before October, then use start year for vintage.
  // let vintageYear = startVintageYear;
  // const startVintageMonth = project.DATE_VINTAGE_START?.substr(2, 2);
  // if (Number(startVintageMonth) >= 10) {
  //   vintageYear = project.DATE_VINTAGE_END?.substr(project.DATE_VINTAGE_END.length - 4, 4);
  // }

  // NOTE: calculate vintage year and add as metadata for carbon projects
  if (batch?.metaData?.DATE_VINTAGE_START) {
    const startVintageYear = batchMetadata?.DATE_VINTAGE_START?.substr(batchMetadata?.DATE_VINTAGE_START.length - 4, 4);

    batchMetadata = {
      ...batchMetadata,
      CALCULATED_VINTAGE_YEAR: startVintageYear ?? '',
    };
  }

  return {
    ...batch,
    metaData: batchMetadata,
  };
};

export default {
  parseEventValues,
  parseTokenBatch,
  parseBatchMetadata,
  parseAccountLedger,
  eventTypes,
  eventProps,
  rootEvents,
  rootEventsNames,
  availableCurrencies,
  tokenAcronym,
  tokenFullname,
  txTypes,
  txTypesAffectingBalance,
  groupEventsBy,
  getRootEvent,
  extractTransactionData,
  ethExplorerUrlFromTXID,
  ethExplorerUrl,
};
