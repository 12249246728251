import { useState } from 'react';
import { Button, ButtonElementProps } from 'refreshed-component/atoms/Button';
import { Icon, IconType } from 'refreshed-component/atoms/Icon';
import { Colors } from 'refreshed-component/design-system';
import { toast } from 'refreshed-component/molecules/toast';

import { User } from 'state/user';

import Loading from './Loading';

interface Props {
  url: string;
  fileName: string;
  loadingColor?: Colors;
}

const onDownload = (blob: Blob, fileName: string) => {
  const url = window.URL.createObjectURL(new Blob([blob]));
  const link = document.createElement('a');

  link.href = url;
  link.setAttribute('download', fileName);
  link.click();
  link.parentNode?.removeChild(link);
};

export const downloadFile = (url: string, authToken: string, fileName: string) =>
  // TODO: Implement data-mutation
  fetch(url, {
    method: 'POST',
    headers: { authorization: `Bearer ${authToken}` },
  })
    .then((resp) => resp.blob())
    .then((blob) => onDownload(blob, fileName))
    .catch((error) => toast.error(error.messages));

const DownloadButton = ({ url, fileName, loadingColor, ...buttonProps }: ButtonElementProps & Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const {
    selector: { getAuthToken },
  } = User.useContainer();
  return (
    <Button
      {...buttonProps}
      onClick={async (event) => {
        if (isLoading) return;
        setIsLoading(true);
        const authToken = await getAuthToken();
        await downloadFile(url, authToken, fileName);
        setIsLoading(false);
        buttonProps.onClick?.(event);
      }}
      config={{
        ...buttonProps.config,
        icon: {
          left: buttonProps.config?.icon?.left,
          right: <Icon width={14} height={14} type={IconType.Download} /> || buttonProps.config?.icon?.right,
        },
      }}
      children={
        <>
          {isLoading && <Loading isOverLay={false} size="small" color={loadingColor} />}
          {buttonProps.children}
        </>
      }
    />
  );
};

export default DownloadButton;
