import { Reducer } from 'react';

export enum UIState {
  /**
   * Empty search input, dropdown is hidden
   */
  Idle,

  /**
   * After showing search results.
   * Search input may contain previous search string.
   * Dropdown closed
   */
  IdleAfterShowingResults,
  /**
   * After showing empty results.
   * Search input may contain previous search string.
   * Dropdown closed
   */
  IdleAfterShowingEmptyResults,
  /**
   * Input contains search term.
   * Dropdown is opened and loader is shown
   */
  SearchInProgress,
  /**
   * Input contains search term.
   * Showing list of search results
   */
  ShowingResults,
  /**
   * InputContains search term.
   * Showing "not found message"
   */
  ShowingEmptyResults,
}

export enum UIEvent {
  /**
   * Search value changed by typing in input or clearing input
   */
  ChangeSearchValue,

  /**
   * Search results found
   */
  ResultsFound,

  /**
   * Search results not found
   */
  ResultsNotFound,

  /**
   * User lost focus on the search input
   */
  Blur,

  /**
   * User focused on the search input
   */
  Focus,

  /**
   * User selected project from search results
   */
  SelectProject,
}

export const toUIState: Reducer<
  UIState,
  {
    event: UIEvent;
    payload?: any;
  }
> = (state, action) => {
  switch (state) {
    case UIState.Idle:
      if (action.event === UIEvent.ChangeSearchValue && action.payload) {
        return UIState.SearchInProgress;
      }
      if (action.event === UIEvent.Focus && action.payload) {
        return UIState.SearchInProgress;
      }
      break;
    case UIState.SearchInProgress:
      if (action.event === UIEvent.Blur) {
        return UIState.Idle;
      }
      if (action.event === UIEvent.ResultsFound) {
        return UIState.ShowingResults;
      }
      if (action.event === UIEvent.ResultsNotFound) {
        return UIState.ShowingEmptyResults;
      }
      if (action.event === UIEvent.ChangeSearchValue && !action.payload) {
        return UIState.Idle;
      }
      break;
    case UIState.ShowingResults:
      if (action.event === UIEvent.ChangeSearchValue && action.payload) {
        return UIState.SearchInProgress;
      }

      if (action.event === UIEvent.ChangeSearchValue && !action.payload) {
        return UIState.IdleAfterShowingResults;
      }

      if (action.event === UIEvent.Blur) {
        return UIState.IdleAfterShowingResults;
      }

      if (action.event === UIEvent.SelectProject) {
        return UIState.Idle;
      }

      break;
    case UIState.IdleAfterShowingResults:
      if (action.event === UIEvent.ChangeSearchValue && action.payload) {
        return UIState.SearchInProgress;
      }
      if (action.event === UIEvent.Focus && action.payload) {
        return UIState.ShowingResults;
      }
      break;
    case UIState.IdleAfterShowingEmptyResults:
      if (action.event === UIEvent.ChangeSearchValue && action.payload) {
        return UIState.SearchInProgress;
      }
      if (action.event === UIEvent.Focus && action.payload) {
        return UIState.ShowingEmptyResults;
      }
      break;
    case UIState.ShowingEmptyResults:
      if (action.event === UIEvent.ChangeSearchValue && action.payload) {
        return UIState.SearchInProgress;
      }

      if (action.event === UIEvent.ChangeSearchValue && !action.payload) {
        return UIState.IdleAfterShowingEmptyResults;
      }

      if (action.event === UIEvent.Blur) {
        return UIState.IdleAfterShowingResults;
      }
      break;
  }

  return state;
};
