import React from 'react';
import { Link } from 'react-router-dom';
import { Colors, Radius } from 'refreshed-component/design-system';
import styled, { css } from 'styled-components';

import { ThemeColors, Themes } from 'pages/account/trading/components/Themes';

import { ScreenSizes } from 'state/ui';

export const Capitalize = styled.div`
  text-transform: lowercase;

  &::first-letter {
    text-transform: uppercase;
  }
`;

export const ButtonStyle = css<{ themeColors?: ThemeColors }>`
  padding: 1rem 2rem;
  display: inline-flex;
  align-items: center;
  background: ${(props) => `rgba(${props.theme.brandRbgColor},0.9)`};
  border: 1px solid ${(props) => `rgba(${props.theme.brandRbgColor},1)`};
  transition: all 0.2s;
  font-weight: 700;
  color: ${({ themeColors }) => themeColors?.dashboard.button.color ?? `white`};
  text-transform: uppercase;
  font-size: 0.95em;
  letter-spacing: 1px;
  justify-content: center;
  height: 40px;
  max-height: 100%;
  border-radius: 4px;
  white-space: nowrap;

  svg {
    transition: all 0.2s;
    fill: white;
  }

  &.spinner {
    &::after {
      border: 2px solid ${({ themeColors }) => themeColors?.dashboard.button.color ?? `#ededed`};
      border-right-color: transparent;
      border-top-color: transparent;
      top: calc(50% - (2.5em / 2));
      left: calc(50% - (2.5em / 2));
      display: block;
      width: 2.5em;
      height: 2.5em;
    }
  }

  &:hover {
    background: ${(props) => `rgba(${props.theme.brandRbgColor},1)`};
    &.disabled-btn {
      background: ${(props) => `rgba(${props.theme.brandRbgColor},0.9)`} !important;
    }
  }

  &.disabled-btn,
  &:disabled {
    margin-bottom: 0;
    &:hover {
      background: ${(props) => `rgba(${props.theme.brandRbgColor},0.9)`} !important;
    }
    color: ${(props) => `rgba(0,0,0,0.4)`} !important;
    svg {
      fill: ${(props) => `rgba(0,0,0,0.4)`} !important;
    }
  }

  &.secondary {
    background-color: transparent;
    border: 1px solid ${(props) => `rgba(${props.theme.brandRbgColor},1)`};
    color: ${(props) => `rgba(${props.theme.brandRbgColor},1)`};

    svg {
      fill: ${(props) => `rgba(${props.theme.brandRbgColor},1)`};
    }

    &:hover {
      background: ${(props) => `rgba(${props.theme.brandRbgColor},1)`};
      color: white;

      svg {
        fill: white;
      }
    }
  }
  &.delete {
    border: 1px solid ${({ themeColors }) => themeColors?.dashboard.form.button.cancel.border ?? `#a51111`};
    background-color: transparent;
    color: ${({ themeColors }) => themeColors?.dashboard.form.button.cancel.color ?? `#cb1919`};
    &:hover {
      background-color: ${({ themeColors }) => themeColors?.dashboard.form.button.cancel.border ?? `#a51111`};
      color: ${({ themeColors }) => themeColors?.dashboard.button.color ?? `white`};
    }
  }
  &.clean {
    background: transparent;
    border-color: transparent;
    color: ${({ themeColors }) => themeColors?.dashboard.color ?? '#222'} !important;
    &.nav {
      height: 60px;
      font-weight: 500;
      padding: 1rem 1rem;
      margin: 0 2rem;
    }
    &.active.nav {
      font-weight: 700;
      border-bottom: 3px solid;
    }
  }

  &.padding-sm {
    padding-left: 1rem;
    padding-right: 1rem;
  }
`;

export const Nav = styled.nav<{ mobile?: boolean; light?: boolean }>`
  display: flex;
  align-items: center;
  gap: 2rem;

  a,
  button {
    color: ${(props) => props.theme.secondaryColor};
    font-weight: 600;
    -webkit-text-decoration: none;
    text-decoration: none;
  }

  & > a,
  & > button {
    display: flex;
    align-items: center;
    box-sizing: border-box;
    cursor: pointer;
    transition: all 0.4s;

    &:hover {
      color: ${(props) => `rgba(${props.theme.brandRbgColor},1)`};
    }
  }

  ${(props) =>
    props.light &&
    `& > a,
      & > button {
      color: #424242;

      &:hover,
      &.active {
        color: ${`rgba(${props.theme.brandRbgColor},1)`};
      }
    }`}

  .cta {
    ${ButtonStyle}

    align-self: center;
    padding: 7px 1rem;
    height: 32px;
    font-size: 13px;

    &:hover,
    &.active {
      color: white;
    }
  }

  .cta-secondary {
    border: 1px solid ${(props) => props.theme.secondaryColor};
    color: ${(props) => props.theme.secondaryColor};
    background: transparent;
    padding: 7px 1rem;
    height: 32px;
    align-self: center;
    font-weight: 500;
    text-transform: uppercase;
    font-size: 12px;
    letter-spacing: 1px;

    &:hover,
    &.active {
      border: ${(props) => `1px solid rgba(${props.theme.brandRbgColor},1)`};
      color: ${(props) => `rgba(${props.theme.brandRbgColor},1)`};
    }
  }
`;

export const UnitStyle = styled.span`
  font-size: 90%;
`;

export const Divider = styled.div`
  width: 100%;
  border-bottom: 1px solid #ddd;
  height: 1px;
  padding-top: 1rem;
  margin-bottom: 0.5rem;
`;

export const Toolbar = styled.div<{ screenSize?: ScreenSizes }>`
  display: flex;
  align-items: center;
  margin: 2rem 0;
  position: relative;

  ${(props) => {
    if (props.screenSize === 'small' || props.screenSize === 'medium') {
      return `
        margin-left: 20px;
        margin-right: 20px;
      `;
    }
  }}

  @media (max-width: 460px) {
    flex-direction: column;
    margin-left: 1rem;
  }
`;

export const PageTitle = styled.h1`
  margin: 3rem 0 2rem;
  font-size: 2rem;
  font-weight: 400;
  line-height: 1.4;
`;
export const Title = styled.h2`
  margin: 0 0 1.8rem;
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 1.4;
`;
export const CardTitle = styled.h3`
  font-size: 1.2rem;
  font-weight: 700;
  line-height: 1.6;
`;

export const WhiteBox = styled.div<{ themeColors: ThemeColors }>`
  border-radius: 10px;
  background: ${({ themeColors }) => themeColors.dashboard.block.background};
  padding: 2em;
  border: 1px solid ${({ themeColors }) => themeColors.dashboard.deepBackground};
  margin-bottom: 2rem;
`;

// Used for general labels (non forms)
export const Label = styled.label<{ themeColors: ThemeColors }>`
  text-transform: uppercase;
  font-weight: 500;
  color: ${({ themeColors }) => themeColors.dashboard.info.label};
  letter-spacing: 0.5px;
  font-size: 12px;
`;
export const InnerLabel = styled.div`
  font-size: 12px;
  text-transform: uppercase;
  margin-right: 5px;
  color: #717171;
`;

export const InputLabel = styled.label`
  display: block;
  font-size: 0.85rem;
  margin-bottom: 5px;
  line-height: 1;
  font-weight: 500;
`;
export const LabelDescription = styled.div`
  font-size: 0.75rem;
  font-style: italic;
`;
export const FieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 7px;
  margin-bottom: 1rem;
  width: 100%;

  label {
    margin: 0 !important;
  }
`;

export const Tag = styled.div<{ background?: string; color?: string }>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0px 0.5rem;
  border-radius: 5px;
  font-size: 0.75rem;
  font-weight: 500;
  letter-spacing: 0.5px;
  color: ${({ color }) => color ?? '#7c7c7c'};
  background: ${({ background }) => background ?? '#e4e4e4'};
`;

export const TextArea = styled.textarea<{ themeColors: ThemeColors }>`
  border: 1px solid ${({ themeColors }) => themeColors.dashboard.form.input.border} !important;
  background: ${({ themeColors }) => themeColors.dashboard.form.input.background} !important;
  padding: 16px;
  height: 120px;
  display: inline-flex;
  width: 100%;
  color: ${({ themeColors }) => themeColors.dashboard.form.input.color};
  border-radius: 5px;

  &:-internal-autofill-selected,
  &:-webkit-autofill:focus,
  &:-webkit-autofill {
    background-color: ${({ themeColors }) => themeColors?.dashboard.deepBackground} !important;
    transition: background-color 600000s 0s, color 600000s 0s;
  }

  &:focus {
    outline: none;
    border: 1px solid ${(props) => `rgba(${props.theme.brandRbgColor},0.9)`} !important;
  }

  &::placeholder {
    font-weight: 300;
    letter-spacing: 0.5px;
    color: ${({ themeColors }) => themeColors.dashboard.form.input.placeholder} !important;
  }
`;

const NavLinkWrapper = styled.div<{ themeColors?: ThemeColors }>`
  display: contents;
  > a {
    color: ${({ themeColors }) => themeColors?.dashboard.lightColor} !important;
    &:hover {
      color: ${(props) => `rgba(${props.theme.brandRbgColor})`} !important;
    }
    &.active {
      font-weight: bold;
      color: ${(props) => `rgba(${props.theme.brandRbgColor})`} !important;
    }
  }
`;

export const NavLinkWrapperWithThemeColors = React.forwardRef((props: any, ref: any) => {
  const { theme: themeColors } = Themes.useContainer();
  return <NavLinkWrapper {...props} ref={ref} themeColors={themeColors} />;
}) as unknown as typeof NavLinkWrapper;

export const Input = styled.input<{ themeColors?: ThemeColors }>`
  border: 1px solid ${({ themeColors }) => themeColors?.dashboard.form.input.border ?? '#ddd'};
  background: ${({ themeColors }) => themeColors?.dashboard.form.input.background ?? '#fff'};
  padding: 12px;
  height: 38px;
  display: inline-flex;
  width: 100%;
  color: ${({ themeColors }) => themeColors?.dashboard.form.input.color ?? '#222'};
  border-radius: 5px;
  appearance: none;

  &:-internal-autofill-selected,
  &:-webkit-autofill:focus,
  &:-webkit-autofill {
    background-color: ${({ themeColors }) => themeColors?.dashboard.deepBackground ?? '#ededed'} !important;
    transition: background-color 600000s 0s, color 600000s 0s;
  }

  &:focus {
    outline: none;
    border-color: ${(props) => `rgba(${props.theme.brandRbgColor},0.9)`};
  }

  &::placeholder {
    font-weight: 300;
    letter-spacing: 0.5px;
    color: ${({ themeColors }) => themeColors?.dashboard.form.input.placeholder ?? '#828282'};
  }
`;

export const InputWithThemeColors = React.forwardRef((props: any, ref: any) => {
  const { theme: themeColors } = Themes.useContainer();
  return <Input {...props} ref={ref} themeColors={themeColors} />;
}) as unknown as typeof Input;

export const InputWrapper = styled.div<{ themeColors?: ThemeColors; isQuantityInput?: boolean }>`
  ${({ isQuantityInput, themeColors }) =>
    isQuantityInput
      ? css`
          > div {
            background: ${themeColors?.dashboard.form.input.border};
          }
        `
      : ``}

  display: contents;
  input {
    color: var(${Colors.gray_900});
    border: 1px solid var(${Colors.gray_200});
    background: var(${Colors.gray_50});
    padding: 12px;
    height: 47px;
    display: inline-flex;
    width: 100%;
    border-radius: var(${Radius.medium});
    appearance: none;
    transition: background-color 200ms ease-in-out, border-color 200ms ease-in-out, outline-color 200ms ease-in-out;
    outline: rgba(0, 0, 0, 0) solid 0px !important;

    &:-internal-autofill-selected,
    &:-webkit-autofill:focus,
    &:-webkit-autofill {
      background-color: var(${Colors.gray_50}) !important;
      transition: background-color 600000s 0s, color 600000s 0s;
    }

    &:hover {
      background: var(${Colors.gray_100});
      border: 1px solid var(${Colors.gray_300});
    }

    &:focus {
      background: var(${Colors.gray_200});
      outline: var(${Colors.gray_100}) solid 3px !important;
      border: 1px solid var(${Colors.gray_400});
    }

    &::placeholder {
      font-weight: 300;
      letter-spacing: 0.5px;
      color: var(${Colors.gray_400});
    }
  }
`;

export const CheckBoxWrapperWithThemeColors = React.forwardRef((props: any, ref: any) => {
  const { theme: themeColors } = Themes.useContainer();
  return <CheckBoxWrapper {...props} ref={ref} themeColors={themeColors} />;
}) as unknown as typeof CheckBoxWrapper;

export const CheckBoxWrapper = styled.div<{ themeColors?: ThemeColors }>`
  display: contents;
  label > div > div,
  label > div > span {
    border: solid 2px ${({ themeColors }) => themeColors?.dashboard.block.border};
    > span,
    > div {
      background: ${({ themeColors }) => themeColors?.dashboard.form.input.placeholder};
    }
  }
`;

export const InputWrapperWithThemeColors = React.forwardRef((props: any, ref: any) => {
  const { theme: themeColors } = Themes.useContainer();
  return <InputWrapper {...props} ref={ref} themeColors={themeColors} />;
}) as unknown as typeof InputWrapper;

export const InputError = styled.div`
  color: var(${Colors.danger_700});
  font-size: 13px;
`;
export const Select = styled.select`
  height: 50px;
  border: 1px solid #ededed;
  width: 100%;
  color: var(${Colors.gray_800});
  border-radius: 5px;
  padding: 0 16px;
`;

export const SelectWrapper = styled.div<{ themeColors?: ThemeColors; noAfter?: boolean }>`
  .ac-input,
  select {
    padding: 10px !important;
    border: 1px solid ${({ themeColors }) => themeColors?.dashboard.form.input.border ?? '#ddd'} !important;
    appearance: none !important;
    margin: 0 !important;
    height: 100% !important;
    z-index: 10;
    position: relative;
    background: transparent !important;
    ${({ themeColors, theme }) =>
      themeColors
        ? `
            background: ${themeColors?.dashboard.form.input.background} !important;
            color: ${themeColors?.dashboard.form.input.color} !important; 
            &:focus {
              border: 1px solid ${`rgba(${theme.brandRbgColor},1)`} !important;
            }
          `
        : ''}
  }

  position: relative;
  border: 0px solid ${({ themeColors }) => themeColors?.dashboard.form.input.border ?? '#ddd'} !important;
  background: ${({ themeColors }) => themeColors?.dashboard.form.input.background ?? '#fff'} !important;
  color: ${({ themeColors }) => themeColors?.dashboard.form.input.color ?? '#fff'} !important;
  border-radius: 5px;
  height: 100% !important;

  ${({ themeColors, noAfter }) =>
    noAfter
      ? ``
      : `&::after {
    content: '⌄';
    color: ${themeColors?.dashboard.form.input.border ?? '#ddd'} !important;
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-70%);
    z-index: 10;
    font-size: 19px;
    font-weight: 600;
  }`}
`;

export const SelectWrapperWithThemeColors = React.forwardRef((props: any, ref: any) => {
  const { theme: themeColors } = Themes.useContainer();
  return <SelectWrapper {...props} ref={ref} themeColors={themeColors} />;
}) as unknown as typeof SelectWrapper;

export const CancelButton = styled.button`
  ${ButtonStyle}
`;

export const DisabledDeleteButton = styled.div`
  ${ButtonStyle}
  cursor: not-allowed;

  border: 1px solid rgb(165 17 17 / 62%);
  background-color: transparent;
  color: #929292;

  &:hover {
    border: 1px solid rgb(165 17 17 / 62%);
    background-color: rgb(203 25 25 / 68%);
    color: #c9c7c7;
  }
`;

export const ButtonLink = styled.button`
  cursor: pointer;
  color: ${(props) => `rgba(${props.theme.brandRbgColor},1)`};
  font-weight: 500;
  letter-spacing: 0.5px;

  &:hover {
    color: ${(props) => `rgba(${props.theme.brandRbgColor},0.9)`};
  }
`;
export const StyledLink = styled(Link)`
  cursor: pointer;
  color: ${(props) => `rgba(${props.theme.brandRbgColor},1)`};
  display: block;
  font-weight: 600;

  &:hover {
    color: ${(props) => `rgba(${props.theme.brandRbgColor},0.9)`};
  }
`;

export const AddressLink = styled(Link)`
  cursor: pointer;
  color: ${(props) => `rgba(${props.theme.brandRbgColor},1)`};
  display: block;
  font-size: 14px;
  text-transform: uppercase;
  line-height: 1;
  font-weight: 600;

  &:hover {
    color: ${(props) => `rgba(${props.theme.brandRbgColor},0.9)`};
  }
`;

export const StyledLink2 = styled.span`
  cursor: pointer;
  text-decoration: underline;
  display: block;
`;

export const ErrorMessage = styled.div`
  background: red;
  color: white;
  padding: 5px 1em;
  border-radius: 30px;
`;

export const TransactionTable = styled.table<{ themeColors: ThemeColors }>`
  width: 100%;
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.6;
  background: ${({ themeColors }) => themeColors.dashboard.block.background};
  padding: 10px;
  text-align: left;

  th {
    font-size: 1rem;
    font-weight: 500;
    text-align: left;
    border: 0 !important;
    padding: 15px 1.5rem;
  }

  tr {
    height: 100%;
    &.expanded {
      background: ${({ themeColors }) => themeColors.dashboard.sideMenu.color};
      color: ${({ themeColors }) => themeColors.dashboard.color};

      .innerLabel {
        color: #ededed;
      }
    }

    &:not(.expanded) {
      border-bottom: 1px solid ${({ themeColors }) => themeColors.dashboard.deepBackground};
    }
  }

  td {
    text-overflow: unset;
    border-left: 0;
    border-right: 0;
    position: relative;
    padding: 1rem 1.5rem;
    height: 100%;

    & > div:not(.blockchain-detail) {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    .blockchain-detail {
      display: inline-flex;
      flex-direction: row;
      font-size: 0.85rem;

      & > div {
        margin-right: 1rem;
      }
    }
  }

  .tx-name {
    font-size: 15px;
    line-height: 1.6;
    font-weight: 400;
  }

  .tx {
    width: 25%;
    min-width: 250px;

    .txInfo {
      width: 100%;
    }

    .txDetail {
      font-size: 1.2rem;
      font-weight: 200;

      .price {
        font-weight: 400;
      }

      .unit {
        font-size: 0.9rem;
      }
    }
  }

  .datetime {
    width: 165px;
    min-width: 165px;
  }

  .address {
    width: 25%;
    min-width: 230px;
  }

  .txEvents {
    font-size: 11px;
    color: #828282;
    font-style: italic;
  }

  .innerLabel {
    font-size: 14px;
    text-transform: uppercase;
    color: ${({ themeColors }) => themeColors.dashboard.lightColor};
    font-weight: 300;
    line-height: 1;
    margin-right: 5px;
  }

  .hiddenName {
    color: ${({ themeColors }) => themeColors.dashboard.brightColor};
    font-style: italic;
  }

  .id {
    min-width: 80px;
  }
  .tokenName {
    min-width: 300px;
  }

  .tableActions {
    min-width: 120px;
    height: 100%;
    overflow: auto;
    padding: 0;
    position: relative;
  }

  .tableButton {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    background: ${({ themeColors }) => themeColors.dashboard.block.background};
    justify-content: center;
    align-items: center;
    margin-left: 1em;
    width: 100%;
    height: 100%;
    transition: all 0.2s;

    &:first-child {
      margin-left: 0;
    }

    svg {
      fill: #aaa;
    }

    &:hover {
      background: ${({ themeColors }) => themeColors.dashboard.background};
    }
  }

  .numEvents {
    font-size: 12px;
    text-transform: none;
    margin-top: 3px;

    button {
      text-transform: uppercase;
      background: #caecdc;
      color: black;
      font-size: 10px;
      border-radius: 7px;
      padding: 3px 5px;
      cursor: pointer;
      font-weight: 500;
      letter-spacing: 0.5px;
      margin-right: 3px;
    }
  }

  .expandedInfo {
    padding: 1em 3em;
    background: #e8e8e8;

    .title {
      text-transform: uppercase;
      font-weight: 500;
    }

    .metaWrapper {
      border: 1px solid #ededed;
      padding: 1rem 0;
    }

    .expandedRow {
      border-top: 1px solid #ededed;
      border-color: #9fb9ac;
      padding: 1em 0;

      .address {
        max-width: 170px;
        min-width: 170px;
      }

      &:first-child {
        border-top: 0;
      }

      & > div {
        &:not(:first-child) {
          margin-left: 3em;
        }
      }

      .expandedEventName {
        width: 180px;
      }

      .expandedEventValue {
        font-weight: 500;
      }
    }

    .tokenType {
      max-width: 200px;

      .expandedEventValue {
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 100%;
        overflow: hidden;
      }
    }
  }

  @media (max-width: 460px) {
    display: block;
    overflow-x: auto;
  }
`;

export const Disclaimer = styled.div`
  border: 1px solid rgba(${(props) => props.theme.brandRbgColor}, 0.8);
  background: rgba(${(props) => props.theme.brandRbgColor}, 0.4);
  padding: 1rem;

  ul {
    margin-left: 2rem;
  }
  ul,
  li {
    list-style: disc;
  }

  a,
  a:hover {
    text-decoration: underline !important;
  }
`;
