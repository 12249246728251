import queryString from 'query-string';

import { AskResponse } from '@aircarbon/utils-common/src/dto/carbon';

import { userAuthenticatedApi } from 'data-provider/fetcher';

export const fetchCMBAsk = async ({
  page,
  limit,
  isAuction,
  assetCategoryId,
  filterQuery,
}: {
  page: number;
  limit: number;
  isAuction: 'yes' | 'no';
  filterQuery: string;
  assetCategoryId: string;
}) => {
  let qs = queryString.stringify(
    {
      page,
      limit,
      isAuction,
      assetCategoryId,
    },
    {
      skipNull: true,
      skipEmptyString: true,
    },
  );
  const userService = await userAuthenticatedApi();
  if (qs && filterQuery) {
    qs = `${qs}&${filterQuery}`;
  } else if (filterQuery) {
    qs = filterQuery;
  }

  const res = userService.url(`/user/carbon/cmb-ask?${qs}${!!filterQuery ? `&${filterQuery}` : ''}`).get();
  return res.json<AskResponse>();
};
