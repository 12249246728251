import { ExternalMarketAsset } from '@aircarbon/utils-common/src/dto';

import { userAuthenticatedApi } from '../fetcher';

export const fetchExternalMarketAsset = async (symbol: string) => {
  const endpoint = '/oms/order/external-market-asset';
  const userService = await userAuthenticatedApi();
  const res = userService.url(endpoint).query({ symbol }).get();
  return res.json<ExternalMarketAsset>();
};
