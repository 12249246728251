import { createContext, useEffect, useMemo, useState } from 'react';
import { queryCache, useQuery } from 'react-query';
import { useLocation } from 'react-router-dom';
import { FilterDropdown, FilterSelections, Filters } from 'refreshed-component/molecules/Filter';
import { Pagination, usePagination } from 'refreshed-component/molecules/Pagination';
import { PageControls } from 'refreshed-component/organisms/PageControls';
import { PageHolder, PageSections } from 'refreshed-component/organisms/PageHolder';
import { SearchProject } from 'refreshed-component/organisms/SearchProject';
import { MarketBoardSummery } from 'refreshed-component/templates/auctions/MarketBoardSummery';
import { MarketPlaceList } from 'refreshed-component/templates/auctions/MarketPlaceList';

import { AssetCategory, helpers } from '@aircarbon/utils-common';

import useCarbonMetaOptions from 'hooks/useCarbonMetaOptions';
import { useMarketplaceProduct } from 'hooks/useMarketplaceProduct';
import useTokenTypes from 'hooks/useTokenTypes';

import { fetchCMBAsk } from 'data-provider/market-board/fetchCMBAsk';

import { useProjectsFromUrlQuery } from '../hooks/useProjectsFromUrlQuery';
import { toFilterSelections } from './utils/toFilterSelections';
import { toQueryParams } from './utils/toQueryParams';

const parseSelectMetaOptions = (metaOptions: Record<string, any>) =>
  Object.keys(metaOptions).reduce((selectOptions: Record<string, any>, optionKey: string) => {
    const metaOption = metaOptions[optionKey];
    const years = Array(50)
      .fill(1)
      .map((el, i) => 2000 + i);
    switch (optionKey) {
      case 'projectRatings':
        return {
          ...selectOptions,
          projectRatings: {
            type: 'check-box',
            label: 'Project Rating',
            list: metaOption.map((item: string) => ({
              label: item,
              id: item,
            })),
          },
        };

      case 'tokenTypes':
        return {
          ...selectOptions,
          tokenTypeId: {
            type: 'radio-box',
            label: 'Asset Type',
            list: metaOption.map((item: { fullName: string; name: string; scId: number }) => ({
              label: `${item.fullName} (${item.name})`,
              id: item.scId,
            })),
          },
        };

      case 'registries':
        return {
          ...selectOptions,
          registryIds: {
            type: 'check-box',
            label: 'Carbon Registry',
            list: metaOption.map((item: { registryName: string; id: number }) => ({
              label: item.registryName,
              id: item.id,
            })),
          },
        };

      case 'sdgGoals':
        return {
          ...selectOptions,
          carbonSdgGoalIds: {
            type: 'check-box',
            label: 'SDG Goals',
            list: metaOption.map((item: { sdgGoalId: string; sdgGoalName: string; id: number }) => ({
              label: `${item.sdgGoalId}. ${item.sdgGoalName}`,
              id: item.sdgGoalId,
            })),
          },
        };

      case 'unSectoralScopes':
        return {
          ...selectOptions,
          unSectoralScopeIds: {
            type: 'check-box',
            label: 'UN Sectoral Scope',
            list: metaOption.map((item: { unSectoralScopeId: string; unSectoralScopeName: string; id: number }) => ({
              label: `${item.unSectoralScopeId}. ${item.unSectoralScopeName}`,
              id: item.id,
            })),
          },
        };

      case 'countries':
        return {
          ...selectOptions,
          countryCodes: {
            type: 'check-box',
            label: 'Country',
            list: Object.keys(metaOption).map((countryCode: string) => ({
              label: metaOption[countryCode],
              id: countryCode,
            })),
          },
        };

      case 'vintageYears':
        return {
          ...selectOptions,
          vintageYears: {
            type: 'check-box',
            label: 'Vintage Year',
            list: years?.map((year: number) => ({
              label: year,
              id: year,
            })),
          },
        };

      case 'sdgVerificationLevels':
        return {
          ...selectOptions,
          sdgVerificationLevelIds: {
            type: 'check-box',
            label: 'SDG Verification Level',
            list: metaOption.map((item: { id: number; name: number }) => ({ label: item.name, id: item.id })),
          },
        };

      default:
        return {
          ...selectOptions,
        };
    }
  }, {});

const auctionBidsQueryKey = '/api/user/carbon/my-auction-bid';

export const AuctionBidsQueryKey = createContext(auctionBidsQueryKey);

export const MarketPlace = () => {
  const { search: queryParams } = useLocation();
  const { tokenTypes } = useTokenTypes({ options: { enabled: true } });
  const { product } = useMarketplaceProduct();

  const { carbonMetaOptions } = useCarbonMetaOptions({
    query: {
      assetCategory: AssetCategory[Number(product)],
    },
  });
  const metaFilters: Record<string, any> = useMemo(
    () =>
      parseSelectMetaOptions({
        ...carbonMetaOptions,
        vintageYears: {},
        tokenTypes,
      }),
    [carbonMetaOptions],
  );

  const filters: Filters = metaFilters as never as Filters;

  const { projects, setProjects } = useProjectsFromUrlQuery({
    assetCategoryId: product,
  });

  const [filterSelections, setFilterSelections] = useState<FilterSelections<any> | undefined>(
    toFilterSelections({
      queryParams,
      filters,
    }),
  );

  const filterSelectionsFilters = useMemo(() => {
    return {
      ...filters,
      projectIds: {
        type: 'check-box' as 'check-box',
        label: 'Projects',
        list: projects,
      },
    };
  }, [filters, projects]);

  const filterSelectionsSelections: FilterSelections<any> | undefined = useMemo(() => {
    return {
      ...filterSelections,
      projectIds: {
        type: 'check-box' as 'check-box',
        selection: projects.map((p) => p.id),
      },
    };
  }, [filterSelections, projects]);

  const filterQuery = helpers.objectToQueryString(
    Object.keys({ ...filterSelectionsSelections } || {}).reduce((selectOptions, optionKey: string) => {
      const selection = filterSelectionsSelections?.[optionKey]?.selection;
      if (selection) {
        return { ...selectOptions, [optionKey]: filterSelectionsSelections?.[optionKey]?.selection };
      } else {
        return selectOptions;
      }
    }, {}),
  );
  const pagination = usePagination();

  const projectUrl = `/user/carbon/cmb-ask?page=${pagination.page}&limit=${
    pagination.pageSize
  }&isAuction=yes&assetCategoryId=${product}${filterQuery ? `&${filterQuery}` : ''}`;

  const { data, isLoading } = useQuery([projectUrl, filterQuery], async () =>
    fetchCMBAsk({
      filterQuery,
      page: pagination.page,
      limit: pagination.pageSize,
      assetCategoryId: product,
      isAuction: 'yes',
    }),
  );

  useEffect(() => {
    const filtersFromQueryParams = toFilterSelections({
      queryParams,
      filters,
    });

    setFilterSelections(filtersFromQueryParams);
  }, [queryParams, filters]);

  useEffect(() => {
    const updatedQueryParams = toQueryParams({
      filterSelections,
      projects,
      assetCategoryId: product,
    });

    window.history.replaceState(
      undefined,
      '',
      `/account/auctions/marketplace${!!updatedQueryParams ? `?${updatedQueryParams}` : ''}`,
    );
  }, [projects, filterSelections, product]);

  const onSelectProject = (project: { id: number; label: string }) => {
    let newProjects = projects;

    if (!newProjects.find((p) => p.id === project.id)) {
      newProjects = [...newProjects, project];
    }
    setProjects(newProjects);
  };

  const onChangeFilterSelections = (newSelections?: FilterSelections<any> | undefined) => {
    let newProjects: Array<{
      label: string;
      id: number;
    }> = [];
    if (!!newSelections && Object.keys(newSelections).includes('projectIds')) {
      if (newSelections.projectIds?.selection?.length) {
        newProjects = projects.filter((p) => newSelections.projectIds?.selection?.includes(p.id));
      }

      setProjects(newProjects);

      delete newSelections.projectIds;
    }
    setFilterSelections(newSelections);
  };

  const onChangeFilters = (value: FilterSelections<any> | undefined) => {
    setFilterSelections(value);
  };

  return (
    <PageHolder>
      <PageSections>
        <MarketBoardSummery />
      </PageSections>
      <PageSections type="card">
        <PageControls
          controls={{
            primary: (
              <>
                <SearchProject onSelectProject={onSelectProject} assetCategoryId={product} />
                <FilterDropdown selections={filterSelections} onChange={onChangeFilters} list={filters} />
              </>
            ),
            secondary: <Pagination actions={pagination} total={data?.total ?? 0} />,
          }}
        />
        <FilterSelections
          selections={filterSelectionsSelections}
          onChange={onChangeFilterSelections}
          list={filterSelectionsFilters}
        />
        <MarketPlaceList
          items={data?.items ?? []}
          isLoading={isLoading}
          refreshData={() => queryCache.invalidateQueries(projectUrl)}
        />
        {/* TODO: better control this scenario. Perhaps telling the Pagination component if location is top or bottom. */}
        {/* If total items outside of view show bottom pagination */}
        {(data?.total ?? 0) > 4 && (
          <PageControls
            controls={{
              secondary: <Pagination actions={pagination} total={data?.total ?? 0} />,
            }}
          />
        )}
      </PageSections>
    </PageHolder>
  );
};
