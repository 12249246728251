import { FilterSelections } from 'refreshed-component/molecules/Filter';

import { AssetCategory } from '@aircarbon/utils-common';

interface ToQueryParamsProps {
  filterSelections: FilterSelections<any> | undefined;
  assetCategoryId: string;
  projects: Array<{ id: number }>;
}

export const toQueryParams = (props: ToQueryParamsProps) => {
  const { filterSelections = {}, projects, assetCategoryId } = props;
  const filterKeys = Object.keys(filterSelections);
  if (!filterKeys.length && !projects.length && assetCategoryId === AssetCategory.token.toString()) {
    return '';
  }

  const paramsObject = filterKeys.reduce(
    (curr, key) => {
      if (!filterSelections[key]) {
        return curr;
      }

      return {
        ...curr,
        [key]:
          filterSelections[key].type === 'check-box'
            ? filterSelections[key].selection.join(',')
            : String(filterSelections[key].selection),
      };
    },
    {} as Record<string, string>,
  );

  if (assetCategoryId !== AssetCategory.token.toString()) {
    paramsObject.assetCategoryId = assetCategoryId;
  }
  if (projects.length) {
    paramsObject.projectIds = projects.map((p) => p.id).join(',');
  }

  return new URLSearchParams(paramsObject).toString();
};
