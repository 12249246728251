import { CleaveOptions, NumeralThousandsGroupStyleType } from 'cleave.js/options';
import Cleave from 'cleave.js/react';
import React, { InputHTMLAttributes, DetailedHTMLProps, useState, useRef, RefObject, ReactNode } from 'react';
import { UseFormRegisterReturn } from 'react-hook-form';
import { Colors, FontSize, FontWeight, Radius, Spacing } from 'refreshed-component/design-system';
import styled from 'styled-components';

type InputBase = {
  prefix?: ReactNode;
  postfix?: ReactNode;
  fontSize?: FontSize;
  fontWeight?: FontWeight;
  validation?:
    | {
        type: 'cleave';
        option: CleaveOptions;
      }
    | {
        type: 'integer';
        numeralThousandsGroupStyle?: NumeralThousandsGroupStyleType | undefined;
        numeralPositiveOnly?: true;
      }
    | {
        type: 'float';
        numeralThousandsGroupStyle?: NumeralThousandsGroupStyleType | undefined;
        numeralDecimalScale?: number;
        numeralPositiveOnly?: true;
      };
};

type VariantGhost = {
  variant: 'ghost';
  size?: 'base';
};

type VariantIconAndButton = {
  variant?: 'icon' | 'button' | undefined;
  color?: 'primary' | 'secondary' | 'gray' | 'success' | 'warning' | 'error' | 'transparent';
  size?: 'xs' | 'sm' | 'base' | 'l';
};

export type InputConfig = InputBase & (VariantGhost | VariantIconAndButton);

export type InputBaseProps = {
  formRegister?: UseFormRegisterReturn;
  config?: InputConfig;
  ref?: React.RefObject<HTMLInputElement> | React.ForwardedRef<HTMLInputElement>;
  children?: never;
  onEnter?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
};

export type InputProps = DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> & InputBaseProps;

const InputRoot = styled.div<{ fontSize: FontSize; fontWeight: FontWeight }>`
  display: flex;
  justify-content: center;
  align-items: center;
  font-style: normal;
  gap: var(${Spacing.small});
  line-height: 21px;
  border-radius: var(${Radius.medium});
  font-weight: ${({ fontWeight }) => `var(${fontWeight})`};
  font-size: ${({ fontSize }) => `var(${fontSize})`};
  cursor: text;
  outline: rgba(0, 0, 0, 0) solid 0px !important;
  transition: background-color 200ms ease-in-out, border-color 200ms ease-in-out, outline-color 200ms ease-in-out;

  > .children {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1 1 0%;
    gap: var(${Spacing.small});
  }

  &.color-primary {
    color: var(${Colors.gray_900});
    background: var(${Colors.primaryLight});
    svg path {
      fill: var(${Colors.gray_900});
    }

    &:hover {
      background: var(${Colors.primaryDark});
      svg path {
        fill: var(${Colors.gray_900});
      }
    }

    &:focus,
    &.is-focused-true {
      background: var(${Colors.primaryMedium});
      outline: var(${Colors.primaryDefault}) solid 3px !important;
    }
  }

  &.color-gray {
    color: var(${Colors.gray_900});
    background: var(${Colors.gray_50});
    &.border-true {
      border: 1px solid var(${Colors.gray_300});
    }

    &:hover {
      background: var(${Colors.gray_100});
      &.border-true {
        border: 1px solid var(${Colors.gray_300});
      }
    }

    &:focus,
    &.is-focused-true {
      background: var(${Colors.gray_200});
      outline: var(${Colors.gray_100}) solid 3px !important;
      &.border-true {
        border: 1px solid var(${Colors.gray_400});
      }
    }
  }

  &.color-error {
    color: var(${Colors.gray_900});
    background: var(${Colors.gray_50});
    &.border-true {
      border: 1px solid var(${Colors.danger_400});
    }

    &:hover {
      background: var(${Colors.danger_100});
      &.border-true {
        border: 1px solid var(${Colors.danger_700});
      }
    }

    &:focus,
    &.is-focused-true {
      background: var(${Colors.danger_100});
      outline: var(${Colors.danger_100}) solid 3px !important;
      &.border-true {
        border: 1px solid var(${Colors.danger_700});
      }
    }
  }

  &.color-transparent {
    color: var(${Colors.gray_900});
    background: transparent;

    &:hover {
      background: var(${Colors.gray_100});
    }

    &:focus,
    &.is-focused-true {
      background: var(${Colors.gray_200});
      outline: var(${Colors.gray_100}) solid 3px !important;
    }
  }

  &.variant-button {
    &.size-xs {
      padding: 8px 12px;
      font-size: var(${FontSize.xs});
      font-weight: var(${FontWeight.medium});
    }

    &.size-sm {
      padding: 8px 20px;
      font-size: var(${FontSize.base});
      font-weight: var(${FontWeight.medium});
    }

    &.size-base {
      padding: 12px 20px;
      font-size: var(${FontSize.base});
      font-weight: var(${FontWeight.medium});
    }

    &.size-l {
      padding: 14px 24px;
      font-size: var(${FontSize.base});
      font-weight: var(${FontWeight.medium});
    }
  }

  &.variant-icon {
    &.size-base {
      padding: var(${Spacing.xs});
      height: 36px;
      width: 36px;
    }
  }

  > input {
    width: 100%;
    color: inherit;
    background-color: transparent;
    flex: 1 0 0%;
    line-height: 21px;
    outline: none;
    &:-internal-autofill-selected,
    &:-webkit-autofill:focus,
    &:-webkit-autofill {
      transition: background-color 600000s 0s, color 600000s 0s;
    }
    &:disabled {
      color: var(${Colors.gray_400});
      background-color: var(${Colors.gray_50});
    }
  }
`;

export const Input: React.FC<InputProps> = React.forwardRef(
  ({ config, className, onEnter, formRegister, ...props }, forwardRef) => {
    const variant = config?.variant !== 'ghost' ? `variant-${config?.variant || 'button'}` : '';
    const color =
      config?.variant === 'icon' || config?.variant === 'button' || config?.variant === undefined
        ? `color-${config?.color || 'primary'}`
        : '';
    const mixProps = { ...props, ...formRegister };
    const inputInternalRef = useRef<HTMLInputElement>(null);
    const inputRef = (forwardRef || inputInternalRef) as RefObject<HTMLInputElement> | undefined;
    const [isFocused, setFocused] = useState(false);

    const getOption: () => CleaveOptions | undefined = () => {
      if (config?.validation?.type === 'integer') {
        const { type, ...rest } = config.validation;
        return {
          numeral: true,
          numeralDecimalScale: 0,
          numeralThousandsGroupStyle: 'none',
          numeralPositiveOnly: true,
          ...rest,
        };
      } else if (config?.validation?.type === 'float') {
        const { type, ...rest } = config.validation;
        return {
          numeral: true,
          numeralThousandsGroupStyle: 'none',
          numeralPositiveOnly: true,
          ...rest,
        };
      } else if (config?.validation?.type === 'cleave') {
        return config.validation.option;
      }
    };
    return (
      <InputRoot
        fontSize={config?.fontSize || FontSize.small}
        fontWeight={config?.fontWeight || FontWeight.default}
        className={`${className || ''} is-focused-${isFocused ? 'true' : 'false'} border-true size-${
          config?.size || 'base'
        } ${color} ${variant}`}
        onMouseUp={(event) => {
          const target: HTMLElement = event.target as any;
          const currentTarget: HTMLElement = event.currentTarget as any;
          if (target && target.tagName !== 'input') {
            (currentTarget.children[0] as any).focus();
          }
        }}
      >
        {config?.prefix}
        {getOption() ? (
          <Cleave
            {...(mixProps as any)}
            htmlRef={(inputNode: HTMLInputElement) => {
              formRegister?.ref(inputNode);
              const ref = inputRef as any;
              if (typeof ref === 'function') {
                ref(inputNode);
              } else {
                ref.current = inputNode;
              }
            }}
            onFocus={(event) => {
              setFocused(true);
              return props.onFocus?.(event);
            }}
            onBlur={(event) => {
              setFocused(false);
              return props.onBlur?.(event);
            }}
            onKeyDownCapture={(event: React.KeyboardEvent<HTMLInputElement>) => {
              if (event.key === 'Enter') {
                onEnter?.(event);
              }
              return props?.onKeyDownCapture?.(event);
            }}
            value={formRegister ? undefined : props.value ?? ''}
            onChange={formRegister?.onChange || props.onChange}
            options={getOption()}
          />
        ) : (
          <input
            {...(mixProps as any)}
            ref={(inputNode) => {
              formRegister?.ref(inputNode);
              const ref = inputRef as any;
              if (typeof ref === 'function') {
                ref(inputNode);
              } else {
                ref.current = inputNode;
              }
            }}
            onFocus={(event) => {
              setFocused(true);
              return props.onFocus?.(event);
            }}
            onBlur={(event) => {
              setFocused(false);
              return props.onBlur?.(event);
            }}
            onKeyDownCapture={(event: React.KeyboardEvent<HTMLInputElement>) => {
              if (event.key === 'Enter') {
                onEnter?.(event);
              }
              return props?.onKeyDownCapture?.(event);
            }}
            value={formRegister ? undefined : props.value ?? ''}
            onChange={formRegister?.onChange || props.onChange}
          />
        )}

        {config?.postfix}
      </InputRoot>
    );
  },
);
