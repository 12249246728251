import { Asset } from '../../dto';
import { Transaction } from '../../dto/transaction';
import { ProjectStatus } from './enums';

export type ApxProjectListing = {
  id: number;
  title: string;
  status: ProjectStatus;
  date: string;
  dateLabel: string;
  vintage: string;
  country: string;
  sectoralScope: string;
  ccbStandards: string;
};

export interface ApxProject {
  id: number;
  apxId: number;
  name: string;
  apxPhaseId?: number;
  createdBy?: number;
  maxOfferPercentage?: number;
  methodologyId?: number;
  registryId?: number;
  carbonProjectTypeId?: number;
  description?: string;
  registryProjectId?: string;
  implementationDate?: string;
  country?: {
    code: string;
    name?: string;
  };
  sectoralScope?: {
    id: number;
    name: string;
  };
  ccbStandards?: {
    id: number;
    name: string;
  }[];
  sdgGoals?: {
    id: number;
    name: string;
  }[];
  status: {
    code: string;
    description: string;
  };
  apxPhase?: {
    id: number;
    name: string;
    description: string;
  };
  vintages?: {
    id: number;
    expectedIssuanceDateUtc?: string;
    vintageYear: number;
    projectedCreditQuantity: number;
    actualCreditQuantity: number;
    offeredCreditQuantity: number;
    maxOfferedPercentage: number;
    mintedQuantity?: number;
    fctAssetId?: number;
    fctAsset?: Asset;
    mintTransactionId?: number;
    mintTransaction?: Transaction;
  }[];
  apxProponents?: {
    id?: number;
    relationType?: string;
    companyName?: string;
    address?: string;
    phone?: {
      phoneCode: string;
      value: string;
    };
    contactPersonName?: string;
    contactEmails?: string;
  }[];
  apxDocuments?: {
    id?: number;
    name?: string;
    size?: number;
    documentTypeId?: number;
    url?: string;
  }[];
  createdAt: string;
  updatedAt?: string;
}
