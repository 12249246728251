/* eslint-disable no-underscore-dangle */

/* eslint-disable camelcase */
import { Page, Text, View, Document, StyleSheet, renderToStream, renderToString, Image } from '@react-pdf/renderer';
import React from 'react';

import formatter from '../../../formatter';
import { countryNames } from '../../../projects';
import { contractImage, logoImage, volumeImage } from './images';

const styles = StyleSheet.create({
  page: {
    display: 'flex',
    flexDirection: 'column',
    fontSize: 12,
    lineHeight: '1.4',
    backgroundColor: '#F3F4F6',
  },
  topBar: {
    backgroundColor: '#3266DB',
    width: '100%',
    height: 6,
  },
  wrapper: {
    padding: 24,
  },
  logoWrapper: {
    marginBottom: 36,
  },
  logo: {
    width: 106,
    height: 70,
  },
  section: {
    marginBottom: 8,
    width: '100%',
  },
  box: {
    backgroundColor: '#FFFFFF',
    borderRadius: 4,
    width: '100%',
    border: '1px solid #E5E7EB',
  },
  grayBox: {
    backgroundColor: '#E5E7EB',
    padding: 12,
  },
  paddingBox: {
    backgroundColor: '#FFFFFF',
    borderRadius: 4,
    padding: 16,
    width: '100%',
    border: '1px solid #E5E7EB',
  },
  rows: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  hGap: {
    width: 16,
  },
  vGap: {
    height: 16,
  },
  hLine: {
    height: 2,
    width: '100%',
    backgroundColor: '#E5E7EB',
  },
  padding: {
    padding: 16,
  },
  flex1: {
    flex: 1,
  },
  flex2: {
    flex: 2,
  },
  header: {
    marginBottom: 8,
    width: '100%',
  },
  h1: {
    fontSize: 24,
    fontWeight: 'bold',
  },
  h2: {
    fontSize: 16,
    fontWeight: 'light',
  },
  h3: {
    fontSize: 16,
    lineHeight: 1,
  },
  h4: {
    fontSize: 14,
  },
  grayText: {
    color: '#6B7280',
  },
  icon: {
    width: 35,
    height: 35,
  },
  sectionTitle: {
    fontSize: 14,
    lineHeight: 1,
  },
  item: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 4,
  },
  noMarginItem: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    margin: 0,
    padding: 0,
    lineHeight: 1,
  },
  itemLabel: {
    width: 120,
    color: '#6B7280',
  },
  itemValue: {
    flex: 1,
    textAlign: 'left',
  },
});

const generateIdxRetirementCertificate = async (retirement: any) => {
  if (!retirement?.id) {
    return null;
  }

  const xAsset = await retirement.xAsset;
  const startDate = retirement?.offsettingStartDate ? new Date(retirement?.offsettingStartDate) : null;
  const endDate = retirement?.offsettingEndDate ? new Date(retirement?.offsettingEndDate) : null;
  const updateDate = retirement?.updatedUtc ? new Date(retirement?.updatedUtc) : null;
  const retirementDate = retirement?.registryActionDate ? new Date(retirement?.registryActionDate) : updateDate;
  const serialBlock = retirement?.registrySerialBlock;

  // generate scopes
  const scopes: string[] = [];
  if (retirement.scope1) scopes.push('Scope 1');
  if (retirement.scope2) scopes.push('Scope 2');
  if (retirement.scope3) scopes.push('Scope 3');

  const carbonProject = await retirement.carbonProject;
  const registry = await carbonProject?.registry;
  let countries: string | null = null;

  if (carbonProject?.countryCode) {
    countries = carbonProject.countryCode
      ?.split(',')
      ?.map((item: string) => countryNames[item])
      ?.join(', ');
  }

  const dateFormatter = (date: Date) =>
    date?.toLocaleDateString('en-US', { day: '2-digit', month: 'short', year: 'numeric' }) ?? '';

  const MyDocument = () => (
    // eslint-disable-next-line react/jsx-filename-extension
    <Document>
      <Page size="A4" style={styles.page} wrap>
        {/* Top Bar */}
        <View style={styles.topBar} />
        {/* Main wrapper */}
        <View style={styles.wrapper}>
          {/* Logo */}
          <View style={styles.logoWrapper}>
            <Image style={styles.logo} src={logoImage} />
          </View>

          {/* Header */}
          <View style={styles.header}>
            <Text style={styles.h2}>CARBON CREDITS</Text>
            <Text style={styles.h1}>Retirement Confirmation</Text>
          </View>

          {/* Main Content */}
          <View style={styles.section}>
            <Text>
              Confirmation that the carbon retirement request has been completed on SRN through IDXCarbon, with the
              following details:
            </Text>
          </View>

          {/* Top Boxes */}
          <View style={styles.section}>
            <View style={styles.rows}>
              {/* Box 1 */}
              <View style={styles.paddingBox}>
                <View style={styles.rows}>
                  <Image style={styles.icon} src={contractImage} />
                  <View style={styles.hGap} />
                  <View style={styles.flex1}>
                    <Text style={styles.grayText}>Contract</Text>
                    <Text>{xAsset?.name ?? 'N/A'}</Text>
                  </View>
                </View>
              </View>

              <View style={styles.hGap} />

              {/* Box 2 */}
              <View style={styles.paddingBox}>
                <View style={styles.rows}>
                  <Image style={styles.icon} src={volumeImage} />
                  <View style={styles.hGap} />
                  <View style={styles.flex1}>
                    <Text style={styles.grayText}>Volume</Text>
                    <Text>{formatter.formatCurrency(retirement?.quantity) || '0'} tCO2</Text>
                  </View>
                </View>
              </View>
            </View>
          </View>

          {/* Main Box */}
          <View style={styles.section}>
            <View style={styles.box}>
              {/* Beneficiary */}
              <View style={styles.padding}>
                <Text style={styles.grayText}>Beneficiary</Text>
                <Text style={styles.h3}>{retirement?.retirementBeneficiary || 'N/A'}</Text>
              </View>

              <View style={styles.hLine} />

              {/* Retirement Details */}
              <View style={styles.padding}>
                <Text style={styles.h4}>Retirement Details</Text>
                <View style={styles.vGap} />

                {startDate && endDate && (
                  <View style={styles.item}>
                    <Text style={styles.itemLabel}>Emissions Period:</Text>
                    <Text style={styles.itemValue}>
                      {dateFormatter(startDate)} - {dateFormatter(endDate)}
                    </Text>
                  </View>
                )}

                {retirementDate && (
                  <View style={styles.item}>
                    <Text style={styles.itemLabel}>Date of Retirement:</Text>
                    <Text style={styles.itemValue}>{dateFormatter(retirementDate)}</Text>
                  </View>
                )}

                {serialBlock && (
                  <View style={styles.item}>
                    <Text style={styles.itemLabel}>Serial Block:</Text>
                    <Text style={styles.itemValue}>{serialBlock}</Text>
                  </View>
                )}

                <View style={styles.item}>
                  <Text style={styles.itemLabel}>Offset Scope:</Text>
                  <Text style={styles.itemValue}>{scopes.join(', ') || 'N/A'}</Text>
                </View>

                <View style={styles.item}>
                  <Text style={styles.itemLabel}>Retirement Note:</Text>
                  <Text style={styles.itemValue}>{retirement?.retirementReasonComments || 'N/A'}</Text>
                </View>
              </View>

              {/* Serial Block ID */}
              {retirement?.registrySerialBlock && (
                <View style={styles.grayBox}>
                  <View style={styles.noMarginItem}>
                    <Text style={styles.itemLabel}>Serial Block ID:</Text>
                    <Text style={styles.itemValue}>{retirement?.registrySerialBlock}</Text>
                  </View>
                </View>
              )}

              {/* Project Details */}
              <View style={styles.padding}>
                <Text style={styles.h4}>Project Details</Text>
                <View style={styles.vGap} />

                <View style={styles.item}>
                  <Text style={styles.itemLabel}>Project Name:</Text>
                  <Text style={styles.itemValue}>{carbonProject?.name || retirement.projectName || 'N/A'}</Text>
                </View>

                <View style={styles.item}>
                  <Text style={styles.itemLabel}>Project ID:</Text>
                  <Text style={styles.itemValue}>{carbonProject?.registryProjectId || 'N/A'}</Text>
                </View>

                {registry?.registryName && (
                  <View style={styles.item}>
                    <Text style={styles.itemLabel}>Registry:</Text>
                    <Text style={styles.itemValue}>{registry?.registryName}</Text>
                  </View>
                )}

                {countries && (
                  <View style={styles.item}>
                    <Text style={styles.itemLabel}>Country:</Text>
                    <Text style={styles.itemValue}>{countries}</Text>
                  </View>
                )}
              </View>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );

  return { streamPDF: renderToStream(<MyDocument />), stringPDF: renderToString(<MyDocument />) };
};

export default generateIdxRetirementCertificate;
