import queryString from 'query-string';

import { AssetCategory, AssetCategoryCode } from '@aircarbon/utils-common';
import { Pair } from '@aircarbon/utils-common/src/dto';

import { userAuthenticatedApi } from '../fetcher';

export const fetchPairs = async ({
  assetCategories = [AssetCategory[AssetCategory.token] as AssetCategoryCode],
  filterByMarket = 'spot',
  includeMarketData = false,
  projectId,
}: {
  assetCategories?: Array<AssetCategoryCode>;
  filterByMarket?: 'spot' | 'block';
  includeMarketData?: boolean;
  projectId?: string;
}) => {
  const endpoint = '/oms/trade/pairs';
  const userService = await userAuthenticatedApi();

  const qs = queryString.stringify(
    {
      assetCategories,
      filterByMarket,
      includeMarketData: includeMarketData ? 'yes' : 'no',
      projectId: projectId ? projectId : undefined,
    },
    {
      arrayFormat: 'bracket',
      skipEmptyString: true,
      skipNull: true,
    },
  );
  const res = userService.url(endpoint + (qs ? `?${qs}` : '')).get();
  return res.json<Pair[]>();
};
