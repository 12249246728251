import { useMemo } from 'react';
import { DownloadCSV } from 'refreshed-component/molecules/DownloadCSV';
import { PageControls } from 'refreshed-component/organisms/PageControls';
import { PageHolder, PageSections } from 'refreshed-component/organisms/PageHolder';
import { ColumnInfo, Table } from 'refreshed-component/templates/Table';

import { formatter, AssetCategory, AssetCategoryCode } from '@aircarbon/utils-common';
import { Asset } from '@aircarbon/utils-common/src/dto';

import { Account, AccountUser } from 'state/account';
import { User } from 'state/user';

import useTokenTypes from 'hooks/useTokenTypes';

import { totalTokenQty } from 'utils/helpers';

const { hex2int, convertUnit } = formatter;

type UserData = {
  ccys: [
    {
      balance: number;
      ccyTypeId: number;
      name: string;
    },
  ];
  tokens: [
    {
      tokTypeId: number;
      currentQty: number;
    },
  ];
};

function transformData({
  accUsers,
  accData,
  tokenTypes,
  holdingTypes,
  isDownloadCSV = false,
}: {
  accUsers: Array<AccountUser>;
  accData: Array<UserData>;
  tokenTypes: Array<Asset>;
  holdingTypes: Array<string>;
  isDownloadCSV?: boolean;
}) {
  return accUsers?.map((user: { fullName: any; account_type: any }, index: number) => {
    const tokens = holdingTypes.reduce((tokenObj: any, currToken: string) => {
      const ccy = accData[index]?.ccys?.find((ccy: { name: string }) => ccy.name === currToken);
      const currTokenType = tokenTypes.find((token: { symbol: string }) => token.symbol === currToken);
      let currTokenObj;
      if (ccy) {
        currTokenObj = isDownloadCSV
          ? convertUnit(ccy?.balance)
          : { balance: convertUnit(ccy?.balance), type: 'currency' };
        return { ...tokenObj, [currToken]: currTokenObj };
      } else if (currTokenType) {
        const allTokensOfId = accData[index]?.tokens.filter(
          (token: { tokTypeId: any }) => hex2int(token.tokTypeId) === currTokenType?.scId,
        );
        currTokenObj = isDownloadCSV
          ? totalTokenQty(allTokensOfId, currTokenType?.uom.scRatio)
          : { balance: totalTokenQty(allTokensOfId, currTokenType?.uom.scRatio), type: 'token' };
        return {
          ...tokenObj,
          [currToken]: currTokenObj,
        };
      } else {
        return tokenObj;
      }
    }, {});

    return {
      account: user.fullName,
      type: user.account_type,
      ...tokens,
    };
  });
}

export const CurrentBalance = () => {
  const {
    status: { canAccessRecs },
  } = User.useContainer();
  const { accountUsers, accountUsersData, accountHoldingTypes } = Account.useContainer();
  const isRecsEnabled = canAccessRecs();

  const assetCategories = useMemo(() => {
    if (isRecsEnabled) {
      return [AssetCategory[AssetCategory.token], AssetCategory[AssetCategory.rec]];
    }

    return [AssetCategory[AssetCategory.token]];
  }, [isRecsEnabled]);

  const { tokenTypes: tokenTypesDB } = useTokenTypes({
    assetCategories: assetCategories as Array<AssetCategoryCode>,
  });
  const filteredTokens = tokenTypesDB.filter((token) => accountHoldingTypes.includes(token.symbol));

  const rows = transformData({
    accUsers: accountUsers.filter((user) => !!user.account),
    accData: accountUsersData,
    tokenTypes: filteredTokens,
    holdingTypes: accountHoldingTypes,
  });
  const csvRows = transformData({
    accUsers: accountUsers.filter((user) => !!user.account),
    accData: accountUsersData,
    tokenTypes: filteredTokens,
    holdingTypes: accountHoldingTypes,
    isDownloadCSV: true,
  });

  const tokenColumns: { [key: string]: ColumnInfo } = {};
  accountHoldingTypes.forEach(
    (asset) =>
      (tokenColumns[asset] = {
        label: asset,
      }),
  );
  const columns = {
    id: {
      label: '#',
      minWidth: 50,
    },
    account: {
      label: 'Account',
    },
    type: {
      label: 'Type',
    },
    ...tokenColumns,
  };

  return (
    <>
      <PageHolder>
        <PageSections>
          <PageControls
            title="Current Balance"
            controls={{
              secondary: <DownloadCSV data={csvRows} fileName={'current-balance-report.csv'} />,
            }}
          />
        </PageSections>
        <PageSections type="card" className="flex flex-col">
          <Table
            key={Object.keys(columns).join('-')}
            config={{
              sticky: {
                left: ['id'],
              },
              columns,
              rows: rows.map(({ account, type, ...rest }, index) => {
                const data = {
                  account,
                  type,
                  id: index + 1,
                } as any;
                accountHoldingTypes.forEach(
                  (asset) =>
                    (data[asset] = formatter.formatNumber(
                      rest[asset]?.balance,
                      rest[asset]?.type === 'currency' ? 2 : 0,
                    )),
                );
                return data;
              }),
            }}
          />
        </PageSections>
      </PageHolder>
    </>
  );
};
