import { TradeAdjustment } from '@aircarbon/utils-common/src/dto';

import { userAuthenticatedApi } from '../fetcher';

export const fetchTradeAdjustment = async (tradeId: number) => {
  const endpoint = '/oms/trade/trade-adjustment';
  const userService = await userAuthenticatedApi();
  const res = userService.url(endpoint).query({ tradeId }).get();
  return res.json<TradeAdjustment>();
};
