import Big from 'big.js';
import moment from 'moment';
import { Dispatch, SetStateAction, useCallback, useContext, useEffect, useRef, useState } from 'react';
import React from 'react';
import { FaExclamationTriangle } from 'react-icons/fa';
import { queryCache } from 'react-query';
import { Button } from 'refreshed-component/atoms/Button';
import { DatePicker } from 'refreshed-component/atoms/DatePicker';
import { Dropdown } from 'refreshed-component/atoms/Dropdown';
import { Input } from 'refreshed-component/atoms/Input';
import { Text } from 'refreshed-component/atoms/Text';
import { Tooltip } from 'refreshed-component/atoms/Tooltip';
import { Colors, FontSize, FontWeight, Radius, Spacing } from 'refreshed-component/design-system';
import { toast } from 'refreshed-component/molecules/toast';
import styled from 'styled-components';

import { FeeType, formatter, helpers, roundDownNumber, roundUpNumber } from '@aircarbon/utils-common';
import { MarketFlags } from '@aircarbon/utils-common/src/dto';

import SelectOboAccount, { AccountDetails } from 'components/SelectOboAccount';
import Loading from 'components/styled/Loading';

import { Account } from 'state/account';
import { Entity } from 'state/entity';
import { UI } from 'state/ui';
import { User } from 'state/user';

import useDebounce from 'hooks/useDebounce';
import useFee from 'hooks/useFee';

import { isTouchDevice } from 'utils/helpers';

import { DialogContext } from '../components/Dialog';
import InfoList from '../components/InfoList';
import SimpleBar from '../components/Simplebar';
import Slider from '../components/Slider';
import Tabs from '../components/Tabs';
import { Themes } from '../components/Themes';
import styles from '../components/index.module.scss';
import { Label, Hint, TipLabel, TipValue } from '../components/styled';
import {
  Pair,
  TimeInForce,
  useTokenPrice,
  useWeb3AccountBalance,
  useUserOpenCurrencyBalance,
  useUserOpenTokenBalance,
  Asset,
} from '../hooks';
import useMarketSettings from '../hooks/useMarketSettings';
import { usePlaceOrder } from '../hooks/usePlaceOrderToken';
import useUserMarketSettings from '../hooks/useUserMarketSettings';

const { truncateMiddle } = formatter;

const Adjust = styled.div`
  position: absolute;
  width: calc(100% + 32px);
  margin-left: -16px;
  height: 100%;
`;

const Wrapper = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  font-size: 13px;
  color: var(${Colors.gray_900});
  gap: var(${Spacing.xs});
  padding-left: 16px;
  padding-right: 16px;
  .spinner {
    width: 25px;
    height: 25px;
    top: 30%;
    left: 25%;
    &:after {
      width: 20px;
      height: 20px;
    }
  }
  input {
    font-size: 13px;
  }
  .buy-button,
  .sell-button {
    height: 40px !important;
    border-radius: var(${Radius.medium}) !important;
    font-size: var(${FontSize.base});
    div {
      font-weight: var(${FontWeight.medium}) !important;
    }
  }
  .buy-button {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .sell-button {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .place-order-button {
    width: 100%;
    height: 40px;
  }
  .order-types {
    margin: 5px 0;
    button {
      & > div {
        font-size: 14px;
        font-weight: bold;
        color: var(${Colors.gray_500});
      }
      &:hover,
      &.selected {
        text-decoration: none;
        & > div {
          color: var(${Colors.gray_900});
        }
      }
    }
  }
  .dropdown {
    background: #2c2f2f;
    border: none;
  }
`;

const TooltipWrapper = styled.div`
  color: var(${Colors.gray_900});
  width: 250px;
  border-radius: 5px;
`;

const TooltipRow = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

const Title = styled.div`
  position: relative;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  flex: 0 0 auto;
  background-color: transparent;
  padding: 16px;
  gap: 16px;
  padding-bottom: 0px;
  padding-top: 0px;
`;

const OBOWrapper = styled.div`
  padding: 0 16px;
`;

const isMarketSettingEnabled = ({
  settingName,
  marketSettings,
  userMarketSettings,
  pairSettings,
}: {
  settingName: 'orderEntryEnabled' | 'matchingEngineEnabled';
  pairSettings: Record<string, any>;
  marketSettings?: MarketFlags;
  userMarketSettings?: MarketFlags | null;
}): boolean => {
  let isOrderEntryEnabled = marketSettings?.[settingName] === 1;
  const isPairOrderEntryEnabled = pairSettings?.[settingName] === 1;
  const isUserOrderEntryDisabled =
    !!userMarketSettings && !!Object.keys(userMarketSettings).length && userMarketSettings?.[settingName] === 0;

  return isOrderEntryEnabled && isPairOrderEntryEnabled && !isUserOrderEntryDisabled;
};

type Props = {
  pairs: Pair[];
  pair: string | undefined;
  timeInForces: TimeInForce[];
  ccyAsset: Asset | undefined;
  lastTradedPrice: number | undefined;
  pairWatchlist?: Pair;
  height?: 'fixed' | 'auto';
  layout?: string;
  selectedAccount: AccountDetails;
  setSelectedAccount: Dispatch<SetStateAction<AccountDetails>>;
};

// TODO: refactor this component to use react-hook-form
const PlaceOrder = ({
  pairs,
  timeInForces,
  pair,
  ccyAsset,
  lastTradedPrice,
  pairWatchlist,
  height,
  layout: viewPort,
  selectedAccount,
  setSelectedAccount,
}: Props) => {
  const {
    status: { canTradeSpot, canTradeTokenObo },
  } = User.useContainer();
  const {
    selector: { mainCcySymbol },
  } = Entity.useContainer();
  const { theme } = Themes.useContainer();
  const { getSetting } = UI.useContainer();
  const [isSell, setSell] = useState<boolean>(false);
  const [orderType, setOrderType] = useState<string>('Limit');
  const [price, setPrice] = useState<string>();
  const [qty, setQty] = useState<string>();
  const [timeInForce, setTimeInForce] = useState<string>('');
  const [expiryUtc, setExpiryUtc] = useState<Date>();
  const [tradeFee, setTradeFee] = useState<number>(0);
  const [tradePrice, setTradePrice] = useState<number>(0);
  const [estimateTotal, setEstimateTotal] = useState<number>(0);
  const [reservedBalance, setReservedBalance] = useState<number>();
  const [availableBalance, setAvailableBalance] = useState<number>();
  const [error, setError] = useState<string>('');
  const [validationTriedCount, setValidationTryCount] = useState<number>(0);
  const { showDialog, removeDialog } = useContext(DialogContext);
  const { placeOrderData } = useContext(PlaceOrderContext);
  const { marketSettings } = useMarketSettings({});
  const { accountUsers } = Account.useContainer();
  const { userMarketSettings } = useUserMarketSettings({
    accountAddress: selectedAccount.account,
  });

  const token = pair ? pair.split('/')[0] : '';
  const ccy = pair ? pair.split('/')[1] : mainCcySymbol; // As a default

  const allowOrdersOutsidePriceLimits = getSetting('web_settings_allowOrdersOutsidePriceLimits');
  const restingOrderWarning = getSetting('web_settings_restingOrder_warning');

  useEffect(() => {
    if (!timeInForce && timeInForces) setTimeInForce(timeInForces[0]?.timeInForce);
  }, [timeInForce, timeInForces]);

  const {
    isLoadingAccountBalance,
    selectors: { getSellCurrentBalance, getBuyCurrentBalance },
    refetchCurrentBalance,
  } = useWeb3AccountBalance(selectedAccount.account);

  const pairMapper: Record<string, Record<string, any>> = pairs.reduce((prevPair, currPair) => {
    return {
      ...prevPair,
      [currPair.name]: {
        id: currPair?.id,
        baseAsset: currPair.baseAsset,
        quoteAsset: currPair.quoteAsset,
        scTokenTypeId: currPair?.baseAsset?.scId,
        scCcyTypeId: currPair?.quoteAsset?.scId,
        baseAssetUomScRatio: currPair?.baseAsset?.uom?.scRatio,
        quoteAssetUomScRatio: currPair?.quoteAsset?.uom?.scRatio,
        autoRetireAsset: currPair?.baseAsset?.autoRetire,
        mapLot: currPair?.settings?.orderQtyMultiple || 1000,
        minOrder: currPair?.settings?.minOrderQty || 1000,
        maxOrder: currPair?.settings?.maxOrderQty || 100000,
        limitUpPercentage: currPair?.settings?.limitUpPercentage || 10,
        limitDownPercentage: currPair?.settings?.limitDownPercentage || 10,
        orderEntryEnabled: currPair?.marketFlags?.orderEntryEnabled,
        matchingEngineEnabled: currPair?.marketFlags?.matchingEngineEnabled,
        priceIncrement: currPair?.settings?.priceIncrement,
        lotQtySize: currPair?.baseAsset?.lotQtySize,
      },
    };
  }, {});

  const timeInFormMapper: Record<string, string> = timeInForces.reduce((prevPair, currPair) => {
    return { ...prevPair, [currPair.timeInForce]: currPair.id };
  }, {});

  const {
    selectors: { getTotalOpenToken, getOpenOrderToken, getPendingTransactionToken },
    isLoadingOpenTokenBalance,
    refetchOpenTokenBalance,
  } = useUserOpenTokenBalance(selectedAccount.account);
  const {
    selectors: { getTotalOpenBalance, getOpenOrderAmount, getOpenRFQAmount, getPendingTransactionAmount },
    isLoadingOpenCurrencyBalance,
    refetchOpenCurrencyBalance,
  } = useUserOpenCurrencyBalance({
    assetId: pair ? pairMapper[pair]?.quoteAsset?.id : null,
    accountAddress: selectedAccount.account,
    ccyTypeId: pair ? pairMapper[pair]?.scCcyTypeId : null,
  });

  const pairInfo = pair ? pairMapper[pair] : {};

  const tokenNumDecimals = pair ? pairMapper[pair]?.baseAsset?.numDecimals : 0;
  const ccyNumDecimals = pair ? pairMapper[pair]?.quoteAsset?.numDecimals : 2;
  const mapLotToUom = pair ? pairMapper[pair]?.mapLot : 1;
  const mapSmtToBaseUom = pair ? pairMapper[pair]?.baseAssetUomScRatio : 1;
  const lotQtySize = pair ? pairMapper[pair]?.lotQtySize : 1;
  const minQtyOrder = pair ? pairMapper[pair]?.minOrder : 1000;
  const maxQtyOrder = pair ? pairMapper[pair]?.maxOrder : 100000;
  const unitPriceIncrement = pair ? pairMapper[pair]?.priceIncrement : undefined;

  const debouncedTokenQty = useDebounce(qty, 500);
  const debouncedPrice = useDebounce(price, 500);
  const debouncedTradePrice = useDebounce(tradePrice, 500);

  const { feeAmount, isLoading: isLoadingFeeAmount } = useFee({
    params: {
      feeType: FeeType.TRADE_SPOT_FEE,
      assetCategoryId: pair ? pairMapper[pair]?.baseAsset?.assetCategoryId : undefined,
      tokenQty: Number(debouncedTokenQty) * lotQtySize,
      totalAmount: Number(debouncedTradePrice),
    },
    options: { enabled: Number(debouncedTokenQty) > 0 && Number(debouncedTradePrice) > 0 },
  });

  const { isLoadingTokenPrice, tokenPrice } = useTokenPrice({
    pairId: pair ? pairMapper[pair]?.id : null,
    type: isSell ? 'SELL' : 'BUY',
  });

  const priceStep = 1 * 10 ** -(ccyAsset?.numDecimals ?? 2);

  let minStlPrice = 0;
  let maxStlPrice = 0;

  if (tokenPrice?.settlementPrice) {
    minStlPrice = Number((tokenPrice?.settlementPrice * (100 - pairMapper[pair ?? 1]?.limitDownPercentage)) / 100);
    minStlPrice = minStlPrice < priceStep ? priceStep : minStlPrice;
    // case when minStlPrice has more decimals than ccyAsset.numDecimals
    minStlPrice = roundUpNumber(minStlPrice, ccyAsset?.numDecimals ?? 2);

    maxStlPrice = Number((tokenPrice?.settlementPrice * (100 + pairMapper[pair ?? 1]?.limitUpPercentage)) / 100);
    maxStlPrice = maxStlPrice < tokenPrice?.settlementPrice ? tokenPrice?.settlementPrice + priceStep : maxStlPrice;
    // case when maxStlPrice has more decimals than ccyAsset.numDecimals
    maxStlPrice = roundDownNumber(maxStlPrice, ccyAsset?.numDecimals ?? 2);
  }
  const minStlPriceFormatted = formatter.formatNumber(minStlPrice, ccyNumDecimals);
  const maxStlPriceFormatted = formatter.formatNumber(maxStlPrice, ccyNumDecimals);

  const sellStopComparePrice =
    !isNaN(Number(pairWatchlist?.marketData?.lastBidPrice)) && pairWatchlist?.marketData?.lastBidPrice
      ? Number(pairWatchlist?.marketData?.lastBidPrice)
      : Number(lastTradedPrice);
  const buyStopComparePrice =
    !isNaN(Number(pairWatchlist?.marketData?.lastAskPrice)) && pairWatchlist?.marketData?.lastAskPrice
      ? Number(pairWatchlist?.marketData?.lastAskPrice)
      : Number(lastTradedPrice);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const currentBalance = {
    openBalance: isSell ? getTotalOpenToken(pair ? pairMapper[pair]?.id : 1) : getTotalOpenBalance(),
    currentBalance: isSell
      ? getSellCurrentBalance(
          mapSmtToBaseUom,
          pair ? pairMapper[pair]?.scTokenTypeId : 1,
          pair ? Boolean(pairMapper[pair]?.autoRetireAsset) : false,
        )
      : getBuyCurrentBalance(ccy),
  };

  const { placeOrder: mutate, isLoading } = usePlaceOrder();

  const refreshTradePrice = useCallback(() => {
    if (orderType === 'Market' && tokenPrice) setPrice(String(tokenPrice?.marketPrice || tokenPrice?.settlementPrice));
    setTradeFee(feeAmount || 0);
    setTradePrice(Number(price) * Number(qty) * mapLotToUom);
    setEstimateTotal(tradeFee * (isSell ? -1 : 1) + tradePrice);
    setAvailableBalance(currentBalance?.currentBalance - (currentBalance?.openBalance ?? 0));
    setReservedBalance(currentBalance?.openBalance);
  }, [
    orderType,
    tokenPrice,
    feeAmount,
    price,
    qty,
    mapLotToUom,
    tradeFee,
    isSell,
    tradePrice,
    currentBalance?.currentBalance,
    currentBalance?.openBalance,
  ]);

  const refSubmitButton = useRef<HTMLButtonElement>(null);
  const quantityInput = useRef<HTMLInputElement>(null);
  const priceInput = useRef<HTMLInputElement>(null);
  const datePicker = useRef<HTMLButtonElement>(null);

  const pairSettings = pair ? pairMapper[pair] : {};

  const isMarketOrderEntryEnabled = marketSettings?.orderEntryEnabled === 1;
  const isPairOrderEntryEnabled = pairSettings?.orderEntryEnabled === 1;
  const isUserOrderEntryDisabled =
    !!userMarketSettings && !!Object.keys(userMarketSettings).length && userMarketSettings?.orderEntryEnabled === 0;

  const isOrderEntryEnabled = isMarketSettingEnabled({
    settingName: 'orderEntryEnabled',
    marketSettings,
    userMarketSettings,
    pairSettings,
  });
  const isMatchingEngineEnabled = isMarketSettingEnabled({
    settingName: 'matchingEngineEnabled',
    marketSettings,
    userMarketSettings,
    pairSettings,
  });

  useEffect(() => {
    if (placeOrderData) {
      const { price, quantity, side } = placeOrderData;
      if (price && quantity) {
        resetOnTypeChange('Limit');
        setPrice(price.toString());
        setQty((quantity / mapLotToUom).toString());
        setSell(side === 'Sell');
        priceInput.current?.focus();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [placeOrderData]);

  const reservedBalanceTip = () => (
    <TooltipWrapper>
      {isSell ? (
        <>
          <TooltipRow>
            <TipLabel>Open Orders</TipLabel>{' '}
            <div className="ml-auto">
              <TipValue className="code">
                {formatter.formatNumber(getOpenOrderToken(pair ? pairMapper[pair]?.id : 1), tokenNumDecimals)}{' '}
                {pairInfo?.baseAsset?.uom?.name}
              </TipValue>
            </div>
          </TooltipRow>
          {getPendingTransactionToken(pair ? pairMapper[pair]?.id : 1) ? (
            <TooltipRow>
              <TipLabel>Pending Transactions</TipLabel>
              <div className="ml-auto">
                <TipValue className="code">
                  {formatter.formatNumber(
                    getPendingTransactionToken(pair ? pairMapper[pair]?.id : 1),
                    tokenNumDecimals,
                  )}{' '}
                  {pairInfo?.baseAsset?.uom?.name}
                </TipValue>
              </div>
            </TooltipRow>
          ) : (
            <></>
          )}
        </>
      ) : (
        <>
          <TooltipRow>
            <TipLabel>Open Orders</TipLabel>
            <div className="ml-auto">
              <TipValue className="code">
                {formatter.formatNumber(getOpenOrderAmount(), ccyNumDecimals)} {pairInfo?.quoteAsset?.uom?.name}
              </TipValue>
            </div>
          </TooltipRow>
          <TooltipRow>
            <TipLabel>Carbon Finder</TipLabel>
            <div className="ml-auto">
              <TipValue className="code">
                {formatter.formatNumber(getOpenRFQAmount(), ccyNumDecimals)} {pairInfo?.quoteAsset?.uom?.name}
              </TipValue>
            </div>
          </TooltipRow>
          {getPendingTransactionAmount() ? (
            <TooltipRow>
              <TipLabel>Pending Transactions</TipLabel>
              <div className="ml-auto">
                <TipValue className="code">
                  {formatter.formatNumber(getPendingTransactionAmount(), ccyNumDecimals)}{' '}
                  {pairInfo?.quoteAsset?.uom?.name}
                </TipValue>
              </div>
            </TooltipRow>
          ) : (
            <></>
          )}
        </>
      )}
    </TooltipWrapper>
  );

  const availableBalanceTip = () => (
    <TooltipWrapper>
      {isSell ? (
        <>
          <TooltipRow>
            <TipValue>Available = Current - Reserved</TipValue>
          </TooltipRow>
          <TooltipRow>
            <TipLabel>Current Balance </TipLabel>
            <div className="ml-auto">
              <TipValue className="code">
                {formatter.formatNumber(
                  getSellCurrentBalance(
                    mapSmtToBaseUom,
                    pair ? pairMapper[pair]?.scTokenTypeId : 1,
                    pair ? Boolean(pairMapper[pair]?.autoRetireAsset) : false,
                  ),
                  tokenNumDecimals,
                )}{' '}
                {pairInfo?.baseAsset?.uom?.name}
              </TipValue>
            </div>
          </TooltipRow>
        </>
      ) : (
        <>
          <TooltipRow>
            <TipValue>Available = Current - Reserved</TipValue>
          </TooltipRow>
          <TooltipRow>
            <TipLabel>Current Balance </TipLabel>
            <div className="ml-auto">
              <TipValue className="code">
                {formatter.formatNumber(getBuyCurrentBalance(ccy), ccyNumDecimals)} {pairInfo?.quoteAsset?.uom?.name}
              </TipValue>
            </div>
          </TooltipRow>
        </>
      )}
    </TooltipWrapper>
  );

  const checkPlaceOrderValidation = useCallback(() => {
    if (!qty || isNaN(Number(qty)) || Number(qty) <= 0) {
      setError('Please specify a valid quantity.');
      return;
    }
    if (Number(qty) * mapLotToUom < minQtyOrder) {
      setError('Insufficient qty.');
      return;
    }
    if (Number(qty) * mapLotToUom > maxQtyOrder) {
      setError('Exceeds maximum allowed quantity.');
      return;
    }
    if (Number(qty) % 1) {
      setError('Odd lots not allowed.');
      return;
    }
    if (orderType !== 'Market') {
      if (!price || isNaN(Number(price)) || Number(price) <= 0) {
        setError('Please specify a price larger than 0');
        return;
      }
      if ((price || '').toString().split('.')?.[1]?.length > (ccyAsset?.numDecimals || 2)) {
        setError(`Only ${ccyAsset?.numDecimals || 2} decimals is allowed`);
        return;
      }
      if (unitPriceIncrement && +helpers.fmod(Big(price), Big(unitPriceIncrement)) !== 0) {
        setError(`Valid price increment (step) is ${unitPriceIncrement}.`);
        return;
      } else {
        setError('');
      }
    }
    if (isSell && currentBalance) {
      if (currentBalance?.currentBalance - currentBalance?.openBalance < Number(qty) * mapLotToUom) {
        setError('Insufficient assets to place order');
        return;
      } else {
        setError('');
        return;
      }
    }
    if (!isSell && currentBalance) {
      if (currentBalance?.currentBalance - currentBalance?.openBalance < Number(qty) * mapLotToUom * Number(price)) {
        setError('Insufficient balance to place order.');
        return;
      } else {
        setError('');
        return;
      }
    }
    if (orderType === 'Stop' && !isNaN(Number(price))) {
      if (isSell) {
        if (!isNaN(sellStopComparePrice) && Number(price) >= sellStopComparePrice) {
          setError(`Invalid price for stop loss order, max price: ${sellStopComparePrice - priceStep}.`);
          return;
        }
      } else {
        if (!isNaN(buyStopComparePrice) && Number(price) <= buyStopComparePrice) {
          setError(`Invalid price for stop order, min price: ${buyStopComparePrice + priceStep}.`);
          return;
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ccyAsset?.numDecimals, currentBalance, isSell, lastTradedPrice, mapLotToUom, minQtyOrder, orderType, price, qty]);

  useEffect(() => {
    refreshTradePrice();
    checkPlaceOrderValidation();
  }, [checkPlaceOrderValidation, refreshTradePrice]);

  const placeOrderValidation = (): boolean => {
    if (!qty || Number(qty) <= 0) {
      toast.error('Quantity must be greater than zero');
      quantityInput.current?.focus();
      return false;
    }
    if (orderType !== 'Market') {
      if (!price || Number(price) <= 0) {
        toast.error('Price must be greater than zero');
        priceInput.current?.focus();
        return false;
      }
      if (unitPriceIncrement && +helpers.fmod(Big(price), Big(unitPriceIncrement)) !== 0) {
        toast.error(`Valid price increment (step) is ${unitPriceIncrement}.`);
        priceInput.current?.focus();
        return false;
      }
    }
    if (isNaN(Number(qty)) || isNaN(Number(price))) {
      toast.error('Quantity either Price must be a valid number');
      return false;
    }
    if (Number(qty) % 1) {
      toast.error('Odd lots not allowed');
      return false;
    }
    if (Number(qty) * mapLotToUom < minQtyOrder) {
      toast.error('Insufficient qty');
      return false;
    }
    if (timeInForce === 'Good Till Date' && !expiryUtc) {
      toast.error('Expiry date must be later than the current date and time.');
      datePicker.current?.focus();
      return false;
    }
    if ((price || '').toString().split('.')?.[1]?.length > (ccyAsset?.numDecimals || 2)) {
      toast.error(`Only ${ccyAsset?.numDecimals || 2} decimals is allowed`);
      return false;
    }
    if (Number(allowOrdersOutsidePriceLimits) === 0 && orderType.toLowerCase() === 'limit') {
      if (Number(price) < minStlPrice || Number(price) > maxStlPrice) {
        toast.error(
          `Price too far from settlement (STL) price ${pairInfo?.quoteAsset?.code}${tokenPrice?.settlementPrice}`,
        );
        return false;
      }
    }
    if (isSell) {
      if (tradeFee > tradePrice) {
        toast.error('You do not have enough balance');
        return false;
      }
      if (currentBalance.currentBalance - currentBalance.openBalance < Number(qty) * mapLotToUom) {
        toast.error('You do not have enough assets');
        return false;
      }
    }
    if (!isSell) {
      const availableBalance = currentBalance?.currentBalance - currentBalance?.openBalance;
      const totalPrice = Number(qty) * mapLotToUom * Number(price) + tradeFee;
      if (availableBalance < totalPrice) {
        toast.error('You do not have enough balance');
        return false;
      }
    }
    if (orderType === 'Stop' && !isNaN(Number(price))) {
      if (isSell) {
        if (!isNaN(sellStopComparePrice) && Number(price) >= sellStopComparePrice) {
          toast.error(`Invalid price for stop loss order, max price: ${sellStopComparePrice - priceStep}.`);
          return false;
        }
      } else {
        if (!isNaN(buyStopComparePrice) && Number(price) <= buyStopComparePrice) {
          toast.error(`Invalid price for stop order, min price: ${buyStopComparePrice + priceStep}.`);
          return false;
        }
      }
    }
    return true;
  };

  const finallyPlaceOrder = () => {
    let order: Record<string, any> = {
      orderType,
      pairId: pair && (pairMapper[pair].id || pairs?.[0]?.id),
      orderSide: isSell ? 'Sell' : 'Buy',
      qty: Number(qty) * mapLotToUom,
      accountAddress: selectedAccount.account,
    };
    if (orderType !== 'Market') {
      order = {
        ...order,
        price: Number(price),
        timeInForceTypeId:
          timeInFormMapper[timeInForce] !== undefined ? timeInFormMapper[timeInForce] : timeInForces[0]?.id,
      };
      if (timeInForce === 'Good Till Date' && expiryUtc) order = { ...order, expiryUtc };
    } else {
      order = {
        ...order,
        timeInForceTypeId: timeInFormMapper['Day'] !== undefined ? timeInFormMapper['Day'] : timeInForces[0]?.id,
      };
    }

    mutate(order)
      .then((data) => {
        if (data?.order?.order_id) {
          queryCache.invalidateQueries(['oms-orders']);
          refetchCurrentBalance();
          refetchOpenCurrencyBalance();
          refetchOpenTokenBalance();
          setQty('');
          setPrice('');
          setValidationTryCount(0);
          setExpiryUtc(undefined);
          setTimeInForce(timeInForces[0]?.timeInForce);
          return;
        }
        const errorResponse = data?.json;
        toast.error(errorResponse ? errorResponse?.message : 'Something went wrong!');
      })
      .catch((err) => {
        toast.error(err?.message ?? 'Something went wrong!');
      });
  };

  const tryToPlaceOrder = () => {
    if (isOrderEntryEnabled) {
      refetchCurrentBalance();
      refetchOpenCurrencyBalance();
      refetchOpenTokenBalance();
      setValidationTryCount(validationTriedCount + 1);
      let restingOrder = false;
      if (Number(allowOrdersOutsidePriceLimits) === 1 && orderType.toLowerCase() === 'limit' && restingOrderWarning) {
        if (Number(price) < minStlPrice || Number(price) > maxStlPrice) {
          restingOrder = true;
        }
      }
      if (placeOrderValidation()) {
        showDialog?.({
          title: 'Place Order',
          message: (
            <>
              <div className="flex flex-col gap-5 items-center w-full h-auto" style={{ minWidth: '350px' }}>
                <div className="font-bold" style={{ color: theme.table.active.text }}>
                  Please confirm order details
                </div>
                <div
                  style={{ color: theme.table.text.default }}
                  className="flex flex-col flex-shrink gap-1 w-11/12 text-sm text-gray-200"
                >
                  {canTradeTokenObo() && (
                    <div className="flex flex-row gap-4">
                      <div className="flex-1 text-left">On behalf of:</div>
                      <div>{`${selectedUserInfo?.fullName}`}</div>
                    </div>
                  )}
                  <div className="flex flex-row gap-4">
                    <div className="flex-1 text-left">Pair:</div>
                    <div>{`${pair}`}</div>
                  </div>
                  <div className="flex flex-row gap-4">
                    <div className="flex-1 text-left">Side:</div>
                    <div>{isSell ? 'Sell' : 'Buy'} </div>
                  </div>
                  <div className="flex flex-row gap-4">
                    <div className="flex-1 text-left">Type:</div>
                    <div>{`${orderType}`}</div>
                  </div>
                  <div className="flex flex-row gap-4">
                    <div className="flex-1 text-left">Quantity:</div>
                    <div className="code">{`${qty}`}</div>
                  </div>
                  <div className="flex flex-row gap-4">
                    <div className="flex-1 text-left">Price:</div>
                    <div className="code">{orderType === 'Market' ? 'MKT' : `${price}`}</div>
                  </div>
                  <div className="flex flex-row gap-4">
                    <div className="flex-1 text-left">Estimated Fee:</div>
                    <div>
                      <span className="code">{formatter.formatNumber(tradeFee)} </span>
                      {pairInfo?.quoteAsset?.uom?.name}
                    </div>
                  </div>
                  <div className="flex flex-row gap-4">
                    <div className="flex-1 text-left">Estimated Total:</div>
                    <div>
                      <span className="code">{isNaN(estimateTotal) ? 0 : formatter.formatNumber(estimateTotal)} </span>
                      {pairInfo?.quoteAsset?.uom?.name}
                    </div>
                  </div>
                  <div className="flex flex-row gap-4">
                    <div className="flex-1 text-left">Time In Force:</div>
                    <div>{`${timeInForce}`}</div>
                  </div>
                  {timeInForce === 'Good Till Date' && (
                    <div className="flex flex-row gap-4">
                      <div className="flex-1 text-left">Expiry Date:</div>
                      <div>{`${expiryUtc ? moment(expiryUtc).format('MMMM Do YYYY, h:mm:ss a') : '-'} `}</div>
                    </div>
                  )}
                  <div className="flex flex-col">
                    <div className="flex-1 text-left">Placed By:</div>
                    <div className="flex flex-row ml-4">
                      <div className="flex-1 text-left">Name:</div>
                      <div>{selectedAccount.fullName}</div>
                    </div>
                    <div className="flex flex-row ml-4">
                      <div className="flex-1 text-left">User ID:</div>
                      <div>{selectedAccount.userId}</div>
                    </div>
                    <div className="flex flex-row ml-4">
                      <div className="flex-1 text-left">Address:</div>
                      <div>{truncateMiddle(selectedAccount.account, 16)}</div>
                    </div>
                  </div>
                </div>
              </div>
              {restingOrder && (
                <div className="pt-5">
                  <div
                    className="flex flex-row items-center p-1 text-xs text-left"
                    style={{
                      color: theme.table.active.text,
                      backgroundColor: theme.panel.button.background,
                      border: theme.panel.button.border,
                    }}
                  >
                    <FaExclamationTriangle size="45px" className="mr-2 ml-2 text-yellow-300" />
                    <div>
                      {restingOrderWarning} ({minStlPriceFormatted} - {maxStlPriceFormatted}) {ccy}.
                    </div>
                  </div>
                </div>
              )}
            </>
          ),
          cancel: {
            style: {
              height: '34px',
              minWidth: '150px',
            },
            onClick: () => {
              removeDialog?.();
              if (!isTouchDevice()) refSubmitButton.current?.focus();
            },
          },
          accept: {
            style: {
              height: '34px',
              minWidth: '150px',
            },
            onClick: () => {
              finallyPlaceOrder();
              removeDialog?.();
              if (!isTouchDevice()) quantityInput.current?.focus();
            },
          },
        });
      }
    }
  };

  const resetOnTypeChange = (orderTypeValue: string) => {
    setOrderType(orderTypeValue);
    if (orderTypeValue === 'Market') {
      setTimeInForce(timeInForces[1]?.timeInForce);
    } else {
      setTimeInForce(timeInForces[0]?.timeInForce);
    }
    setQty('');
    setPrice('');
  };

  const setDateExpiry = (date: any) => {
    if (date) {
      if (date?.toDate() > new Date()) {
        setExpiryUtc(date?.toDate());
      } else {
        setExpiryUtc(undefined);
        toast.error('Expiry date must be later than the current date and time.');
      }
    } else {
      setExpiryUtc(undefined);
    }
  };

  const isPlaceOrderButtonDisabled =
    isLoadingFeeAmount ||
    isLoadingTokenPrice ||
    isLoadingAccountBalance ||
    isLoadingOpenCurrencyBalance ||
    getTotalOpenBalance() === undefined ||
    isLoadingOpenTokenBalance ||
    Boolean(!validationTriedCount ? false : (error ?? false));

  if (!marketSettings || !userMarketSettings || pairs.length === 0) return <Loading />;

  const isFormEnabled = isOrderEntryEnabled && canTradeSpot();

  const selectedUserInfo = accountUsers?.find(
    (account: { account: string }) => account?.account === selectedAccount.account,
  );

  const content = (
    <div
      style={{
        display: 'contents',
      }}
    >
      {(!isOrderEntryEnabled || !isMatchingEngineEnabled) && (
        <Wrapper className="flex flex-col mt-2 w-full">
          <div className="relative text-xs font-semibold text-grey-100 text-nowrap">
            {!isMarketOrderEntryEnabled && <div className="p-1 mb-1 bg-red-500">Order entry is disabled</div>}
            {isMarketOrderEntryEnabled && !isPairOrderEntryEnabled && (
              <div className="p-1 mb-1 bg-red-500">Order entry is disabled for this contract</div>
            )}
            {isMarketOrderEntryEnabled && isPairOrderEntryEnabled && isUserOrderEntryDisabled && (
              <div className="p-1 mb-1 bg-red-500">Order entry is disabled for this user</div>
            )}
            {!isMatchingEngineEnabled && <div className="p-1 mb-1 bg-red-500">Matching of orders is disabled</div>}
          </div>
        </Wrapper>
      )}
      {canTradeTokenObo() && (
        <div>
          <Title>
            <Label color={theme.panel.text.default}>On Behalf Of</Label>
          </Title>
          <OBOWrapper className="mt-1 mb-4 w-full">
            <SelectOboAccount
              name="OBO"
              inputValue={selectedAccount.account}
              setFieldValue={(_selectedField, value) => {
                setSelectedAccount({ account: value.value, userId: value.user_id, fullName: value.fullName });
              }}
            />
          </OBOWrapper>
        </div>
      )}
      <Wrapper
        style={{
          opacity: isFormEnabled ? 1 : 0.5,
          pointerEvents: isFormEnabled ? 'auto' : 'none',
          userSelect: isFormEnabled ? 'inherit' : 'none',
        }}
        className="flex flex-col mt-2 w-full"
      >
        <div
          style={{
            background: `var(${Colors.gray_100})`,
          }}
          className={`relative flex flex-row justify-center flex-auto rounded-lg z-0`}
        >
          <Button
            config={{
              color: isSell || !isFormEnabled ? 'ghost' : 'secondary',
            }}
            onClick={() => setSell(false)}
            className={`relative buy-button flex-1`}
          >
            BUY
          </Button>
          <Button
            config={{
              color: !isSell || !isFormEnabled ? 'ghost' : 'secondary',
            }}
            className={`relative sell-button flex-1`}
            onClick={() => setSell(true)}
          >
            SELL
          </Button>
        </div>
        <div className="flex relative flex-row">
          <div className="flex flex-1 items-center">
            <div>
              <Tabs
                className="order-types"
                onSelect={(item) => {
                  if (item.value !== orderType) resetOnTypeChange(item.value);
                }}
                type={'ghost'}
                selected={orderType}
                list={[
                  {
                    label: 'Limit',
                    value: 'Limit',
                  },
                  {
                    label: 'Market',
                    value: 'Market',
                  },
                  {
                    label: 'Stop',
                    value: 'Stop',
                  },
                ]}
              />
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-small">
          <div>
            <div className="flex flex-col gap-2xs">
              <Text color={Colors.gray_900} size={FontSize.small}>
                Quantity
              </Text>
              <Input
                ref={quantityInput}
                config={{
                  size: 'sm',
                  postfix: (
                    <Text color={Colors.gray_500} size={FontSize.small}>
                      LOTS
                    </Text>
                  ),
                  color: 'gray',
                }}
                onEnter={() => tryToPlaceOrder()}
                onKeyDownCapture={(event) => {
                  if (event.key === 'Tab' && event.shiftKey === true) {
                    event.preventDefault();
                    if (refSubmitButton.current) {
                      refSubmitButton.current.focus();
                    }
                  }
                }}
                value={qty}
                onBlur={() => qty?.endsWith('.') && setQty(`${qty}0`)}
                onChange={(e) => {
                  const value = e.target.value === '.' ? '0.' : e.target.value;
                  const re = RegExp(`${tokenNumDecimals ? `(^\\d+\\.\\d{0,${tokenNumDecimals}}$)|` : ``}(^\\d+$)`);
                  if (value === '' || re.test(value)) {
                    setQty(value);
                  }
                }}
              />
              <Hint
                className="mt-1"
                style={{
                  color: theme.placeHolder.input.label,
                }}
              >
                <span className="code">{formatter.formatNumber(Number(qty ?? 0) * mapLotToUom, 0)}</span>{' '}
                {pairInfo?.baseAsset?.uom?.name} of {token}
              </Hint>
            </div>
            {orderType !== 'Market' ? (
              <>
                <div className="flex flex-col gap-2xs">
                  <Text color={Colors.gray_900} size={FontSize.small}>
                    Unit Price
                  </Text>
                  <Input
                    ref={priceInput}
                    config={{
                      size: 'sm',
                      postfix: (
                        <Text color={Colors.gray_500} size={FontSize.small}>
                          {pairInfo?.quoteAsset?.uom?.name}
                        </Text>
                      ),
                      color: 'gray',
                    }}
                    value={price}
                    onBlur={() => price?.endsWith('.') && setPrice(`${price}0`)}
                    onChange={(e) => {
                      const value = e.target.value === '.' ? '0.' : e.target.value;
                      const re = RegExp(`${ccyNumDecimals ? `(^\\d+\\.\\d{0,${ccyNumDecimals}}$)|` : ``}(^\\d+$)`);
                      if (value === '' || re.test(value)) {
                        setPrice(value);
                      }
                    }}
                    disabled={orderType === 'Market'}
                    onEnter={() => tryToPlaceOrder()}
                  />
                  {orderType.toLowerCase() === 'stop' && (
                    <Hint
                      className="mt-1"
                      style={{
                        color: theme.placeHolder.input.label,
                      }}
                    >
                      {isSell ? 'Max Price: ' : 'Min Price: '}
                      <span className="code">
                        {formatter.formatNumber(
                          Number(isSell ? sellStopComparePrice - priceStep : buyStopComparePrice + priceStep),
                          ccyAsset?.numDecimals ?? 2,
                        )}
                      </span>
                    </Hint>
                  )}
                  {orderType.toLowerCase() === 'limit' &&
                    tokenPrice?.settlementPrice &&
                    !isNaN(tokenPrice?.settlementPrice) &&
                    tokenPrice?.settlementPrice !== 0 && (
                      <div>
                        <Slider
                          layout={viewPort}
                          selectedValue={Number(price)}
                          divider={6}
                          showDivider={!unitPriceIncrement}
                          valueIncrement={unitPriceIncrement}
                          onChange={(value) => {
                            if (value) setPrice(value.toFixed(ccyNumDecimals));
                          }}
                          side={isSell ? 'Sell' : 'Buy'}
                          list={[
                            {
                              label: !isNaN(minStlPrice) ? minStlPriceFormatted : '-',
                              value: !isNaN(minStlPrice) ? minStlPrice : undefined,
                            },
                            {
                              label: !isNaN(tokenPrice?.settlementPrice)
                                ? formatter.formatNumber(tokenPrice?.settlementPrice, ccyNumDecimals)
                                : '-',
                              value: !isNaN(tokenPrice?.settlementPrice)
                                ? Number(tokenPrice?.settlementPrice)
                                : undefined,
                            },
                            {
                              label: !isNaN(maxStlPrice) ? maxStlPriceFormatted : '-',
                              value: !isNaN(maxStlPrice) ? maxStlPrice : undefined,
                            },
                          ]}
                        />
                      </div>
                    )}
                </div>
                <div className="flex flex-col gap-1 justify-end mt-base gap-base">
                  <Dropdown
                    className="flex-1"
                    key={'to'}
                    list={timeInForces.map((item) => ({
                      id: item.timeInForce.toString(),
                      label: item.timeInForce,
                    }))}
                    selected={[timeInForce]}
                    onSelectItem={(item) => {
                      setTimeInForce(item.id.toString());
                    }}
                    children={(item) => {
                      const firstItem = item?.[0];
                      if (firstItem) {
                        return (
                          <Text display="block" color={Colors.gray_900} size={FontSize.small}>
                            Time In Force: <span className="font-semibold">{firstItem.label}</span>
                          </Text>
                        );
                      } else {
                        return (
                          <Text display="block" color={Colors.gray_900}>
                            Time In Force
                          </Text>
                        );
                      }
                    }}
                    config={{
                      color: 'gray',
                      size: 'sm',
                    }}
                  />

                  {timeInForce === 'Good Till Date' && (
                    <DatePicker
                      ref={datePicker}
                      selected={expiryUtc}
                      onChange={(date) => {
                        setDateExpiry(date ? moment(date) : undefined);
                      }}
                      onClear={() => {
                        setDateExpiry(undefined);
                      }}
                      showTimeSelect={true}
                      disabled={orderType === 'Market'}
                      placeholderText="Expiry Date"
                    />
                  )}
                </div>
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
        <InfoList
          className="mt-1"
          border={
            !isFormEnabled
              ? 'transparent'
              : isSell
                ? theme.placeHolder.input.highlight.sell
                : theme.placeHolder.input.highlight.buy
          }
          borderStyle={
            isLoadingFeeAmount ||
            isLoadingTokenPrice ||
            isLoadingOpenTokenBalance ||
            isLoadingOpenCurrencyBalance ||
            getTotalOpenBalance() === undefined ||
            isLoadingAccountBalance
              ? 'dashed'
              : 'solid'
          }
          info={[
            {
              label: (
                <div>
                  Available Balance
                  <span className="inline-block relative top-1 left-1 cursor-pointer">
                    <Tooltip
                      text={
                        <Text className="whitespace-pre" align="center" size={FontSize.small}>
                          {availableBalanceTip()}
                        </Text>
                      }
                    />
                  </span>
                </div>
              ),
              font: 'code',
              value: (
                <>
                  <span
                    className={`${
                      isLoadingAccountBalance || isLoadingOpenCurrencyBalance || isLoadingOpenTokenBalance
                        ? 'h-1/5 w-1/5 p-4 spinner'
                        : ''
                    }`}
                  >
                    {availableBalance
                      ? formatter.formatNumber(availableBalance, isSell ? tokenNumDecimals : ccyNumDecimals)
                      : 0}
                  </span>{' '}
                  {isSell ? token : ccy}
                </>
              ),
            },
            {
              label: (
                <div>
                  Reserved Balance
                  <span className="inline-block relative top-1 left-1 cursor-pointer">
                    <Tooltip
                      text={
                        <Text className="whitespace-pre" align="center" size={FontSize.small}>
                          {reservedBalanceTip()}
                        </Text>
                      }
                    />
                  </span>
                </div>
              ),
              font: 'code',
              value: (
                <>
                  <span
                    className={`${
                      isLoadingAccountBalance || isLoadingOpenCurrencyBalance || isLoadingOpenTokenBalance
                        ? 'h-1/5 w-1/5 p-4 spinner'
                        : ''
                    }`}
                  >
                    {reservedBalance
                      ? formatter.formatNumber(reservedBalance, isSell ? tokenNumDecimals : ccyNumDecimals)
                      : 0}
                  </span>{' '}
                  {isSell ? token : ccy}
                </>
              ),
            },
            {
              label: 'Estimated Fee',
              font: 'code',
              value: (
                <>
                  <span className={`${isLoadingFeeAmount ? 'h-1/5 w-1/5 p-4 spinner' : ''}`}>
                    {formatter.formatNumber(tradeFee)}{' '}
                  </span>
                  {pairInfo?.quoteAsset?.uom?.name}
                </>
              ),
            },
            {
              label: 'Estimated Total',
              font: 'code',
              color: Colors.gray_900,
              fontSize: FontSize.base,
              value: (
                <>
                  <span>{isNaN(estimateTotal) ? 0 : formatter.formatNumber(estimateTotal)} </span>
                  {pairInfo?.quoteAsset?.uom?.name}
                </>
              ),
            },
          ]}
        />
        {!!validationTriedCount && (
          <div className="flex relative flex-row flex-auto">
            <span className="text-red-500 text-nowrap">{error}</span>
          </div>
        )}
      </Wrapper>
    </div>
  );

  return height === 'auto' ? (
    <>
      <div className="flex flex-col h-full max-h-full">
        <div className="overflow-hidden relative flex-row flex-1">{content}</div>
        <div className="flex relative flex-col justify-center px-base pt-base">
          {isLoading ? (
            <div className="spinner" />
          ) : (
            <>
              <Button
                ref={refSubmitButton}
                className={`place-order-button ${styles['border-focused-white']}`}
                key={`isSell-${isSell}`}
                config={{
                  color: 'primary',
                  size: 'l',
                }}
                onClick={tryToPlaceOrder}
                disabled={
                  isPlaceOrderButtonDisabled ||
                  !isOrderEntryEnabled ||
                  !tokenPrice?.settlementPrice ||
                  isNaN(tokenPrice?.settlementPrice) ||
                  tokenPrice?.settlementPrice === 0 ||
                  !isFormEnabled
                }
                onKeyDownCapture={(event) => {
                  if (event.key === 'Tab' && event.shiftKey === false) {
                    event.preventDefault();
                    quantityInput.current?.focus();
                  }
                }}
              >
                PLACE ORDER
              </Button>
            </>
          )}
        </div>
      </div>
    </>
  ) : (
    <Adjust>
      <div className="flex flex-col h-full max-h-full">
        <div className="overflow-hidden relative flex-col flex-1">
          <div className="absolute w-full h-full">
            <SimpleBar>{content}</SimpleBar>
          </div>
        </div>
        <div className="flex relative flex-col justify-center px-base pt-small">
          <Button
            ref={refSubmitButton}
            key={`isSell-${isSell}`}
            config={{
              color: 'primary',
              size: 'l',
            }}
            isLoading={isLoading}
            onClick={tryToPlaceOrder}
            disabled={
              isLoading
                ? true
                : isPlaceOrderButtonDisabled ||
                  !isOrderEntryEnabled ||
                  !tokenPrice?.settlementPrice ||
                  isNaN(tokenPrice?.settlementPrice) ||
                  tokenPrice?.settlementPrice === 0 ||
                  !isFormEnabled
            }
            onKeyDownCapture={(event) => {
              if (event.key === 'Tab' && event.shiftKey === false) {
                event.preventDefault();
                quantityInput.current?.focus();
              }
            }}
          >
            {`PLACE ORDER - ${pair}`}
          </Button>
        </div>
      </div>
    </Adjust>
  );
};

export type PlaceOrderContextProps = {
  setPlaceOrderData?: (props: {
    price: number;
    quantity: number;
    side: 'Buy' | 'Sell';
    principalId?: number;
    shippingTypeId?: number;
    fobTypeId?: number;
  }) => void;
  placeOrderData?: {
    price: number;
    quantity: number;
    side: 'Buy' | 'Sell';
    principalId?: number;
    shippingTypeId?: number;
    fobTypeId?: number;
  };
};

export const PlaceOrderContext = React.createContext<PlaceOrderContextProps>({} as PlaceOrderContextProps);

export default PlaceOrder;
