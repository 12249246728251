import { useEffect, useState } from 'react';

import { logger } from '@aircarbon/utils-common';

import { fetchContractTxTypes } from 'data-provider/contract/fetchContractTxTypes';

export default function useContractTxTypes(withAffectBalance: boolean) {
  const [txTypes, setTxTypes] = useState<Array<Record<string, string>>>([]);
  useEffect(() => {
    fetchContractTxTypes(withAffectBalance)
      .then((values) => values.data && setTxTypes(values.data))
      .catch((error: any) => {
        logger.error('Failed to fetchContractTxTypes: Error', error);
      });
  }, [withAffectBalance]);
  return txTypes;
}
