import React from 'react';
import { ButtonHTMLAttributes, DetailedHTMLProps } from 'react';
import { Colors, FontSize, FontWeight, Radius, Spacing } from 'refreshed-component/design-system';
import Loading, { LoadingRoot } from 'refreshed-component/molecules/Loading';
import styled from 'styled-components';

type ButtonBase = {
  icon?: {
    left?: React.ReactNode | null;
    right?: React.ReactNode | null;
  };
  isFocused?: boolean;
  border?: boolean;
  fontSize?: FontSize;
  fontWeight?: FontWeight;
};

type VariantGhost = {
  variant: 'ghost';
  size?: 'base';
};

type VariantIconAndButton = {
  variant?: 'icon' | 'button' | undefined;
  color?: 'primary' | 'secondary' | 'outlined' | 'green' | 'red' | 'ghost' | 'gray';
  size?: 'xs' | 'sm' | 'base' | 'l';
};

export type ButtonProps = ButtonBase & (VariantGhost | VariantIconAndButton);

const ButtonRoot = styled.button<{ fontSize: FontSize; fontWeight: FontWeight }>`
  display: flex;
  justify-content: center;
  align-items: center;
  font-style: normal;
  gap: var(${Spacing.small});
  line-height: 21px;
  border-radius: var(${Radius.medium});
  font-weight: ${({ fontWeight }) => `var(${fontWeight})`};
  font-size: ${({ fontSize }) => `var(${fontSize})`};
  outline: rgba(0, 0, 0, 0) solid 0px !important;
  transition: background-color 200ms ease-in-out, border-color 200ms ease-in-out, outline-color 200ms ease-in-out;

  > .children {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1 1 0%;
    gap: var(${Spacing.small});
    overflow: hidden;
    line-height: 21px;
    text-overflow: ellipsis;
  }

  &.is-disabled {
    cursor: not-allowed !important;
    opacity: 0.5;
  }

  &.is-loading {
    cursor: not-allowed !important;
  }

  &.color-primary {
    color: var(${Colors.buttonPrimaryContentDefault});
    background: var(${Colors.buttonPrimarySurfaceDefault});
    > .children > ${LoadingRoot} > .loader .lds-ring div {
      border-color: var(${Colors.buttonPrimaryContentDefault}) transparent transparent transparent !important;
    }
    svg {
      path[fill] {
        fill: var(${Colors.buttonPrimaryContentDefault});
      }
    }

    &:hover {
      background: var(${Colors.buttonPrimarySurfaceHover});
    }

    &:focus,
    &.is-focused-true {
      background: var(${Colors.buttonPrimarySurfaceHover});
      outline: var(${Colors.primaryNormal}) solid 3px !important;
    }

    &:disabled {
      color: var(${Colors.buttonPrimaryContentDisabled});
      background: var(${Colors.buttonPrimarySurfaceDisabled});
      svg {
        path[fill] {
          fill: var(${Colors.buttonPrimaryContentDisabled});
        }
      }
    }
  }

  &.color-secondary {
    color: var(${Colors.buttonSecondaryContentDefault});
    background: var(${Colors.buttonSecondarySurfaceDefault});
    > .children > ${LoadingRoot} > .loader .lds-ring div {
      border-color: var(${Colors.buttonSecondaryContentDefault}) transparent transparent transparent !important;
    }
    svg {
      path[fill] {
        fill: var(${Colors.buttonSecondaryContentDefault});
      }
    }

    &:hover {
      background: var(${Colors.buttonSecondarySurfaceHover});
    }

    &:focus,
    &.is-focused-true {
      background: var(${Colors.buttonSecondarySurfaceHover});
      outline: var(${Colors.gray_300}) solid 3px !important;
    }

    &:disabled {
      color: var(${Colors.buttonSecondaryContentDisabled});
      background: var(${Colors.buttonSecondarySurfaceDisabled});
      svg {
        path[fill] {
          fill: var(${Colors.buttonSecondaryContentDisabled});
        }
      }
    }
  }

  &.color-outlined {
    color: var(${Colors.buttonOutlinedContentDefault});
    background: transparent;
    border: 1px solid var(${Colors.buttonOutlinedStrokeDefault});
    > .children > ${LoadingRoot} > .loader .lds-ring div {
      border-color: var(${Colors.buttonOutlinedContentDefault}) transparent transparent transparent !important;
    }
    svg {
      path[fill] {
        fill: var(${Colors.buttonOutlinedContentDefault});
      }
    }

    &:hover {
      border: 1px solid transparent;
      background: var(${Colors.buttonOutlinedSurfaceHover});
      color: var(${Colors.buttonOutlinedContentHover});
      svg {
        path[fill] {
          fill: var(${Colors.buttonOutlinedContentHover});
        }
      }
    }

    &:focus,
    &.is-focused-true {
      border: 1px solid transparent;
      background: var(${Colors.buttonOutlinedSurfaceHover});
      color: var(${Colors.buttonOutlinedContentHover});
      svg {
        path[fill] {
          fill: var(${Colors.buttonOutlinedContentHover});
        }
      }
      outline: var(${Colors.gray_300}) solid 3px !important;
    }

    &:disabled {
      color: var(${Colors.buttonOutlinedContentDisabled});
      border: 1px solid var(${Colors.buttonOutlinedContentDisabled});
      background: transparent;
      svg {
        path[fill] {
          fill: var(${Colors.buttonOutlinedContentDisabled});
        }
      }
    }
  }

  &.color-ghost {
    color: var(${Colors.buttonGhostContentDefault});
    background: transparent;
    > .children > ${LoadingRoot} > .loader .lds-ring div {
      border-color: var(${Colors.buttonGhostContentDefault}) transparent transparent transparent !important;
    }
    svg {
      path[fill] {
        fill: var(${Colors.buttonGhostContentDefault});
      }
    }

    &:hover {
      color: var(${Colors.buttonGhostContentHover});
    }

    &:focus,
    &.is-focused-true {
      color: var(${Colors.buttonGhostContentHover});
    }

    &:disabled {
      color: var(${Colors.buttonGhostContentDisabled});
      svg {
        path[fill] {
          fill: var(${Colors.buttonGhostContentDisabled});
        }
      }
    }
  }

  &.color-disabled {
    color: var(${Colors.gray_900});
    background: var(${Colors.gray_50});
    > .children > ${LoadingRoot} > .loader .lds-ring div {
      border-color: var(${Colors.gray_900}) transparent transparent transparent !important;
    }

    &.border-true {
      border: 1px solid var(${Colors.gray_200});
    }

    &:hover {
      background: var(${Colors.gray_100});
      &.border-true {
        border: 1px solid var(${Colors.gray_300});
      }
    }

    &:focus,
    &.is-focused-true {
      background: var(${Colors.gray_200});
      outline: var(${Colors.gray_100}) solid 3px !important;
      &.border-true {
        border: 1px solid var(${Colors.primaryMedium});
      }
    }
  }

  &.color-green {
    color: var(${Colors.gray_900});
    background: var(${Colors.gray_50});
    > .children > ${LoadingRoot} > .loader .lds-ring div {
      border-color: var(${Colors.gray_900}) transparent transparent transparent !important;
    }
    &.border-true {
      border: 1px solid var(${Colors.gray_200});
    }

    &:hover {
      background: var(${Colors.gray_100});
      &.border-true {
        border: 1px solid var(${Colors.gray_300});
      }
    }

    &:focus,
    &.is-focused-true {
      background: var(${Colors.gray_200});
      outline: var(${Colors.gray_100}) solid 3px !important;
      &.border-true {
        border: 1px solid var(${Colors.primaryMedium});
      }
    }
  }

  &.color-red {
    color: var(${Colors.gray_900});
    background: var(${Colors.gray_50});
    > .children > ${LoadingRoot} > .loader .lds-ring div {
      border-color: var(${Colors.gray_900}) transparent transparent transparent !important;
    }
    &.border-true {
      border: 1px solid var(${Colors.gray_200});
    }

    &:hover {
      background: var(${Colors.gray_100});
      &.border-true {
        border: 1px solid var(${Colors.gray_300});
      }
    }

    &:focus,
    &.is-focused-true {
      background: var(${Colors.gray_200});
      outline: var(${Colors.gray_100}) solid 3px !important;
      &.border-true {
        border: 1px solid var(${Colors.primaryMedium});
      }
    }
  }

  &.color-gray {
    color: var(${Colors.gray_900});
    background: var(${Colors.gray_50});
    > .children > ${LoadingRoot} > .loader .lds-ring div {
      border-color: var(${Colors.gray_900}) transparent transparent transparent !important;
    }
    &.border-true {
      border: 1px solid var(${Colors.gray_200});
    }

    &:hover {
      background: var(${Colors.gray_100});
      &.border-true {
        border: 1px solid var(${Colors.gray_300});
      }
    }

    &:focus,
    &.is-focused-true {
      background: var(${Colors.gray_200});
      outline: var(${Colors.gray_100}) solid 3px !important;
      &.border-true {
        border: 1px solid var(${Colors.gray_400});
      }
    }
  }

  &.color-black {
    color: var(${Colors.gray_0});
    background: var(${Colors.gray_500});
    > .children > ${LoadingRoot} > .loader .lds-ring div {
      border-color: var(${Colors.gray_0}) transparent transparent transparent !important;
    }

    > .children > ${LoadingRoot} > .loader .lds-ring div {
      border-color: var(${Colors.gray_0}) transparent transparent transparent !important;
    }
    &.border-true {
      border: 1px solid var(${Colors.gray_900});
    }

    &:hover {
      background: var(${Colors.gray_600});
      &.border-true {
        border: 1px solid var(${Colors.gray_900});
      }
    }

    &:focus,
    &.is-focused-true {
      background: var(${Colors.gray_900});
      outline: var(${Colors.gray_100}) solid 3px !important;
      &.border-true {
        border: 1px solid var(${Colors.gray_900});
      }
    }
  }

  &.variant-button {
    &.size-xs {
      padding: 8px 12px;
      font-size: var(${FontSize.xs});
      font-weight: var(${FontWeight.medium});
    }

    &.size-sm {
      padding: 8px 20px;
      font-size: var(${FontSize.base});
      font-weight: var(${FontWeight.medium});
    }

    &.size-base {
      padding: 12px 20px;
      font-size: var(${FontSize.base});
      font-weight: var(${FontWeight.medium});
    }

    &.size-l {
      padding: 14px 24px;
      font-size: var(${FontSize.base});
      font-weight: var(${FontWeight.medium});
    }
  }

  &.variant-icon {
    &.size-base {
      padding: var(${Spacing.xs});
      height: 36px;
      width: 36px;
    }
  }
`;

export type ButtonElementProps = {
  config?: ButtonProps;
  isLoading?: boolean;
  ref?: React.RefObject<HTMLButtonElement> | React.ForwardedRef<HTMLButtonElement>;
} & DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>;

export const Button = React.forwardRef(({ config, isLoading, ...props }, ref) => {
  const variant = config?.variant !== 'ghost' ? `variant-${config?.variant || 'button'}` : '';
  let color =
    config?.variant === 'icon' || config?.variant === 'button' || config?.variant === undefined
      ? `color-${config?.color || 'primary'}`
      : '';

  return (
    <ButtonRoot
      {...(props as any)}
      fontSize={config?.fontSize || FontSize.small}
      fontWeight={config?.fontWeight || FontWeight.default}
      ref={ref}
      onClick={!isLoading ? props.onClick : undefined}
      className={`${props.className || ''} ${isLoading ? 'is-loading' : ''} ${
        props.disabled ? 'is-disabled' : ''
      } is-focused-${config?.isFocused ? 'true' : 'false'} border-${config?.border ? 'true' : 'false'} size-${
        config?.size || 'base'
      } ${color} ${variant}`}
    >
      {config?.icon?.left}
      {props.children && (
        <div className="children">
          {!isLoading && props.children}
          {isLoading && (
            <>
              <>Loading...</>
              <Loading size="small" isOverLay={false} />
            </>
          )}
        </div>
      )}
      {config?.icon?.right}
    </ButtonRoot>
  );
}) as React.FC<ButtonElementProps>;
