import { add } from 'date-fns';
import { useState } from 'react';
import { useQuery } from 'react-query';
// import { useParams } from 'react-router-dom';
import { Button } from 'refreshed-component/atoms/Button';
import { CheckBoxProps } from 'refreshed-component/atoms/CheckBox';
import { Empty } from 'refreshed-component/atoms/Empty';
import { HitArea } from 'refreshed-component/atoms/HitArea';
import { Icon, IconType } from 'refreshed-component/atoms/Icon';
import { Input } from 'refreshed-component/atoms/Input';
import { Text } from 'refreshed-component/atoms/Text';
import { Colors, FontSize, FontWeight, Spacing } from 'refreshed-component/design-system';
import { ConfirmModal } from 'refreshed-component/molecules/ConfirmModal';
import DownloadText from 'refreshed-component/molecules/DownloadText';
import { FilterDropdown, FilterSelections } from 'refreshed-component/molecules/Filter';
import Loading from 'refreshed-component/molecules/Loading';
import { Pagination, usePagination } from 'refreshed-component/molecules/Pagination';
import { toast } from 'refreshed-component/molecules/toast';
import { PageControls } from 'refreshed-component/organisms/PageControls';
import { PageHolder, PageSections } from 'refreshed-component/organisms/PageHolder';
import { MarketPlaceProjectCard } from 'refreshed-component/templates/market-board/MarketPlaceProjectCard';

// import styled from 'styled-components';
import { formatter, logger } from '@aircarbon/utils-common';

import useMarketSettings from 'pages/account/trading/hooks/useMarketSettings';

import { UI } from 'state/ui';
import { User } from 'state/user';

import useCurrencies from 'hooks/useCurrencies';
import { useMarketplaceProduct } from 'hooks/useMarketplaceProduct';

import { fetchMyAuctionBid } from 'data-provider/market-board/fetchMyAuctionBid';

// TODO: check this commented logic bellow see if there's anything relevant.

// const statusCode: Record<string, any> = {
//   open: ['NEW', 'CONFIRMED_BY_SELLER', 'PROCESS_TRADE', 'PROCESS_FEE', 'CREDITS_ACQUIRED'],
//   ready: ['CREDITS_ACQUIRED'],
//   available: ['CONFIRMED_BY_SELLER'],
//   new: ['NEW'],
//   done: ['DONE'],
//   rejected: ['REJECTED_BY_SELLER'],
//   canceled: ['CANCELLED'],
// };

// const MyBidsRoot = styled.div`
//   padding: var(${Spacing.large});
//   gap: var(${Spacing.large});
//   display: flex;
//   flex-direction: column;
//   position: relative;
//   width: 100%;
//   > .header {
//     display: flex;
//     flex-direction: row;
//     flex: space;
//     justify-content: space-between;
//     > .title {
//       display: flex;
//       flex-direction: row;
//       flex: space;
//       gap: var(${Spacing.small});
//       > .account-type {
//         min-width: 200px;
//       }
//     }
//     > .actions {
//       > .transfer {
//         min-width: 200px;
//       }
//     }
//   }
//   .asset-filter-dropdown {
//     min-width: 200px;
//   }
//   .token-img {
//     width: 44px;
//     height: 44px;
//   }
// `;

const statusLabel: Record<string, any> = {
  CANCELLED: { status: 'Canceled', message: 'Canceled by user' },
  CANCELLED_BY_OPS: { status: 'Canceled', message: 'Canceled by Operations' },
  FORFEIT: { status: 'Forfeited', message: '' },
  DONE: { status: 'Filled', message: 'Trade completed' },
  CONFIRMED_BY_SELLER: { status: 'In Progress', message: 'Please ensure you have enough funds to cover the trade' },
  REJECTED_BY_SELLER: { status: 'Rejected', message: 'Bid was rejected by seller.' },
  CREDITS_ACQUIRED: { status: 'Pending Payment', message: 'Please ensure you have enough funds to cover the trade' },
  NEW: { status: 'New', message: 'Contacting seller..' },
};

export const MyBids = () => {
  const {
    selector: { getAuthToken },
    status: { canBidAuction },
  } = User.useContainer();
  const { currenciesById } = useCurrencies();
  const currenciesObjById = currenciesById();
  const [search, setSearch] = useState('');
  // const params: { status: string } = useParams();
  const { getSetting } = UI.useContainer();
  // const { status } = params;
  const { product } = useMarketplaceProduct();
  const pagination = usePagination();
  const page = pagination.page;
  const limit = pagination.pageSize;

  const { marketSettings, isLoading: isLoadingMarketSettings } = useMarketSettings({});

  const expiryDays = getSetting('global_emb_expireDays');
  const closingTime = getSetting('global_emb_closingTime');

  const bidsUrl = `/api/user/carbon/my-auction-bid?assetCategoryId=${product}&page=${page}&limit=${limit}`;

  const { data, isLoading, refetch } = useQuery([bidsUrl, page, limit], () =>
    fetchMyAuctionBid({
      assetCategoryId: product,
      page,
      limit,
    }),
  );
  const filters: {
    status: {
      type: 'check-box';
      label: string;
      list: CheckBoxProps[];
    };
  } = {
    status: {
      type: 'check-box',
      label: 'Status',
      list: [
        {
          id: 1,
          label: 'New',
        },
        {
          id: 2,
          label: 'Accepted',
        },
        {
          id: 3,
          label: 'Rejected',
        },
      ],
    },
  };
  const [filterSelections, setFilterSelections] = useState<FilterSelections<typeof filters> | undefined>();
  const filteredBid = data?.items?.filter?.(
    (item: { status: string; __cmbAsk__: { __carbonProject__: { name: string | undefined } } }) => {
      const statusSelection = filterSelections?.status?.selection;
      if (!statusSelection?.length && !search) return true;
      if (statusSelection?.includes(1) && item.status === 'NEW') {
        return true;
      }
      if (
        statusSelection?.includes(2) &&
        ['CONFIRMED_BY_SELLER', 'CREDITS_ACQUIRED', 'DONE', 'PROCESS_FEE', 'PROCESS_TRADE'].includes(item.status)
      ) {
        return true;
      }
      if (
        statusSelection?.includes(3) &&
        ['REJECTED_BY_SELLER', 'CANCELLED', 'CANCELLED_BY_OPS', 'FORFEIT', 'REJECTED_BY_SELLER'].includes(item.status)
      ) {
        return true;
      }
      const name = item?.__cmbAsk__?.__carbonProject__?.name as string | undefined;
      if (search && name?.toLocaleLowerCase().startsWith(search.toLocaleLowerCase())) {
        return true;
      }
      return false;
    },
  );

  const onCancelRequest = async (bidId: number) => {
    toast.info('Sending cancellation request..');

    // TODO: Implement data-mutation
    const authToken = await getAuthToken();
    const request = await fetch(`/api/user/carbon/cmb-bid/${bidId}/request-cancel`, {
      method: 'POST',
      headers: {
        accept: 'application/json',
        'Content-Type': 'application/json',
        authorization: `Bearer ${authToken}`,
      },
    });

    if (request.ok) {
      toast.success('Your bid cancellation request was sent.');
    } else {
      const error = await request?.json();
      toast.error(error?.message ?? 'Something went wrong!');
      logger.warn({ error });
    }
    refetch();
  };

  if (isLoadingMarketSettings) return <Loading />;

  return (
    <PageHolder>
      <PageSections>
        <PageControls title="My Bids" />
      </PageSections>
      <PageSections type="card" className="flex flex-col">
        <PageControls
          controls={{
            primary: (
              <>
                <Input
                  placeholder="Search"
                  config={{
                    size: 'base',
                    color: 'gray',
                    postfix: (
                      <HitArea
                        width={20}
                        height={20}
                        className="cursor-pointer"
                        onClick={() => {
                          setSearch('');
                        }}
                      >
                        <Icon type={IconType.X} width={10} height={10} />
                      </HitArea>
                    ),
                    prefix: <Icon type={IconType.Search} width={14} height={14} />,
                  }}
                  value={search}
                  onChange={(event) => {
                    setSearch(event.target.value || '');
                  }}
                />
                <FilterDropdown
                  selections={filterSelections}
                  onChange={(value) => setFilterSelections(value)}
                  list={filters}
                  appearance={{
                    size: 'base',
                  }}
                />
              </>
            ),
            secondary: <Pagination actions={pagination} total={data?.total ?? 0} />,
          }}
        />
        <FilterSelections
          selections={filterSelections}
          onChange={(value) => setFilterSelections(value)}
          list={filters}
        />
        <div className="flex flex-col w-full gap-large">
          {isLoading && <Loading isOverLay={false} />}
          {filteredBid &&
            (Array.isArray(filteredBid) ? filteredBid : []).map((item) => {
              const auction = item.__cmbAsk__;
              const createDate = new Date(item.createdUtc ?? '');
              const expireDate = add(new Date(item.confirmedUtc ?? ''), {
                days: Number(expiryDays) ?? 5,
              });
              const status = statusLabel[item?.status]?.status ?? item?.status;
              let statusMessage = statusLabel[item?.status]?.message ?? item?.status;
              if (item.status === 'REJECTED_BY_SELLER') statusMessage = item.statusMessage ?? statusMessage;
              if (['CONFIRMED_BY_SELLER', 'CREDITS_ACQUIRED'].includes(item.status))
                statusMessage = (
                  <div>
                    {statusMessage} by{' '}
                    <strong>
                      {closingTime ? `${closingTime} on` : ''} {expireDate.toDateString()}
                    </strong>
                  </div>
                );

              let dateLabel = 'Created on';
              let date = createDate;
              if (item.confirmedUtc) {
                dateLabel = 'Accepted on';
                date = new Date(item.confirmedUtc);
              }

              const total = item?.price * item?.quantity;
              const ccyAsset = currenciesObjById?.[item?.__cmbAsk__?.quoteAssetId];
              const isObo = item.userId !== item.createdBy; // if bid was placed by member
              const tokenAsset = (item?.__cmbAsk__?.__tokenAsset__ as any | undefined) || {};

              return (
                <MarketPlaceProjectCard
                  info={{
                    tokenAsset,
                    carbonProject: {
                      ...item?.__cmbAsk__?.__carbonProject__,
                      vintageYear: item.__cmbAsk__?.carbonProjectVintageYear,
                    },
                    id: `Bid #${item.id}`,
                    carbonProjectVintageYear: item.__cmbAsk__?.carbonProjectVintageYear,
                  }}
                  controls={
                    <>
                      <div className="flex flex-row whitespace-pre gap-base">
                        <Text
                          background={Colors.gray_200}
                          spacing={Spacing.xs}
                          spacingTB={Spacing._2xs}
                          color={Colors.gray_900}
                          size={FontSize.small}
                          weight={FontWeight.medium}
                        >
                          {status}
                        </Text>
                      </div>
                      <div className="flex flex-col">
                        <Text color={Colors.gray_500} size={FontSize.small} weight={FontWeight.semibold}>
                          Auction ID: {item.cmbAskId}
                        </Text>
                        <Text color={Colors.gray_500} size={FontSize.small}>
                          {dateLabel}: {date.toDateString()}
                        </Text>
                        <Text color={Colors.gray_500} size={FontSize.small}>
                          Status:
                        </Text>
                        <Text color={Colors.gray_500} size={FontSize.small}>
                          {statusMessage}
                        </Text>
                      </div>

                      <div className="flex flex-col w-full gap-3xs">
                        <div className="flex flex-row justify-between gap-small">
                          <Text color={Colors.gray_500} size={FontSize.small}>
                            Quantity ({item?.__cmbAsk__?.__tokenAsset__?.__uom__?.code || 'tCO2'})
                          </Text>
                          <Text color={Colors.gray_500} size={FontSize.small}>
                            {formatter.formatNumber(item?.quantity, 0)}
                          </Text>
                        </div>

                        <div className="flex flex-row justify-between gap-small">
                          <Text color={Colors.gray_500} size={FontSize.small}>
                            Price (per {item?.__cmbAsk__?.__tokenAsset__?.__uom__?.code || 'tCO2'})
                          </Text>
                          <Text color={Colors.gray_500} size={FontSize.small}>
                            {ccyAsset?.code}
                            {formatter.formatNumber(item?.price, ccyAsset?.numDecimals)}
                          </Text>
                        </div>
                        <div className="flex flex-row justify-between gap-small">
                          <Text color={Colors.gray_900} size={FontSize.small}>
                            Total
                          </Text>
                          <Text color={Colors.gray_900} size={FontSize.small}>
                            {ccyAsset?.code}
                            {formatter.formatNumber(total, ccyAsset?.numDecimals)}
                          </Text>
                        </div>
                      </div>
                      <div className="flex flex-col">
                        <Text color={Colors.gray_500} size={FontSize.small}>
                          Account: {item.__user__?.firstName} {item.__user__?.lastName} [{item.userId}]{' '}
                        </Text>
                        <Text color={Colors.gray_500} size={FontSize.small}>
                          Placed by: {item.__createdByUser__?.firstName} {item.__createdByUser__?.lastName} [
                          {item.createdBy}] {!isObo && '(me)'}
                        </Text>
                      </div>
                      <div className="flex flex-col gap-base">
                        {item.status === 'NEW' && canBidAuction() && (
                          <ConfirmModal
                            title={`Cancel Bid #${item.id}`}
                            accept={{
                              label: 'Cancel Bid',
                              icon: 'no-icon',
                              callback(param) {
                                param.onLoading(true);
                                onCancelRequest(item.id)
                                  .then(() => {
                                    param.onClose();
                                    param.onLoading(false);
                                  })
                                  .catch(() => {
                                    param.onLoading(false);
                                  });
                                return false;
                              },
                            }}
                            cancel={{
                              label: 'Keep',
                              icon: 'no-icon',
                            }}
                            action={
                              <Button
                                disabled={marketSettings?.otcCancelationEnabled === 0}
                                className="flex-1"
                                config={{
                                  color: 'outlined',
                                  size: 'base',
                                }}
                              >
                                Cancel Bid <Icon type={IconType.InformationCircleFilled} width={14} height={14} />
                              </Button>
                            }
                          >
                            <div className="flex flex-col items-center gap-base">
                              <Text
                                align="center"
                                color={Colors.gray_500}
                                size={FontSize.base}
                                weight={FontWeight.medium}
                              >
                                Are you sure you want to{' '}
                                <Text
                                  align="center"
                                  color={Colors.gray_900}
                                  size={FontSize.base}
                                  weight={FontWeight.medium}
                                >
                                  cancel bid #{item.id}?
                                </Text>
                              </Text>
                            </div>
                          </ConfirmModal>
                        )}

                        {!['CANCELLED', 'CANCELLED_BY_OPS', 'NEW', 'DONE', 'REJECTED_BY_SELLER'].includes(
                          item.status,
                        ) &&
                          canBidAuction() && (
                            <Button
                              disabled={marketSettings?.otcCancelationEnabled === 0}
                              className="flex-1"
                              config={{
                                color: 'outlined',
                                size: 'base',
                              }}
                            >
                              Request Cancel <Icon type={IconType.InformationCircleFilled} width={14} height={14} />
                            </Button>
                          )}
                      </div>
                      <div className="flex flex-col">
                        <Text color={Colors.gray_500} size={FontSize.small} display={'block'}>
                          * Fees are charged according to the{' '}
                          <DownloadText
                            url={`/api/user/carbon/auction-rules-file/${auction.id}`}
                            fileName={`acx-cmb-auction-rules-${auction.id}.pdf`}
                            className="text-gray_900"
                          >
                            Auction Rules
                          </DownloadText>{' '}
                          and will be incurred on the winning bids.
                        </Text>
                      </div>
                    </>
                  }
                />
              );
            })}
          {!isLoading && (Array.isArray(filteredBid) ? filteredBid : []).length === 0 && (
            <Empty
              title="No bids"
              description="You haven't place any bid yet. Start trading from the marketplace."
              buttonLabel="Start Trading"
              buttonLink="/account/auctions/marketplace"
            />
          )}
        </div>
        {/* TODO: better control this scenario. Perhaps telling the Pagination component if location is top or bottom. */}
        {/* If total items outside of view show bottom pagination */}
        {(data?.total ?? 0) > 4 && (
          <PageControls
            controls={{
              secondary: (data?.total ?? 0) > 0 && <Pagination actions={pagination} total={data?.total ?? 0} />,
            }}
          />
        )}
      </PageSections>
    </PageHolder>
  );
};
