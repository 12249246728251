import { ReactNode } from 'react';
import { Text } from 'refreshed-component/atoms/Text';
import { Tooltip } from 'refreshed-component/atoms/Tooltip';
import { Colors, FontSize, FontWeight, Radius, Spacing } from 'refreshed-component/design-system';
import styled from 'styled-components';

import { projects, Dto, formatter } from '@aircarbon/utils-common';

import { UI } from 'state/ui';

import { DetailedCard, DetailedCardDescriptionItem } from '../DetailedCard';
import { ProjectRatingBadge } from '../project-rating/ProjectRatingBadge';
import useProjectRatingDisclaimers from '../project-rating/hooks/useProjectRatingDisclaimers';

const MarketPlaceProjectCardRoot = styled.div`
  display: contents;
  > .project-item > .children > .description > .controls {
    width: 400px;
  }
`;

export const MarketPlaceProjectCard = ({
  type = 'light',
  info: { carbonProject, tokenAsset, id, indicators, carbonProjectVintageYear, footer, onClick },
  controls,
}: {
  type?: 'light' | 'white';
  info: {
    carbonProject: any;
    tokenAsset?: any | undefined;
    id: string;
    carbonProjectVintageYear: number;
    onClick?: () => void;
    indicators?: string[];
    footer?: ReactNode;
  };

  controls?: ReactNode;
}) => {
  const { bezeroDisclaimerUrls, sylveraDisclaimerUrls } = useProjectRatingDisclaimers();

  const bezeroRating = carbonProject?.__ratings__?.find(
    (r: any) => r.ratingType === Dto.ProjectRatingType.BezeroCarbonRating,
  )?.rating;
  const sylveraRating = carbonProject?.__ratings__?.find(
    (r: any) => r.ratingType === Dto.ProjectRatingType.SylveraRatingCategory,
  )?.rating;

  return (
    <MarketPlaceProjectCardRoot>
      <DetailedCard type={type} className={`project-item`}>
        <DetailedCardDescriptionItem
          onClick={onClick}
          className={`flex flex-col w-full h-auto p-large gap-base justify-between ${onClick ? 'cursor-pointer' : ''}`}
        >
          <div className="flex flex-col w-full h-auto gap-base">
            <div className="flex flex-col justify-between w-full md:flex-row">
              <div className="flex flex-col md:flex-row gap-base">
                <Text
                  background={Colors.gray_200}
                  spacing={Spacing.base}
                  spacingTB={Spacing.xs}
                  color={Colors.gray_900}
                  size={FontSize.base}
                  className="w-min whitespace-pre"
                >
                  {id}
                </Text>
                <Text
                  color={Colors.gray_900}
                  size={FontSize._2Xl}
                  postfix={!!tokenAsset?.symbol ? { view: <Tooltip text="INDICATIVE" /> } : undefined}
                >
                  {tokenAsset?.symbol}
                </Text>
              </div>
            </div>
            <Text color={Colors.gray_900} size={FontSize.large} weight={FontWeight.semibold}>
              {carbonProject.name}
              {carbonProjectVintageYear ? ` - Vintage: ${carbonProjectVintageYear}` : ''}
            </Text>
            <div className="flex flex-row flex-wrap w-full gap-xl">
              {carbonProject.__registry__?.registryName && (
                <div className="flex flex-col">
                  <Text color={Colors.gray_500} size={FontSize.small}>
                    REGISTRY / PROJECT ID
                  </Text>
                  <Text color={Colors.gray_900} size={FontSize.small} weight={FontWeight.medium}>
                    {carbonProject.__registry__?.registryName} | ID {carbonProject?.registryProjectId ?? '-'}
                  </Text>
                </div>
              )}
              {carbonProjectVintageYear && (
                <div className="flex flex-col">
                  <Text color={Colors.gray_500} size={FontSize.small}>
                    VINTAGE
                  </Text>
                  <Text color={Colors.gray_900} size={FontSize.small} weight={FontWeight.semibold}>
                    {carbonProjectVintageYear}
                  </Text>
                </div>
              )}
              {carbonProject?.__unSectoralScope__ && (
                <div className="flex flex-col">
                  <Text color={Colors.gray_500} size={FontSize.small}>
                    SECTORAL SCOPE
                  </Text>
                  <Text color={Colors.gray_900} size={FontSize.small} weight={FontWeight.semibold}>
                    {carbonProject?.__unSectoralScope__?.unSectoralScopeId}.{' '}
                    {carbonProject?.__unSectoralScope__?.unSectoralScopeName}
                  </Text>
                </div>
              )}
              {carbonProject?.countryCode && (
                <div className="flex flex-col">
                  <Text color={Colors.gray_500} size={FontSize.small}>
                    COUNTRY
                  </Text>
                  <Text color={Colors.gray_900} size={FontSize.small} weight={FontWeight.semibold}>
                    {carbonProject.countryCode
                      .split(',')
                      .map((country: string | number) => projects.countryNames[country])
                      .join(', ')}
                  </Text>
                </div>
              )}
              {carbonProject?.stateOrProvince && (
                <div className="flex flex-col">
                  <Text color={Colors.gray_500} size={FontSize.small}>
                    STATE OR PROVINCE
                  </Text>
                  <Text color={Colors.gray_900} size={FontSize.small} weight={FontWeight.semibold}>
                    {carbonProject.stateOrProvince}
                  </Text>
                </div>
              )}

              {!!carbonProject?.technology && (
                <div className="flex flex-col">
                  <Text color={Colors.gray_500} size={FontSize.small}>
                    TECHNOLOGY
                  </Text>
                  <Text color={Colors.gray_900} size={FontSize.small} weight={FontWeight.medium}>
                    {carbonProject.technology}
                  </Text>
                </div>
              )}
              {carbonProject?.capacity && (
                <div className="flex flex-col">
                  <Text color={Colors.gray_500} size={FontSize.small}>
                    CAPACITY
                  </Text>
                  <Text color={Colors.gray_900} size={FontSize.small} weight={FontWeight.medium}>
                    {formatter.formatNumber(carbonProject.capacity, 6)} MW
                  </Text>
                </div>
              )}
              {carbonProject?.volumeMwh && (
                <div className="flex flex-col">
                  <Text color={Colors.gray_500} size={FontSize.small}>
                    VOLUME
                  </Text>
                  <Text color={Colors.gray_900} size={FontSize.small} weight={FontWeight.medium}>
                    {formatter.formatNumber(carbonProject.volumeMwh, 6)} MWh
                  </Text>
                </div>
              )}
              {!!carbonProject?.primaryFuel && (
                <div className="flex flex-col">
                  <Text color={Colors.gray_500} size={FontSize.small}>
                    PRIMARY FUEL
                  </Text>
                  <Text color={Colors.gray_900} size={FontSize.small} weight={FontWeight.medium}>
                    {carbonProject.primaryFuel}
                  </Text>
                </div>
              )}
              {!!carbonProject?.fuelCode && (
                <div className="flex flex-col">
                  <Text color={Colors.gray_500} size={FontSize.small}>
                    FUEL CODE
                  </Text>
                  <Text color={Colors.gray_900} size={FontSize.small} weight={FontWeight.medium}>
                    {carbonProject.fuelCode}
                  </Text>
                </div>
              )}
              {carbonProject?.isSupportedByGovSubsidies !== null && (
                <div className="flex flex-col">
                  <Text color={Colors.gray_500} size={FontSize.small}>
                    SUPPORTED BY GOVERNMENT SUBSIDIES
                  </Text>
                  <Text color={Colors.gray_900} size={FontSize.small} weight={FontWeight.medium}>
                    {!!carbonProject?.isSupportedByGovSubsidies ? 'Supported' : 'Not Supported'}
                  </Text>
                </div>
              )}
              {carbonProject?.worldMapLink && (
                <div className="flex flex-col">
                  <Text color={Colors.gray_500} size={FontSize.small}>
                    Seagrass Carbon Map
                  </Text>
                  <Text color={Colors.gray_900} size={FontSize.small} weight={FontWeight.semibold}>
                    <a
                      href={`${carbonProject.worldMapLink}`}
                      className="flex flex-row items-center gap-xs"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Text className="underline" color={Colors.primaryDark}>
                        Project Link
                      </Text>
                    </a>
                  </Text>
                </div>
              )}
              {bezeroRating && (
                <ProjectRatingBadge
                  projectRating={bezeroRating}
                  urls={bezeroDisclaimerUrls}
                  ratingType={Dto.ProjectRatingType.BezeroCarbonRating}
                />
              )}
              {sylveraRating && (
                <ProjectRatingBadge
                  projectRating={sylveraRating}
                  urls={sylveraDisclaimerUrls}
                  ratingType={Dto.ProjectRatingType.SylveraRatingCategory}
                />
              )}
              {carbonProject.carbonProjectCcbStandards?.length > 0 && (
                <div className="flex flex-col">
                  <Text color={Colors.gray_500} size={FontSize.small}>
                    CCB STANDARDS
                  </Text>
                  <Text color={Colors.gray_900} size={FontSize.small} weight={FontWeight.semibold}>
                    {carbonProject.carbonProjectCcbStandards
                      ?.map((standard: { __ccbStandard__: { name: any } }) => standard?.__ccbStandard__?.name)
                      .join(',')}
                  </Text>
                </div>
              )}
            </div>
            {carbonProject.carbonProjectSdgGoals?.length > 0 && (
              <div className="flex flex-col flex-wrap flex-1 w-full gap-small ju">
                <Text color={Colors.gray_500} size={FontSize.small}>
                  SDG GOALS
                </Text>
                <div className="flex flex-row flex-wrap flex-1 w-full gap-base">
                  {carbonProject.carbonProjectSdgGoals?.map((goal: { carbonSdgGoalId: number }) => (
                    <div
                      key={`sdg-icon-${goal.carbonSdgGoalId}`}
                      style={{
                        width: '48px',
                        height: '48px',
                        backgroundImage: 'url(/sdg_icons/E-WEB-Goal-' + goal.carbonSdgGoalId + '.png)',
                        backgroundSize: 'contain',
                        borderRadius: `var(${Radius.small})`,
                      }}
                    />
                  ))}
                </div>
              </div>
            )}
          </div>
          {(indicators || footer) && (
            <div className="flex flex-col w-full h-auto border-t border-gray_200 gap-base pt-large">
              {indicators && (
                <div className="flex flex-row gap-base">
                  {indicators?.map((label, index) => {
                    return (
                      <Text
                        key={`${label}-${index}`}
                        background={Colors.gray_100}
                        spacing={Spacing.xs}
                        spacingTB={Spacing._2xs}
                        color={Colors.gray_900}
                        size={FontSize.base}
                      >
                        {label}
                      </Text>
                    );
                  })}
                </div>
              )}
              {footer && <div className="flex flex-row gap-base">{footer}</div>}
            </div>
          )}
        </DetailedCardDescriptionItem>
        {controls && (
          <DetailedCardDescriptionItem className="flex flex-col justify-between h-auto controls p-large gap-base">
            {controls}
          </DetailedCardDescriptionItem>
        )}
      </DetailedCard>
    </MarketPlaceProjectCardRoot>
  );
};
