import queryString from 'query-string';

import { AssetCategoryCode } from 'pages/account/trading/layouts/tradingPublic.hook';

import { userAuthenticatedApi } from '../fetcher';

export const fetchOtherTrades = async (data: {
  pairId: string;
  page: number;
  limit: number;
  status: 'confirmed';
  assetCategory?: AssetCategoryCode;
}) => {
  const qs = queryString.stringify(data, { arrayFormat: 'bracket', skipEmptyString: true, skipNull: true });
  const endpoint = `/oms/trade/all-other-trades?${qs}`;
  const userService = await userAuthenticatedApi();

  const res = userService.url(endpoint).get();
  return res.json();
};
