import { ReactNode, useContext } from 'react';
import {
  ContainerSize,
  ResponsiveContainerContext,
  ResponsiveContainerWrapper,
} from 'refreshed-component/atoms/ResponsiveContainer';
import { Colors, FontSize, FontWeight, Spacing } from 'refreshed-component/design-system';
import styled from 'styled-components';

import { Text, TypographyVariant } from '@aircarbon/ui';

const Header = styled.div<{ size: ContainerSize }>`
  position: relative;
  display: flex;
  gap: var(${Spacing.base});
  align-items: center;

  ${({ size }) => {
    if (size === 'medium' || size === 'xs' || size === 'small') {
      return `flex-direction: column;`;
    } else {
      return `flex-direction: row;`;
    }
  }}
`;

const Title = styled.div`
  position: relative;
  color: var(${Colors.gray_900});
`;

const Controls = styled.div<{ size: ContainerSize }>`
  position: relative;
  flex: 1 1 0;
  display: flex;
  width: 100%;
  gap: var(${Spacing.base});
  flex-wrap: wrap;

  > div {
    position: relative;
    display: flex;
    gap: var(${Spacing.base});
    ${({ size }) => {
      if (size === 'xs') {
        return `
          flex-direction: column; align-items: center;
        `;
      } else {
        return `flex-direction: row;`;
      }
    }}
  }

  ${({ size }) => {
    if (size === 'medium' || size === 'small' || size === 'xs') {
      return `justify-content: center; flex-direction: column;`;
    } else {
      return `justify-content: space-between; flex-direction: row;`;
    }
  }}
`;

export const PageControls = ResponsiveContainerWrapper(
  ({
    title,
    controls,
  }: {
    title?: string;
    controls?: {
      primary?: ReactNode;
      secondary?: ReactNode;
    };
  }) => {
    const responsiveContainerContext = useContext(ResponsiveContainerContext);
    return (
      <Header size={responsiveContainerContext.size}>
        {title && (
          <Title>
            <Text variant={TypographyVariant.subtitle1}>{title}</Text>
          </Title>
        )}
        <Controls size={responsiveContainerContext.size}>
          <div>{controls?.primary}</div>
          <div>{controls?.secondary}</div>
        </Controls>
      </Header>
    );
  },
);
