import { Dropdown, DropdownItem } from 'refreshed-component/atoms/Dropdown';
import { Input } from 'refreshed-component/atoms/Input';
import { Text } from 'refreshed-component/atoms/Text';
import { Textarea } from 'refreshed-component/atoms/Textarea';
import { Colors, FontSize } from 'refreshed-component/design-system';
import { SelectCountry } from 'refreshed-component/molecules/SelectCountry';

import { FieldWrapper } from 'components/styled/Styled';

export interface BasicDetailsValue {
  id: string;
  projectName: string;
  projectDescription: string;
  projectLocation: string;
  projectPhase: string;
  methodology: string;
  customMethodology: string;
  projectType: string;
  standardUsed: string;
  projectId: string;
  sdgGoals: Array<string>;
  sectoralScope: string;
}

export interface BasicDetailsMetadata {
  countries: { name: string; code: string }[];
  projectPhases: DropdownItem[];
  registries: DropdownItem[];
  methodologies: DropdownItem[];
  projectTypes: DropdownItem[];
  sectoralScopes: DropdownItem[];
  sdgGoals: DropdownItem[];
  additonalCertifications: DropdownItem[];
  internalGradesEligibilities: DropdownItem[];
}

export const BasicDetails: React.FC<{
  value: BasicDetailsValue;
  metadata: BasicDetailsMetadata;
  error?: Record<string, string>;
  onChange(value: BasicDetailsValue): void;
}> = (props) => {
  const { value, onChange, metadata, error } = props;

  const onChangeFormValue =
    <K extends keyof typeof value>(key: K) =>
    (fieldValue: (typeof value)[K]) => {
      const newValue = {
        ...value,
        [key]: fieldValue,
      };
      onChange(newValue);
    };

  const onChangeMethodology = (selectedItem: DropdownItem) => {
    if (!selectedItem.id && selectedItem.label) {
      onChange({
        ...value,
        methodology: '',
        customMethodology: selectedItem.label as string,
      });

      return;
    }
    onChange({
      ...value,
      customMethodology: '',
      methodology: selectedItem.id as string,
    });
  };

  const toNewItems =
    <K extends keyof typeof value>(key: K) =>
    (fieldValue: string) => {
      if (!Array.isArray(value[key])) {
        return fieldValue;
      }

      const selectionIndex = (value[key] as string[]).findIndex((item) => item === fieldValue);

      if (selectionIndex === -1) {
        return [...value[key], fieldValue];
      }

      const newItems = [...value[key]];

      newItems.splice(selectionIndex, 1);

      return newItems;
    };

  return (
    <>
      {!value.id && (
        <div className="mb-6">
          <Text className="mb-3" size={FontSize._2Xl}>
            List New Carbon Project
          </Text>
          <Text size={FontSize.large}>Offer Pre-Issued Carbon Credits on the Exchange</Text>
        </div>
      )}
      <FieldWrapper>
        <Text size={FontSize.small}>Project Name*</Text>
        <Input
          config={{
            color: !!error?.projectName ? 'error' : 'gray',
          }}
          value={value.projectName}
          onChange={(e) => onChangeFormValue('projectName')(e.target.value)}
        />
        {!!error?.projectName && (
          <Text color={Colors.danger_700} size={FontSize.small}>
            {error?.projectName}
          </Text>
        )}
      </FieldWrapper>
      <FieldWrapper>
        <Text size={FontSize.small}>Project Description</Text>
        <Textarea value={value.projectDescription} onChange={onChangeFormValue('projectDescription')} />
      </FieldWrapper>
      <div className="flex w-full">
        <div className="pr-2 w-1/2">
          <SelectCountry
            label="Project Location*"
            placeholder="Select"
            countries={metadata.countries}
            onChange={(selectedCountries) => onChangeFormValue('projectLocation')(selectedCountries[0] as string)}
            selectedCountries={value.projectLocation ? [value.projectLocation] : []}
            error={error?.projectLocation}
          />
        </div>
        <div className="pl-2 w-1/2">
          <FieldWrapper>
            <Text size={FontSize.small}>Project Phase*</Text>
            <Dropdown
              list={metadata.projectPhases}
              selected={value.projectPhase ? [value.projectPhase] : []}
              config={{
                color: 'gray',
              }}
              error={error?.projectPhase}
              placeholder="Select"
              isSearchable
              onSelectItem={(selectedItems) => {
                onChangeFormValue('projectPhase')(selectedItems.id as string);
              }}
            />
          </FieldWrapper>
        </div>
      </div>
      <div className="flex w-full">
        <div className="pr-2 w-1/2">
          <FieldWrapper>
            <Text size={FontSize.small}>Standard Used / Registry*</Text>
            <Dropdown
              list={metadata.registries}
              selected={value.standardUsed ? [value.standardUsed] : []}
              config={{
                color: 'gray',
              }}
              error={error?.standardUsed}
              onSelectItem={(selectedItems) => {
                onChangeFormValue('standardUsed')(selectedItems.id as string);
              }}
              placeholder="Select"
            />
          </FieldWrapper>
        </div>
        <div className="pl-2 w-1/2">
          <FieldWrapper>
            <Text size={FontSize.small}>Project ID (If registered)</Text>
            <Input
              config={{
                color: 'gray',
              }}
              placeholder="ID#"
              value={value.projectId}
              onChange={(e) => onChangeFormValue('projectId')(e.target.value)}
            />
          </FieldWrapper>
        </div>
      </div>
      <div className="flex w-full">
        <div className="pr-2 w-1/2">
          <FieldWrapper>
            <Text size={FontSize.small}>Proposed Methodology*</Text>
            <Dropdown
              list={metadata.methodologies}
              selected={
                value.customMethodology ? [value.customMethodology] : value.methodology ? [value.methodology] : []
              }
              config={{
                color: 'gray',
                shouldStayWithinMaxWidth: true,
              }}
              isCustomValueAllowed
              isSearchable
              error={error?.customMethodology || error?.methodology}
              placeholder="Select"
              onSelectItem={onChangeMethodology}
            />
          </FieldWrapper>
        </div>
        <div className="pl-2 w-1/2">
          <FieldWrapper>
            <Text size={FontSize.small}>Project Type*</Text>
            <Dropdown
              key={'from'}
              list={metadata.projectTypes}
              error={error?.projectType}
              selected={value.projectType ? [value.projectType] : []}
              config={{
                color: 'gray',
              }}
              onSelectItem={(selectedItems) => {
                onChangeFormValue('projectType')(selectedItems.id as string);
              }}
              placeholder="Select"
            />
          </FieldWrapper>
        </div>
      </div>
      <div className="flex w-full">
        <div className="pr-2 w-1/2">
          <FieldWrapper>
            <Text size={FontSize.small}>Sectoral Scope</Text>
            <Dropdown
              list={metadata.sectoralScopes}
              isSearchable
              selected={value.sectoralScope ? [value.sectoralScope] : []}
              onSelectItem={(selectedItems) => {
                onChangeFormValue('sectoralScope')(selectedItems.id as string);
              }}
              config={{
                color: 'gray',
                shouldStayWithinMaxWidth: true,
              }}
              placeholder="Select"
            />
          </FieldWrapper>
        </div>
        <div className="pl-2 w-1/2">
          <FieldWrapper>
            <Text size={FontSize.small}>SDG Goals (if any)</Text>
            <Dropdown
              isSearchable
              list={metadata.sdgGoals}
              selected={value.sdgGoals}
              onSelectItem={(selectedItems) => {
                onChangeFormValue('sdgGoals')(toNewItems('sdgGoals')(selectedItems.id as string) as string[]);
              }}
              config={{
                color: 'gray',
                shouldStayWithinMaxWidth: true,
              }}
              placeholder="Select"
            />
          </FieldWrapper>
        </div>
      </div>
    </>
  );
};
