import { toVintagePeriod } from 'refreshed-pages/project-exchange/utils/toVintagePeriod';

import { formatter } from '@aircarbon/utils-common';
import { ApxProject } from '@aircarbon/utils-common/src/dto';

import { Project } from './Project';

export const toResults = (items: Array<ApxProject>): Array<Project> => {
  return items.map((item) => ({
    id: item.id,
    apxId: item.apxId,
    title: item.name,
    description: item.description || '',
    subtitle: [
      `ACX ID: ${item.id}`,
      item.registryProjectId ? `REGISTRY PROJECT ID: ${item.registryProjectId}` : undefined,
    ]
      .filter((v) => !!v)
      .join(' | '),
    vintageYear: toVintagePeriod(item.vintages),
    markets:
      item.vintages?.map((vintage) => {
        const basePair = vintage.fctAsset?.basePairs?.[0];
        let label = `${vintage.vintageYear} - Not Available`;
        let value = '';
        if (basePair && basePair?.tokenAuditPrices?.[0]?.price) {
          label = `${vintage.vintageYear} - ${basePair?.symbol}`;
          value = `$${formatter.formatNumber(basePair?.tokenAuditPrices?.[0]?.price ?? 0, 2)}`;
        }
        return {
          label,
          value,
        };
      }) || [],
    isTrending: false,
    country: item.country?.name || item.country?.code || '-',
    sectoralScope: item.sectoralScope?.name || '-',
    ccbStandards: (item.ccbStandards?.length
      ? item.ccbStandards
      : [
          {
            id: '-',
            name: '-',
          },
        ]
    )
      .map((standard) => standard.name)
      .join(', '),
    sdgGoals:
      item.sdgGoals?.map((goal) => ({
        value: '' + goal.id,
        label: goal.name,
      })) || [],
  }));
};
