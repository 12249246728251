import { isWithinInterval } from 'date-fns';

import { logger, formatter, helpers, projects as utilsProjects, UserType } from '@aircarbon/utils-common';
import { Asset } from '@aircarbon/utils-common/src/dto';

const { formatNumber, hex2int } = formatter;

export const clearSelection = () => {
  if (window.getSelection) {
    if (window.getSelection()?.empty) {
      window.getSelection()?.empty();
    } else if (window.getSelection()?.removeAllRanges) {
      window.getSelection()?.removeAllRanges();
    }
  }
};

export function isTouchDevice() {
  return 'ontouchstart' in window || navigator.maxTouchPoints > 0;
}

// convert empty string or string number (100,000) to number (100000)
export const convertTextNumberToValue = (originalValue: string | number) => {
  if (typeof originalValue === 'number') {
    return originalValue;
  }

  const convertValue = Number(String(originalValue).replace(/,/g, ''));
  return Number.isNaN(convertValue) ? 0 : convertValue;
};

export const totalTokenQty = (selectedTokens: Array<any>, scRatio: number = 1) => {
  const total =
    selectedTokens?.reduce((result, token) => {
      return result + hex2int(token.currentQty);
    }, 0) ?? 0;

  return total / scRatio;
};

export const ipfsLink = (hash: string): string => {
  return `https://gateway.pinata.cloud/ipfs/${hash}`;
};

export function getIpfsHash(value: string): string {
  const [ipfsHash] = value?.split(',') ?? [];
  return ipfsHash;
}

export function getFileName(value: string): string {
  const [, filename] = value?.split(',') ?? [];
  return filename;
}

export const toUTCDate = (date: Date | number | string) => new Date(date).toUTCString();
export const unixToUTCDate = (timestamp: number) => new Date(timestamp * 1000).toUTCString();
export const formatDate = (date: Date | string) =>
  new Date(date)
    .toLocaleString(undefined, {
      hour12: true,
      weekday: 'short',
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
    })
    .replaceAll(',', '');
export const formatACXDate = ({
  date,
  day = true,
  week = true,
  month = true,
  year = true,
}: {
  date: Date | string;
  day?: boolean;
  week?: boolean;
  month?: boolean;
  year?: boolean;
}) => {
  let dateConfiguration = {};
  if (day) dateConfiguration = { ...dateConfiguration, day: 'numeric' };
  if (week) dateConfiguration = { ...dateConfiguration, week: 'short' };
  if (month) dateConfiguration = { ...dateConfiguration, month: 'short' };
  if (year) dateConfiguration = { ...dateConfiguration, year: 'numeric' };
  return new Date(date)
    .toLocaleString(undefined, {
      ...dateConfiguration,
      hour12: true,
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
    })
    .replaceAll(',', '');
};
// TODO: support format date from timestamp

export const isValidDate = (
  selectedRange: {
    startDate?: Date | null | undefined;
    endDate?: Date | null | undefined;
  },
  timestamp: number,
) => {
  if (!selectedRange.endDate || !selectedRange.startDate) {
    return true;
  }

  return isWithinInterval(new Date(unixToUTCDate(timestamp)), {
    start: new Date(toUTCDate(selectedRange.startDate)),
    end: new Date(toUTCDate(selectedRange.endDate)),
  });
};

export const getPaginatedTransactions = ({
  transactions,
  page,
  limit,
}: {
  transactions: Record<string, any>;
  page: number;
  limit: number | string;
}) => {
  if (Number.isNaN(Number(limit))) {
    return Object.keys(transactions).reduce((result: Record<string, any>, key) => {
      const res = { ...result };
      res[key] = transactions[key];

      return res;
    }, {});
  }

  const from = (page - 1) * Number(limit);
  const to = page * Number(limit);

  return Object.keys(transactions)
    .slice(from, to)
    .reduce((result: Record<string, any>, key) => {
      const res = { ...result };
      res[key] = transactions[key];

      return res;
    }, {});
};

export function groupEventsBy(records: Array<any>, key: string, singleChild = false) {
  const transactions = records
    .sort(({ blockNumber }: Record<string, any>, { blockNumber: nextBlockNumber }: Record<string, any>) => {
      if (blockNumber > nextBlockNumber) return -1;
      if (blockNumber < nextBlockNumber) return +1;
      return 0;
    })
    .reduce((result, record) => {
      const res = { ...result };
      const recordKey = record[key];

      if (!singleChild) {
        res[recordKey] = res[recordKey] || [];
        res[recordKey].push(record);
      } else {
        res[recordKey] = record;
      }

      return res;
    }, {});

  return transactions;
}
export function arrayToObject(records: Array<any>, key: string) {
  const transactions = records.reduce((result, record) => {
    const res = { ...result };
    if (record?.[key]) {
      const recordKey = record[key];
      res[recordKey] = record;
    }

    return res;
  }, {});

  return transactions;
}

export function wrapString(str: string) {
  if (!str || str === '') return '';

  let convertedStr = '';
  const toPosition = Math.floor(str.length / 2);
  convertedStr += str.substring(0, toPosition / 3);
  convertedStr += '.'.repeat(3);
  convertedStr += str.substring(toPosition + toPosition / 2, str.length);
  return convertedStr;
}

export function clipboardCopy(text: string) {
  let success = false;

  if (typeof window !== 'undefined') {
    // Use the Async Clipboard API when available. Requires a secure browsing
    // context (i.e. HTTPS)
    if (navigator.clipboard) {
      return navigator.clipboard.writeText(text).catch(function (err) {
        throw err !== undefined ? err : new DOMException('The request is not allowed', 'NotAllowedError');
      });
    }

    // ...Otherwise, use document.execCommand() fallback

    // Put the text to copy into a <span>
    const span = document.createElement('span');
    span.textContent = text;

    // Preserve consecutive spaces and newlines
    span.style.whiteSpace = 'pre';

    // Add the <span> to the page
    document.body.appendChild(span);

    // Make a selection object representing the range of text selected by the user
    const selection = window.getSelection();
    const range = window.document.createRange();

    if (selection) {
      selection.removeAllRanges();
      range.selectNode(span);
      selection.addRange(range);

      // Copy text to the clipboard
      try {
        success = window.document.execCommand('copy');
      } catch (err: any) {
        logger.error(err);
      }

      // Cleanup
      selection.removeAllRanges();
      window.document.body.removeChild(span);
    }
  }

  return success
    ? Promise.resolve()
    : Promise.reject(new DOMException('The request is not allowed', 'NotAllowedError'));
}

export const removeEmptyValue = (values?: Array<string>) =>
  values?.filter((value: string | null | undefined) => !!value);

/**
 * Convert unit and show with formatted
 * @param val int
 * @param showUnit boolean
 */
export const convertFormattedUnit = (val: number, scRatio: number, unit: string) =>
  `${formatNumber(val / scRatio, 0)} ${unit}`;

export const convertArrayToURParams = (key: string, values: Array<any>) => {
  return values.map((value) => `${key}[]=${value}`).join('&');
};

export const getRoleName = ({
  accountType,
  isMember = false,
  isRoot = false,
}: {
  accountType?: string;
  isMember?: boolean;
  isRoot?: boolean;
}) => {
  const companyType = isMember ? 'Member' : 'Corporate';
  switch (accountType) {
    case UserType.CORPORATE_CLIENT_DMA:
      return {
        name: `${companyType} Client DMA`,
        short: 'Client DMA',
      };
    case UserType.CORPORATE_CLIENT_READ_ONLY:
      return {
        name: `${companyType} Client OBO`,
        short: 'Client OBO',
      };
    case UserType.CORPORATE_TRADER:
      return {
        name: `${companyType} Trader`,
        short: 'Trader',
      };

    case UserType.CORPORATE_ADMIN:
      return {
        name: `${companyType} ${isRoot ? 'Root' : 'Admin'}`,
        short: isRoot ? 'Root' : 'Admin',
      };

    case UserType.CORPORATE_MONITOR:
      return {
        name: `${companyType} Monitor`,
        short: 'Monitor',
      };

    default:
      return {
        name: '',
        short: '',
      };
  }
};

export const decryptData = (value: string) => (value.startsWith('U2Fsd') ? helpers.revealMessage(value) : value);

/**
 * Gets SC batch and returns decrypted metadata
 * @param batch smart contract raw batch
 * @returns decrypted project metadata
 */
export const getProjectData = (batch: any) => {
  const projectData: Record<string, any> = {};

  batch?.metaKeys.forEach((key: string, index: number) => {
    const value = batch.metaValues[index];
    const msg = decryptData(value);
    projectData[key] = msg;
  });

  return projectData;
};

export const groupBatchesByBatchId = (
  batches: Array<any>,
  tokens: Array<any>,
  displayName: string,
  tokenTypes: Asset[],
) =>
  batches?.reduce((projects: Record<string, any>, batch: Record<string, any>) => {
    const batchId = hex2int(batch.batchId);
    const tokenTypeId = hex2int(batch.tokTypeId);
    const assetToken = tokenTypes?.find((asset) => asset.scId === Number(tokenTypeId));
    const batchSTs = tokens.filter(
      (token: { batchId: { _hex: string | number } }) => hex2int(token.batchId) === batchId,
    );

    const stIds = batchSTs.map((token: { stId: { _hex: string | number } }) => hex2int(token.stId));
    const qty = batchSTs.reduce((sum: number, st: Record<string, any>) => {
      sum += hex2int(st.currentQty);
      return sum;
    }, 0);

    const currentQty = qty / (assetToken?.uom?.scRatio ?? 1);
    const createdAt = batch.mintedTimestamp ? unixToUTCDate(batch.mintedTimestamp) : '';
    const project: Record<string, any> = batch.metaData;

    if (projects[batchId]) {
      return {
        ...projects,
        [batchId]: {
          ...projects[batchId],
          stIds: [...projects[batchId].stIds, ...stIds],
        },
        currentQty: projects[batchId].currentQty + qty,
      };
    }

    const startVintageYear = project.DATE_VINTAGE_START?.substr(project.DATE_VINTAGE_START.length - 4, 4);

    // NOTE: disabling this for now until business confirms if this is the official approach.
    // NOTE: if vintage start month is before October, then use start year for vintage.
    // let vintageYear = startVintageYear;
    // const startVintageMonth = project.DATE_VINTAGE_START?.substr(2, 2);
    // if (Number(startVintageMonth) >= 10) {
    //   vintageYear = project.DATE_VINTAGE_END?.substr(project.DATE_VINTAGE_END.length - 4, 4);
    // }

    return {
      ...projects,
      [batchId]: {
        batchId,
        tokenTypeId: hex2int(batch.tokTypeId),
        stIds,
        project: {
          ...project,
          vintage: project.TXT_VINTAGE_YEAR || startVintageYear || '',
          countryName: utilsProjects?.countryNames?.[String(project.LIST_COUNTRY)] ?? '',
        },
        currentQty,
        createdAt,
      },
    };
  }, {});

function buildParams(prefix: string, obj: Record<string, any>, add: (s: any, t: any) => void) {
  const rbracket = /\[\]$/;
  if (obj instanceof Array) {
    for (let i = 0; i <= obj.length; i += 1) {
      if (rbracket.test(prefix)) {
        add(prefix, obj[i]);
      } else {
        buildParams(`${prefix}[${typeof obj[i] === 'object' ? i : ''}]`, obj[i], add);
      }
    }
  } else if (typeof obj === 'object') {
    // Serialize object item.
    for (const name in obj) {
      buildParams(`${prefix}[${name}]`, obj[name], add);
    }
  } else {
    // Serialize scalar item.
    add(prefix, obj);
  }
}
export function objectToQueryString(obj: Record<string, any>) {
  const s: Array<any> = [];
  const r20 = /%20/g;
  const add = (key: any, value: any) => {
    // If value is a function, invoke it and return its value
    const cloneValue = typeof value === 'function' ? value() : value;
    s[s.length] = `${encodeURIComponent(key)}=${encodeURIComponent(cloneValue)}`;
  };
  if (obj instanceof Array) {
    for (const name in obj) {
      add(name, obj[name]);
    }
  } else {
    for (const prefix in obj) {
      buildParams(prefix, obj[prefix], add);
    }
  }
  const output = s.join('&').replace(r20, '+');
  return output;
}
