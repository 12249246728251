import { BalanceResponse } from '@aircarbon/utils-common/src/dto/user';

import { userAuthenticatedApi } from '../fetcher';

export const fetchAccountBalance = async (getRootBalance: 'yes' | 'no', ccyTypeId: number) => {
  const endpoint = '/user/balance';
  const userService = await userAuthenticatedApi();
  const res = userService.url(endpoint).query({ getRootBalance, ccyTypeId }).get();
  return res.json<BalanceResponse>();
};
