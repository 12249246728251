import { useMemo } from 'react';
import { CheckBox } from 'refreshed-component/atoms/CheckBox';
import { Dropdown, DropdownItem } from 'refreshed-component/atoms/Dropdown';
import { Text } from 'refreshed-component/atoms/Text';
import { Colors, FontSize, FontWeight } from 'refreshed-component/design-system';
import styled from 'styled-components';

import { ImageWithPlaceholder } from '../InputPhoneNumber/components/ImageWithPlaceholder';

export interface SelectCountryProps {
  /**
   * List of countries and their country codes
   */
  countries: Array<{
    name: string;
    code: string;
  }>;

  /**
   * Country code or array of codes (in case of multiselect)
   *
   * @example ["UA", "AE", "US"]
   */
  selectedCountries?: string[];

  /**
   * Input label
   */
  label?: string;

  /**
   * Select placeholder
   */
  placeholder?: string;

  /**
   * Error text. If set, will put input into error state
   */
  error?: string;

  /**
   * If set to true, the select will allow multiple selections of countries
   */
  isMultiselect?: boolean;

  /**
   * Handles select changes
   */
  onChange(selectedCountries: string[]): void;
}

export const SelectCountry: React.FC<SelectCountryProps> = (props) => {
  const { label, countries, selectedCountries = [], error, placeholder, isMultiselect, onChange } = props;

  const dropdownItems = useMemo(() => {
    return countries.map((country) => ({
      id: country.code,
      label: country.name,
    }));
  }, [countries]);

  const onSearchCountry = (searchValue: string) => {
    if (!searchValue) {
      return countries.map((country) => ({
        id: country.code,
        label: country.name,
      }));
    }

    return countries
      .filter((country) => {
        return (
          country.code.toLowerCase().includes(searchValue.toLowerCase()) ||
          country.name.toLowerCase().includes(searchValue)
        );
      })
      .map((country) => ({
        id: country.code,
        label: country.name,
      }));
  };

  const onSelectCountry = (selectedCountry: DropdownItem) => {
    if (!isMultiselect) {
      onChange([selectedCountry.id as string]);
      return;
    }

    const newSelection = [...selectedCountries];

    const selectedCountryIndex = newSelection.findIndex((item) => selectedCountry.id === item);
    if (selectedCountryIndex !== -1) {
      newSelection.splice(selectedCountryIndex, 1);

      onChange(newSelection);
      return;
    }

    newSelection.push(selectedCountry.id as string);
    onChange(newSelection);
  };

  return (
    <StyledSelectCountry>
      {!!label && <Text size={FontSize.small}>{label}</Text>}
      <Dropdown
        selected={selectedCountries}
        onSearch={onSearchCountry}
        list={dropdownItems}
        placeholder={placeholder}
        onSelectItem={onSelectCountry}
        error={error}
        renderItem={({ selectedItem, item, index, onPressItem }) => {
          const isCountrySelected = !!selectedItem?.find((i) => i.id === item.id);
          return (
            <CountryContainer
              onClick={(event) => {
                onPressItem(event, { item, index });
              }}
              isSelected={isCountrySelected}
              key={index}
            >
              <StyledImageWithPlaceholder
                src={`/assets/country-flags/${((item.id as string) || '').toUpperCase()}.svg`}
                alt={item.label as string}
                placeholder={'/assets/country-flags/placeholder.svg'}
              />
              <StyledNameText size={FontSize.small} weight={FontWeight.semibold}>
                {item.label as string}
              </StyledNameText>
              {isMultiselect && <CheckBox id={item.id} label="" isSelected={isCountrySelected} />}
            </CountryContainer>
          );
        }}
        config={{
          color: 'gray',
        }}
      />
    </StyledSelectCountry>
  );
};

const StyledSelectCountry = styled.div`
  display: flex;
  flex-direction: column;
  gap: 7px;
  position: relative;
`;

const CountryContainer = styled.div<{
  isSelected: boolean;
}>`
  display: flex;
  flex-direction: row !important;
  padding: 12px 0;
  border: none !important;
  gap: 12px;
  cursor: pointer;

  background-color: ${({ isSelected }) => (isSelected ? `var(${Colors.gray_100})` : 'transparent')};

  &:not(:last-of-type) {
    border-bottom: 1px solid var(${Colors.gray_200});
  }
`;

const StyledNameText = styled(Text)`
  flex-grow: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
`;

const StyledImageWithPlaceholder = styled(ImageWithPlaceholder)`
  flex-shrink: 0;
  width: 28px;
`;
