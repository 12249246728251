import Big from 'big.js';
import moment from 'moment';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { FaExclamationTriangle } from 'react-icons/fa';
import { queryCache } from 'react-query';
import { Button } from 'refreshed-component/atoms/Button';
import { DatePicker } from 'refreshed-component/atoms/DatePicker';
import { Dropdown } from 'refreshed-component/atoms/Dropdown';
import { Input } from 'refreshed-component/atoms/Input';
import { Text } from 'refreshed-component/atoms/Text';
import { Tooltip } from 'refreshed-component/atoms/Tooltip';
import { Colors, FontSize, FontWeight, Radius, Spacing } from 'refreshed-component/design-system';
import { toast } from 'refreshed-component/molecules/toast';
import styled from 'styled-components';

import { formatter, helpers, FeeType } from '@aircarbon/utils-common';
import { MarketFlags } from '@aircarbon/utils-common/src/dto';

import Loading from 'components/styled/Loading';

import { Entity } from 'state/entity';
import { UI } from 'state/ui';
import { User } from 'state/user';

import useDebounce from 'hooks/useDebounce';
import useFee from 'hooks/useFee';

import { isTouchDevice } from 'utils/helpers';

import { DialogContext } from '../components/Dialog';
import InfoList from '../components/InfoList';
import SimpleBar from '../components/Simplebar';
import Tabs from '../components/Tabs';
import { Themes } from '../components/Themes';
import styles from '../components/index.module.scss';
import { Hint } from '../components/styled';
import { Pair, TimeInForce, Asset } from '../hooks';
import { useExternalMarketAsset } from '../hooks/externalMarketAsset';
import { useExternalBrokerClient } from '../hooks/useExternalBrokerClient';
import { useFobTypes } from '../hooks/useFobTypes';
import useMarketSettings from '../hooks/useMarketSettings';
import { usePlaceOrder } from '../hooks/usePlaceOrderBiofuel';
import { useShippingTypes } from '../hooks/useShippingTypes';
import { PlaceOrderContext } from './PlaceOrderCarbon';

const Adjust = styled.div`
  position: absolute;
  width: calc(100% + 32px);
  margin-left: -16px;
  height: 100%;
`;

const Wrapper = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  font-size: 13px;
  color: var(${Colors.gray_900});
  gap: var(${Spacing.xs});
  padding-left: 16px;
  padding-right: 16px;
  .spinner {
    width: 25px;
    height: 25px;
    top: 30%;
    left: 25%;
    &:after {
      width: 20px;
      height: 20px;
    }
  }
  input {
    font-size: 13px;
  }
  .buy-button,
  .sell-button {
    height: 40px !important;
    border-radius: var(${Radius.medium}) !important;
    font-size: var(${FontSize.base});
    div {
      font-weight: var(${FontWeight.medium}) !important;
    }
  }
  .buy-button {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .sell-button {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .place-order-button {
    width: 100%;
    height: 40px;
  }
  .order-types {
    margin: 5px 0;
    button {
      & > div {
        font-size: 14px;
        font-weight: bold;
        color: var(${Colors.gray_500});
      }
      &:hover,
      &.selected {
        text-decoration: none;
        & > div {
          color: var(${Colors.gray_900});
        }
      }
    }
  }
  .dropdown {
    background: #2c2f2f;
    border: none;
  }
`;

const TooltipWrapper = styled.div`
  color: var(${Colors.gray_900});
  width: 250px;
  border-radius: 5px;
`;

const isMarketSettingEnabled = ({
  settingName,
  pairSettingName,
  marketSettings,
  pairSettings,
}: {
  settingName: 'biofuelEntryEnabled' | 'biofuelMatchingEnabled';
  pairSettingName: 'orderEntryEnabled' | 'matchingEngineEnabled';
  pairSettings: Record<string, any>;
  marketSettings?: MarketFlags;
}): boolean => {
  let isOrderEntryEnabled = marketSettings?.[settingName] === 1;
  const isPairOrderEntryEnabled = pairSettings?.[pairSettingName] === 1;

  return isOrderEntryEnabled && isPairOrderEntryEnabled;
};

type Props = {
  pairs: Pair[];
  pair: string | undefined;
  timeInForces: TimeInForce[];
  ccyAsset: Asset | undefined;
  lastTradedPrice: number | undefined;
  pairWatchlist?: Pair;
  height?: 'fixed' | 'auto';
  layout?: string;
};

// TODO: refactor this component to use react-hook-form
const PlaceOrder = ({ pairs, pair, ccyAsset, height, timeInForces }: Props) => {
  const {
    selector: { getAccountAddress },
    status: { canSeeExternalBrokerClientForBiofuel, canTradeBiofuel },
  } = User.useContainer();
  const {
    selector: { mainCcySymbol },
  } = Entity.useContainer();
  const { theme } = Themes.useContainer();
  const { getSetting } = UI.useContainer();
  const [isSell, setSell] = useState<boolean>(false);
  const [orderType, setOrderType] = useState<string>('Limit');
  const [price, setPrice] = useState<string>();
  const [brokerClient, setBrokerClient] = useState<{
    id: number;
    value: string;
  } | null>(null);
  const [qty, setQty] = useState<string>();
  const [fobType, setFobType] = useState<{
    id: number;
    value: string;
  } | null>(null);
  const [shipping, setShipping] = useState<{
    id: number;
    value: string;
  }>({
    id: 6,
    value: 'Week 5',
  });

  const [timeInForce, setTimeInForce] = useState<string>('');
  const [expiryUtc, setExpiryUtc] = useState<Date | null | undefined>();
  const [tradeFee, setTradeFee] = useState<number>(0);
  const [referencePrice, setReferencePrice] = useState<number>(0);
  const [total, setTotal] = useState<number>(0);
  const [error, setError] = useState<string>('');
  const [validationTriedCount, setValidationTryCount] = useState<number>(0);
  const { showDialog, removeDialog } = useContext(DialogContext);
  const { placeOrderData } = useContext(PlaceOrderContext);
  const { marketSettings } = useMarketSettings({});

  const { data: clientList } = useExternalBrokerClient();

  const token = pair ? pair.split('/')[0] : '';
  const ccy = pair ? pair.split('/')[1] : mainCcySymbol; // As a default

  const pairMapper: Record<string, Record<string, any>> = pairs.reduce((prevPair, currPair) => {
    return {
      ...prevPair,
      [currPair.name]: {
        id: currPair?.id,
        baseAsset: currPair.baseAsset,
        quoteAsset: currPair.quoteAsset,
        scTokenTypeId: currPair?.baseAsset?.scId,
        scCcyTypeId: currPair?.quoteAsset?.scId,
        baseAssetUomScRatio: currPair?.baseAsset?.uom?.scRatio,
        quoteAssetUomScRatio: currPair?.quoteAsset?.uom?.scRatio,
        autoRetireAsset: currPair?.baseAsset?.autoRetire,
        mapLot: currPair?.settings?.orderQtyMultiple || 1000,
        minOrder: currPair?.settings?.minOrderQty || 1000,
        maxOrder: currPair?.settings?.maxOrderQty || 100000,
        limitUpPercentage: currPair?.settings?.limitUpPercentage || 10,
        limitDownPercentage: currPair?.settings?.limitDownPercentage || 10,
        orderEntryEnabled: currPair?.marketFlags?.orderEntryEnabled,
        matchingEngineEnabled: currPair?.marketFlags?.matchingEngineEnabled,
        priceIncrement: currPair?.settings?.priceIncrement,
        lotQtySize: currPair?.baseAsset?.lotQtySize,
      },
    };
  }, {});

  const timeInFormMapper: Record<string, string> = timeInForces.reduce((prevPair, currPair) => {
    return { ...prevPair, [currPair.timeInForce]: currPair.id };
  }, {});

  useEffect(() => {
    if (!timeInForce && timeInForces) setTimeInForce(timeInForces[0]?.timeInForce);
  }, [timeInForce, timeInForces]);

  const pairInfo = pair ? pairMapper[pair] : {};

  const tokenNumDecimals = pair ? pairMapper[pair]?.baseAsset?.numDecimals : 0;
  const ccyNumDecimals = pair ? pairMapper[pair]?.quoteAsset?.numDecimals : 2;
  const mapLotToUom = pair ? pairMapper[pair]?.mapLot : 1;
  const lotQtySize = pair ? pairMapper[pair]?.lotQtySize : 1;
  const minQtyOrder = pair ? pairMapper[pair]?.minOrder : 1000;
  const maxQtyOrder = pair ? pairMapper[pair]?.maxOrder : 100000;
  const unitPriceIncrement = pair ? pairMapper[pair]?.priceIncrement : undefined;

  const { fobTypes } = useFobTypes(pair ? pairMapper[pair]?.baseAsset?.id : NaN);
  const { shippingTypes } = useShippingTypes();
  const { externalMarketICE } = useExternalMarketAsset('ICE');

  const debouncedTokenQty = useDebounce(qty, 500);
  const debouncedPrice = useDebounce(price, 500);
  const debouncedTotalAmount = (Number(debouncedPrice) + referencePrice) * (Number(debouncedTokenQty) * mapLotToUom);

  const { feeAmount, isLoading: isLoadingFeeAmount } = useFee({
    params: {
      feeType: FeeType.TRADE_SPOT_FEE,
      assetCategoryId: pair ? pairMapper[pair]?.baseAsset?.assetCategoryId : undefined,
      tokenQty: Number(debouncedTokenQty) * lotQtySize,
      totalAmount: debouncedTotalAmount,
    },
    options: { enabled: Number(debouncedTokenQty) > 0 && debouncedTotalAmount > 0 },
  });

  useEffect(() => {
    if (externalMarketICE?.price) {
      setReferencePrice(externalMarketICE?.price);
    } else {
      setReferencePrice(0);
    }
  }, [externalMarketICE?.price]);

  const { placeOrder: mutate, isLoading } = usePlaceOrder();

  const refSubmitButton = useRef<HTMLButtonElement>(null);
  const quantityInput = useRef<HTMLInputElement>(null);
  const priceInput = useRef<HTMLInputElement>(null);
  const datePicker = useRef<HTMLButtonElement>(null);

  const pairSettings = pair ? pairMapper[pair] : {};

  const isMarketOrderEntryEnabled = marketSettings?.biofuelEntryEnabled === 1;
  const isPairOrderEntryEnabled = pairSettings?.orderEntryEnabled === 1;

  const isOrderEntryEnabled = isMarketSettingEnabled({
    settingName: 'biofuelEntryEnabled',
    pairSettingName: 'orderEntryEnabled',
    marketSettings,
    pairSettings,
  });

  const isMatchingEngineEnabled = isMarketSettingEnabled({
    settingName: 'biofuelMatchingEnabled',
    pairSettingName: 'matchingEngineEnabled',
    marketSettings,
    pairSettings,
  });

  useEffect(() => {
    if (placeOrderData) {
      const { price, quantity, side, fobTypeId, shippingTypeId } = placeOrderData;
      if (price !== undefined && quantity) {
        if (orderType !== 'Limit') resetOnTypeChange('Limit');
        setPrice(price.toString());
        setQty((quantity / mapLotToUom).toString());
        setSell(side === 'Sell');
        setFobType(
          fobTypes
            ?.map(({ id, name }) => ({
              id,
              value: name,
            }))
            .find((item) => fobTypeId && item.id === Number(fobTypeId)) || null,
        );
        setShipping(
          shippingTypes
            ?.map(({ id, name }) => ({
              id,
              value: name,
            }))
            .find((item) => shippingTypeId && item.id === Number(shippingTypeId)) || shipping,
        );
        setTimeout(() => {
          refSubmitButton.current?.click();
        }, 100);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [placeOrderData]);

  const tipIcon = () => (
    <svg
      className="inline-block ml-1 w-4 h-4"
      fill="none"
      stroke="currentColor"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
      />
    </svg>
  );

  const reservedBalanceTip = () => (
    <TooltipWrapper>Absolute Price (FRONT ICE Gasoil + Premium) x Quantity + Fee</TooltipWrapper>
  );

  const iceReferencePriceDisclaimer = getSetting('web_settings_ICEReferencePriceDisclaimer');
  const frontIceGasoilTip = () => <TooltipWrapper>{iceReferencePriceDisclaimer}</TooltipWrapper>;

  const checkPlaceOrderValidation = useCallback(() => {
    if (!qty || isNaN(Number(qty)) || Number(qty) <= 0) {
      setError('Please specify a valid quantity.');
      return;
    }
    if (Number(qty) * mapLotToUom < minQtyOrder) {
      setError(`Insufficient quantity, minimum is ${formatter.formatNumber(minQtyOrder, 0)}.`);
      return;
    }
    if (Number(qty) * mapLotToUom > maxQtyOrder) {
      setError(`Exceeds maximum allowed quantity of ${formatter.formatNumber(maxQtyOrder, 0)}.`);
      return;
    }
    if (Number(qty) % 1) {
      setError('Odd lots not allowed.');
      return;
    }
    if (canSeeExternalBrokerClientForBiofuel() && !brokerClient?.value) {
      setError('Please select Client.');
      return;
    }
    if (!fobType?.value) {
      setError('Please select FOB.');
      return;
    }
    if (orderType !== 'Market') {
      if (!price || isNaN(Number(price))) {
        setError('Please specify a valid premium');
        return;
      }
      if ((price || '').toString().split('.')?.[1]?.length > (ccyAsset?.numDecimals || 2)) {
        setError(`Only ${ccyAsset?.numDecimals || 2} decimals is allowed`);
        return;
      }
      if (unitPriceIncrement && +helpers.fmod(Big(price), Big(unitPriceIncrement)) !== 0) {
        setError(`Valid price increment (step) is ${unitPriceIncrement}.`);
        return;
      }
    }
    setError('');
    const refreshedTotal = feeAmount + (Number(price) + referencePrice) * (Number(qty) * mapLotToUom);
    setTradeFee(feeAmount);
    setTotal(refreshedTotal);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    ccyAsset?.numDecimals,
    isSell,
    mapLotToUom,
    minQtyOrder,
    orderType,
    price,
    qty,
    fobType,
    fobType?.value,
    brokerClient?.value,
    feeAmount,
  ]);

  useEffect(() => {
    checkPlaceOrderValidation();
  }, [checkPlaceOrderValidation]);

  const placeOrderValidation = (): boolean => {
    if (!qty || Number(qty) <= 0) {
      toast.error('Quantity must be greater than zero');
      quantityInput.current?.focus();
      return false;
    }
    if (orderType !== 'Market') {
      if (!price || isNaN(Number(price))) {
        toast.error('Premium must be a valid number');
        priceInput.current?.focus();
        return false;
      }
      if (unitPriceIncrement && +helpers.fmod(Big(price), Big(unitPriceIncrement)) !== 0) {
        toast.error(`Valid price increment (step) is ${unitPriceIncrement}.`);
        priceInput.current?.focus();
        return false;
      }
    }
    if (isNaN(Number(qty)) || isNaN(Number(price))) {
      toast.error('Quantity and Price must be valid numbers');
      return false;
    }
    if (Number(qty) % 1) {
      toast.error('Odd lots not allowed');
      return false;
    }
    if (Number(qty) * mapLotToUom < minQtyOrder) {
      toast.error(`Insufficient quantity, minimum is ${formatter.formatNumber(minQtyOrder, 0)}.`);
      return false;
    }
    if (Number(qty) * mapLotToUom > maxQtyOrder) {
      setError(`Exceeds maximum allowed quantity of ${formatter.formatNumber(maxQtyOrder, 0)}.`);
      return false;
    }
    if (timeInForce === 'Good Till Date' && !expiryUtc) {
      toast.error('Expiry date is required, and it must be later than the current date and time.');
      datePicker.current?.focus();
      return false;
    }
    if ((price || '').toString().split('.')?.[1]?.length > (ccyAsset?.numDecimals || 2)) {
      toast.error(`Only ${ccyAsset?.numDecimals || 2} decimals is allowed`);
      return false;
    }
    if (canSeeExternalBrokerClientForBiofuel() && !brokerClient?.value) {
      toast.error(`Please select Client.`);
      return false;
    }
    if (!fobType?.value) {
      toast.error(`Please select FOB.`);
      return false;
    }
    return true;
  };

  const finallyPlaceOrder = () => {
    let order: Record<string, any> = {
      orderType,
      pairId: pair && (pairMapper[pair].id || pairs?.[0]?.id),
      orderSide: isSell ? 'Sell' : 'Buy',
      qty: Number(qty) * mapLotToUom,
      accountAddress: getAccountAddress(),
      timeInForceTypeId: timeInFormMapper[timeInForce],
      extraDetails: {
        type: 'biofuel',
        values: {
          fobId: fobType?.id,
          clientId: brokerClient?.id,
          shippingId: shipping.id,
        },
      },
    };

    if (timeInForce === 'Good Till Date' && expiryUtc) order.expiryUtc = expiryUtc;

    if (orderType !== 'Market') {
      order = {
        ...order,
        price: Number(price),
      };
    }

    mutate(order)
      .then((data) => {
        if (data?.order?.order_id) {
          queryCache.invalidateQueries(['oms-orders']);
          resetOnTypeChange('Limit');
        } else {
          const errorResponse = (data as any)?.json;
          toast.error(errorResponse ? errorResponse?.message : 'Something went wrong!');
        }
      })
      .catch((err) => {
        toast.error(err?.message ?? 'Something went wrong!');
      });
  };

  const tryToPlaceOrder = () => {
    if (isOrderEntryEnabled) {
      setValidationTryCount(validationTriedCount + 1);
      const restingOrder = false;
      if (placeOrderValidation()) {
        showDialog?.({
          title: 'Place Order',
          message: (
            <>
              <div className="flex flex-col gap-5 items-center w-full h-auto" style={{ minWidth: '350px' }}>
                <div className="text-gray_0" style={{ color: theme.table.active.text }}>
                  Please confirm order details
                </div>
                <div
                  style={{ color: theme.table.text.default }}
                  className="flex flex-col flex-shrink gap-1 w-11/12 text-sm text-gray-200"
                >
                  <div className="flex flex-row gap-4">
                    <div className="flex-1 text-left">Pair:</div>
                    <div>{`${pair}`}</div>
                  </div>
                  <div className="flex flex-row gap-4">
                    <div className="flex-1 text-left">Side:</div>
                    <div>{isSell ? 'Sell' : 'Buy'} </div>
                  </div>
                  <div className="flex flex-row gap-4">
                    <div className="flex-1 text-left">Type:</div>
                    <div>{`${orderType}`}</div>
                  </div>
                  <div className="flex flex-row gap-4">
                    <div className="flex-1 text-left">Quantity:</div>
                    <div className="code">{`${qty}`}</div>
                  </div>
                  <div className="flex flex-row gap-4">
                    <div className="flex-1 text-left">Price:</div>
                    <div className="code">{orderType === 'Market' ? 'MKT' : `${price}`}</div>
                  </div>
                  <div className="flex flex-row gap-4">
                    <div className="flex-1 text-left">Time In Force:</div>
                    <div>{`${timeInForce}`}</div>
                  </div>
                  {timeInForce === 'Good Till Date' && (
                    <div className="flex flex-row gap-4">
                      <div className="flex-1 text-left">Expiry Date:</div>
                      <div>{`${expiryUtc ? moment(expiryUtc).format('MMMM Do YYYY, h:mm:ss a') : '-'} `}</div>
                    </div>
                  )}
                  {canSeeExternalBrokerClientForBiofuel() && (
                    <div className="flex flex-row gap-4">
                      <div className="flex-1 text-left">Client:</div>
                      <div className="code">{`${brokerClient?.value || 'Any'}`}</div>
                    </div>
                  )}
                  <div className="flex flex-row gap-4">
                    <div className="flex-1 text-left">FOB:</div>
                    <div className="code">{`${fobType?.value || 'Any'}`}</div>
                  </div>
                  <div className="flex flex-row gap-4">
                    <div className="flex-1 text-left">Shipping:</div>
                    <div className="code">{`${shipping.value}`}</div>
                  </div>
                  <div className="flex flex-row gap-4">
                    <div className="flex-1 text-left">
                      Reference Price:
                      <span className="inline-block relative top-1 left-1 cursor-pointer">
                        <Tooltip
                          text={
                            <Text className="whitespace-pre" align="center" size={FontSize.small}>
                              {frontIceGasoilTip()}
                            </Text>
                          }
                        />
                      </span>
                    </div>
                    <div className="code">{`${referencePrice}`}</div>
                  </div>
                  <div className="flex flex-row gap-4">
                    <div className="flex-1 text-left">Fee:</div>
                    <div>
                      <span className="code">{formatter.formatNumber(tradeFee)} </span>
                      {pairInfo?.quoteAsset?.uom?.name}
                    </div>
                  </div>
                  <div className="flex flex-row gap-4">
                    <div className="flex-1 text-left">Total:</div>
                    <div>
                      <span className="code">{isNaN(total) ? 0 : formatter.formatNumber(total)} </span>
                      {pairInfo?.quoteAsset?.uom?.name}
                    </div>
                  </div>
                </div>
              </div>
              {restingOrder && (
                <div className="pt-5">
                  <div
                    className="flex flex-row items-center p-1 text-xs text-left"
                    style={{
                      color: theme.table.active.text,
                      backgroundColor: theme.panel.button.background,
                      border: theme.panel.button.border,
                    }}
                  >
                    <FaExclamationTriangle size="45px" className="mr-2 ml-2 text-yellow-300" />
                  </div>
                </div>
              )}
            </>
          ),
          cancel: {
            style: {
              height: '34px',
              minWidth: '150px',
            },
            onClick: () => {
              removeDialog?.();
              if (!isTouchDevice()) refSubmitButton.current?.focus();
            },
          },
          accept: {
            style: {
              height: '34px',
              minWidth: '150px',
            },
            onClick: () => {
              finallyPlaceOrder();
              removeDialog?.();
              if (!isTouchDevice()) quantityInput.current?.focus();
            },
          },
        });
      }
    }
  };

  const resetOnTypeChange = (orderTypeValue: string) => {
    setOrderType(orderTypeValue);
    setQty('');
    setPrice('');
    setFobType(null);
    setBrokerClient(null);
    setShipping({
      id: 6,
      value: 'Week 5',
    });
    if (orderTypeValue !== 'Market') {
      setTimeInForce(timeInForces[0]?.timeInForce);
    } else {
      setTimeInForce('Day');
    }
    setExpiryUtc(null);
    setValidationTryCount(0);
    setError('');
  };

  const setDateExpiry = (date: any) => {
    if (date) {
      if (date?.toDate() > new Date()) {
        setExpiryUtc(date?.toDate());
      } else {
        setExpiryUtc(expiryUtc === null ? undefined : null);
        toast.error('Expiry date must be later than the current date and time.');
      }
    } else {
      setExpiryUtc(expiryUtc === null ? undefined : null);
    }
  };

  const isPlaceOrderButtonDisabled = Boolean(!validationTriedCount ? false : (error ?? false)) || isLoadingFeeAmount;

  if (!marketSettings || pairs.length === 0) return <Loading />;

  const isFormEnabled = isOrderEntryEnabled && canTradeBiofuel();

  const content = (
    <div
      style={{
        display: 'contents',
      }}
    >
      {(!isOrderEntryEnabled || !isMatchingEngineEnabled) && (
        <Wrapper className="flex flex-col mt-2 w-full">
          <div className="relative text-xs font-semibold text-grey-100 text-nowrap">
            {!isMarketOrderEntryEnabled && <div className="p-1 mb-1 bg-danger_500">Order entry is disabled</div>}
            {isMarketOrderEntryEnabled && !isPairOrderEntryEnabled && (
              <div className="p-1 mb-1 bg-danger_500">Order entry is disabled for this contract</div>
            )}
            {!isMatchingEngineEnabled && (
              <div className="p-1 mb-1 bg-bg-danger_500">Matching of orders is disabled</div>
            )}
          </div>
        </Wrapper>
      )}
      <Wrapper
        style={{
          opacity: isFormEnabled ? 1 : 0.5,
          pointerEvents: isFormEnabled ? 'auto' : 'none',
          userSelect: isFormEnabled ? 'inherit' : 'none',
        }}
        className="flex flex-col mt-2 w-full"
      >
        <div
          style={{
            background: `var(${Colors.gray_100})`,
          }}
          className={`relative flex flex-row justify-center flex-auto rounded-lg z-0`}
        >
          <Button
            config={{
              color: isSell || !isFormEnabled ? 'ghost' : 'secondary',
            }}
            onClick={() => setSell(false)}
            className={`relative buy-button flex-1`}
          >
            BUY
          </Button>
          <Button
            config={{
              color: !isSell || !isFormEnabled ? 'ghost' : 'secondary',
            }}
            className={`relative sell-button flex-1`}
            onClick={() => setSell(true)}
          >
            SELL
          </Button>
        </div>
        <div className="flex relative flex-row">
          <div className="flex flex-1 items-center">
            <div>
              <Tabs
                className="order-types"
                onSelect={(item) => {
                  if (item.value !== orderType) resetOnTypeChange(item.value);
                }}
                type={'ghost'}
                selected={orderType}
                list={[
                  {
                    label: 'Limit',
                    value: 'Limit',
                  },
                  {
                    label: 'Market',
                    value: 'Market',
                  },
                ]}
              />
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-base">
          <div>
            <div className="flex flex-col gap-2xs">
              <Text color={Colors.gray_900} size={FontSize.small}>
                Quantity
              </Text>
              <Input
                ref={quantityInput}
                config={{
                  size: 'sm',
                  postfix: (
                    <Text color={Colors.gray_500} size={FontSize.small}>
                      LOTS
                    </Text>
                  ),
                  color: 'gray',
                }}
                onEnter={() => tryToPlaceOrder()}
                onKeyDownCapture={(event) => {
                  if (event.key === 'Tab' && event.shiftKey === true) {
                    event.preventDefault();
                    if (refSubmitButton.current) {
                      refSubmitButton.current.focus();
                    }
                  }
                }}
                value={qty}
                onBlur={() => qty?.endsWith('.') && setQty(`${qty}0`)}
                onChange={(e) => {
                  const value = e.target.value === '.' ? '0.' : e.target.value;
                  const re = RegExp(`${tokenNumDecimals ? `(^\\d+\\.\\d{0,${tokenNumDecimals}}$)|` : ``}(^\\d+$)`);
                  if (value === '' || re.test(value)) {
                    setQty(value);
                  }
                }}
              />
              <Hint
                className="mt-1"
                style={{
                  color: theme.placeHolder.input.label,
                }}
              >
                <span className="code">{formatter.formatNumber(Number(qty ?? 0) * mapLotToUom, 0)}</span>{' '}
                {pairInfo?.baseAsset?.uom?.name} of {token}
              </Hint>
            </div>
            {orderType !== 'Market' ? (
              <>
                <div className="flex flex-col gap-2xs">
                  <Text color={Colors.gray_900} size={FontSize.small}>
                    Premium
                  </Text>
                  <Input
                    ref={priceInput}
                    config={{
                      size: 'sm',
                      postfix: (
                        <Text color={Colors.gray_500} size={FontSize.small}>
                          {pairInfo?.quoteAsset?.uom?.name}
                        </Text>
                      ),
                      color: 'gray',
                    }}
                    value={price}
                    onBlur={() => price?.endsWith('.') && setPrice(`${price}0`)}
                    onChange={(e) => {
                      const value = e.target.value === '.' ? '0.' : e.target.value;
                      const re = RegExp(
                        `${ccyNumDecimals ? `(^-?\\d+\\.\\d{0,${ccyNumDecimals}}$)|` : ``}(^-?\\d+$)|(^-$)`,
                      );
                      if (value === '' || re.test(value)) {
                        setPrice(value);
                      }
                    }}
                    disabled={orderType === 'Market'}
                    onEnter={() => tryToPlaceOrder()}
                  />
                </div>
              </>
            ) : (
              <></>
            )}
          </div>
          <div className="flex flex-col gap-2 justify-end">
            {canSeeExternalBrokerClientForBiofuel() && (
              <div className="flex relative flex-1 gap-4 items-center mt-1">
                <Dropdown
                  className="flex-1"
                  key={'client'}
                  list={
                    clientList?.map((item) => ({
                      id: item.id,
                      label: item.name,
                    })) ?? []
                  }
                  selected={[brokerClient?.id]}
                  onSelectItem={(item) => {
                    setBrokerClient({ id: Number(item.id), value: item.label?.toString() ?? '' });
                  }}
                  children={(item) => {
                    const firstItem = item?.[0];
                    if (firstItem) {
                      return (
                        <Text display="block" color={Colors.gray_900} size={FontSize.small}>
                          Client: <span className="font-semibold">{firstItem.label}</span>
                        </Text>
                      );
                    } else {
                      return (
                        <Text display="block" color={Colors.gray_900} size={FontSize.small}>
                          Client
                        </Text>
                      );
                    }
                  }}
                  config={{
                    color: 'gray',
                    size: 'sm',
                  }}
                />
              </div>
            )}
            <div className="flex relative flex-1 gap-4 items-center mt-1">
              <Dropdown
                className="flex-1"
                key={'fob'}
                list={
                  fobTypes?.map((item) => ({
                    id: item.id,
                    label: item.name,
                  })) ?? []
                }
                selected={[fobType?.id]}
                onSelectItem={(item) => {
                  item.id === -1
                    ? setFobType(null)
                    : setFobType({ id: Number(item.id), value: item.label?.toString() ?? '' });
                }}
                children={(item) => {
                  const firstItem = item?.[0];
                  if (firstItem) {
                    return (
                      <Text display="block" color={Colors.gray_900} size={FontSize.small}>
                        FOB: <span className="font-semibold">{firstItem.label}</span>
                      </Text>
                    );
                  } else {
                    return (
                      <Text display="block" color={Colors.gray_900} size={FontSize.small}>
                        FOB
                      </Text>
                    );
                  }
                }}
                config={{
                  color: 'gray',
                  size: 'sm',
                }}
              />
            </div>
            <div className="flex relative flex-1 gap-4 items-center mt-1">
              <Dropdown
                className="flex-1"
                key={'shipping'}
                list={
                  shippingTypes?.map((item) => ({
                    id: item.id,
                    label: item.name,
                  })) ?? []
                }
                selected={[shipping.id]}
                onSelectItem={(item) => {
                  setShipping({ id: Number(item.id), value: item.label?.toString() ?? '' });
                }}
                children={(item) => {
                  const firstItem = item?.[0];
                  if (firstItem) {
                    return (
                      <Text display="block" color={Colors.gray_900} size={FontSize.small}>
                        Shipping: <span className="font-semibold">{firstItem.label}</span>
                      </Text>
                    );
                  } else {
                    return (
                      <Text display="block" color={Colors.gray_900} size={FontSize.small}>
                        Shipping
                      </Text>
                    );
                  }
                }}
                config={{
                  color: 'gray',
                  size: 'sm',
                }}
              />
            </div>
            {orderType !== 'Market' && (
              <>
                <div className="flex flex-col gap-4 justify-end mt-1">
                  <Dropdown
                    className="flex-1"
                    key={'tif'}
                    list={timeInForces.map((item) => ({
                      id: item.timeInForce.toString(),
                      label: item.timeInForce,
                    }))}
                    selected={[timeInForce]}
                    onSelectItem={(item) => {
                      setTimeInForce(item.id.toString());
                    }}
                    children={(item) => {
                      const firstItem = item?.[0];
                      if (firstItem) {
                        return (
                          <Text display="block" color={Colors.gray_900} size={FontSize.small}>
                            Time In Force: <span className="font-semibold">{firstItem.label}</span>
                          </Text>
                        );
                      } else {
                        return (
                          <Text display="block" color={Colors.gray_900}>
                            Time In Force
                          </Text>
                        );
                      }
                    }}
                    config={{
                      color: 'gray',
                      size: 'sm',
                    }}
                  />

                  {timeInForce === 'Good Till Date' && (
                    <>
                      <DatePicker
                        ref={datePicker}
                        selected={expiryUtc}
                        onChange={(date) => {
                          setDateExpiry(date ? moment(date) : undefined);
                        }}
                        onClear={() => {
                          setDateExpiry(undefined);
                        }}
                        showTimeSelect={true}
                        disabled={orderType === 'Market'}
                        placeholderText="Expiry Date"
                      />
                    </>
                  )}
                </div>
              </>
            )}
          </div>
        </div>
        <InfoList
          className="mt-1"
          border={
            !isFormEnabled
              ? 'transparent'
              : isSell
                ? theme.placeHolder.input.highlight.sell
                : theme.placeHolder.input.highlight.buy
          }
          borderStyle={'solid'}
          info={[
            {
              label: 'Total fee (exc. VAT)',
              font: 'code',
              value: (
                <>
                  <span>{formatter.formatNumber(tradeFee)} </span>
                  {pairInfo?.quoteAsset?.uom?.name}
                </>
              ),
            },
            {
              label: (
                <div>
                  FRONT ICE Gasoil
                  <span className="inline-block relative top-1 left-1 cursor-pointer">
                    <Tooltip
                      text={
                        <Text align="center" size={FontSize.small}>
                          {frontIceGasoilTip()}
                        </Text>
                      }
                    />
                  </span>
                </div>
              ),
              font: 'code',
              value: (
                <>
                  <span>{isNaN(referencePrice) ? 0 : formatter.formatNumber(referencePrice)} </span>
                  {pairInfo?.quoteAsset?.uom?.name}
                </>
              ),
            },
            {
              label: (
                <div>
                  Total
                  <span className="inline-block relative top-1 left-1 cursor-pointer">
                    <Tooltip
                      text={
                        <Text align="center" size={FontSize.small}>
                          {reservedBalanceTip()}
                        </Text>
                      }
                    />
                  </span>
                </div>
              ),
              font: 'code',
              value: (
                <>
                  <span>{isNaN(total) ? 0 : formatter.formatNumber(total)}</span> {ccy}
                </>
              ),
            },
          ]}
        />
        {!!validationTriedCount && (
          <div className="flex relative flex-row flex-auto">
            <span className="text-red-500 text-nowrap">{error}</span>
          </div>
        )}
      </Wrapper>
    </div>
  );

  return height === 'auto' ? (
    <>
      <div className="flex flex-col h-full max-h-full">
        <div className="overflow-hidden relative flex-row flex-1">{content}</div>
        <div className="flex relative flex-col flex-auto justify-center">
          {isLoading ? (
            <div className="spinner">{}</div>
          ) : (
            <>
              <Button
                ref={refSubmitButton}
                className={`place-order-button ${styles['border-focused-white']}`}
                key={`isSell-${isSell}`}
                config={{
                  color: 'primary',
                  size: 'l',
                }}
                onClick={tryToPlaceOrder}
                disabled={isPlaceOrderButtonDisabled || !isOrderEntryEnabled}
                onKeyDownCapture={(event) => {
                  if (event.key === 'Tab' && event.shiftKey === false) {
                    event.preventDefault();
                    quantityInput.current?.focus();
                  }
                }}
              >
                {`PLACE ORDER - ${pair}`}
              </Button>
            </>
          )}
        </div>
      </div>
    </>
  ) : (
    <Adjust>
      <div className="flex flex-col h-full max-h-full">
        <div className="overflow-hidden relative flex-col flex-1">
          <div className="absolute w-full h-full">
            <SimpleBar>{content}</SimpleBar>
          </div>
        </div>
        <div className="flex relative flex-col justify-center px-base pt-small">
          <Button
            ref={refSubmitButton}
            key={`isSell-${isSell}`}
            config={{
              color: 'primary',
              size: 'l',
            }}
            isLoading={isLoading}
            onClick={tryToPlaceOrder}
            disabled={isLoading ? true : isPlaceOrderButtonDisabled || !isOrderEntryEnabled}
            onKeyDownCapture={(event) => {
              if (event.key === 'Tab' && event.shiftKey === false) {
                event.preventDefault();
                quantityInput.current?.focus();
              }
            }}
          >
            {`PLACE ORDER - ${pair}`}
          </Button>
        </div>
      </div>
    </Adjust>
  );
};

export default PlaceOrder;
