import queryString from 'query-string';

import { BalanceResponse } from '@aircarbon/utils-common/src/dto';

import { userAuthenticatedApi } from '../fetcher';

export const fetchAvailableBalance = async (data: {
  getRootBalance: 'yes' | 'no';
  ccyTypeId: number;
  userId?: number;
}) => {
  const qs = queryString.stringify(data, { arrayFormat: 'bracket' });
  const endpoint = `/user/balance?${qs}`;

  const userService = await userAuthenticatedApi();
  const res = userService.url(endpoint).get();
  return res.json<BalanceResponse>();
};
