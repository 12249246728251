import { ProjectFormValue } from '../../ProjectForm';

export const initialProjectFormValue: ProjectFormValue = {
  id: '',
  apxId: '',
  projectName: '',
  projectDescription: '',
  projectPhase: '',
  standardUsed: '',
  projectId: '',
  projectType: '',
  projectLocation: '',
  methodology: '',
  customMethodology: '',
  sdgGoals: [],
  additionalCertifications: [],
  sectoralScope: '',
  eligibilityForInternalGrades: '',
  implementationDate: '',
  vintages: [],
  documents: {
    projectIdeaNote: null,
    preFeasibilityStudyDocument: null,
    projectDesignDocument: null,
    verificationReport: null,
    emissionReductionCalculation: null,
    monitoringProtocols: null,
  },
  proponents: [
    {
      companyName: '',
      companyInvolvement: '',
      contactPersonName: '',
      contactPersonRole: '',
      email: '',
      phone: {
        value: '',
        phoneCode: '+971',
      },
    },
  ],
};
