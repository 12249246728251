import { CarbonMetaOptions } from '@aircarbon/utils-common/src/dto/carbon';

import { ApiResponse, userAuthenticatedApi } from '../fetcher';

export const fetchCarbonMetaOptions = async (query?: Record<string, any>) => {
  const userService = await userAuthenticatedApi();
  let res = userService.url('/carbon/meta-options');

  if (query) {
    res = res.query(query);
  }

  return res.get().json<ApiResponse<CarbonMetaOptions>>();
};
