import { useQuery } from 'react-query';

import { fetchAddressData } from 'data-provider/user/fetchAddressData';

function useAddressData({
  address,
  options = {},
  queryName = '/api/contract/address-data',
}: {
  address: string;
  options?: Record<string, any>;
  queryName?: string;
}) {
  const { data, isLoading, error, refetch } = useQuery([queryName, address], () => fetchAddressData(address), {
    ...(options ?? {}),
    refetchOnWindowFocus: false,
  });

  return {
    addressData: data,
    isLoading,
    error,
    refetch,
  };
}

export default useAddressData;
