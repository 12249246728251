import { Colors, Radius } from 'refreshed-component/design-system';
import styled from 'styled-components';

import { ThemeColors } from 'pages/account/trading/components/Themes';

import { ScreenSizes } from 'state/ui';

export const Wrapper = styled.div<{ screenSize?: ScreenSizes }>`
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: var(${Colors.gray_0});
  overflow: hidden;

  ${(props) => {
    if (props.screenSize === 'small' || props.screenSize === 'medium') {
      return `
        position: relative;
      `;
    } else {
      return `
        position: absolute;
      `;
    }
  }}

  &.section-btn {
    border-radius: 6px;
  }

  .formTitle {
    background: var(${Colors.gray_0});
    padding: 1rem 2rem;
    border-bottom: 1px solid var(${Colors.borderNormal});
  }

  .list-square {
    margin-left: 1rem;
    li {
      list-style: square;
    }
  }
`;

export const BidFields = styled.div<{ themeColor: ThemeColors }>`
  display: flex;
  flex-direction: column;
  background: ${(props) => props.themeColor.rfq.content.form.input.fieldBackground};
  color: ${(props) => props.themeColor.rfq.content.form.input.color};
  padding: 2rem;
  width: 100%;
  height: 100%;

  .ac-input,
  .select div {
    background: ${(props) => props.themeColor.rfq.content.form.input.background};
    border-color: ${({ themeColor }) => themeColor.rfq.content.form.input.border};
    color: ${({ themeColor }) => themeColor.rfq.content.form.input.selectColor};
  }
  .ac-input {
    height: 38px;

    &:hover,
    &:focus {
      border-color: ${(props) => props.theme.brandColor};
    }
  }
`;

export const CriteriaFields = styled.div<{ screenSize?: ScreenSizes }>`
  width: 100%;
  height: 100%;
  background: var(${Colors.gray_0});
  color: var(${Colors.gray_500});
  border-right: 1px solid var(${Colors.borderNormal});
  overflow-y: auto;

  & > div {
    align-self: self-start;
  }

  .select {
    border-color: transparent !important;
    outline: none !important;
    > div > div > div {
      border-radius: var(${Radius.medium});
      > div:nth-child(2) {
        border-radius: var(${Radius.medium});
      }
    }

    > div {
      background: var(${Colors.gray_50});
      border-color: var(${Colors.gray_200});
      color: var(${Colors.gray_800});
      border-radius: var(${Radius.medium});
    }
  }
`;

export const Inventory = styled.div<{ screenSize?: ScreenSizes }>`
  font-size: 1.2em;
  font-weight: 500;
  background: var(${Colors.gray_0});
  padding: 1rem 2rem;
  border-top: var(${Colors.borderLight});
  flex-wrap: wrap;

  .label {
    span {
      display: block;
      color: var(${Colors.gray_500});
      font-size: 90%;
      font-weight: 400;
    }
  }

  ${(props) => {
    if (props.screenSize === 'small') {
      return `
        gap: 1rem;
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        .label {
          grid-column: span 2 / span 2;
        }
      `;
    } else {
      return `
        gap: 3rem;
        display: flex;
        flex-direction: row;
        align-items: center;
      `;
    }
  }}
`;

export const InventoryItem = styled.button`
  display: flex;
  flex-direction: column;
  font-size: 1em;
  font-weight: 300;
  gap: 5px;

  .tokenName {
    font-size: 0.85rem;
    color: var(${Colors.gray_900});
    font-weight: 600;
  }

  &.not-available {
    opacity: 0.5;

    .tokenName {
      color: var(${Colors.gray_900});
    }
  }
`;

export const PriceMinMax = styled.div`
  margin-bottom: 1rem;
  display: flex;
  gap: 1.5rem;
  justify-content: space-between;
  background: #191919;
  padding: 5px 0;

  button {
    padding: 7px 13px;
    color: rgba(${(props) => props.theme.brandRbgColor}, 1);
    display: flex;
    flex-direction: column;
    gap: 5px;
    align-items: center;

    span {
      text-transform: uppercase;
      color: #929292;
      font-weight: 600;
      font-size: 0.8em;
    }
  }
`;
