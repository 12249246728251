export enum FiatDepositStatus {
  Canceled = 'CANCELLED',
  Done = 'DONE',
  Pending = 'PENDING',
  Processing = 'PROCESSING',
  Rejected = 'REJECTED',
  InProgress = 'IN PROGRESS',
}

export enum FiatWithdrawalStatus {
  Canceled = 'CANCELLED',
  Debited = 'DEBITED',
  Done = 'DONE',
  Pending = 'PENDING',
  Processing = 'PROCESSING',
  Refunding = 'REFUNDING',
  Reverted = 'REVERTED',
  Submitted = 'SUBMITTED',
  Submitting = 'SUBMITTING',
  InProgress = 'IN PROGRESS',
}
