import Big from 'big.js';
import AES from 'crypto-js/aes';
import EncUtf8 from 'crypto-js/enc-utf8';
import sha256 from 'crypto-js/sha256';
import Web3 from 'web3';
import { isBN, isBigNumber } from 'web3-utils';

import logger from './logger';

function buildParams(prefix: any, obj: any, add: any) {
  const rbracket = /\[\]$/;
  if (obj instanceof Array) {
    obj.forEach((value: any, index) => {
      if (rbracket.test(prefix)) {
        add(prefix, value);
      } else {
        buildParams(`${prefix}[${typeof obj[index] === 'object' ? index : ''}]`, value, add);
      }
    });
  } else if (typeof obj === 'object') {
    Object.keys(obj).forEach((key: any) => {
      buildParams(`${prefix}[${key}]`, obj[key], add);
    });
  } else {
    // Serialize scalar item.
    add(prefix, obj);
  }
}

export const objectToQueryString = (a: any) => {
  const s: Array<string> = [];

  const r20 = /%20/g;
  const add = (key: any, value: any) => {
    let checkValue;
    // If value is a function, invoke it and return its value
    if (typeof value === 'function') {
      checkValue = value();
    } else {
      checkValue = value == null ? '' : value;
    }
    s.push(`${encodeURIComponent(key)}=${encodeURIComponent(checkValue)}`);
  };
  if (a instanceof Array) {
    a.forEach((name: any, index) => {
      add(index, name);
    });
  } else {
    Object.keys(a).forEach((index: any) => {
      buildParams(index, a[index], add);
    });
  }
  const output = s.join('&').replace(r20, '+');
  return output;
};

const SECRET_KEY = 'AirCarbon@2020';
export function hideMessage(msg: string): string {
  if (msg.length > 1) return AES.encrypt(msg, SECRET_KEY).toString();

  return msg;
}

export function revealMessage(cipherText: string): string {
  try {
    // If it is not encrypted, return the original text
    if (!cipherText.startsWith('U2Fsd')) return cipherText;

    const bytes = AES.decrypt(cipherText, SECRET_KEY);
    return bytes.toString(EncUtf8);
  } catch (err) {
    return cipherText;
  }
}

export const parseBankInfo = (bank: string | undefined) => {
  const SEPARATOR = ' | ';
  const groups = bank?.length
    ? bank?.split(SEPARATOR)
    : [
        'bankName:',
        'bankAddress:',
        'swiftCode:',
        'accountIBAN:',
        'accountName:',
        'contactPhone:',
        'referenceNote:',
        'bankCountry:',
      ];

  return groups.reduce((result: Record<string, any>, group: string) => {
    const [key, value] = group.split(':');
    return { ...result, [key]: (value && value !== '' ? value : '-').trim() };
  }, {});
};

export const parseRegistryInfo = (registry: string | undefined) => {
  const SEPARATOR = ' | ';
  const groups = registry?.length ? registry?.split(SEPARATOR) : ['accountId:', 'accountName:'];

  return groups.reduce((result: Record<string, any>, group: string) => {
    const [key, value] = group.split(':');
    return { ...result, [key]: (value && value !== '' ? value : '-').trim() };
  }, {});
};

export function generateSHA256Hash(text: string) {
  return sha256(text).toString();
}

function isLowerCase(value: string) {
  return /[a-z]+/g.test(value);
}

function isUpperCase(value: string) {
  return /[A-Z]+/g.test(value);
}

function isNumber(value: string) {
  return /\d/g.test(value);
}

export function validatePasswordPolicy(password: string) {
  return isUpperCase(password) && isLowerCase(password) && isNumber(password);
}

export const convertArrayToURParams = (key: string, values: Array<any>) =>
  values.map((value) => `${key}[]=${value}`).join('&');

export function generatePassword() {
  const minLength = 10;
  const maxLength = 12;
  const charset = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';

  let attempt = 10;
  let password = '';

  while (attempt > 0 && !validatePasswordPolicy(password)) {
    password = '';
    const randomLength = Math.floor(Math.random() * (maxLength - minLength)) + minLength;
    for (let i = 0, n = charset.length; i < randomLength; i += 1) {
      password += charset.charAt(Math.floor(Math.random() * n));
    }
    attempt -= 1;
  }

  // fallback to fixed password after 10 attempts
  if (attempt === 0 && !validatePasswordPolicy(password)) {
    password = `Trader${Date.now()}`;
  }

  return password;
}

// NOTE: It should have both number key and field name in the array
export function isWeb3ReturnData(values: Record<string, any> | Array<Record<string | number, any>> | null): boolean {
  if (Array.isArray(values)) {
    let hasKeyNumber = false;
    let hasFieldName = false;
    Object.entries(values).forEach(([key]) => {
      if (Number.isNaN(Number(key))) {
        hasFieldName = true;
      }
      if (!Number.isNaN(Number(key))) {
        hasKeyNumber = true;
      }
    });
    return hasFieldName && hasKeyNumber;
  }

  const keys = Object.keys(values || {});
  return keys.length > 0 && Number.isInteger(Number(keys[0])) && keys.length % 2 === 0;
}

/**
 * Is that single array with same data type
 * @param values
 */
export function isSameArrayDataType(values: any[]): boolean {
  if (Array.isArray(values)) {
    // just for the case empty array
    if (values.length === 0) return true;

    const dataType = typeof values[0];
    if (dataType === 'object') {
      return values.every((item) => typeof item === 'object' && !Array.isArray(item));
    }
    return values.every((item) => typeof item === dataType);
  }

  return false;
}

/**
 * Decode web3 return data and keep the same field name as smart contract
 * @param values
 */
export function decodeWeb3Object(values: any): any {
  // skip decode for those cases: string, number, array<string|number>
  if (
    !values || // null, undefined
    ['string', 'number'].includes(typeof values) || // number or string
    isBN(values) || // big number
    isBigNumber(values) || // big number
    (Array.isArray(values) && isSameArrayDataType(values)) // Array[string | number]
  )
    return values;

  if (Array.isArray(values)) {
    // support nested array
    if (Array.from(values).every((item) => Array.isArray(item) && isSameArrayDataType(item))) {
      return Array.from(values).map((item) => {
        if (Array.isArray(item) && isSameArrayDataType(item)) {
          return Array.from(item);
        }
        return item;
      });
    }

    const result: Record<string, any> = {};

    const entries = Object.entries(values);
    if (entries.length === 1 && values[0]) {
      // NOTE: process edge case if return array with number
      return decodeWeb3Object(values[0]);
    }

    entries.forEach(([key, value]) => {
      if (Number.isNaN(Number(key))) {
        if (Array.isArray(value)) {
          // same singular data type
          if (isSameArrayDataType(value)) {
            result[key] = Array.from(value);
          } else {
            let items: any;
            Object.entries(value).forEach(([childKey, childVal]) => {
              if (isWeb3ReturnData(childVal)) {
                if (!items) {
                  items = [];
                }
                items.push(decodeWeb3Object(childVal));
              } else if (Number.isNaN(Number(childKey))) {
                if (!items) items = {};
                items[childKey] = childVal;
              }
            });
            result[key] = items;
          }
        } else {
          result[key] = value;
        }
      }
    });

    return result;
  }

  const result: Record<string, any> = {};

  Object.keys(values || {}).forEach((field) => {
    if (Number.isNaN(Number(field))) result[field] = decodeWeb3Object(values[field]);
  });
  return result;
}

/* eslint-disable no-restricted-syntax */
/* eslint-disable no-prototype-builtins */
export function serialize(obj: Record<string, any>) {
  const str: string[] = [];
  for (const p in obj)
    if (obj.hasOwnProperty(p)) {
      str.push(`${encodeURIComponent(p)}=${encodeURIComponent(obj[p])}`);
    }
  return str.join('&');
}

export function getRevertReasonFromErrorMessage(error: Error) {
  const { message = '' } = error;
  let rawMessageData = '';
  if (message.startsWith('Node error: ')) {
    // Trim "Node error: "
    const errorObjectStr = message.slice(12);
    // Parse the error object
    const errorObject = JSON.parse(errorObjectStr);
    if (!errorObject.data) {
      throw Error(`Failed to parse data field error object:${errorObjectStr}`);
    }
    const errorObjectData = errorObject.data;
    const errorObjectDataString = JSON.stringify(errorObjectData);
    if (errorObjectDataString.startsWith('Reverted 0x')) {
      // Trim "Reverted 0x" from the data field
      rawMessageData = errorObjectDataString.slice(81);
    } else if (errorObjectDataString.startsWith('{"0x')) {
      // Trim "0x" from the data field
      rawMessageData = errorObjectDataString.slice(70);
    } else {
      throw Error(`Failed to parse data field error object:${errorObjectStr}`);
    }
  }
  const errorMessageExtract = rawMessageData.replace('},', ',');
  const objectInst = JSON.parse(errorMessageExtract);
  return objectInst.reason;
}

export const getRevertReason = async (web3: Web3, receipt: Record<string, any> | null | undefined): Promise<string> => {
  if (!receipt) return 'Receipt is not available';

  const { transactionHash, gasUsed } = receipt;
  const transaction = await web3.eth.getTransaction(transactionHash);

  if (gasUsed === transaction.gas) {
    return 'Transaction failed as it ran out of gas.';
  }
  let rawMessageData;
  try {
    if (transaction) {
      const { to, input, from, value, gas, gasPrice, blockNumber } = transaction;
      const result = await web3.eth.call(
        {
          to: to || undefined,
          data: input,
          from,
          value,
          gas,
          gasPrice,
        },
        blockNumber ?? '',
      );

      logger.info('RAW result:', result);
      // Trim the 0x prefix
      rawMessageData = result.slice(2);
    }
  } catch (e: any) {
    logger.info('RAW error message:', e.message);

    if (e.message.startsWith('Node error: ')) {
      // Trim "Node error: "
      const errorObjectStr = e.message.slice(12);
      // Parse the error object
      const errorObject = JSON.parse(errorObjectStr);

      if (!errorObject.data) {
        throw Error(`Failed to parse data field error object:${errorObjectStr}`);
      }

      if (errorObject.data.startsWith('Reverted 0x')) {
        // Trim "Reverted 0x" from the data field
        rawMessageData = errorObject.data.slice(11);
      } else if (errorObject.data.startsWith('0x')) {
        // Trim "0x" from the data field
        rawMessageData = errorObject.data.slice(2);
      } else {
        throw Error(`Failed to parse data field error object:${errorObjectStr}`);
      }
    } else {
      throw Error(`Failed to parse error message from Ethereum call: ${e.message}`);
    }
  }

  // Get the length of the revert reason
  const strLen = parseInt(rawMessageData.slice(8 + 64, 8 + 128), 16);
  // Using the length and known offset, extract and convert the revert reason
  const reasonCodeHex = rawMessageData.slice(8 + 128, 8 + 128 + strLen * 2);
  // Convert reason from hex to string
  const reason = web3.utils.hexToAscii(`0x${reasonCodeHex}`);
  return reason;
};

export function timestampToUtc(timestamp: number) {
  return new Date(timestamp * 1000).toUTCString();
}

// convert empty string or string number (100,000) to number (100000)
export const convertTextNumberToValue = (originalValue: string | number) => {
  if (typeof originalValue === 'number') {
    return originalValue;
  }

  const convertValue = Number(String(originalValue).replace(/,/g, ''));
  return Number.isNaN(convertValue) ? 0 : convertValue;
};

// convert date to utc date
export const convertDateToUtc = (date: Date): string => {
  const tzOffset = date.getTimezoneOffset();
  const utcDate = new Date(date.getTime() - tzOffset * 60 * 1000);
  return utcDate.toISOString().split('T')[0];
};

export function getProviderCredentials(providerUrl: string) {
  if (providerUrl.includes('@')) {
    const [user, password] = providerUrl.split('@')[0].replace('https://', '').replace('http://', '').split(':');

    if (!user || !password) return null;

    return {
      url: providerUrl,
      user,
      password,
    };
  }

  return null;
}

const isLocalhost = (host?: string) => host?.includes('localhost');

/**
 * This is only for getting top level domain.
 * @note - use getHostFromUrl for detecting entity with subdomain
 */
export function getTopLevelDomainByUrl(url?: string): string {
  if (!url) return '';
  if (isLocalhost(url)) return 'localhost';

  try {
    // add https protocol if not exist
    const urlWithProtocol = url.startsWith('http') || url.startsWith('ws') ? url : `https://${url}`;
    const urlObj = new URL(urlWithProtocol);
    const hostParts = urlObj.hostname.split('.');
    if (hostParts.length === 1) return hostParts[0]; // This is for localhost
    return `${hostParts[hostParts.length - 2]}.${hostParts[hostParts.length - 1]}`;
  } catch (error) {
    logger.error(error);
    logger.warn(`Find TLD - Invalid URL: ${url}`);
    return '';
  }
}

/**
 * Get host from url.
 *
 * @param {string} url
 * @example
 * getHostFromUrl('https://localhost:3000') // 'localhost'
 */
export function getHostFromUrl(url?: string): string {
  if (!url) return '';
  if (isLocalhost(url)) return 'localhost';

  try {
    // add https protocol if not exist
    const urlWithProtocol = url.startsWith('http') || url.startsWith('ws') ? url : `https://${url}`;
    const urlObj = new URL(urlWithProtocol);
    return urlObj.hostname;
  } catch (error) {
    logger.error(error);
    logger.warn(`Find host - Invalid URL: ${url}`);
    return '';
  }
}

// floating point modulo function
export function fmod(val: Big, step: Big) {
  const precision = 12;
  const valInt = Big(val.toFixed(precision).replace('.', ''));
  const stepInt = Big(step.toFixed(precision).replace('.', ''));
  return valInt.mod(stepInt).div(10 ** precision);
}

export const downloadFile = (blob: any, fileName: string) => {
  const url = window.URL.createObjectURL(new Blob([blob]));
  const link = document.createElement('a');

  link.href = url;
  link.setAttribute('download', fileName);
  link.click();
  link.parentNode?.removeChild(link);
};

export const downloadFileFromUrl = async (url: any, headers: HeadersInit, fileName: string) => {
  await fetch(url, {
    method: 'GET',
    headers,
  })
    .then((resp) => resp.blob())
    .then((blob) => downloadFile(blob, fileName));
};

export default {
  objectToQueryString,
  revealMessage,
  hideMessage,
  parseBankInfo,
  parseRegistryInfo,
  validatePasswordPolicy,
  generatePassword,
  convertArrayToURParams,
  decodeWeb3Object,
  isSameArrayDataType,
  isWeb3ReturnData,
  generateSHA256Hash,
  serialize,
  timestampToUtc,
  getRevertReason,
  getRevertReasonFromErrorMessage,
  convertTextNumberToValue,
  convertDateToUtc,
  getProviderCredentials,
  getTopLevelDomainByUrl,
  getHostFromUrl,
  fmod,
  downloadFileFromUrl,
};
