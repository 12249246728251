import { useQuery } from 'react-query';
import { CreateRequestForm } from 'refreshed-component/forms/CreateRequestForm';
import { PageNavigationHolder } from 'refreshed-component/molecules/PageNavigation';
import { PageHolder, PageSections } from 'refreshed-component/organisms/PageHolder';

import { User as UserType } from 'pages/account/block-trade/types';

import { User } from 'state/user';

export const CreateRequest = () => {
  const {
    selector: { getAuthToken },
  } = User.useContainer();

  const { data: clientList } = useQuery([`/api/user/broker/participants`], async (): Promise<UserType[]> => {
    // TODO: Implement data-provider
    const authToken = await getAuthToken();
    const response = await fetch(`/api/user/broker/participants`, {
      method: 'GET',
      headers: {
        accept: 'application/json',
        'content-type': 'application/json',
        authorization: `Bearer ${authToken}`,
      },
    });
    if (response.ok) {
      return response.json();
    }
    throw new Error(response.statusText);
  });
  return (
    <PageHolder>
      <PageSections>
        <PageNavigationHolder
          list={[
            {
              label: 'Block Trades',
              path: '/account/block-trade/incoming-requests',
            },
            {
              label: 'Outgoing Requests',
              path: '/account/block-trade/outgoing-requests',
            },
            {
              label: `Create Request`,
            },
          ]}
        />
      </PageSections>
      <PageSections className="flex flex-col justify-between w-full gap-base">
        {clientList && <CreateRequestForm clientList={clientList} />}
      </PageSections>
    </PageHolder>
  );
};
