import generateDepositReference from './accountReference';
import {
  ExchangeAssetCategory,
  AssetCategory,
  TokenAssetCategories,
  AssetCategoryCode,
  AssetCategories,
} from './assetCategory';
import BilateralTradeType from './bilateralTradeType';
import BinanceCurrency from './binanceCurrency';
import branding from './branding';
import CmbStatus from './cmbStatus';
import Const from './const';
import contractGetters from './contractGetters';
import contractParser from './contractParser';
import convert from './convert';
import DateRange from './dateRange';
import { FeeType, FeeTypesWithoutAssetCategory } from './feeType';
import { FiatDepositStatus, FiatWithdrawalStatus } from './fiatStatus';
import formatter from './formatter';
import helpers from './helpers';
import hooks from './hooks';
import { containsHtml, containsUrl } from './injection-detection';
import logger from './logger';
import matching from './matching';
import MfaType from './mfa-type';
import type { ProductPermissionType } from './productPermission';
import {
  productPermissionLabel,
  productPermissionRole,
  ProductPermissionMoleculerProps,
  productPermissionDefaultRoles,
} from './productPermission';
import SystemType from './systemType';
import AssetCategoryDto from './tdos/assetCategory';
import themes from './themes';
import TokenMintStatus from './tokenMintStatus';
import TokenRetirementStatus from './tokenRetirementStatus';
import TradeType from './tradeType';
import UserType from './userType';
import {
  WebSettingsAnnouncementInfo,
  WebSettingsAnnouncementOption,
  resolveWebSettingsAnnouncement,
} from './webSettingsAnnouncement';

export * as projects from './projects';
export * as metadata from './metadata';
export * as SmartContractTypes from './sc-types';
export * as CommonInterfaces from './interfaces';
export * as Dto from './dto/index';
export * as PDF from './pdf/index';
export * as invoices from './invoices';
export * as trades from './trades';
export * as tokens from './tokens';
export * as transfers from './transfers';
export * as apx from './apx';
export * as LocalStorage from './local-storage';

// TODO: get num decimals from x_asset
export function roundUpNumber(num: number, decimals = 2) {
  return Math.round(num * 10 ** decimals) / 10 ** decimals;
}
export function roundDownNumber(num: number, decimals = 2) {
  return Math.floor(num * 10 ** decimals) / 10 ** decimals;
}

export function calculateAbsoluteDifference(a: number, b: number) {
  // Check if both numbers are on the same side of 0
  if ((a >= 0 && b >= 0) || (a < 0 && b < 0)) {
    // Calculate the absolute difference by subtracting the smaller absolute value from the larger absolute value
    return Math.abs(Math.max(a, b) - Math.min(a, b));
  }
  // Calculate the absolute difference by adding the absolute values
  return Math.abs(a) + Math.abs(b);
}

export {
  Const,
  formatter,
  contractParser,
  contractGetters,
  hooks,
  helpers,
  themes,
  convert,
  branding,
  logger,
  matching,
  containsHtml,
  containsUrl,
  TradeType,
  MfaType,
  BilateralTradeType,
  AssetCategory,
  FeeType,
  FeeTypesWithoutAssetCategory,
  TokenAssetCategories,
  AssetCategories,
  FiatDepositStatus,
  FiatWithdrawalStatus,
  AssetCategoryDto,
  UserType,
  SystemType,
  CmbStatus,
  TokenRetirementStatus,
  TokenMintStatus,
  ProductPermissionType,
  productPermissionLabel,
  productPermissionRole,
  ProductPermissionMoleculerProps,
  productPermissionDefaultRoles,
  BinanceCurrency,
  resolveWebSettingsAnnouncement,
  generateDepositReference,
};

export type { AssetCategoryCode };

export const FeeCollectionType = {
  FIXED_AMOUNT: 'FIXED_AMOUNT',
  PERCENTAGE: 'PERCENTAGE',
  PER_THOUSAND: 'PER_THOUSAND',
} as const;

export type { ExchangeAssetCategory, DateRange, WebSettingsAnnouncementInfo, WebSettingsAnnouncementOption };
