import { ChangeEventHandler, useState } from 'react';
import { Input } from 'refreshed-component/atoms/Input';
import styled from 'styled-components';

type InputRangeProps = {
  /**
   * Label for the "From" input field
   */
  fromLabel: string;
  /**
   * Label for the "To" input field
   */
  toLabel: string;

  /**
   * Placeholder for the "From" input field
   */
  fromPlaceholder: string;

  /**
   * Placeholder for the "To" input field
   */
  toPlaceholder: string;

  /**
   * From value
   */
  from?: number;

  /**
   * To value
   */
  to?: number;

  /**
   * Handle change of from and to values
   */
  onChange(params: { from?: number; to?: number }): void;
};

export const InputRange: React.FC<InputRangeProps> = (props) => {
  const { fromLabel, toLabel, fromPlaceholder, toPlaceholder, from, to, onChange } = props;
  const [state, setState] = useState({
    from: String(from !== undefined ? from : ''),
    to: String(to !== undefined ? to : ''),
  });

  const onChangeFrom: ChangeEventHandler<HTMLInputElement> = (e) => {
    setState((prev) => ({ ...prev, from: e.target.value }));

    if (!e.target.value) {
      onChange({ to });
      return;
    }

    const newValue = Number(e.target.value);

    if (isNaN(newValue) || newValue > (to || Infinity)) {
      return;
    }

    onChange({ from: newValue, to });
  };

  const onChangeTo: ChangeEventHandler<HTMLInputElement> = (e) => {
    setState((prev) => ({ ...prev, to: e.target.value }));

    if (!e.target.value) {
      onChange({ from });
      return;
    }

    const newValue = Number(e.target.value);

    if (isNaN(newValue) || newValue < (from || -Infinity)) {
      return;
    }

    onChange({ from, to: newValue });
  };

  return (
    <StyledInputRange>
      <StyledInput
        config={{
          prefix: fromLabel,
          color: 'gray',
        }}
        value={state.from}
        placeholder={fromPlaceholder}
        onChange={onChangeFrom}
      />
      <StyledInput
        config={{
          prefix: toLabel,
          color: 'gray',
        }}
        placeholder={toPlaceholder}
        value={state.to}
        onChange={onChangeTo}
      />
    </StyledInputRange>
  );
};

const StyledInputRange = styled.div`
  display: flex;
  gap: 8px;
`;

const StyledInput = styled(Input)`
  max-width: 152px !important;
`;
