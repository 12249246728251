import React, { useState, useEffect } from 'react';
import { Colors, FontSize, FontWeight, Spacing } from 'refreshed-component/design-system';
import styled from 'styled-components';

import { hooks } from '@aircarbon/utils-common';

const Wrapper = styled.div`
  font-size: 1rem;
  font-weight: 500;
  letter-spacing: 0.5px;
  width: 100%;
  text-align: center;
  border-radius: 3px;
  display: flex;
  flex-direction: row;
  gap: var(${Spacing.base});
  justify-content: center;
  > .endLable {
    color: var(${Colors.danger_700});
    font-weight: var(${FontWeight.semibold});
    font-size: var(${FontSize.small});
  }
  > .item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    > .num {
      font-weight: var(${FontWeight.bold});
      font-size: var(${FontSize.large});
    }
    > .label {
      font-weight: var(${FontWeight.default});
      font-size: var(${FontSize.xs});
      color: var(${Colors.gray_500});
    }
  }
`;
type Props = {
  date: Date;
  endLabel?: string;
  onZero?: () => void;
};

const calculateTimeLeft = (endDate: Date, endLable: string = 'Ended') => {
  const end = new Date(endDate);
  const start = new Date();
  const diffTime = end.getTime() - start.getTime();

  const days = Math.floor(diffTime / (1000 * 3600 * 24));
  const hours = Math.floor(diffTime / (1000 * 3600)) - days * 24;
  const minutes = Math.floor((diffTime / (1000 * 60)) % 60);
  const seconds = Math.floor((diffTime / 1000) % 60);
  if (diffTime <= 0) {
    return {
      isEnded: true,
      view: <div className="endLable">{endLable}</div>,
    };
  }
  return {
    isEnded: false,
    view: (
      <>
        <div className="item">
          <div className="num">{days}</div>
          <div className="label">Days</div>
        </div>
        <div className="item">
          <div className="num">{hours}</div>
          <div className="label">Hours</div>
        </div>
        <div className="item">
          <div className="num">{minutes}</div>
          <div className="label">Minutes</div>
        </div>
        <div className="item">
          <div className="num">{seconds}</div>
          <div className="label">Seconds</div>
        </div>
      </>
    ),
  };
};

const Countdown: React.FC<Props> = ({ date, endLabel: endLabelProp, onZero }) => {
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft(date, endLabelProp));
  useEffect(() => {
    let timer: NodeJS.Timeout | undefined;
    if (timeLeft && !timeLeft.isEnded) {
      timer = setTimeout(() => {
        const newTimeLeft = calculateTimeLeft(date, endLabelProp);
        if (newTimeLeft.isEnded) {
          onZero?.();
          clearTimeout(timer);
        }
        setTimeLeft(newTimeLeft);
      }, 1000);
    }
    return () => clearTimeout(timer);
  }, [timeLeft]);

  return <Wrapper>{timeLeft.view}</Wrapper>;
};

export default Countdown;
