import { Dto } from '@aircarbon/utils-common';

interface ToAssetsAllocationsMapProps {
  assetConfigurationJSON?: string | null;
  assets: Dto.Asset[];
}

export const toAssetsAllocationsMap = (props: ToAssetsAllocationsMapProps) => {
  const { assetConfigurationJSON, assets } = props;
  const assetsAllocationsMap: Record<string, number> = {};

  try {
    if (!assetConfigurationJSON) {
      return assetsAllocationsMap;
    }

    const decodedConfiguration = JSON.parse(assetConfigurationJSON);
    const tokensByIdMap = decodedConfiguration.tokens?.reduce(
      (current: any, token: { tokenTypeId: string }) => ({
        ...current,
        [Number(token.tokenTypeId)]: token,
      }),
      {} as Record<number, any>,
    );

    assets.forEach((asset) => {
      const { scId, name } = asset;
      const token = tokensByIdMap[scId];
      if (!token) {
        return;
      }

      assetsAllocationsMap[name.toLowerCase()] = token.percentage;
    });
    return assetsAllocationsMap;
  } catch {
    return {};
  }
};
