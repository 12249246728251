import queryString from 'query-string';

import { BalanceResponse } from '@aircarbon/utils-common/src/dto/user';

import { userAuthenticatedApi } from '../fetcher';

export const fetchAccountBalances = async (userIds: number[], ccyTypeId: number) => {
  const qs = queryString.stringify({ userIds, ccyTypeId }, { arrayFormat: 'bracket' });
  const endpoint = `/user/balances?${qs}`;

  const userService = await userAuthenticatedApi();
  const res = userService.url(endpoint).get();

  return res.json<BalanceResponse[]>();
};
