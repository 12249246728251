import { UserInbox } from '@aircarbon/utils-common/src/dto';

import { userAuthenticatedApi } from '../fetcher';

export const fetchUserInbox = async (category?: string, searchFilter?: string) => {
  const endpoint = '/user/user/user-inbox';
  const userService = await userAuthenticatedApi();
  const res = userService.url(endpoint).query({ category, searchFilter }).get();
  return res.json<UserInbox[]>();
};
