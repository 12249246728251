import { ApiResponse, externalApi } from '../fetcher';

export type FooterMenuItem = {
  id: string;
  name: string;
  url: string;
  openInNewPage: boolean;
};

export type BrandingEntity = {
  id: number;
  name: string;
  hostname: string;
  displayName: string;
  logoUrl: string;
  country: string;
  theme: {
    admin: {
      favFile: string;
      logoUrl: string;
      brandColor: string;
      brandRbgColor: string;
      backgroundColor: string;
      secondaryBackgroundColor: string;
      secondaryColor: string;
    };
    web: {
      manifestFile: string;
      favFile: string;
      logoWhite: string;
      sliderHandleImage: string;
      brandColor: string;
      brandRbgColor: string;
      backgroundColor: string;
      secondaryBackgroundColor: string;
      secondaryColor: string;
      headerBackgroundColor: string;
      headerNavLinkColor: string;
      footerBackgroundColor: string;
      footerNavLinkColor: string;
      footerTextColor: string;
      trading: any;
      logoSidebarDark: string;
      logoSidebarLight: string;
      logoSidebarMiniDark: string;
      logoSidebarMiniLight: string;
      authBrandImageDark: string;
      authBrandImageLight: string;
      errorPageImageDark: string;
      errorPageImageLight: string;
    };
    colorsDark: { [index: string]: string };
    colorsLight: { [index: string]: string };
  };
  themeCode: string;
  bankAccount?: string;
  config?: {
    name: string;
    phone: string;
    address: string;
    description: string;
    helpEnable: string;
    registerLink: string;
    email: {
      info: string;
      support: string;
      onboarding: string;
      operations: string;
      trusteeFiat: string;
    };
    footer: {
      loggedIn: FooterMenuItem[];
      loggedOut: FooterMenuItem[];
    };
  };
  mainCcyScId: number;
  mainCcyCode: string;
  mainCcySymbol: string;
  mainCcyNumDecimals: number;
};

export const fetchBrandingEntity = () => {
  const res = externalApi.get('/entity');
  return res.json<
    ApiResponse<{
      entity: BrandingEntity;
    }>
  >();
};
