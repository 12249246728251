import { useQuery } from 'react-query';

import { Asset } from '@aircarbon/utils-common/src/dto';

import { fetchCurrencies } from 'data-provider/contract/fetchCurrencies';

function useCurrencies({ options }: { options?: Record<string, any> } = {}) {
  const { data, isLoading, error } = useQuery('/api/currencies', () => fetchCurrencies(), {
    ...(options ?? {}),
    refetchOnWindowFocus: false,
  });

  const currencies = data?.data ?? [];

  const activeCurrenciesBySymbol = () =>
    currencies.filter((currency) => currency.enabled).map((currency) => currency.symbol);

  const currenciesByScId = () =>
    currencies.reduce((obj: Record<number, Asset>, currency) => ({ ...obj, [currency.scId]: currency }), {});

  const currenciesById = () =>
    currencies.reduce((obj: Record<number, Asset>, currency) => ({ ...obj, [currency.id]: currency }), {});

  const activeCurrenciesScIds = () =>
    currencies.filter((currency) => currency.enabled).map((currency) => Number(currency.scId));

  return {
    currencies,
    currenciesByScId,
    activeCurrenciesBySymbol,
    activeCurrenciesScIds,
    currenciesById,
    isLoading,
    error,
  };
}

export default useCurrencies;
