import * as yup from 'yup';

import { ProjectFormValue } from '../../ProjectForm';

const formValidationSchemas = [
  yup.object().shape(
    {
      projectName: yup.string().required('Please provide Project Name'),
      projectDescription: yup.string(),
      projectLocation: yup.string().required('Please specify Project Location'),
      projectPhase: yup.string().required('Please specify Project Phase'),
      standardUsed: yup.string().required('Please specify Standard Used / Registry'),
      projectId: yup.string(),
      projectType: yup.string().required('Please specify Project Type'),
      methodology: yup.string().when('customMethodology', {
        is: '',
        then: (schema) => schema.required('Please specify methodology'),
      }),
      customMethodology: yup
        .string()
        .when('methodology', { is: '', then: (schema) => schema.required('Please specify methodology') }),
    },
    [['methodology', 'customMethodology']],
  ),
  yup.object().shape({
    proponents: yup
      .array()
      .of(
        yup.object().shape({
          companyName: yup.string().required('Please provide Company Name'),
          companyInvolvement: yup.string().required('Please specify Company Involvement'),
          contactPersonName: yup.string().required("Please provide Contact Person's Name"),
          contactPersonRole: yup.string().required("Please provide Contact Person's Role"),
          email: yup
            .string()
            .email('Please provide a valid Email address')
            .required('Please provide a valid Email address'),
          phone: yup.object().shape({
            value: yup.string().required('Please provide phone number'),
          }),
        }),
      )
      .min(1)
      .required('Please provide at least 1 proponent'),
  }),
  yup.object().shape({
    implementationDate: yup.string().required('Please provide an implementation date'),
    vintages: yup
      .array()
      .of(
        yup.object().shape({
          year: yup.string().required(),
          offeredQty: yup.string().required('Please provide offered quantity'),
        }),
      )
      .min(1, 'Please provide Vintage Start and End years')
      .required('Please provide Vintage Start and End years'),
  }),
  yup.object().shape({
    documents: yup
      .object()
      .shape({
        projectIdeaNote: yup
          .object()
          .shape({
            name: yup.string().required('Please upload Project Idea Note (PIN) document'),
          })
          .nullable()
          .required('Please upload Project Idea Note (PIN) document'),
      })
      .required(),
  }),
];

export const toErrors = (props: {
  formValue: ProjectFormValue;
}): Array<Partial<Record<keyof ProjectFormValue, string>>> => {
  return formValidationSchemas.map((schema) => {
    if (schema === null) {
      return {};
    }

    try {
      schema.validateSync(props.formValue, {
        abortEarly: false,
      });
      return {};
    } catch (e) {
      return (e as yup.ValidationError).inner.reduce((acc, error) => {
        return {
          ...acc,
          [error.path as string]: error.errors[0],
        };
      }, {});
    }
  });
};
