import * as React from 'react';
import Popover from 'refreshed-component/atoms/Popover';
import { Colors, Radius, Spacing } from 'refreshed-component/design-system';
import styled from 'styled-components';

import { Text, TextColor, TypographyVariant } from '@aircarbon/ui';

import { FooterMenuItem } from 'data-provider/entity';

const MenuRoot = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: var(${Radius.medium});
  border: 1px solid var(${Colors.gray_300}, #d1d5db);
  background: var(${Colors.gray_0}, #fff);
  box-shadow:
    0px 4px 6px 0px rgba(0, 0, 0, 0.05),
    0px 10px 15px -3px rgba(0, 0, 0, 0.1);
  padding: var(${Spacing.base});
  gap: var(${Spacing.base});
  .item {
    display: flex;
    flex-direction: row;
    gap: var(${Spacing.small});
    white-space: pre;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
`;

interface Props {
  items: FooterMenuItem[];
}

const FooterLinks: React.FC<Props> = ({ items }) => {
  return (
    <>
      <Popover
        trigger={'click'}
        placement={'bottomRight'}
        view="transparent"
        content={() => (
          <MenuRoot>
            {items.map((item) => (
              <a
                key={`a-${item.name}`}
                href={item.url}
                target={item.openInNewPage ? '_blank' : '_self'}
                rel="noopener noreferrer"
              >
                <Text variant={TypographyVariant.body2} className="item" key={item.name}>
                  {item.name}
                </Text>
              </a>
            ))}
          </MenuRoot>
        )}
      >
        {() => {
          return (
            <div className="cursor-pointer item">
              <Text variant={TypographyVariant.body2} color={TextColor.secondary}>
                Information
              </Text>
            </div>
          );
        }}
      </Popover>
    </>
  );
};

export default FooterLinks;
