import _ from 'lodash';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { StepStatus } from 'refreshed-component/atoms/Step';

import { wait } from '../../../../utils/wait';
import { fetchProjectById } from '../../../utils/fetchProjectById';
import { ProjectFormValue } from '../../ProjectForm';
import { createProject } from '../utils/createProject';
import { initialProjectFormValue } from '../utils/initialProjectFormValue';
import { saveProject } from '../utils/saveProject';
import { submitProject } from '../utils/submitProject';
import { toErrors } from '../utils/toErrors';
import { toFormValue } from '../utils/toFormValue';
import { toNewProjectApiBody } from '../utils/toNewProjectApiBody';
import { toUpdateProjectApiBody } from '../utils/toUpdateProjectApiBody';

interface Project {
  id: number | null;
  name?: string;
  createdAt?: string;
  updatedAt?: string;
}

interface UseProjectCreatorHook {
  project?: Project;
  formTitle: string;
  formValue: ProjectFormValue;
  formSteps: Array<{
    label: string;
    value: string;
    status: StepStatus;
  }>;
  formErrors: Array<Partial<Record<keyof ProjectFormValue, string>>>;
  activeFormStep: number;
  isSaveVisible: boolean;
  isSaveButtonDisabled: boolean;
  isSaveButtonLoading: boolean;
  saveButtonLabel: string;
  isPreviousButtonVisible: boolean;
  isNextButtonVisible: boolean;
  isNextButtonLoading: boolean;
  isSubmitButtonVisible: boolean;
  isSubmitButtonLoading: boolean;
  isSavingProject: boolean;
  isFormSubmitted: boolean;
  isLoading: boolean;
  changeFormValue(newValue: Partial<ProjectFormValue>): void;
  goToNextStep(): void;
  goToPreviousStep(): void;
  goToStep(stepIndex: number): void;
  submitForm(): void;
}

export const useProjectCreator = (projectId?: string): UseProjectCreatorHook => {
  const history = useHistory();
  const [formState, setFormState] = useState<'fetchingProject' | 'creatingProject' | 'submittingProject' | 'idle'>(
    'idle',
  );
  const [project, setProject] = useState<Project>({
    id: null,
  });
  const [formValue, setFormValue] = useState<ProjectFormValue>(initialProjectFormValue);
  const [prevFormValue, setPrevFormValue] = useState<ProjectFormValue>(initialProjectFormValue);
  const [activeFormStep, setActiveFormStep] = useState(0);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [isSavingProject, setIsSavingProject] = useState(false);
  const [saveButtonLabel, setSaveButtonLabel] = useState('');
  const [formErrors, setFormErrors] = useState<Array<Partial<Record<keyof typeof formValue, string>>>>([]);

  useEffect(() => {
    if (!projectId) {
      return;
    }

    const fetchProject = async () => {
      setFormState('fetchingProject');

      const response = await fetchProjectById({
        projectId,
      });

      if (!response) {
        // TODO: show error or redirect to the new project page
        return;
      }

      const formValueFromProject = toFormValue(response.data);

      setFormValue(formValueFromProject);
      setPrevFormValue(formValueFromProject);
      setProject({
        id: response.data.id || null,
        name: response.data.name,
        createdAt: response.data.createdAt ? moment(response.data.createdAt).fromNow() : undefined,
        updatedAt: response.data.updatedAt ? moment(response.data.updatedAt).fromNow() : undefined,
      });
      setFormState('idle');
    };

    fetchProject();
  }, [projectId]);

  useEffect(() => {
    setFormErrors((prevErrors) => {
      if (!prevErrors.length) {
        return prevErrors;
      }
      const newErrors = toErrors({
        formValue,
      });

      return newErrors;
    });
  }, [formValue]);

  const formTitle = project.name || 'List new project';

  const formSteps = useMemo(() => {
    const formErrors = toErrors({ formValue });

    return [
      {
        label: 'Basic Information',
        value: 'basic-information',
        status:
          activeFormStep === 0
            ? StepStatus.Active
            : Object.keys(formErrors[0]).length
            ? StepStatus.Current
            : StepStatus.Completed,
      },
      {
        label: 'Proponents',
        value: 'proponents',
        status:
          activeFormStep === 1
            ? StepStatus.Active
            : Object.keys(formErrors[1]).length
            ? StepStatus.Current
            : StepStatus.Completed,
      },
      {
        label: 'Milestones',
        value: 'milestones',
        status:
          activeFormStep === 2
            ? StepStatus.Active
            : Object.keys(formErrors[2]).length
            ? StepStatus.Current
            : StepStatus.Completed,
      },
      {
        label: 'Documentation',
        value: 'documentation',
        status:
          activeFormStep === 3
            ? StepStatus.Active
            : Object.keys(formErrors[3]).length
            ? StepStatus.Current
            : StepStatus.Completed,
      },
    ];
  }, [activeFormStep, formValue]);

  const isFormValueChanged = useMemo(() => {
    return !_.isEqual(prevFormValue, formValue);
  }, [prevFormValue, formValue]);

  useEffect(() => {
    let cancelableSaveProject: ReturnType<typeof wait>;

    const autosaveProject = async () => {
      if (!isFormValueChanged || !project.id) {
        return;
      }

      setIsSavingProject(true);
      setSaveButtonLabel('Saving...');
      cancelableSaveProject = wait(
        async () =>
          saveProject({
            params: toUpdateProjectApiBody({ projectId: project.id as number, formValue }),
          }),
        300,
      );
      const savedProject = (await cancelableSaveProject.job) as
        | {
            id: string;
            name: string;
            updatedAt: string;
          }
        | false;

      if (savedProject) {
        setPrevFormValue(formValue);
        setProject((prevProject) => ({
          ...prevProject,
          name: savedProject.name,
          updatedAt: moment(savedProject.updatedAt).fromNow(),
        }));
        setSaveButtonLabel('');
      }

      setIsSavingProject(false);
    };

    autosaveProject();

    return () => {
      if (!isFormValueChanged) {
        return;
      }
      if (cancelableSaveProject) {
        setIsSavingProject(false);
        setSaveButtonLabel('');
        cancelableSaveProject.cancel();
      }
    };
  }, [isFormValueChanged, formValue, project.id]);

  const isSaveVisible = !!project.id; // Should be visible after project is created
  const isSaveButtonDisabled = !isFormValueChanged;
  const isSaveButtonLoading = isSavingProject;
  const isNextButtonVisible = activeFormStep < 3; // Should not be visible once user reached the last form step
  const isPreviousButtonVisible = activeFormStep !== 0; // Should be visible when user is not on the first step

  const isNextButtonLoading = formState === 'creatingProject';
  const isSubmitButtonVisible = !!project.id; // should be set to true once project is created
  const isSubmitButtonLoading = formState === 'submittingProject'; // should be set to true once form state is equal to 'submittingProject'
  const isLoading = formState === 'fetchingProject';

  const changeFormValue = (newValue: Partial<ProjectFormValue>) => {
    setFormValue((prevValue) => ({
      ...prevValue,
      ...newValue,
    }));
  };

  const goToNextStep = async () => {
    if (formState !== 'idle') {
      return;
    }

    // if on first step and didn't save the project yet then save the project first
    if (!project.id) {
      setFormState('creatingProject');

      const formErrors = toErrors({ formValue });

      // If form is invalid then show form errors
      if (Object.keys(formErrors?.[0])?.length) {
        setFormErrors(formErrors);
        setFormState('idle');
        return;
      }

      setFormErrors([]);
      const newProject = await createProject({
        params: toNewProjectApiBody(formValue),
      });

      if (!newProject) {
        // todo: show error mesage of unsuccessful project creation
        setFormState('idle');

        return;
      }

      setProject({
        id: newProject.id,
      });
      setActiveFormStep((prevActive) => prevActive + 1);
      setPrevFormValue(formValue);
      setFormState('idle');
      history.replace(`/account/apx/my-projects/${newProject.id}/edit`);
      return;
    }
    setActiveFormStep((prevActive) => prevActive + 1);
  };

  const goToPreviousStep = () => {
    setActiveFormStep((prevActive) => prevActive - 1);
  };

  const goToStep = (stepIndex: number) => {
    setActiveFormStep(stepIndex);
  };

  const submitForm = async () => {
    if (formState !== 'idle') {
      return;
    }

    setFormState('submittingProject');

    const formErrors = toErrors({ formValue });

    const hasErrors = !!formErrors.filter((error) => !!Object.keys(error).length).length;
    const firstErrorStepIndex = formErrors.findIndex((error) => !!Object.keys(error).length);
    // If form is invalid then change the first active form step that contains the error
    if (hasErrors) {
      setFormErrors(formErrors);
      setActiveFormStep(firstErrorStepIndex);
      setFormState('idle');
      return;
    }

    setFormErrors([]);

    if (isFormValueChanged) {
      const isProjectSaved = await saveProject({
        params: toUpdateProjectApiBody({ projectId: project.id as number, formValue }),
      });

      if (!isProjectSaved) {
        setFormState('idle');
        // show form saving error
        return;
      }

      setPrevFormValue(formValue);
    }

    const isProjectSubmitted = await submitProject({
      id: project.id as number,
    });

    setFormState('idle');

    if (!isProjectSubmitted) {
      // show project submission error
      return;
    }

    setIsFormSubmitted(true);
  };

  return {
    project,
    formTitle,
    formSteps,
    formValue,
    formErrors,
    saveButtonLabel,
    activeFormStep,
    isLoading,
    isSaveVisible,
    isSaveButtonDisabled,
    isSaveButtonLoading,
    isPreviousButtonVisible,
    isNextButtonVisible,
    isNextButtonLoading,
    isSubmitButtonVisible,
    isSubmitButtonLoading,
    isFormSubmitted,
    isSavingProject,
    changeFormValue,
    goToNextStep,
    goToPreviousStep,
    goToStep,
    submitForm,
  };
};
