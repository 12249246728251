import { Button } from 'refreshed-component/atoms/Button';
import { Empty } from 'refreshed-component/atoms/Empty';
import { Icon, IconType } from 'refreshed-component/atoms/Icon';
import { Text } from 'refreshed-component/atoms/Text';
import { Tooltip } from 'refreshed-component/atoms/Tooltip';
import { Colors, FontSize, FontWeight } from 'refreshed-component/design-system';
import PlaceBid from 'refreshed-component/forms/PlaceBid';
import Loading from 'refreshed-component/molecules/Loading';
import Modal from 'refreshed-component/molecules/Modal';

import { formatter } from '@aircarbon/utils-common';

import { CmbAsk } from 'pages/account/carbon/CMB/types';
import useMarketSettings from 'pages/account/trading/hooks/useMarketSettings';

import { Entity } from 'state/entity';
import { UI } from 'state/ui';
import { User } from 'state/user';

import useAvailableBalance from 'hooks/useAvailableBalance';
import useCurrencies from 'hooks/useCurrencies';

import { MarketPlaceProjectCard } from './MarketPlaceProjectCard';

export const MarketPlaceList = ({ items, isLoading }: { items: CmbAsk[]; isLoading: boolean }) => {
  const {
    status: { canBidCmbAsk },
  } = User.useContainer();
  const {
    selector: { mainCcyScId },
  } = Entity.useContainer();

  const { marketSettings, isLoading: isLoadingMarketSettings } = useMarketSettings({});
  const { getSetting } = UI.useContainer();

  const asks = items ?? [];
  const genericAssetSymbol = getSetting('global_genericCarbonAssetSymbol');
  const depositGenericToken = getSetting('global_emb_depositGenericToken');
  const placeBidDeposit = getSetting('global_emb_placeBidDeposit') ?? 0;
  const acceptedBidDeposit = getSetting('global_emb_acceptedBidDeposit') ?? 0;
  const bidPriceMinPercentage = getSetting('global_emb_bidPriceMinPercentage');

  const { balance } = useAvailableBalance({
    params: { ccyTypeId: mainCcyScId, getRoot: false },
  });
  const { currenciesById } = useCurrencies();
  const currenciesObjById = currenciesById();

  if (isLoading || isLoadingMarketSettings) {
    return (
      <div className="flex flex-col w-full gap-large">
        <div className="p-large">
          <Loading isOverLay={false} />
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col w-full gap-large">
      {asks && asks.length > 0 ? (
        asks.map((ask: CmbAsk) => {
          const carbonProject = (ask.__carbonProject__ as any | undefined) || {};
          const tokenAsset = (ask.__tokenAsset__ as any | undefined) || {};
          const tokenUnit = tokenAsset?.__uom__?.name ?? '';
          const ccyAsset = currenciesObjById?.[ask?.quoteAssetId];
          return (
            <MarketPlaceProjectCard
              key={ask.id}
              info={{
                carbonProject,
                tokenAsset,
                id: `Offer #${ask.id}`,
                carbonProjectVintageYear: ask?.carbonProjectVintageYear,
              }}
              controls={
                <>
                  <div className="flex flex-row justify-between whitespace-pre gap-base">
                    <div className="flex flex-col">
                      <Text color={Colors.gray_500} size={FontSize.small}>
                        Available Qty
                      </Text>
                      <Text color={Colors.gray_900} size={FontSize.small} weight={FontWeight.semibold}>
                        {formatter.formatNumber(ask?.quantity, 0)}
                        <Text color={Colors.gray_500} size={null}>
                          {tokenUnit}
                        </Text>
                      </Text>
                    </div>
                    <div className="flex flex-col">
                      <Text
                        color={Colors.gray_500}
                        size={FontSize.small}
                        postfix={{
                          view: <Tooltip text={`Price (per ${tokenUnit})`} />,
                        }}
                      >
                        Price (per {tokenUnit})
                      </Text>
                      <Text color={Colors.gray_900} size={FontSize.small} weight={FontWeight.semibold}>
                        {ccyAsset?.code}
                        {formatter.formatNumber(ask?.price, ccyAsset?.numDecimals)}{' '}
                        <Text color={Colors.gray_500} size={null}>
                          {ccyAsset?.symbol}
                        </Text>
                      </Text>
                    </div>
                  </div>
                  <div className="flex flex-col gap-base">
                    {canBidCmbAsk() && (
                      <Modal
                        title={'Place Bid'}
                        action={
                          <Button
                            className="flex-1"
                            disabled={marketSettings?.otcEntryEnabled === 0}
                            config={{
                              color: 'primary',
                              size: 'base',
                            }}
                          >
                            Place Bid
                          </Button>
                        }
                      >
                        {({ onClose, onLoading }) => {
                          const generalDepositPercentage =
                            Number(placeBidDeposit) > 0 ? placeBidDeposit : acceptedBidDeposit;
                          const isGenericAsset =
                            genericAssetSymbol && genericAssetSymbol === ask?.__tokenAsset__?.symbol;
                          const depositPercentage = isGenericAsset ? depositGenericToken : generalDepositPercentage;
                          const tokenAsset = ask?.__tokenAsset__;
                          const tokenUnit = tokenAsset?.__uom__?.name ?? '';
                          const minBid = ask?.price - (ask?.price * Number(bidPriceMinPercentage)) / 100;
                          return (
                            <PlaceBid
                              ask={ask}
                              tokenAssetCategoryId={tokenAsset?.assetCategoryId}
                              minQty={ask?.minBidQty}
                              maxQty={ask?.maxBidQty}
                              bidQtyMultiplier={ask?.bidQtyMultiplier}
                              minBid={minBid}
                              availableBalance={balance?.availableAmount ?? 0}
                              depositPercentage={Number(depositPercentage ?? 0)}
                              tokenUnit={tokenUnit}
                              onLoading={onLoading}
                              onSuccess={() => onClose()}
                            />
                          );
                        }}
                      </Modal>
                    )}
                    <Button
                      className="flex-1"
                      config={{
                        color: 'outlined',
                        size: 'base',
                      }}
                      onClick={() => {
                        window.open(`mailto:markets@aircarbon.co?subject=Request Information CMB Offer ${ask.id}`);
                      }}
                    >
                      Request Information <Icon type={IconType.InformationCircleFilled} width={14} height={14} />
                    </Button>
                  </div>
                </>
              }
            />
          );
        })
      ) : (
        <Empty title="No listing available" description="There are no market board listing available currently." />
      )}
    </div>
  );
};
