import { useQuery } from 'react-query';

import { AssetCategory, AssetCategoryCode } from '@aircarbon/utils-common';
import { Asset } from '@aircarbon/utils-common/src/dto';

import { fetchTokenTypes } from 'data-provider/contract/fetchTokenTypes';

function useTokenTypes({
  assetCategories,
  options,
}: { assetCategories?: Array<AssetCategoryCode>; options?: Record<string, any> } = {}) {
  const {
    data,
    isLoading: isLoadingTokenTypes,
    error,
  } = useQuery(['/api/token/types', assetCategories], () => fetchTokenTypes(assetCategories), {
    ...(options ?? {}),
    refetchOnWindowFocus: false,
  });

  const tokenTypes = data?.data ?? [];

  const activeTokenTypesNonGER = () =>
    tokenTypes?.filter((type) => !type.isBasket && !type.isBasketUnderlyer && type.enabled);

  const tokenTypesGER = () => tokenTypes?.filter((type) => type.isBasket || type.isBasketUnderlyer);

  const activeTokenTypesScIdsNonGER = () =>
    tokenTypes?.filter((type) => !type.isBasket && !type.isBasketUnderlyer && type.enabled).map((type) => type.scId);

  const activeTokenTypes = () => tokenTypes?.filter((type) => type.enabled);

  const tokenTypesByScId = () =>
    tokenTypes.reduce((obj: Record<number, Asset>, tokenType) => ({ ...obj, [tokenType.scId]: tokenType }), {});

  return {
    tokenTypes,
    tokenTypesByScId,
    tokenTypesGER,
    activeTokenTypesNonGER,
    activeTokenTypesScIdsNonGER,
    activeTokenTypes,
    isLoadingTokenTypes,
    error,
  };
}

export default useTokenTypes;
