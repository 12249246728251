import format from 'date-fns/format';
import { useMemo, useState } from 'react';
import { useMutation, queryCache } from 'react-query';
import { useHistory } from 'react-router-dom';
import { Button } from 'refreshed-component/atoms/Button';
import { Card } from 'refreshed-component/atoms/Card';
import { Dropdown, DropdownItem } from 'refreshed-component/atoms/Dropdown';
import { Empty } from 'refreshed-component/atoms/Empty';
import { Icon as IconLegacy, IconType } from 'refreshed-component/atoms/Icon';
import { Text } from 'refreshed-component/atoms/Text';
import { Colors, FontSize, FontWeight } from 'refreshed-component/design-system';
import FiatDepositForm from 'refreshed-component/forms/FiatDepositForm';
import FiatWithdrawForm from 'refreshed-component/forms/FiatWithdrawForm';
import { ConfirmModal } from 'refreshed-component/molecules/ConfirmModal';
import Loading from 'refreshed-component/molecules/Loading';
import Modal from 'refreshed-component/molecules/Modal';
import { Pagination } from 'refreshed-component/molecules/Pagination';
import { SummaryCard } from 'refreshed-component/molecules/SummaryCard';
import { SummaryHolder } from 'refreshed-component/molecules/SummaryHolder';
import { toast } from 'refreshed-component/molecules/toast';
import { PageControls } from 'refreshed-component/organisms/PageControls';
import { PageHolder, PageSections } from 'refreshed-component/organisms/PageHolder';
import { Table } from 'refreshed-component/templates/Table';

import { Icon, IconName, TextColor } from '@aircarbon/ui';
import { AssetCategory, AssetCategoryCode, logger } from '@aircarbon/utils-common';
import { formatter, generateDepositReference } from '@aircarbon/utils-common';

import useMarketSettings from 'pages/account/trading/hooks/useMarketSettings';
import { usePairs } from 'pages/account/trading/hooks/usePairs';

import { Account } from 'state/account';
import { Entity } from 'state/entity';
import { UI } from 'state/ui';
import { User } from 'state/user';

import useAccountBalances from 'hooks/useAccountBalances';
import { FiatHistory, Status, StatusLabel, useFiatHistory } from 'hooks/useFiatHistory';

const { formatNumber } = formatter;

export const Cash = () => {
  const history = useHistory();
  const { consolidatedTokens, refetchAccountData, accountUsers } = Account.useContainer();
  const {
    rootUser,
    selector: { getAuthToken, getUserRootId, getUserId, getAccountAddress, isBankAccountApproved },
    status: { canDeposit, canWithdraw, hasBankAccount },
  } = User.useContainer();
  const {
    selector: { mainCcyCode, mainCcyNumDecimals, mainCcySymbol, availableCurrencies },
  } = Entity.useContainer();
  const { getSetting } = UI.useContainer();

  const depositAccountReferencePrefix = getSetting('web_settings_depositAccountReferencePrefix');
  const accountReference = generateDepositReference({
    rootUserId: getUserRootId(),
    tradingName: rootUser?.tradingName,
    accountReferencePrefix: depositAccountReferencePrefix,
  });
  const { marketSettings, isLoading: isLoadingMarketSettings } = useMarketSettings({});

  const {
    balances,
    selector: { totalOpenQuantities },
  } = useAccountBalances(accountUsers.filter((user) => !!user.account).map((user) => user.user_id));

  const [depositStatusDropdownItem, setStatusDropdownItemForDeposit] = useState<DropdownItem | null>(null);
  const [withdrawStatusDropdownItem, serStatusDropdownItemForWithdraw] = useState<DropdownItem | null>(null);

  const { data: fiatDepositHistory, pagination: fiatDepositPagination } = useFiatHistory(
    FiatHistory.Deposit,
    depositStatusDropdownItem?.id ? [depositStatusDropdownItem.id as Status] : [],
  );
  const { data: fiatWithdrawHistory, pagination: fiatWithdrawPagination } = useFiatHistory(
    FiatHistory.Withdraw,
    withdrawStatusDropdownItem?.id ? [withdrawStatusDropdownItem.id as Status] : [],
  );

  const { getPairByAssetId } = usePairs({
    assetCategories: [AssetCategory[AssetCategory.token] as AssetCategoryCode],
    includeMarketData: true,
  });

  const filteredBalances = useMemo(() => balances?.filter((item) => !!item.accountAddress), [balances]);

  const totalCash =
    useMemo(
      () =>
        filteredBalances?.reduce((previousValue: number, currentValue: { balance: number }) => {
          return previousValue + currentValue.balance;
        }, 0),
      [filteredBalances],
    ) ?? 0;

  const totalOpenCash = useMemo(
    () =>
      filteredBalances?.reduce(
        (previousValue: number, currentValue: { balance: number; availableAmount: number }): number => {
          const openAmount = currentValue?.balance - currentValue?.availableAmount ?? 0;
          return previousValue + openAmount;
        },
        0,
      ),
    [filteredBalances],
  );

  const assetValue = Object.values(consolidatedTokens).reduce((previousValue, currentValue) => {
    const pair = getPairByAssetId(currentValue.asset.id);
    const qty = currentValue.qty || 0;
    return previousValue + (pair?.marketData?.referencePrice || 0) * qty;
  }, 0);

  const openAssetValue = useMemo(
    () =>
      Object.values(consolidatedTokens).reduce((previousValue, currentValue) => {
        const pair = getPairByAssetId(currentValue.asset.id);
        const qty = totalOpenQuantities({ symbol: pair?.symbol }) || 0;

        return previousValue + (pair?.marketData?.referencePrice || 0) * qty;
      }, 0),
    [consolidatedTokens, getPairByAssetId, totalOpenQuantities],
  );

  const totalAssetQty = Object.values(consolidatedTokens).reduce((previousValue, currentValue) => {
    const qty = currentValue.qty || 0;
    return previousValue + (currentValue.isCurrency ? 0 : qty);
  }, 0);

  const totalOpenAsset = useMemo(() => totalOpenQuantities({}), [totalOpenQuantities]);

  const [addDeposit] = useMutation(
    async (formData: Record<string, any>) => {
      // TODO: Implement data-mutation
      const authToken = await getAuthToken();
      return fetch(`/api/user/fiat/deposit-user-notify`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          authorization: `Bearer ${authToken}`,
        },
        body: JSON.stringify({
          amount: Number(formData.amount),
          ccyTypeId: Number(formData.ccyTypeId),
          userId: getUserId(),
          account: getAccountAddress(),
          depositRef: accountReference,
        }),
      });
    },
    {
      onSuccess: async (data) => {
        if (data.ok) {
          queryCache.invalidateQueries('deposit');
          await refetchAccountData();
          toast.success('Thank you. We will be expecting your deposit.');
        } else {
          logger.warn(data);
          const error = await data.json();
          toast.error(error?.message ?? 'Something went wrong!');
          logger.warn({ error });
        }
      },
      onError: (error: Error) => {
        toast.error(error?.message ?? 'Something went wrong!');
        logger.error(error);
      },
    },
  );

  const [addWithdraw] = useMutation(
    async (formData: Record<string, any>) => {
      // TODO: Implement data-mutation
      const authToken = await getAuthToken();
      return fetch(`/api/user/fiat/withdraw-user-notify`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          authorization: `Bearer ${authToken}`,
        },
        body: JSON.stringify({
          ...formData,
          amount: Number(formData.amount),
          ccyTypeId: Number(formData.ccyTypeId),
          userId: getUserId(),
          account: getAccountAddress(),
        }),
      });
    },
    {
      onSuccess: async (data) => {
        if (data.ok) {
          queryCache.invalidateQueries('withdraw');
          await refetchAccountData();
          toast.default('Submitting withdrawal request. It may take several seconds.');
          return;
        }
        const error = await data.json();
        logger.error(error);
        toast.error(error?.message ?? 'Something went wrong!');
      },
      onError: (error: Error) => {
        toast.error(error?.message ?? 'Something went wrong!');
        logger.error(error);
      },
    },
  );

  if (isLoadingMarketSettings) return <Loading />;

  return (
    <PageHolder>
      <PageSections>
        <PageControls
          title="Overview"
          controls={{
            secondary: (
              <>
                {canDeposit() && isBankAccountApproved() ? (
                  <Modal
                    title={'Deposit'}
                    action={
                      <Button
                        config={{
                          color: 'outlined',
                        }}
                        disabled={marketSettings?.fiatEntryEnabled === 0}
                      >
                        Deposit
                        <IconLegacy type={IconType.Deposit} width={20} height={20} />
                      </Button>
                    }
                  >
                    {({ onClose }) => (
                      <FiatDepositForm
                        onSubmit={(
                          formData: Record<string, any>,
                          handlers: { onSuccess?: (() => void) | undefined } | null | undefined,
                        ) => {
                          addDeposit(formData, {
                            onSuccess: (data) => {
                              onClose();
                              if (data.ok && handlers?.onSuccess) {
                                handlers?.onSuccess();
                              }
                            },
                          });
                        }}
                        accountReference={accountReference}
                      />
                    )}
                  </Modal>
                ) : (
                  <ConfirmModal
                    title="Bank Account"
                    action={
                      <Button
                        config={{
                          color: 'outlined',
                        }}
                        disabled={marketSettings?.fiatEntryEnabled === 0}
                      >
                        Deposit
                        <IconLegacy type={IconType.Deposit} width={20} height={20} />
                      </Button>
                    }
                    accept={{
                      label: 'Go to Bank Details',
                      icon: 'no-icon',
                      callback() {
                        history.push('/account/company-settings/bank-details');
                        return false;
                      },
                    }}
                    cancel={{
                      label: 'Cancel',
                      icon: 'no-icon',
                    }}
                  >
                    <div className="flex flex-col items-center gap-base">
                      <Text align="center" color={Colors.gray_500} size={FontSize.base} weight={FontWeight.medium}>
                        {!hasBankAccount() && 'Please fill your bank details first.'}
                        {hasBankAccount() &&
                          !isBankAccountApproved() &&
                          'Please wait for your bank account to be approved.'}
                      </Text>
                    </div>
                  </ConfirmModal>
                )}
                {canWithdraw() && isBankAccountApproved() ? (
                  <Modal
                    title={'Withdraw'}
                    action={
                      <Button disabled={marketSettings?.fiatEntryEnabled === 0}>
                        Withdraw
                        <IconLegacy type={IconType.Withdraw} width={20} height={20} />
                      </Button>
                    }
                  >
                    {({ onClose }) => (
                      <FiatWithdrawForm
                        onSubmit={(formData: Record<string, any>, resetForm: () => void) => {
                          addWithdraw(formData, {
                            onSuccess: (data) => {
                              onClose();
                              if (data.ok && resetForm) {
                                resetForm();
                              }
                            },
                          });
                        }}
                      />
                    )}
                  </Modal>
                ) : (
                  <ConfirmModal
                    title="Bank Account"
                    action={
                      <Button disabled={marketSettings?.fiatEntryEnabled === 0}>
                        Withdraw
                        <IconLegacy type={IconType.Withdraw} width={20} height={20} />
                      </Button>
                    }
                    accept={{
                      label: 'Go to Bank Details',
                      icon: 'no-icon',
                      callback() {
                        history.push('/account/company-settings/bank-details');
                        return false;
                      },
                    }}
                    cancel={{
                      label: 'Cancel',
                      icon: 'no-icon',
                    }}
                  >
                    <div className="flex flex-col items-center gap-base">
                      <Text align="center" color={Colors.gray_500} size={FontSize.base} weight={FontWeight.medium}>
                        {!hasBankAccount() && 'Please fill your bank details first.'}
                        {hasBankAccount() &&
                          !isBankAccountApproved() &&
                          'Please wait for your bank account to be approved.'}
                      </Text>
                    </div>
                  </ConfirmModal>
                )}
              </>
            ),
          }}
        />
      </PageSections>
      <PageSections>
        <SummaryHolder>
          <SummaryCard
            title="Available Balance"
            value={`${mainCcyCode}${formatNumber(totalCash, mainCcyNumDecimals)}`}
            icon={<Icon name={IconName.Wallet} />}
            secondaryValue={
              totalOpenCash > 0 ? `-${mainCcyCode}${formatNumber(totalOpenCash, mainCcyNumDecimals)}` : undefined
            }
            secondaryValueColor={TextColor.error}
            secondaryTooltip={`Reserved Cash Amount`}
          />
          <SummaryCard
            title="Assets Value"
            tooltip={`Total assets value in ${mainCcySymbol}`}
            value={`${mainCcyCode}${formatNumber(assetValue, mainCcyNumDecimals)}`}
            icon={<Icon name={IconName.Bids} />}
            secondaryValue={
              openAssetValue ? `-${mainCcyCode}${formatNumber(openAssetValue, mainCcyNumDecimals)}` : undefined
            }
            secondaryValueColor={TextColor.error}
            secondaryTooltip={`Reserved Asset Value`}
          />
          <SummaryCard
            title="Total Qty (tCO2)"
            tooltip={'Total quantity for all contracts in tCO2'}
            value={formatNumber(totalAssetQty, 0) ?? ''}
            icon={<Icon name={IconName.Database} />}
            secondaryValue={totalOpenAsset > 0 ? `-${formatNumber(totalOpenAsset, 0)}` ?? '' : undefined}
            secondaryValueColor={TextColor.error}
            secondaryTooltip={`Reserved Asset Quantity`}
          />
        </SummaryHolder>
      </PageSections>
      {canDeposit() ? (
        <PageSections>
          <div className="flex flex-row gap-4 w-full h-full">
            <Card className={'flex flex-col'}>
              <Text size={FontSize.large}>Deposit History</Text>
              <div className="flex flex-row gap-4 justify-start w-full">
                <Dropdown
                  config={{
                    color: 'gray',
                  }}
                  list={[
                    ...Object.entries(StatusLabel).map(([key, label]) => {
                      return {
                        id: key,
                        label,
                      };
                    }),
                  ]}
                  onSelectItem={(item) => {
                    setStatusDropdownItemForDeposit(item);
                  }}
                >
                  <div className="flex flex-row gap-2">
                    <Text size={FontSize.small} color={Colors.gray_500}>
                      Status:
                    </Text>
                    <Text size={FontSize.small}>{depositStatusDropdownItem?.label || 'All'}</Text>
                  </div>
                </Dropdown>
              </div>
              {fiatDepositHistory.resolvedData?.items && (
                <Table
                  config={{
                    sticky: {
                      left: ['id'],
                      right: [],
                    },
                    columns: {
                      id: {
                        label: '#',
                        minWidth: 50,
                      },
                      date: {
                        label: 'DATE',
                        minWidth: 300,
                      },
                      value: {
                        label: `VALUE (${mainCcySymbol})`,
                        minWidth: 150,
                      },
                      status: {
                        label: 'STATUS',
                      },
                    },
                    rows: fiatDepositHistory.resolvedData?.items.map((item, index) => {
                      const date = format(new Date(item.created_utc), 'MMM d yyyy h:mm:ss a');
                      const value = `${availableCurrencies[item.ccy_type_id]} ${formatter.formatCurrency(
                        item.amount,
                        2,
                      )}`;
                      return {
                        id: item.id.toString(),
                        date,
                        value,
                        status: item.status_code,
                        _key: item.id.toString(),
                      };
                    }),
                    loading: fiatDepositHistory.isLoading,
                    whenNoData: (
                      <Empty title="No cash deposits" description="You haven't made any cash deposits yet." />
                    ),
                  }}
                />
              )}
              <Pagination total={fiatDepositHistory.resolvedData?.total || 0} actions={fiatDepositPagination} />
            </Card>
          </div>
        </PageSections>
      ) : undefined}
      {canWithdraw() ? (
        <PageSections>
          <div className="flex flex-row gap-4 w-full h-full">
            <Card className={'flex flex-col'}>
              <Text size={FontSize.large}>Withdrawal History</Text>
              <div className="flex flex-row gap-4 justify-start w-full">
                <Dropdown
                  config={{
                    color: 'gray',
                  }}
                  list={[
                    ...Object.entries(StatusLabel).map(([key, label]) => {
                      return {
                        id: key,
                        label,
                      };
                    }),
                  ]}
                  onSelectItem={(item) => {
                    serStatusDropdownItemForWithdraw(item);
                  }}
                >
                  <div className="flex flex-row gap-2">
                    <Text size={FontSize.small} color={Colors.gray_500}>
                      Status:
                    </Text>
                    <Text size={FontSize.small}> {withdrawStatusDropdownItem?.label || 'All'}</Text>
                  </div>
                </Dropdown>
              </div>
              {fiatWithdrawHistory.resolvedData?.items && (
                <Table
                  config={{
                    sticky: {
                      left: ['id'],
                      right: [],
                    },
                    columns: {
                      id: {
                        label: '#',
                        minWidth: 50,
                      },
                      date: {
                        label: 'DATE',
                        minWidth: 300,
                      },
                      value: {
                        label: `VALUE (${mainCcySymbol})`,
                      },
                      status: {
                        label: 'STATUS',
                      },
                    },
                    rows: fiatWithdrawHistory.resolvedData?.items.map((item, index) => {
                      const date = format(new Date(item.created_utc), 'MMM d yyyy h:mm:ss a');
                      const value = `${availableCurrencies[item.ccy_type_id]} ${formatter.formatCurrency(
                        item.amount,
                        2,
                      )}`;
                      return {
                        id: item.id.toString(),
                        date,
                        value,
                        status: item.status_code,
                        _key: item.id.toString(),
                      };
                    }),
                    loading: fiatWithdrawHistory.isLoading,
                    whenNoData: (
                      <Empty title="No cash withdrawals" description="You haven't made any cash withdrawals yet." />
                    ),
                  }}
                />
              )}
              <Pagination total={fiatWithdrawHistory.resolvedData?.total || 0} actions={fiatWithdrawPagination} />
            </Card>
          </div>
        </PageSections>
      ) : undefined}
    </PageHolder>
  );
};
