import { ApiMutationError, userAuthenticatedApi } from '../mutator';

type CancelOrderResponse = {
  createdAtUtc: string;
  id: number;
  orderId: number;
  price?: number;
  processed: number;
  processedAtUtc?: string;
  qty?: number;
  requestTypeId: number;
};

export const mutateCancelOrder = async (orderId: number) => {
  const userService = await userAuthenticatedApi();
  const res = userService.url(`/oms/order/${orderId}/cancel`).post();
  return res.json<CancelOrderResponse | ApiMutationError>().catch((err) => err);
};
