import * as React from 'react';
import styled from 'styled-components';

const Button = styled.button`
  position: absolute;
  right: 1rem;
  top: 1rem;
  cursor: pointer;
`;

interface Props {
  onClick: () => void;
  className?: string;
}

const CloseXButton: React.FC<Props> = ({ onClick, className }) => {
  return (
    <Button type="button" onClick={onClick} className={className ?? ''}>
      <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24">
        <path
          fill="currentcolor"
          d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12 19 6.41z"
        />
      </svg>
    </Button>
  );
};

export default CloseXButton;
