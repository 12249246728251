import { ProjectsListFilteringOptions } from '../components/MyProjects/components/ProjectsList';
import { CarbonMetaOptionsResponse } from './CarbonMetaOptionsResponse';
import { ProjectStatus } from './ProjectStatus';

export const toProjectsListFilteringOptions = (options: CarbonMetaOptionsResponse): ProjectsListFilteringOptions => {
  return {
    statuses: [
      {
        label: 'All',
        value: 'all',
      },
      {
        label: 'Draft',
        value: ProjectStatus.Draft,
      },
      {
        label: 'Rejected',
        value: ProjectStatus.Rejected,
      },
      {
        label: 'Submitted',
        value: ProjectStatus.Submitted,
      },
      {
        label: 'Active',
        value: ProjectStatus.Active,
      },
    ],
    projectTypes: options.projectTypes.map((item) => ({
      id: item.id,
      label: item.name,
    })),
    ccbStandards: options.ccbStandards.map((item) => ({
      id: item.id,
      label: item.name,
    })),
    sectoralScopes: options.unSectoralScopes.map((item) => ({
      id: item.id,
      label: item.unSectoralScopeName,
    })),
  };
};
