import { isArray } from 'lodash';
import React, { ReactNode, useEffect, useRef, useState } from 'react';
import Popover from 'refreshed-component/atoms/Popover';
import { Colors, FontSize, FontWeight, Radius, Spacing } from 'refreshed-component/design-system';
import styled, { CSSProperties } from 'styled-components';

import { hooks } from '@aircarbon/utils-common';

import SimpleBar from './Simplebar';
import { LIGHT } from './theme';

const { useOnClickOutside } = hooks;

const TableWrapper = styled.table`
  tr > {
    > th {
      border-bottom: 0px !important;
    }
    &:first-child {
      outline: none;
    }
  }
`;

const Tr = styled.tr<{
  activeBackgroundColor?: Colors;
  hoverBackgroundColor?: Colors;
  fontSize?: FontSize;
  size?: 'default' | 'big';
  color?: Colors;
  activeColor?: Colors;
}>`
  font-size: ${(props) => `var(${props.fontSize || FontSize.small})`};
  height: 21px;
  color: ${(props) => `var(${props.color || Colors.gray_900})`};
  line-height: ${(props) => `${props.size === 'big' ? '32px' : '26px'}`};
  &:focus {
    outline: none;
  }
  &:hover {
    background-color: ${(props) =>
      props?.hoverBackgroundColor ? `var(${props?.hoverBackgroundColor})` : `var(${props?.activeBackgroundColor})`};
  }
  &.selected {
    background-color: ${(props) => `${props.activeBackgroundColor || `#222`}`};
    color: ${(props) => `${props.activeColor || LIGHT.text}`};
  }
  &.row-disabled {
    opacity: 0.5;
    background: transparent;
  }
`;

const Td = styled.td`
  &.row-disabled {
    opacity: 0.5;
    background: transparent;
  }
`;

const Selection = styled.div`
  width: 100%;
  height: auto;
  position: relative;
  left: 0;
  right: 0;
  border-radius: 0px 0px var(${Radius.medium}) var(${Radius.medium});
  display: flex;
  flex-direction: column;
  font-size: var(${FontSize.small});
`;

const OptionItem = styled.div<{ color: Colors; hoverBackground: Colors }>`
  color: var(${(props) => props.color});
  width: 100%;
  left: 0;
  right: 0;
  padding-left: var(${Spacing.small});
  padding-right: var(${Spacing.small});
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: var(${FontSize.small});

  &:hover {
    background-color: var(${(props) => props.hoverBackground});
  }
`;

const TableLabel = styled.div`
  font-weight: var(${FontWeight.semibold});
  font-size: var(${FontSize.xs});
  color: var(${Colors.gray_500});
  &:hover > div {
    opacity: 1 !important;
  }
`;

type OrderType = 'ascending' | 'descending' | 'default';

export type TableHeaderOption = {
  sort?: OrderType;
  option?: {
    list: string[];
    default?: string;
  } & ({ selected?: string[]; type: 'multiple' } | { selected?: string; type: 'single' });
  background?: string;
};

export default function Table<
  T extends {
    [index: string]: {
      label: string | JSX.Element;
      width?: string;
      style?: CSSProperties;
      headerStyle?: CSSProperties;
      align?: 'left' | 'right';
      font?: 'default' | 'code';
      _key?: string;
      skipSpecialClass?: boolean;
    };
  },
  K extends keyof T,
>({
  config,
  ...props
}: React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLTableElement>, HTMLTableElement> & {
  config: {
    size?: 'default' | 'big';
    withCustomScrollBar?: boolean;
    backgroundColor?: string;
    hoverBackgroundColor?: Colors;
    columns?: T;
    rows: ({
      [T in K]: ReactNode;
    } & {
      _key?: string;
    } & { specialClassName?: string })[];
    onClick?: (
      row: {
        [T in K]: ReactNode;
      } & {
        _key?: string;
      },
    ) => void;
    options?: {
      list: string[];
      onClick: (
        item: string,
        index: number,
        row: {
          [T in K]: ReactNode;
        } & {
          _key?: string;
        },
      ) => void;
    };
    headerOption?: {
      value: {
        [T in K]?: TableHeaderOption;
      };
      onChange: (value: {
        [T in K]?: TableHeaderOption;
      }) => void;
    };
  };
}) {
  const [headerOptionValue, setHeaderOptionValue] = useState<{
    [T in K]?: TableHeaderOption;
  }>(config.headerOption?.value || {});

  const [selected, setSelected] = useState<number>(-1);
  const [isOpen, setOpen] = useState<boolean>(false);
  const [dropdownKey, setDropdownKey] = useState<string>();

  useEffect(() => {
    if (config.headerOption?.value !== headerOptionValue && config.headerOption?.value)
      setHeaderOptionValue(config.headerOption?.value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [config.headerOption?.value]);

  useEffect(() => {
    config.headerOption?.onChange(headerOptionValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [headerOptionValue]);

  useEffect(() => {
    const onScroll = () => {
      setOpen(false);
    };
    window.addEventListener('scroll', onScroll, true);
    return () => {
      window.removeEventListener('scroll', onScroll, true);
    };
  }, []);

  const dropdownHolderRef = useRef<HTMLDivElement>(null);
  useOnClickOutside(dropdownHolderRef, () => {
    if (dropdownHolderRef) setDropdownKey(undefined);
  });

  const filters = [] as ReactNode[];
  // eslint-disable-next-line array-callback-return
  Object.entries(headerOptionValue).map(([key, value]) => {
    const item = value as any as TableHeaderOption;
    if (item.option) {
      if (isArray(item.option.selected)) {
        item.option.selected.forEach((selectedItem) => {
          filters.push(
            <div className="flex flex-row justify-center items-center gap-xs">
              <span
                style={{
                  color: `var(${Colors.gray_900})`,
                }}
              >
                <span className="flex flex-row justify-center items-center gap-xs">
                  {config.columns?.[key].label}: {selectedItem}
                </span>
              </span>
              <svg
                onClick={() => {
                  if (item.option?.selected && isArray(item.option?.selected))
                    item.option.selected = item.option.selected.filter((value) => value !== selectedItem);
                  setHeaderOptionValue({ ...headerOptionValue });
                }}
                className={'cursor-pointer'}
                width="18"
                height="18"
                viewBox="0 0 48 48"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M14 14L34 34" stroke="#848e9c" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M14 34L34 14" stroke="#848e9c" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
            </div>,
          );
        });
      } else if (item.option.selected) {
        filters.push(
          <div className="flex flex-row justify-center items-center gap-xs">
            <span
              style={{
                color: `var(${Colors.gray_900})`,
              }}
            >
              {config.columns?.[key].label}: {item.option.selected}
            </span>
            <svg
              onClick={() => {
                if (item.option) item.option.selected = undefined;
                setHeaderOptionValue({ ...headerOptionValue });
              }}
              className={'cursor-pointer'}
              width="18"
              height="18"
              viewBox="0 0 48 48"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M14 14L34 34" stroke="#848e9c" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M14 34L34 14" stroke="#848e9c" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
          </div>,
        );
      }
    }
  });

  const table = (
    <div className="flex flex-col w-full h-full">
      {!!filters.length && (
        <div className="flex flex-row items-center pb-2 border-b border-gray-500 gap-xs">
          <svg width="18" height="18" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
            <polygon
              stroke="#848e9c"
              strokeWidth="2"
              fill="none"
              fillRule="nonzero"
              strokeLinejoin="round"
              points="6 9 20.4 25.8177778 20.4 38.4444444 27.6 42 27.6 25.8177778 42 9"
            />
          </svg>
          {filters}
        </div>
      )}
      <TableWrapper cellSpacing={0} cellPadding={0} className="w-auto min-w-full" {...(props as any)}>
        {config.columns && (
          <thead>
            <tr
              style={{
                position: 'sticky',
                top: '0',
                background: config.backgroundColor || `var(${Colors.gray_0})`,
                textAlign: 'left',
                zIndex: 1,
                fontSize: config.size === 'big' ? '14px' : '12px',
              }}
            >
              {Object.entries(config.columns).map(([key, column], index, list) => {
                const isDropDownOpen = key === dropdownKey;
                const headerItem: TableHeaderOption = (headerOptionValue as any)[key];
                const order = headerItem?.sort;
                const option = headerItem?.option;
                const isRightAlign =
                  list.length - 1 === index
                    ? column.align === 'left'
                      ? false
                      : true
                    : column.align === 'right'
                    ? true
                    : false;
                const labelElement = (
                  <TableLabel
                    className={`flex flex-row cursor-pointer ${isRightAlign ? 'w-full justify-end' : 'w-min'}`}
                    onMouseDown={(event) => {
                      const dropdownValue = isDropDownOpen ? undefined : key;
                      const mouseup = () => {
                        setDropdownKey(dropdownValue);
                      };
                      const clear = () => {
                        event.target.removeEventListener('mouseup', mouseup);
                        window.removeEventListener('mouseup', clear);
                      };
                      event.target.addEventListener('mouseup', mouseup);
                      window.addEventListener('mouseup', clear);
                    }}
                  >
                    {column.label}
                    {!!option && (
                      <div className="flex flex-row justify-center items-center ml-1">
                        <svg width="9" height="6" viewBox="0 0 9 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M5.18199 4.52511L4.47488 5.23222L0.232239 0.989577L0.939346 0.282471L5.18199 4.52511Z"
                            fill={isDropDownOpen ? `var(${Colors.gray_900})` : `var(${Colors.gray_900})`}
                          />
                          <path
                            d="M4.47488 5.23222L3.76777 4.52511L8.01041 0.282472L8.71752 0.989579L4.47488 5.23222Z"
                            fill={isDropDownOpen ? `var(${Colors.gray_900})` : `var(${Colors.gray_900})`}
                          />
                        </svg>
                      </div>
                    )}
                  </TableLabel>
                );

                const finalLabelElement =
                  option || order ? (
                    <Popover
                      visible={isDropDownOpen}
                      placement={isRightAlign ? 'bottomRight' : 'bottomLeft'}
                      content={
                        <div style={{ minWidth: '150px' }} ref={isDropDownOpen ? dropdownHolderRef : undefined}>
                          <Selection>
                            {option?.default && (
                              <OptionItem
                                color={Colors.gray_900}
                                hoverBackground={Colors.gray_100}
                                key={option?.default}
                                onMouseDown={(event) => {
                                  event.preventDefault();
                                }}
                                onClick={() => {
                                  option.selected = undefined;
                                  setHeaderOptionValue({ ...headerOptionValue });
                                }}
                                className={`cursor-pointer`}
                              >
                                <div className="flex flex-row justify-center items-center min-w-full h-full">
                                  <div className="flex-1">{option?.default}</div>
                                  {option?.selected === undefined && (
                                    <svg
                                      width="18"
                                      height="18"
                                      viewBox="0 0 48 48"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M10 24L20 34L40 14"
                                        stroke={`var(${Colors.gray_900})`}
                                        strokeWidth="2"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      />
                                    </svg>
                                  )}
                                </div>
                              </OptionItem>
                            )}
                            {option?.list?.map((item: string) => {
                              const selected = (isArray(option.selected) ? option.selected : [option.selected]).filter(
                                (value) => value,
                              );
                              const isSelected = selected?.includes(item);
                              return (
                                <OptionItem
                                  color={Colors.gray_900}
                                  hoverBackground={Colors.gray_100}
                                  key={item}
                                  onMouseDown={(event) => {
                                    event.preventDefault();
                                  }}
                                  onClick={() => {
                                    if (option.type === 'single') {
                                      if (option.selected === item) {
                                        option.selected = undefined;
                                      } else {
                                        option.selected = item;
                                      }
                                    } else if (isArray(option.selected) || option.selected === undefined) {
                                      let selected = option.selected || [];
                                      if (selected.includes(item)) {
                                        selected = selected.filter((value) => item !== value);
                                      } else {
                                        selected.push(item);
                                      }
                                      option.selected = selected;
                                    }
                                    setHeaderOptionValue({ ...headerOptionValue });
                                  }}
                                  className={`cursor-pointer ${item}`}
                                >
                                  <div className="flex flex-row justify-center items-center min-w-full h-full">
                                    <div className="flex-1">{item}</div>
                                    {isSelected && (
                                      <svg
                                        width="18"
                                        height="18"
                                        viewBox="0 0 48 48"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M10 24L20 34L40 14"
                                          stroke={`var(${Colors.gray_900})`}
                                          strokeWidth="2"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        />
                                      </svg>
                                    )}
                                  </div>
                                </OptionItem>
                              );
                            })}
                          </Selection>
                        </div>
                      }
                    >
                      {labelElement}
                    </Popover>
                  ) : (
                    labelElement
                  );

                return (
                  <th
                    key={`${column._key || column.label} ${index}`}
                    style={{
                      width: column.width,
                      whiteSpace: 'pre',
                      height: '30px',
                      border: '0px',
                      color: LIGHT.text,
                      paddingRight: '15px',
                      ...column.headerStyle,
                    }}
                  >
                    {finalLabelElement}
                  </th>
                );
              })}
              {!!config.options && (
                <th
                  style={{
                    width: 28,
                  }}
                />
              )}
            </tr>
          </thead>
        )}
        <tbody>
          {config?.rows?.map((row, index) => {
            return (
              <React.Fragment key={`${row._key || index}`}>
                <Tr
                  color={Colors.gray_900}
                  activeBackgroundColor={Colors.gray_50}
                  activeColor={Colors.gray_900}
                  fontSize={config.size === 'big' ? FontSize.base : FontSize.small}
                  size={config.size}
                  className={`${selected === index && isOpen ? 'selected' : ''} ${
                    config.onClick ? 'cursor-pointer' : ''
                  } ${index % 2 ? 'even' : 'odd'}`}
                  onFocus={() => {
                    setSelected(index);
                  }}
                  onClick={() => {
                    config.onClick?.(row);
                    setOpen(!isOpen);
                  }}
                  onBlur={() => {
                    setSelected(-1);
                    setOpen(false);
                  }}
                  tabIndex={0}
                  hoverBackgroundColor={Colors.gray_50}
                >
                  {Object.entries(config.columns || {})?.map(([key, data], index, list) => {
                    const column = config.columns?.[key];
                    const isRightAlign =
                      list.length - 1 === index
                        ? column?.align === 'left'
                          ? false
                          : true
                        : column?.align === 'right'
                        ? true
                        : false;

                    return (
                      <Td
                        style={{ ...(column?.style || {}) }}
                        key={`${row._key}${index}`}
                        className={`${row?.specialClassName && !data?.skipSpecialClass ? row?.specialClassName : ''}`}
                      >
                        <div
                          style={{
                            paddingRight: `var(${Spacing.small})`,
                            whiteSpace: 'pre',
                            minWidth: 'max-content',
                          }}
                          className={`flex flex-row ${isRightAlign ? 'w-full justify-end' : ''} ${
                            column?.font === 'code' ? 'code' : column?.font
                          }`}
                        >
                          {(row as any)[key]}
                        </div>
                      </Td>
                    );
                  })}
                  {config.options?.list && (
                    <Popover
                      placement="topRight"
                      visible={selected === index && isOpen && !!config.options}
                      content={
                        <div style={{ minWidth: '150px' }}>
                          <Selection>
                            {config.options?.list.map((item) => {
                              return (
                                <OptionItem
                                  color={Colors.gray_900}
                                  hoverBackground={Colors.gray_100}
                                  key={item}
                                  onMouseDown={(event) => {
                                    event.preventDefault();
                                  }}
                                  onClick={() => {
                                    config.options?.onClick(item, index, row);
                                    setOpen(false);
                                  }}
                                  className={`cursor-pointer ${item}`}
                                >
                                  {item}
                                </OptionItem>
                              );
                            })}
                          </Selection>
                        </div>
                      }
                    >
                      <th>
                        <div className="flex justify-center items-center w-full h-full">
                          <svg width="9" height="6" viewBox="0 0 9 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M5.18199 4.52511L4.47488 5.23222L0.232239 0.989577L0.939346 0.282471L5.18199 4.52511Z"
                              fill={`var(${Colors.gray_900})`}
                            />
                            <path
                              d="M4.47488 5.23222L3.76777 4.52511L8.01041 0.282472L8.71752 0.989579L4.47488 5.23222Z"
                              fill={`var(${Colors.gray_900})`}
                            />
                          </svg>
                        </div>
                      </th>
                    </Popover>
                  )}
                </Tr>
              </React.Fragment>
            );
          })}
        </tbody>
      </TableWrapper>
    </div>
  );

  return config.withCustomScrollBar ? <SimpleBar>{table}</SimpleBar> : table;
}
