import React, { useContext } from 'react';
import {
  ContainerSize,
  ResponsiveContainerContext,
  ResponsiveContainerWrapper,
} from 'refreshed-component/atoms/ResponsiveContainer';
import { Spacing } from 'refreshed-component/design-system';
import styled from 'styled-components';

import { Layer } from '@aircarbon/ui';

import { SummaryCard } from './SummaryCard';

export const SummaryStyle = styled.div<{ size: ContainerSize }>`
  position: relative;
  width: 100%;
  gap: var(${Spacing.base});
  display: grid;

  ${({ size }) => {
    if (size === 'medium' || size === 'xs' || size === 'small') {
      return `grid-template-columns: 1fr;`;
    } else if (size === 'large') {
      return `grid-template-columns: 1fr 1fr;`;
    } else {
      return `grid-template-columns: 1fr 1fr 1fr;`;
    }
  }}
`;

export const SummaryHolder = ResponsiveContainerWrapper(
  ({ children }: { children: React.ReactElement<typeof SummaryCard> | React.ReactElement<typeof SummaryCard>[] }) => {
    const responsiveContainerContext = useContext(ResponsiveContainerContext);
    return (
      <Layer>
        <SummaryStyle size={responsiveContainerContext.size}>{children}</SummaryStyle>
      </Layer>
    );
  },
);
