import { useQuery } from 'react-query';

import { BalanceResponse } from '@aircarbon/utils-common/src/dto';

import { fetchAvailableBalance } from 'data-provider/user/fetchAvailableBalance';

function useAvailableBalance({
  params,
  options,
}: {
  params: { ccyTypeId: number; getRoot: boolean; userId?: number };
  options?: Record<string, any>;
}) {
  const endpoint = `/api/user/balance?ccyTypeId=${params.ccyTypeId}&getRootBalance=${params.getRoot ? 'yes' : 'no'}${
    params.userId ? `&userId=${params.userId}` : ''
  }`;
  const {
    data: balance,
    isLoading,
    error,
  } = useQuery<BalanceResponse>(
    [endpoint, params.userId, params.getRoot, params.ccyTypeId],
    () =>
      fetchAvailableBalance({
        getRootBalance: params.getRoot ? 'yes' : 'no',
        ccyTypeId: params.ccyTypeId,
        userId: params.userId,
      }),
    {
      ...(options ?? {}),
      refetchInterval: 30_000,
      cacheTime: 0,
    },
  );

  return {
    balance,
    isLoading,
    error,
  };
}

export default useAvailableBalance;
