import { toProjectStatus } from 'refreshed-pages/project-exchange/utils/toProjectStatus';
import { toVintagePeriod } from 'refreshed-pages/project-exchange/utils/toVintagePeriod';

import { Dto } from '@aircarbon/utils-common';

import { ProjectInformationProps } from '../../ProjectDetail/components/ProjectInformation';

interface Market {
  id: string;
  token: string;
  bid: string;
  ask: string;
  last: string;
}

export const toProjectInformation = (
  response: Dto.GetProjectApiResponse,
): ProjectInformationProps & {
  apxId: string;
  name: string;
  description: string;
  milestones: Array<[string, string, string]>;
  creditingPeriod: string;
  markets: Array<Market>;
} => {
  const project = response.data;

  let documents: ProjectInformationProps['documents'] = [];

  if (project.apxDocuments) {
    const allDocuments = project.apxDocuments?.map((document) => {
      const documentUrlPaths = document.url?.split('/');

      return {
        label: document.documentType?.name || '',
        name: document.name || '',
        url: documentUrlPaths?.length
          ? `/api/user/user/download-s3-file?filename=${encodeURI(documentUrlPaths[documentUrlPaths.length - 1])}`
          : '',
      };
    });

    documents = splitIntoTwoChunks(allDocuments);
  }

  return {
    id: '' + project.id,
    apxId: '' + project.apxId,
    name: '' + project.name,
    description: project.description ?? '',
    status: project.status?.code ? toProjectStatus(project.status?.code) : undefined,
    projectDetails: [
      [
        {
          type: 'country',
          label: 'Project Location',
          value: {
            code: project.country?.code || '',
            name: project.country?.name || '',
          },
        },
        {
          type: 'item',
          label: 'Standard Used / Registry',
          value: project.registry?.name || '-',
        },
        {
          type: 'item',
          label: 'Proposed Methodology',
          value: project.methodology
            ? [project.methodology.methodologyId, project.methodology.name].filter((v) => !!v).join(': ')
            : '-',
        },
        {
          type: 'list',
          label: 'Additioinal Certifications Co-benefits',
          value: [],
        },
        {
          type: 'item',
          label: 'Sectoral Scope',
          value: project.sectoralScope?.name || '-',
        },
      ],
      [
        {
          type: 'item',
          label: 'Project Phase',
          value: project.apxPhase?.name || '-',
        },
        {
          type: 'item',
          label: 'Registry Project ID',
          value: project.registryProjectId || '-',
        },
        {
          type: 'item',
          label: 'Project Type',
          value: project.carbonProjectType?.name || '-',
        },
        {
          type: 'list',
          label: 'Eligibility for Internal Grades',
          value: [],
        },
      ],
    ],
    sdgGoals: project.sdgGoals
      ? project.sdgGoals.map((goal) => ({
          id: '' + goal.id,
          name: goal.name,
        }))
      : [],
    proponents: project.apxProponents?.length
      ? project.apxProponents.map((proponent) => [
          [
            {
              label: 'Company Name',
              value: proponent.companyName || '-',
            },
            proponent.contactPersonName
              ? {
                  label: "Contact Person's Name (Only me can see it)",
                  value: proponent.contactPersonName,
                }
              : undefined,
            proponent.phone
              ? {
                  label: 'Phone Number (Only me can see it)',
                  value: proponent.phone,
                }
              : undefined,
          ].filter((v) => !!v) as { label: string; value: string }[],
          [
            {
              label: 'Company Involvement',
              value: proponent.relationType || '-',
            },
            proponent.contactEmails
              ? {
                  label: 'Email Address (Only me can see it)',
                  value: proponent.contactEmails,
                }
              : undefined,
          ].filter((v) => !!v) as { label: string; value: string }[],
        ])
      : [],
    milestones:
      project.vintages?.map((vintage) => [
        String(vintage.vintageYear),
        new Intl.NumberFormat('en-US', {
          maximumFractionDigits: 0,
          notation: 'compact',
        }).format(vintage.projectedCreditQuantity),
        new Intl.NumberFormat('en-US', {
          maximumFractionDigits: 0,
          notation: 'compact',
        }).format(vintage.offeredCreditQuantity),
      ]) || [],
    documents,
    markets: (project.vintages
      ?.map((vintage) => {
        const basePair = vintage.fctAsset?.basePairs?.[0];

        if (!basePair?.tokenAuditPrices?.[0]?.price) {
          return;
        }

        return {
          id: vintage.fctAsset?.symbol as string,
          token: basePair?.symbol,
          bid: '-',
          ask: '-',
          last: new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
          }).format(basePair?.tokenAuditPrices?.[0]?.price),
        };
      })
      .filter((v) => !!v) || []) as Array<Market>,
    creditingPeriod: toVintagePeriod(project.vintages),
  };
};

function splitIntoTwoChunks<T>(arr: T[]): T[][] {
  if (arr.length <= 1) {
    return [arr, []];
  }

  const middle = Math.ceil(arr.length / 2);
  const firstChunk = arr.slice(0, middle);
  const secondChunk = arr.slice(middle);

  return [firstChunk, secondChunk];
}
