import styled from 'styled-components';

import { ThemeColors } from '../trading/components/Themes';

export const InputControlWrapper = styled.div`
  .ac-input {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
`;
export const InputControlButton = styled.button<{ themeColors: ThemeColors }>`
  width: 38px;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 1.2em;
  background: ${({ themeColors }) => themeColors.dashboard.deepBackground};
  color: ${({ themeColors }) => themeColors.dashboard.color};

  &.left {
    border-right: 1px solid ${({ themeColors }) => themeColors.dashboard.deepBackground};
  }
  &.right {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }
`;

export const ContentWrapper = styled.div<{ themeColors: ThemeColors }>`
  margin: 1em;
  flex: 1;
  overflow: auto;
  padding: 1em;
  background: ${({ themeColors }) => themeColors.dashboard.block.background};
  color: ${({ themeColors }) => themeColors.dashboard.color};
  height: 70vh;
`;

export const Input = styled.input`
  border: 1px solid #ddd;
  background: white;
  padding: 12px;
  height: 38px;
  display: inline-flex;
  width: 100%;
  color: #222;
  border-radius: 5px;

  &:-internal-autofill-selected,
  &:-webkit-autofill:focus,
  &:-webkit-autofill {
    transition: background-color 600000s 0s, color 600000s 0s;
  }

  &:focus {
    outline: none;
    border-color: ${(props) => `rgba(${props.theme.brandRbgColor},0.9);`};
  }

  &::placeholder {
    font-weight: 300;
    letter-spacing: 0.5px;
    color: #828282;
  }
`;
