import { useQuery } from 'react-query';

import { fetchMarketSettings } from 'data-provider/oms/fetchMarketSettings';

function useMarketSettings({
  includeMarketStatus = false,
  options = {},
}: {
  includeMarketStatus?: boolean;
  options?: Record<string, any>;
}) {
  const {
    data: marketSettings,
    isLoading,
    error,
  } = useQuery(
    ['/api/oms/order/market-settings', includeMarketStatus],
    () => fetchMarketSettings(includeMarketStatus),
    {
      ...(options ?? {}),
      refetchOnWindowFocus: true,
    },
  );

  const isMarketClosed = () => marketSettings?.marketStatus === 'CLOSED';
  const isMarketOpen = () => marketSettings?.marketStatus === 'OPEN';

  return {
    marketSettings,
    selector: { isMarketOpen, isMarketClosed },
    isLoading,
    error,
  };
}

export default useMarketSettings;
