import { useContext, useEffect } from 'react';

import { AssetCategory } from '@aircarbon/utils-common';

import { MarketplaceProductContext } from 'providers/MarketplaceProductProvider';

export const useMarketplaceProduct = (products?: Array<AssetCategory>) => {
  const context = useContext(MarketplaceProductContext);

  useEffect(() => {
    if (!!products) {
      context.changeAvailableProducts(products);
    }
  }, [products]);

  return context;
};
