import { Fragment } from 'react';
import { Text } from 'refreshed-component/atoms/Text';
import { Colors, FontSize, FontWeight } from 'refreshed-component/design-system';
import styled from 'styled-components';

export const BiddingTable: React.FC<{
  items: Array<{
    id: string;
    token: string;
    bid: string;
    ask: string;
    last: string;
  }>;
  onPressRow(id: string): void;
}> = (props) => {
  return (
    <StyledBiddingTable>
      <Row size={FontSize.xs}>Pair</Row>
      <Row size={FontSize.xs}>Bid</Row>
      <Row size={FontSize.xs}>Ask</Row>
      <Row size={FontSize.xs}>Last</Row>
      {props.items.map((item, index) => (
        <Fragment key={index}>
          <Row onClick={() => props.onPressRow(item.id)}>{item.token}</Row>
          <Row onClick={() => props.onPressRow(item.id)} color={Colors.success_700}>
            {item.bid}
          </Row>
          <Row onClick={() => props.onPressRow(item.id)} color={Colors.danger_700}>
            {item.ask}
          </Row>
          <Row onClick={() => props.onPressRow(item.id)} color={Colors.gray_100}>
            {item.last}
          </Row>
        </Fragment>
      ))}
    </StyledBiddingTable>
  );
};

const StyledBiddingTable = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 12px;
  background: var(${Colors.gray_50});
  padding: 24px;
`;

const Row = styled(Text).attrs(({ size = FontSize.small, color = Colors.gray_500 }) => ({
  size,
  color,
  weight: FontWeight.medium,
}))<{
  size?: FontSize;
  color?: Colors;
}>`
  text-transform: uppercase;
  display: block;
  justify-self: stretch;
  text-align: center;
  &:nth-child(4n + 1),
  &:first-child {
    text-align: start;
  }
  &:nth-child(4n) {
    text-align: end;
  }
  &:hover {
    cursor: pointer;
  }
`;
