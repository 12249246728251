import Cleave from 'cleave.js/react';
import React from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';

import { formatter } from '@aircarbon/utils-common';

interface ChangeEvent<T> extends React.ChangeEvent<T> {
  target: { rawValue: string } & EventTarget & T;
}

type ChangeEventHandler<T = Element> = React.EventHandler<ChangeEvent<T>>;

export interface QuantityInputProps {
  value?: number;
  placeholder?: string;
  onChange?: ChangeEventHandler<HTMLInputElement> | undefined;
  className?: string;
  unit: string;
  suffix?: string;
  multiplier?: number;
}

const Wrapper = styled.div`
  ${tw`flex`};
  width: 100%;
  flex-direction: column;
  align-items: stretch;
  border-radius: 4px;
  background: #dedede;
  padding: 1px;
  overflow: hidden;

  input {
    outline: none;
    min-height: 50px;
    flex: 1;
    min-width: 0;
    padding: 0 1rem;
    border: none;

    &::placeholder {
      text-align: left;
      font-weight: 300;
      color: #787878 !important;
    }
  }
`;
const Suffix = styled.div`
  display: flex;
  white-space: nowrap;
  justify-content: flex-end;
  font-weight: bold;
  padding: 5px 1rem;
`;

const QuantityInput = React.forwardRef(
  ({ unit, suffix = '', value, multiplier, ...rest }: QuantityInputProps, ref: any) => {
    let finalValue = Number(`${value?.toString()}${suffix}`);
    if (multiplier) {
      finalValue = (value ?? 0) * multiplier;
    }
    return (
      <Wrapper>
        <Cleave options={{ numeral: true, numeralDecimalScale: 0 }} value={value} htmlRef={ref} {...rest} />
        <Suffix>
          {formatter.formatNumber(finalValue, 0)} {unit}
        </Suffix>
      </Wrapper>
    );
  },
);

export default QuantityInput;
